import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';
import history from '../router/history';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  {},
  applyMiddleware(routerMiddleware(history), sagaMiddleware),
);

sagaMiddleware.run(rootSaga);

export default store;
