import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import Result from '../../../../components/result';
import { paginateAreas, getAreas, queryAreasByDates } from '../../../../redux/areas/areas.actions';
import { getFishColor, mapRegion } from '../../../../utils/location';
import { fishTypeMappings } from '../../../../mappings';
import routeConstants from '../../../../constants/route.constants';
import { Button, Pagination, RangePicker } from '../../../../components/ui';
import { CloudinaryImage } from '../../../../utils/cloudinary';
import './areas.scss';
import ListIcon from '../../../../assets/List.svg';

const AreaInfo = ({
  fishTypes, region, fishingHouseAvailable,
}) => {
  const arr = [];
  if (region !== '' && region !== null) {
    arr.push(mapRegion(region));
  }
  arr.push(...Object.keys(fishTypes).filter((k) => fishTypes[k]).map((k) => fishTypeMappings[k]));
  if (fishingHouseAvailable) {
    arr.push('Veiðihús');
  }
  return (
    <div className='row-wrap jc-fs info-points'>
      {arr.map((v) => (
        <p key={v} className='small info-point'>{v}</p>
      ))}
    </div>
  );
};

AreaInfo.propTypes = {
  fishTypes: PropTypes.shape({
    bleikja: PropTypes.bool.isRequired,
    lax: PropTypes.bool.isRequired,
    sjobirtingur: PropTypes.bool.isRequired,
    urridi: PropTypes.bool.isRequired,
    hnudlax: PropTypes.bool.isRequired,
  }).isRequired,
  baitTypes: PropTypes.shape({
    fluga: PropTypes.bool.isRequired,
    madkur: PropTypes.bool.isRequired,
    spunn: PropTypes.bool.isRequired,
  }).isRequired,
  fishingHouseAvailable: PropTypes.bool.isRequired,
  region: PropTypes.string,
};

AreaInfo.defaultProps = {
  region: null,
};

const AreasList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    areas,
    getAreasPending,
    getAreasError,
    limit,
    totalResults,
    page,
    totalPages,
    dateFrom,
    dateTo,
  } = useSelector((state) => state.areas);

  const onPageChange = (newPage) => {
    dispatch(paginateAreas(newPage, limit));
    dispatch(getAreas());
    window.scrollTo(0, 0);
  };

  const onSearch = ({ startDate, endDate }) => {
    dispatch(queryAreasByDates(startDate, endDate));
    dispatch(getAreas());
  };

  // if (getAreasPending) return null;
  // if (getAreasError) return null;
  // if (areas.length === 0 && !getAreasPending) return nu
  return (
    <div className='list' style={!areas.length ? { width: '100%' } : {}}>
      <div className='row ph-1 area-date-picker'>
        <RangePicker
          onSearch={onSearch}
          initalStartDate={dateFrom}
          initialEndDate={dateTo}
          onDatesChange={(({ startDate, endDate }) => {
            dispatch(queryAreasByDates(startDate, endDate));
            dispatch(getAreas());
          })}
          title='Finna veiðisvæði'
          hideButton
        />
        <Link to='/all-areas'>
          <Button inverse className='burger-button' style={{ height: '100%' }}>
            <img src={ListIcon} alt='list' />
          </Button>
        </Link>
      </div>
      {areas.length === 0
        ? <Result.Empty title='Veiðisvæði' message='Ekkert veiddist, engin svæði fundust' buttonText='Til baka' onClick={() => history.goBack()} />
        : areas.map(({
          name,
          image: { imagePublicId },
          slug,
          fishTypes,
          fishingHouseAvailable,
          baitTypes,
          region,
        }) => (
          <Link key={slug} to={`${routeConstants.AREAS}/${slug}`}>
            <div key={slug} className='area-list-item'>
              <div className='img' style={{ backgroundImage: `url(${CloudinaryImage(imagePublicId)})` }} />
              <div className='info'>
                <div className='item-title'>

                  <div className='fish-types-wrapper'>
                    <div className='fish-types'>
                      {Object.keys(fishTypes).map((k) => (fishTypes[k] ? <div className='status' style={{ backgroundColor: getFishColor(k) }} /> : null))}
                    </div>

                  </div>
                  {/* <span style={{ fontSize: 26 }}>{name}</span> */}
                  <h2 className='black medium name'>
                    {name}
                  </h2>

                </div>
                <AreaInfo
                  fishTypes={fishTypes}
                  fishingHouseAvailable={fishingHouseAvailable}
                  baitTypes={baitTypes}
                  region={region}
                />
              </div>
            </div>
          </Link>
        ))}
      <div className='area-pagination'>
        <Pagination
          onPageChange={onPageChange}
          page={page}
          totalPages={totalPages}
          title={`Samtals: ${totalResults} veiðisvæði`}
        />
      </div>
    </div>
  );
};

export default AreasList;
