import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ArrowRight } from 'react-feather';
import {
  getAreas, sortAreas, resetParams, paginateAreas,
} from '../../../redux/areas/areas.actions';
import routeConstants from '../../../constants/route.constants';
import { Button, PageHeader } from '../../../components/ui';
import Result from '../../../components/result';
import './all-areas.scss';
import ChevronLeft from '../../../assets/ChevronLeft.svg';

const AllAreasPage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { areas, getAreasPending, getAreasError } = useSelector((state) => state.areas);

  useEffect(() => {
    dispatch(sortAreas('name:asc'));
    dispatch(paginateAreas(0, 150));
    dispatch(getAreas());
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  const handleOnClick = () => {
    history.push(routeConstants.AREAS);
  };

  const handleRetry = () => {
    dispatch(getAreas());
  };

  if (getAreasPending) return null;
  if (getAreasError) {
    return (
      <Result.Empty
        title='Villa kom upp'
        message='Gat ekki sótt veiðisvæði'
        buttonText='Reyna aftur'
        onClick={handleRetry}
      />
    );
  }
  if (areas.length === 0 && !getAreasPending) {
    return (
      <Result.Empty
        title='Engin veiðisvæði'
        message='Ekkert veiddist, fann ekki veiðisvæði'
        buttonText='Til baka'
        onClick={() => history.goBack()}
      />
    );
  }

  return (
    <div>
      <PageHeader title='Veiðisvæði'>
        <Button smaller bold inverse onClick={handleOnClick}>
          Ýtarlegri upplýsingar
        </Button>
      </PageHeader>
      {areas.map(({ name, slug }, i) => (
        <div key={slug} className={`area-link ${i % 2 === 0 && 'blue-background'}`}>
          <Link to={`${routeConstants.AREAS}/${slug}`}>
            <div className='row ph-1 jc-sb ai-c area-item'>
              <div>{name}</div>
              <img src={ChevronLeft} alt='left-arrow' />
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default AllAreasPage;
