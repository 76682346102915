export const GET_CLUBS_REQUEST = 'GET_CLUBS_REQUEST';
export const GET_CLUBS_SUCCESS = 'GET_CLUBS_SUCCESS';
export const GET_CLUBS_FAILURE = 'GET_CLUBS_FAILURE';

export const GET_CLUB_BY_SLUG_REQUEST = 'GET_CLUB_BY_SLUG_REQUEST';
export const GET_CLUB_BY_SLUG_SUCCESS = 'GET_CLUB_BY_SLUG_SUCCESS';
export const GET_CLUB_BY_SLUG_FAILURE = 'GET_CLUB_BY_SLUG_FAILURE';

export const CLEAR_CURRENT_CLUB = 'CLEAR_CURRENT_CLUB';

export const PAGINATE_CLUBS = 'PAGINATE_CLUBS';
export const SORT_CLUBS = 'SORT_CLUBS';
export const RESET_PARAMS = 'RESET_PARAMS';
