/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Result from '../../../components/result';
import { Button, HeaderBack, Select } from '../../../components/ui';
import { years } from '../../../constants/constants';
import modalConstants from '../../../constants/modal.constants';
import routeConstants from '../../../constants/route.constants';
import { baitTypeMappings, fishTypeMappings } from '../../../mappings';
import {
  clearCurrentArea,
  getLogArea,
} from '../../../redux/log-areas/log-areas.actions';
import {
  filterByFishType,
  filterBySubarea,
  filterBySubplace,
  filterByYear,
  getLogs,
  paginateLogs,
  resetParams,
  sortLogs
} from '../../../redux/logs/logs.actions';
import { setModalVisible } from '../../../redux/modal/modal.actions';
import CreateLogForm from './components/create';
import Table from './components/table';
import './logs.scss';

const getDate = (date) => moment(date).format('Do MMM YYYY');
const DEFAULT_YEAR = years[years.length - 1];
const getSelectValue = (value) => ({ name: value, label: value, id: value });
const LogsPage = (props) => {
  const [open, setOpen] = useState(false);
  const [subarea, setSubarea] = useState(null);
  const [subplace, setSubplace] = useState(null);
  const [year, setYear] = useState(getSelectValue(DEFAULT_YEAR));
  const [fishType, setFishType] = useState(getSelectValue('bleikja'));
  const [initialFilterSet, setInitialFilter] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const {
    logs, totalResults, getLogsError, totalPages,
  } = useSelector(
    (state) => state.logs,
  );
  const { area, getLogAreaError } = useSelector((state) => state.logAreas);
  const { user } = useSelector((state) => state.auth);
  const fetchIdRef = React.useRef(0);

  useEffect(() => {
    dispatch(getLogArea(id));
    dispatch(getLogs(id));
    return () => {
      dispatch(resetParams());
      dispatch(clearCurrentArea());
    };
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(filterBySubarea(subarea ? subarea.name : null));
    dispatch(getLogs(id));
  }, [subarea, dispatch, id]);

  useEffect(() => {
    dispatch(filterBySubplace(subplace ? subplace.name : null));
    dispatch(getLogs(id));
  }, [subplace, dispatch, id]);

  useEffect(() => {
    dispatch(filterByYear(year ? year.name : null));
    dispatch(getLogs(id));
  }, [year, dispatch, id]);

  useEffect(() => {
    dispatch(filterByFishType(fishType ? fishType.name : null));
    dispatch(getLogs(id));
  }, [fishType, dispatch, id]);

  const handlePaginate = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        dispatch(paginateLogs(pageIndex, pageSize));
        dispatch(getLogs(id));
      }
    },
    [dispatch, id],
  );

  const handleSort = React.useCallback(
    (sortBy) => {
      if (sortBy.length) {
        const field = sortBy[0].id;
        if (sortBy[0].desc) {
          dispatch(sortLogs(`${field}:desc`));
        } else {
          dispatch(sortLogs(`${field}:asc`));
        }
      } else {
        dispatch(sortLogs(null));
      }
      dispatch(getLogs(id));
    },
    [id, dispatch],
  );

  const handleRetry = () => {
    dispatch(getLogs());
  };

  const toggle = () => {
    if (!user) {
      dispatch(setModalVisible(modalConstants.LOGIN));
    } else if (!user.isEmailVerified) {
      dispatch(setModalVisible(modalConstants.VERIFY_EMAIL));
    } else if (!user.address || !user.phone) {
      dispatch(setModalVisible(modalConstants.COMPLETE_PROFILE));
    } else {
      setOpen(!open);
    }
  };

  const handleGoBack = () => {
    history.replace(routeConstants.LOG_AREAS);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Dags.',
        id: 'date',
        accessor: (r) => getDate(r.date),
      },
      {
        Header: 'Tegund',
        id: 'fishType',
        accessor: (r) => fishTypeMappings[r.fishType],
      },
      {
        Header: 'Þyngd (kg)',
        id: 'weight',
        accessor: (r) => {
          if (r.calculated === 'weight') {
            return `${r.weight} *`;
          }
          return r.weight;
        },
      },
      {
        Header: 'Lengd (cm)',
        id: 'length',
        accessor: (r) => {
          if (r.calculated === 'length') {
            return `${r.length} *`;
          }
          return r.length;
        },
      },
      {
        Header: 'Sleppt',
        id: 'released',
        disableSortBy: true,
        accessor: (r) => {
          if (r.released !== undefined && r.released !== null) {
            return r.released ? 'Já' : 'Nei';
          }
          return '';
        },
      },
      {
        Header: 'Undirsvæði',
        id: 'subarea.name',
        accessor: (r) => r.subarea?.name,
      },
      {
        Header: 'Veiðistaður',
        id: 'subplace.name',
        accessor: (r) => r.subplace?.name,
      },
      {
        Header: 'Agn',
        id: 'baitType',
        accessor: (r) => baitTypeMappings[r.baitType],
      },
      {
        Header: 'Heiti agns',
        id: 'baitName',
        accessor: 'baitName',
      },
      {
        Header: 'Vist',
        id: 'ecoSystem',
        accessor: 'ecoSystem',
      },
      {
        Header: 'Athugasemd',
        disableSortBy: true,
        accessor: 'comment',
      },
    ],
    [],
  );

  if (getLogsError || getLogAreaError) {
    return (
      <Result.Empty
        title='Villa kom upp'
        message='Gat ekki sótt veiðibók'
        buttonText='Reyna aftur'
        onClick={handleRetry}
      />
    );
  }

  // eslint-disable-next-line no-underscore-dangle
  const subareasOptions = area
    ? area.subareas.map((a) => ({ value: a.code, label: a.name, id: a._id }))
    : [];
  const allSubplaceOptions = [];
  if (area) {
    area.subareas.forEach((a, i) => allSubplaceOptions.push(
      ...a.subplaces.map((subP) => ({
        // eslint-disable-next-line no-underscore-dangle
        id: subP._id,
        value: subP.code,
        label: `${subP.name} - ${subP.code}`,
      })),
    ));
  }

  const getSubplacesOptions = (subA) => {
    if (subA !== null && subA !== undefined) {
      if (subA.id !== null && subA.id !== undefined) {
        if (area) {
          return area.subareas
            .find((s) => s._id === subA.id)
            .subplaces.map((s) => ({
              id: s._id,
              value: s.code,
              label: `${s.name} - ${s.code}`,
            }));
        }
      }
    }
    return allSubplaceOptions;
  };

  const getFishTypeMappings = () => Object.keys(fishTypeMappings);

  return (
    <div>
      <div className='logs-page-header'>
        <HeaderBack title={`Veiðibók ${area?.name}`} goBack={handleGoBack} />
        <Button smaller onClick={toggle}>
          {open ? 'Loka' : 'Skrá veiði'}
        </Button>
      </div>
      {open && <CreateLogForm id={id} />}
      {!open && (
        <div className='row-wrap jc-sb ai-c'>
          <div className='table-info'>
            <span className='bold'>Þyngd er skráð í kg. og lengd í cm</span>
            <br />
            <span className='smaller italic'>
              Gildi merkt eru með * eru reiknuð
            </span>
          </div>
          <div className='log-area-filters'>
            <Select
              options={years.map((y) => ({ name: y, label: y, id: y }))}
              label='Leita e. ári'
              isClearable
              value={year || null}
              onChange={(v) => {
                if (v) {
                  setYear(v);
                } else {
                  setYear('year', null);
                }
              }}
            />
            <Select
              options={getFishTypeMappings().map(getSelectValue)}
              label='Leita e. tegund'
              isClearable
              value={fishType || null}
              onChange={(v) => {
                if (v) {
                  setFishType(v);
                } else {
                  setFishType('fishType', null);
                }
              }}
            />
            <Select
              options={subareasOptions}
              label='Leita e. undirsvæði'
              isClearable
              value={
                subarea ? subareasOptions.find((c) => c.id === subarea.id) : ''
              }
              onChange={(v) => {
                if (v) {
                  setSubarea({ name: v.label, code: v.value, id: v.id });
                } else {
                  setSubarea(null);
                }
              }}
            />
            <Select
              options={getSubplacesOptions(subarea)}
              label='Leita e. veiðistað'
              isClearable
              value={
                subplace
                  ? getSubplacesOptions().find((c) => c.id === subplace.id)
                  : ''
              }
              onChange={(v) => {
                if (v) {
                  setSubplace({ name: v.label, code: v.value, id: v.id });
                } else {
                  setSubplace('subplace', null);
                }
              }}
            />
          </div>
        </div>
      )}
      <Table
        columns={columns}
        data={logs}
        fetchData={handlePaginate}
        loading={false}
        pageCount={totalPages}
        totalResults={totalResults}
        onChangeSort={handleSort}
        initialSortBy={[
          {
            id: 'date',
            desc: true,
          },
        ]}
      />
    </div>
  );
};

export default LogsPage;
