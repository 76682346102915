import * as Yup from 'yup';

const MessageSchema = Yup.object().shape({
  message: Yup
    .string()
    .max(256, 'Skilaboð mega ekki vera lengra en 256 stafir'),
});

export {
  MessageSchema,
};
