import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { getMyBookings, paginateBookings, resetParams } from '../../../redux/bookings/bookings.actions';
import { Booking } from './components';
import routeConstants from '../../../constants/route.constants';
import Result from '../../../components/result';
import { Pagination, PageHeader } from '../../../components/ui';
import { formatIsk } from '../../../utils/currency';
import './bookings.scss';

const BookingsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    bookings,
    page,
    limit,
    totalPages,
    totalResults,
    getMyBookingsPending,
    getMyBookingsError,
  } = useSelector((state) => state.bookings);

  useEffect(() => {
    dispatch(getMyBookings());
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  const handleRetry = () => {
    dispatch(getMyBookings());
  };

  const onPageChange = (newPage) => {
    dispatch(paginateBookings(newPage, limit));
    dispatch(getMyBookings());
    window.scrollTo(0, 0);
  };

  if (getMyBookingsPending) return null;
  if (getMyBookingsError) {
    return (
      <Result.Empty
        title='Villa kom upp'
        message='Gat ekki sótt bókanir'
        buttonText='Reyna aftur'
        onClick={handleRetry}
      />
    );
  }
  if (bookings.length === 0 && !getMyBookingsPending) {
    return (
      <Result.Empty
        title='Bókanir'
        message='Ekkert veiddist, engar bókanir hafa verið gerðar'
        buttonText='Skoða veiðisvæði'
        onClick={() => history.push(routeConstants.AREAS)}
      />
    );
  }

  return (
    <>
      <PageHeader title='Bókanir' />
      <div className='bookings-list'>
        {bookings.map((b, i) => (
          <Booking
            key={b.id}
            name={b.area.name}
            totalPrice={formatIsk(b.totalPrice)}
            url={`${routeConstants.BOOKINGS}/${b.orderId}`}
            createdAt={moment(b.createdAt).format('lll')}
            line={bookings.length > 1}
          />
        ))}
        <Pagination
          onPageChange={onPageChange}
          page={page}
          totalPages={totalPages}
          title={`Samtals: ${totalResults} bókanir`}
        />
      </div>
    </>
  );
};

export default BookingsPage;
