/* eslint-disable no-param-reassign */
const byString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1');
  s = s.replace(/^\./, '');
  const a = s.split('.');
  // eslint-disable-next-line no-plusplus
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  // eslint-disable-next-line consistent-return
  return o;
};

const getError = (errors, touched, key) => {
  if (!errors || !touched || !key) return '';
  const error = byString(errors, key);
  const touch = byString(touched, key);
  if (error && touch) {
    return error;
  }
  return '';
};

export {
  getError
};

