import api from './api';
import catchAsync from './error';

const getLogs = catchAsync(async (id, params) => {
  const response = await api.GET(`/fishing-logs/${id}`, params);
  return response.data;
});

const createLog = catchAsync(async (id, body) => {
  const response = await api.POST(`/fishing-logs/${id}`, body);
  return response.data;
});

export default {
  getLogs,
  createLog,
};
