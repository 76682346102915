import React from 'react';
import './permit-action-header.scss';

const PermitActionHeader = () => (
  <div className='permit-action-wrapper'>
    <div className='permit-action-inner row jc-sb ai-c mv-1 mh-1'>
      <p className='permit-header-description-container small medium primary'>Dags./ lýsing</p>
      <p className='permit-header-price-container small medium primary ta-left'>Verð</p>
      <p className='permit-header-quantity-container small medium primary ta-center'>Fjöldi</p>
    </div>
  </div>
);

export default PermitActionHeader;
