/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import './area-info.scss';
import { Link } from 'react-router-dom';
import { mapRegion } from '../../../../../utils/location';
import { fishTypeMappings } from '../../../../../mappings';
import routeConstants from '../../../../../constants/route.constants';
import { CloudinaryImage } from '../../../../../utils/cloudinary';

const AreaInfo = ({ area, selected }) => {
  if (!selected) return null;
  if (!area) return null;
  const {
    image: { imagePublicId },
    slug,
    fishTypes,
    fishingHouseAvailable,
    region,
  } = area;
  const areaInfo = () => {
    const arr = [];
    if (region !== '' && region !== null) {
      arr.push(mapRegion(region));
    }
    arr.push(...Object.keys(fishTypes).filter((k) => fishTypes[k]).map((k) => fishTypeMappings[k]));
    if (fishingHouseAvailable) {
      arr.push('Veiðihús');
    }
    return (
      <div className='row-wrap jc-fs info-points'>
        {arr.map((v) => (
          <p key={v} className='small info-point'>{v}</p>
        ))}
      </div>
    );
  };

  return (
    <Link to={`${routeConstants.AREAS}/${slug}`} target='__blank'>
      <div className='area-info-wrapper'>
        <img className='img' src={CloudinaryImage(imagePublicId)} alt='area' />
        <h2 className='black medium name'>{area.name}</h2>
        {areaInfo()}
      </div>

    </Link>
  );
};

AreaInfo.propTypes = {
  area: PropTypes.object,
  selected: PropTypes.bool,
};

AreaInfo.defaultProps = {
  area: null,
  selected: false,
};

export default AreaInfo;
