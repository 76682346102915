import {
  take, call, put, all, select,
} from 'redux-saga/effects';
import logAreasApi from '../../api/logareas';
import message from '../../services/message.service';
import * as logAreasTypes from './log-areas.types';
import { getParams } from './log-areas.selectors';

function* getLogAreas() {
  while (true) {
    yield take(logAreasTypes.GET_LOG_AREAS_REQUEST);
    try {
      const params = yield select(getParams);
      const {
        results, page, limit, totalPages, totalResults,
      } = yield call(logAreasApi.getLogsAreas, params);
      yield put({
        type: logAreasTypes.GET_LOG_AREAS_SUCCESS,
        results,
        page,
        limit,
        totalPages,
        totalResults,
      });
    } catch (error) {
      message.error(error.message);
      yield put({
        type: logAreasTypes.GET_LOG_AREAS_FAILURE,
        error,
      });
    }
  }
}

function* getLogArea() {
  while (true) {
    const { id } = yield take(logAreasTypes.GET_LOG_AREA_REQUEST);
    try {
      const area = yield call(logAreasApi.getLogArea, id);
      yield put({
        type: logAreasTypes.GET_LOG_AREA_SUCCESS,
        area,
      });
    } catch (error) {
      message.error(error.message);
      yield put({
        type: logAreasTypes.GET_LOG_AREA_FAILURE,
        error,
      });
    }
  }
}

export default function* logAreasSaga() {
  yield all([
    getLogAreas(),
    getLogArea(),
  ]);
}
