import React from 'react';
import './technical.scss';

const Technical = () => (
  <div className='technical-banner'>
    <h2>Viðgerðir í gangi !</h2>
    <br />
    <p>Veiðitorg.is kemur aftur í loftið innan skamms</p>
  </div>
);

export default Technical;
