import React from 'react';
import PropTypes from 'prop-types';
import { mapRegion } from '../../../../../utils/location';
import { fishTypeMappings } from '../../../../../mappings';
import './area-base-information.scss';

const AreaBaseInformation = ({ fishTypes, region, fishingHouseAvailable }) => {
  const arr = [];
  if (region !== '' && region !== null) {
    arr.push(mapRegion(region));
  }
  arr.push(...Object.keys(fishTypes).filter((k) => fishTypes[k]).map((k) => fishTypeMappings[k]));
  if (fishingHouseAvailable) {
    arr.push('Veiðihús');
  }
  return (
    <div className='row-wrap jc-fs area-info-points mv-05'>
      {arr.map((v) => (
        <p key={v} className='small area-info-point'>{v}</p>
      ))}
    </div>
  );
};

AreaBaseInformation.propTypes = {
  fishTypes: PropTypes.shape({
    bleikja: PropTypes.bool.isRequired,
    lax: PropTypes.bool.isRequired,
    sjobirtingur: PropTypes.bool.isRequired,
    urridi: PropTypes.bool.isRequired,
    hnudlax: PropTypes.bool.isRequired,
  }).isRequired,
  baitTypes: PropTypes.shape({
    fluga: PropTypes.bool.isRequired,
    madkur: PropTypes.bool.isRequired,
    spunn: PropTypes.bool.isRequired,
  }).isRequired,
  fishingHouseAvailable: PropTypes.bool.isRequired,
  region: PropTypes.string,
};

AreaBaseInformation.defaultProps = {
  region: null,
};

export default AreaBaseInformation;
