/* eslint-disable max-len */
import * as React from 'react';

const Empty = (props) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width='1em'
    height='1em'
    viewBox='0 0 372.45 1018.8'
  >
    <g transform='translate(-1226.786 -514.6)'>
      <path
        data-name='Path 2106'
        d='M1576 1341.7c9.8 19 31.4 53 13.1 91s-42 49.3-73.7 30.2a44.424 44.424 0 01-10.2-8.4c25.1-5.7 45.7-7.9 53.5-29.2 7.9-21.4 8.7-57.6-13.1-95.6s-48-91.5-33.2-143.7-.1-114.7-7.2-132.5c-7-17.5-44.5-109.4-94.8-186.8 6.4.7 14.1 5.2 20.3 12.1a59.868 59.868 0 013.8 4.7c1.6 2.3 4.9 7.3 9.2 14.2 22 34.9 72.4 119 84.4 157.3 14.4 45.8 28.1 76.6 8.5 134.8-19.5 58.3 29.6 132.9 39.4 151.9z'
        fill='#8f9d45'
      />
      <path
        data-name='Path 2107'
        d='M1545.7 1329.7c21.8 38 20.9 74.2 13.1 95.6s-28.4 23.6-53.5 29.2c-19.6-22.1-13.1-61.2-9.1-93.8 4.6-37.3-10.5-62.8-20.3-75.3-9.8-12.4-25.5-44.5-10.5-95.6 8.5-28.8 10.7-66 4.6-100.9-4.7-27.1-14.4-52.8-30.2-72.1-24.6-30.2-36.7-77.2-40.8-109.2-.7-5.2-1.1-10-1.4-14.3-.2-3.2-.3-6.1-.3-8.6a47.46 47.46 0 01.6-8.7c1.4-7.2 6.4-10 12.5-9.3 50.3 77.3 87.7 169.3 94.8 186.8 7.2 17.8 22.1 80.3 7.2 132.5-14.7 52.2 11.5 105.7 33.3 143.7z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 2108'
        d='M1522.4 632.5a43.659 43.659 0 01-34.2 42.6v85l-22.7-14.8V674a43.649 43.649 0 019.1-85l.5 22.9a20.8 20.8 0 107.4 0v-23a43.75 43.75 0 0139.9 43.6z'
        fill='#3d4d6c'
      />
      <path
        data-name='Path 2109'
        d='M1488.2 760.1v12l-22.8-14.8v-12.1z'
        fill='#c6d1f5'
      />
      <path
        data-name='Path 2110'
        d='M1488.2 772.1v18.7l-22.8-14.8v-18.7z'
        fill='#3d4d6c'
      />
      <path
        data-name='Path 2111'
        d='M1488.2 790.8v25.3l-22.8-14.9V776z'
        fill='#c6d1f5'
      />
      <path
        data-name='Path 2112'
        d='M1488.2 816.1v7.3a84.4 84.4 0 01-44.3 74.3c-4.3-6.9-7.6-11.8-9.2-14.2a35.436 35.436 0 00-3.8-4.7 61.64 61.64 0 0034.6-55.4v-22.1z'
        fill='#3d4d6c'
      />
      <path
        data-name='Path 2113'
        d='M1471.3 1318.1c8.5 30.1 9.8 109.3 7.9 118.5-2 9.2-19 43.9-51.1 15.1-27.5-24.7-47.4-158.1-54.5-211 4.1-19.1 3.8-40.4-1.8-62.5-11.1-43.9-5.9-72 13.8-90.4 19.6-18.3 19.2-61.3 6.5-89.2s-18.8-42.8-11.3-59.8c5.6-12.8 12.2-21.2 15.1-31.3 1.1.1 2.2.2 3.2.2 4.1 32 16.2 78.9 40.8 109.2 15.7 19.3 25.4 45 30.2 72.1-7.6 15.4-16.8 37.5-16.4 54.4.6 28.8-2.6 45.2-2.6 45.2-1.2 63 11.7 99.4 20.2 129.5z'
        fill='#8f9d45'
      />
      <path
        data-name='Path 2114'
        d='M1376.2 1041.5c1-11.6-.5-25-6.3-40.9-19.6-53.7-12.4-62-3.8-79.9s19.5-48.6 17.3-56.1c9.4 5.1 12.5 11.8 13.5 20 .3 2.7.4 5.6.5 8.7v3.5a39.315 39.315 0 01-1.5 10.6c-2.9 10.1-9.5 18.5-15.1 31.3-7.4 17-1.3 31.9 11.3 59.8 12.7 27.9 13.1 70.9-6.5 89.2s-24.9 46.5-13.8 90.4c5.6 22.1 5.9 43.5 1.8 62.5-4 18.8-12.3 35.3-24.1 48-23.6 25.5-19 81.8-9.8 103.4s17.7 64.8 11.1 83.8-13.8 52.4-62.8 54.3c-43.4 1.7-50-6.3-54.5-25.3 12.1 3.1 41 6.2 59-10.9s34.5-49.2 28.9-66.3-30.8-77.4 5-145.5 16.8-87.2 13.7-104c-3-16.8-2.6-62 8.7-79.9 8.1-12.6 25-29.2 27.4-56.7z'
        fill='#8f9d45'
      />
      <path
        data-name='Path 2115'
        d='M1376.2 1041.5c-2.4 27.4-19.3 44-27.3 56.6-11.3 17.9-11.8 63-8.7 79.9 3.1 16.8 22 36-13.7 104-35.8 68.1-10.6 128.4-5 145.5s-10.9 49.2-28.9 66.3-46.9 14-59 10.9c-.6-2.5-1.2-5.2-1.8-8-5.2-24.9-.7-43.2 23.6-76.6 24.2-33.4 32.1-91 16.4-130.9s-7.2-83.8 22.2-136.8c29.5-53 19-88.4 2.6-140.1s6-73.5 18.9-91.6c7.8-11.1 21-27.1 29-37.5.8-1.1 1.6-2.2 2.3-3.1 3.8-5.1 6.5-11.7 10.9-15.9 4.2-4.1 10-6.1 19.6-2.4a50.089 50.089 0 016.1 2.8c2.2 7.5-8.7 38.3-17.3 56.1-8.6 17.9-15.8 26.2 3.8 79.9 5.8 15.9 7.3 29.4 6.3 40.9z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 2116'
        d='M1374.3 838a52.567 52.567 0 01-13.6-2.9c-9.3-19.5-28.3-36.6-41.2-44.2.1-6.9.4-12.5.8-13.7-.1 0 38 20.2 54 60.8z'
        fill='#c6d1f5'
      />
      <path
        data-name='Path 2117'
        d='M1357.6 864.3c-4.4 4.2-7.1 10.8-10.9 15.9-.7 1-1.5 2-2.3 3.1a83.9 83.9 0 01-25-60s-.2-18.8 0-32.5c13 7.5 32 24.7 41.2 44.2-12.1-4.3-18.5-11.7-18.5-11.7a61.769 61.769 0 0015.5 41z'
        fill='#3d4d6c'
      />
      <path
        data-name='Path 2118'
        d='M1478.8 656.3a23.84 23.84 0 01-4.2-47.3 24.271 24.271 0 018.5 0 23.84 23.84 0 01-4.3 47.3zm0-41.7a16.55 16.55 0 00-3.2.3 18.033 18.033 0 106.3.1 10.129 10.129 0 00-3.1-.4z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2119'
        d='M1344.4 886.3a2.878 2.878 0 01-2.1-.9 86.734 86.734 0 01-25.9-62.1c0-.2-.2-19 0-32.5.2-12.5.7-13.9 1-14.6a3.024 3.024 0 011.7-1.8 3.114 3.114 0 012.5.1c1.6.8 39.3 21.2 55.5 62.3a3 3 0 01-.4 2.9 2.96 2.96 0 01-2.6 1.2 54.637 54.637 0 01-28.5-10.7 58.608 58.608 0 0014.4 32 2.973 2.973 0 11-4.4 4 64.587 64.587 0 01-16.3-42.9 2.975 2.975 0 015.2-2 45.554 45.554 0 0017.3 10.9 52.832 52.832 0 007.8 2.1c-13.1-28.7-37.4-46.2-46.8-52.2-.1 1.9-.2 4.7-.3 8.8-.2 13.5 0 32.2 0 32.4a81.156 81.156 0 0024.1 57.9 2.9 2.9 0 010 4.2 3.282 3.282 0 01-2.2.9z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2120'
        d='M1399.1 910.6h-.2c-1.2-.1-2.3-.2-3.4-.3a3.1 3.1 0 01-2.7-3.3 3.026 3.026 0 013.3-2.7 30.291 30.291 0 003.1.2 2.954 2.954 0 012.8 3.2 2.84 2.84 0 01-2.9 2.9z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2121'
        d='M1397.4 887.7h-.2a1.95 1.95 0 01-.8-.1 3.09 3.09 0 01-2.5-3.4 3.032 3.032 0 013.4-2.5h.2a2.954 2.954 0 012.8 3.2 2.818 2.818 0 01-2.9 2.8z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2122'
        d='M1443.9 900.6a2.883 2.883 0 01-2.6-1.6 3.068 3.068 0 011.2-4.1 81.336 81.336 0 0042.7-71.7V675a2.987 2.987 0 012.4-2.9 40.65 40.65 0 00-5.3-80.2 46.942 46.942 0 00-7.5 0 40.678 40.678 0 00-8.5 79.2 3.13 3.13 0 012.1 2.9v149.3a64.261 64.261 0 01-36.2 58.1 3 3 0 11-2.6-5.4 58.219 58.219 0 0032.9-52.7V676.2a46.638 46.638 0 0111.8-90.1 49.624 49.624 0 018.6-.1 46.691 46.691 0 018.4 91.5v145.9a87.288 87.288 0 01-45.9 76.9 4.433 4.433 0 01-1.5.3z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2123'
        d='M1482.5 615a3.1 3.1 0 01-3-2.5l-.8-4.8-.8 4.8a2.986 2.986 0 01-3.2 2.5 3.025 3.025 0 01-2.8-2.9l-.5-22.9a3.08 3.08 0 012.9-3.1 3.037 3.037 0 012.1.8 3.212 3.212 0 012.2-1 2.64 2.64 0 011.9.7 3.157 3.157 0 011.9-.7 2.946 2.946 0 013 3V612a2.971 2.971 0 01-2.8 3z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2124'
        d='M1478.8 591.8a2.946 2.946 0 01-3-3v-71.2a3 3 0 016 0v71.2a3.009 3.009 0 01-3 3z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2125'
        d='M1490.6 587.7h-23.2a3 3 0 010-6h7.9l-9.1-3.7a2.987 2.987 0 01.7-5.7l14.6-2.5-15.2-6.5a2.959 2.959 0 01-1.8-3 3.1 3.1 0 012.3-2.7l14.7-3.3-15.1-5.7a3.037 3.037 0 112.1-5.7l24.5 9.2a3.04 3.04 0 011.9 3 2.977 2.977 0 01-2.3 2.7l-15.5 3.5 16 6.8a2.959 2.959 0 011.8 3.1 3.08 3.08 0 01-2.5 2.6l-14.3 2.5 13.6 5.5a2.973 2.973 0 011.8 3.4 2.834 2.834 0 01-2.9 2.5z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2126'
        d='M1451.7 1465.9c-8.1 0-16.7-4-25.6-12-13.9-12.5-34.8-59.5-55.5-212.8a3.027 3.027 0 116-.8c15.9 118.2 34.9 192.5 53.5 209.2 10 9 19.1 12.2 27.2 9.6 11.5-3.7 17.9-18.2 18.9-23 1.9-9 .6-87.2-7.8-117-.9-3.2-1.9-6.4-2.8-9.8-8.3-28.4-18.6-63.8-17.6-120.7a.75.75 0 01.1-.5c0-.2 3.2-16.4 2.6-44.5-.4-17.5 8.9-40 16.7-55.9a3 3 0 115.4 2.6c-7.6 15.2-16.5 36.8-16.1 53 .6 27.1-2.2 43.2-2.6 45.5-1 55.8 9.2 90.6 17.3 118.6 1 3.4 1.9 6.6 2.8 9.8 8.6 30.4 9.9 110.7 7.9 119.9-1.5 7-9.1 23-22.9 27.5a21.937 21.937 0 01-7.5 1.3z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2127'
        d='M1446.4 1398.3a2.9 2.9 0 01-2.9-2.3s-9.2-38.6-26.2-110.6c-14.1-59.6-11.9-122 6.6-185.5 9.8-33.6-1.8-69.6-9.4-93.4-3-9.3-5.3-16.6-6-22a2.971 2.971 0 015.9-.7c.6 4.8 3 12.2 5.7 20.8 7.9 24.5 19.8 61.5 9.5 96.9-18.2 62.4-20.4 123.8-6.5 182.4 17 72 26.2 110.6 26.2 110.6a2.981 2.981 0 01-2.2 3.6.843.843 0 01-.7.2z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2128'
        d='M1277.3 1533.4c-33.7 0-42-8-46.7-27.9q-.9-3.75-1.8-8.1c-5.7-26.9.2-46.1 24.1-79 24.1-33.3 31.2-89.5 16-128.1-15.6-39.5-8.4-83.8 22.4-139.3 29.3-52.7 18-88.4 2.4-137.7-15.2-48 2.4-71.5 15.2-88.7 1.4-1.9 2.8-3.8 4.1-5.6 5.1-7.2 12.6-16.7 19.2-25 3.6-4.6 7.1-8.9 9.8-12.6.8-1.1 1.6-2.1 2.3-3.1a58.313 58.313 0 003.9-6.1 49.767 49.767 0 017.3-10.2c5.8-5.7 13.3-6.6 22.8-3.1 2.3.9 4.5 1.9 6.5 2.9 11.7 6.4 14.2 15.2 15 22.2a73.323 73.323 0 01.5 9v3.5a39.752 39.752 0 01-1.6 11.4c-1.7 6.1-4.8 11.6-8 17.4a158.347 158.347 0 00-7.2 14.3c-6.9 15.8-1.2 29.7 11.3 57.3 13.2 29.2 13.5 73.4-7.2 92.7-19.3 18-23.4 45.8-12.9 87.4 5.5 21.7 6.1 43.8 1.8 63.9-4.1 19.3-12.7 36.3-24.8 49.4-22 23.8-18.5 78.4-9.3 100.2 9.2 21.6 18.1 65.9 11.2 85.9-.5 1.4-.9 2.8-1.4 4.3-6.6 20-16.5 50.1-64.1 52a68.219 68.219 0 01-10.8.7zm90.8-670.5a11.288 11.288 0 00-8.3 3.5c-2.4 2.3-4.3 5.6-6.4 9-1.3 2.2-2.7 4.5-4.2 6.6-.7 1-1.5 2-2.4 3.2-2.8 3.7-6.3 8-9.9 12.7-6.5 8.3-13.9 17.6-19 24.7-1.3 1.9-2.7 3.7-4.2 5.7-12.7 17.1-28.6 38.3-14.3 83.3 16.1 50.8 27.7 87.5-2.9 142.4-29.9 53.8-36.9 96.5-22.1 134.2 15.8 40.3 8.5 99-16.7 133.8-25.3 34.9-27.6 52.4-23.1 74.2.6 2.9 1.2 5.5 1.7 8 3.9 16.3 8 24.8 51.5 23 43.4-1.7 52.2-28.4 58.7-47.9.5-1.5 1-3 1.5-4.4 6.3-18.4-2.4-61.3-11.1-81.6-9.4-22.2-14.3-79.9 10.4-106.6 11.4-12.3 19.4-28.4 23.3-46.6 4.1-19.2 3.5-40.3-1.8-61.2-11.1-44-6.5-73.6 14.6-93.3 18.5-17.3 18-59.1 5.9-85.8-12.9-28.4-19.2-44.1-11.4-62.2a155.839 155.839 0 017.5-14.8c3.2-5.8 5.9-10.7 7.5-16.2a35.544 35.544 0 001.4-9.7v-3.4a63.019 63.019 0 00-.5-8.4c-1.1-8.5-4.5-13.7-12-17.7a50.874 50.874 0 00-5.7-2.6 19.866 19.866 0 00-8-1.9z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2129'
        d='M1311.8 1003.6a3.008 3.008 0 01-2.8-2c-3.2-9.2-1.6-38.2 3.8-48.2a3.021 3.021 0 115.3 2.9c-4.5 8.4-6.1 35.7-3.4 43.4a3.013 3.013 0 01-1.8 3.8 4.482 4.482 0 01-1.1.1z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2130'
        d='M1372.9 1071.3a4.254 4.254 0 01-1.1-.2 3.08 3.08 0 01-1.8-3.9c2.1-5.5 3.2-15.3 3.1-25.6a93.6 93.6 0 00-3.2-24 2.974 2.974 0 115.7-1.7 102.438 102.438 0 013.4 25.5c.2 11.2-1.1 21.6-3.5 27.8a2.578 2.578 0 01-2.6 2.1z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2131'
        d='M1287.8 1260.7a3.019 3.019 0 01-3-2.6c-2.2-16.2-.6-39.2 10.5-55.3a3.023 3.023 0 115 3.4c-10.1 14.8-11.6 36.1-9.5 51.1a3.03 3.03 0 01-2.6 3.4z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2132'
        d='M1301.8 1491.1a2.908 2.908 0 01-2.9-2.4 2.964 2.964 0 012.4-3.5c8.9-1.8 21.2-14.3 25.6-31.4a3.044 3.044 0 115.9 1.5c-4.5 17.6-17.8 33.3-30.2 35.8a2.078 2.078 0 00-.8 0z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2133'
        d='M1543.6 1474.9c-9.2 0-19.2-3.2-29.7-9.5a47.572 47.572 0 01-10.9-9c-19.2-21.6-14.7-57.8-10.7-89.7.3-2.2.5-4.4.8-6.5 4.5-36.3-10.4-61.3-19.7-73-6.8-8.6-27.8-41.3-11-98.3 8.6-29.3 10.3-66.5 4.6-99.6-5-28.6-15.2-53.1-29.5-70.7-29.9-36.8-39.3-94-41.4-110.7-.7-5.1-1.1-10-1.4-14.5-.2-3.2-.3-6.2-.3-8.8a45.6 45.6 0 01.7-9.3c1.7-8.2 7.6-12.6 15.8-11.7 7.3.8 15.6 5.7 22.2 13a62.523 62.523 0 014 5c1.8 2.5 5.2 7.7 9.3 14.3 24.2 38.3 72.9 120.5 84.7 158 1.3 4.1 2.6 8.1 3.9 12.1 12.7 39.5 22.8 70.7 4.6 124.6-16.9 50.2 18.9 113.6 34.2 140.7 2.1 3.7 3.7 6.6 4.9 8.9.8 1.5 1.7 3.2 2.6 4.9 10.5 19.6 28 52.5 10.5 88.7-10.7 22.2-23 35.1-37.4 39.4a32.977 32.977 0 01-10.8 1.7zm-134.9-605.3c-4.2 0-6.9 2.3-7.8 7a38.4 38.4 0 00-.6 8.1c0 2.5.1 5.4.3 8.4.3 4.4.7 9.2 1.4 14.1 8.3 64.3 32.8 98.7 40.1 107.7 15 18.4 25.6 43.8 30.8 73.5 5.9 33.9 4.2 72.2-4.7 102.3-4 13.7-15.3 60.9 9.9 92.9 9.8 12.5 25.6 39.1 20.9 77.5-.3 2.1-.5 4.3-.8 6.5-3.9 30.6-8.2 65.3 9.2 84.9a42.562 42.562 0 009.5 7.8c13.5 8.1 25.1 10.5 35.7 7.3 12.8-3.8 23.8-15.7 33.7-36.3 16.2-33.5.2-63.5-10.4-83.3-.9-1.8-1.8-3.4-2.6-5-1.1-2.2-2.7-5-4.8-8.7-15.7-27.8-52.4-92.8-34.7-145.5 17.5-52 8.2-80.9-4.7-120.9-1.3-3.9-2.6-8-3.9-12.1-11.6-36.9-60.1-118.6-84.1-156.6-4.1-6.5-7.4-11.6-9.1-14-1.1-1.5-2.3-3-3.5-4.4-5.6-6.2-12.7-10.5-18.5-11.1a4.884 4.884 0 00-1.3-.1z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2134'
        d='M1572.8 1424.2h-.3a3.026 3.026 0 01-2.7-3.3c1.2-12.8-3.9-37.6-8.9-47.5a2.974 2.974 0 015.3-2.7c5.4 10.9 10.9 36.4 9.5 50.7a2.948 2.948 0 01-2.9 2.8z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2135'
        d='M1496.8 1273a2.878 2.878 0 01-2.1-.9c-1.1-1.1-2.3-2.2-3.7-3.4-9.6-8.8-22.7-20.9-13.9-47.1a3 3 0 115.7 1.9c-7.6 22.5 3 32.2 12.3 40.8 1.4 1.3 2.6 2.4 3.8 3.6a2.9 2.9 0 010 4.2 2.725 2.725 0 01-2.1.9z'
        fill='#3e3e3e'
      />
      <path
        data-name='Path 2136'
        d='M1483.4 1013.8a2.979 2.979 0 01-2.9-2.3c-5.2-21.1-20.3-48.3-25.3-54.9a2.991 2.991 0 114.7-3.7c5.4 7 20.9 34.9 26.4 57.1a2.981 2.981 0 01-2.2 3.6.843.843 0 01-.7.2z'
        fill='#3e3e3e'
      />
      <ellipse
        data-name='Ellipse 45'
        cx={12.3}
        cy={8.1}
        rx={12.3}
        ry={8.1}
        transform='rotate(-79.252 1381.005 -258.408)'
        fill='#fdbc54'
      />
      <ellipse
        data-name='Ellipse 46'
        cx={8.1}
        cy={12.3}
        rx={8.1}
        ry={12.3}
        transform='rotate(-5.536 13449.563 -14085.726)'
        fill='#fdbc54'
      />
      <ellipse
        data-name='Ellipse 47'
        cx={8.1}
        cy={12.3}
        rx={8.1}
        ry={12.3}
        transform='rotate(-5.536 15017.536 -13927.975)'
        fill='#fdbc54'
      />
      <ellipse
        data-name='Ellipse 48'
        cx={12.3}
        cy={8.1}
        rx={12.3}
        ry={8.1}
        transform='rotate(-79.252 1387.54 -312.877)'
        fill='#fdbc54'
      />
    </g>
  </svg>
);

export default Empty;
