import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '../../components/ui';
import { forgotPassword } from '../../redux/auth/auth.actions';
import { setModalVisible } from '../../redux/modal/modal.actions';
import modalConstants from '../../constants/modal.constants';
import { ForgotPasswordForm } from './components';

const LoginModal = ({ ...rest }) => {
  const dispatch = useDispatch();

  const onSubmit = ({ email }) => {
    dispatch(forgotPassword(email));
  };

  const onBackClick = () => {
    dispatch(setModalVisible(modalConstants.LOGIN));
  };

  return (
    <Modal icon='back' onIconClick={onBackClick} title='Gleymt lykilorð' {...rest}>
      <p className='mv-1'>
        Skráðu netfangið þitt og við sendum þér kóða til þess að endursetja lykilorð.
      </p>
      <ForgotPasswordForm
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default LoginModal;
