import React from 'react';
import PropTypes from 'prop-types';
import './permit-view.scss';
import { formatIsk } from '../../../utils/currency';

const permitPrice = (permit) => {
  if (permit.discountPrice) {
    return formatIsk(permit.discountPrice);
  }
  return formatIsk(permit.price);
};

const calculatePermitPrice = (permit, quantity) => {
  if (permit.discountPrice) {
    return formatIsk(permit.discountPrice * quantity);
  }
  return formatIsk(permit.price * quantity);
};

const PermitView = ({ permit, quantity }) => (
  <div className='row jc-sb ai-c mb-05 full-width permit-info'>
    <p className='permit-view-description-container smaller'>{permit.is.description}</p>
    <p className='permit-view-price-container small ta-left no-wrap'>{permitPrice(permit)}</p>
    <p className='permit-view-quantity-container smaller ta-center'>{quantity}</p>
    <p className='permit-view-total-price-container smaller ta-right'>{calculatePermitPrice(permit, quantity)}</p>
  </div>
);

PermitView.propTypes = {
  permit: PropTypes.shape({
    available: PropTypes.number.isRequired,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    discountPrice: PropTypes.number,
    id: PropTypes.string.isRequired,
    is: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
    minOrder: PropTypes.number.isRequired,
    arrangement: PropTypes.number,
    price: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    subarea: PropTypes.string,
  }).isRequired,
  quantity: PropTypes.number.isRequired,
};

export default PermitView;
