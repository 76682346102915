import React from 'react';
import { PageHeader } from '../../../components/ui';
import './privacy.scss';

const PrivacyPage = () => (
  <div className='privacy-container'>
    <PageHeader title='Persónuvernd'>
      <p className='ta-right'>Síðast uppfært: 18.02.2021</p>
    </PageHeader>
    <div className='mv-1'>
      <p>
        Persónuvernd og öryggi persónuupplýsinga eru mikilvægir þættir í starfsemi
        Veiðitorgs og við tökum þeim skyldum alvarlega.
      </p>
    </div>
  </div>
);

export default PrivacyPage;
