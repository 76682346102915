const addToCart = (cart, permit) => {
  const index = cart.findIndex((c) => c.permit === permit.id);
  const newCart = [...cart];
  const price = permit.discountPrice ? permit.discountPrice : permit.price;
  if (index !== -1) {
    // Increment.
    const newQty = newCart[index].quantity + permit.minOrder;
    if (newQty <= permit.available) {
      newCart[index] = {
        ...newCart[index],
        quantity: newQty,
        price,
        ...permit,
      };
    }
  } else {
    newCart.push({
      permit: permit.id, quantity: permit.minOrder, price, ...permit,
    });
  }
  return newCart;
};

const removeFromCart = (cart, permit) => {
  const index = cart.findIndex((c) => c.permit === permit.id);
  if (index !== -1) {
    // There is something to remove
    const newCart = [...cart];
    const newQty = newCart[index].quantity - permit.minOrder;
    if (newQty > 0) {
      newCart[index] = {
        ...newCart[index],
        quantity: newQty,
      };
    } else {
      newCart.splice(index, 1);
    }
    return newCart;
  }
  return cart;
};

export {
  addToCart,
  removeFromCart,
};
