const defaultLocation = {
  lat: 65,
  lng: -18.841548,
};

const mapLocation = (location) => ({
  lat: location.coordinates[0],
  lng: location.coordinates[1],
});

const mapRegion = (region) => {
  switch (region) {
    case 'Westfjords Region':
      return 'Vestfirðir';
    case 'Northeastern Region':
      return 'Norðurland eystra';
    case 'Northwestern Region':
      return 'Norðurland vestra';
    case 'Eastern Region':
      return 'Austurland';
    case 'Western Region':
      return 'Vesturland';
    case 'Southern Region':
      return 'Suðurland';
    case 'Capital Region':
      return 'Höfuðborgarsvæðið';
    case 'Southern Peninsula Region':
      return 'Suðurnes';
    default:
      return '';
  }
};

const getFishColor = (fish) => {
  switch (fish) {
    case 'bleikja':
      return '#EA9B72';
    case 'lax':
      return '#c6d1f5';
    case 'sjobirtingur':
      return '#A7B55C';
    case 'urridi':
      return '#D9C9B8';
    case 'hnudlax':
      return '#D9C9B8';
    default:
      return '';
  }
};

export {
  mapLocation,
  defaultLocation,
  mapRegion,
  getFishColor,
};
