/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import './summary.scss';
import { formatIsk } from '../../../utils/currency';

const Summary = ({
  discount, couponCode, toPay, cartPrice, totalWholedayDiscount, wholedayDiscountPercentage,
}) => (
  <section className='column full-width mv-1'>
    <div className='row jc-sb ai-fe'>
      <p className='small'>Samtals</p>
      <p className='small'>{formatIsk(cartPrice)}</p>
    </div>
    {(discount > 0) && (
    <div className='row jc-sb ai-fe'>
      <p className='small'>{`Afsláttarkóði (${couponCode})`}</p>
      <p className='small'>{`- ${formatIsk(discount)}`}</p>
    </div>
    )}
    {totalWholedayDiscount > 0 && (
    <>
      <div className='row jc-sb ai-fe'>
        <p className='small'>Afsláttur fyrir heilan dag</p>
        <p className='small'>{`${wholedayDiscountPercentage}%`}</p>
      </div>

      <div className='row jc-sb ai-fe'>
        <p className='small'>Samtals afsláttur fyrir heila daga</p>
        <p className='small'>{`- ${formatIsk(totalWholedayDiscount)}`}</p>
      </div>
    </>
    ) }
    <div className='row mv-1 jc-sb ai-fe'>
      <p className='medium'>Til greiðslu</p>
      <p className='medium'>{formatIsk(toPay)}</p>
    </div>
  </section>
);

Summary.propTypes = {
  discount: PropTypes.number,
  couponCode: PropTypes.string,
  toPay: PropTypes.string.isRequired,
  cartPrice: PropTypes.string.isRequired,
  totalWholedayDiscount: PropTypes.number,
  wholedayDiscountPercentage: PropTypes.number,
};

Summary.defaultProps = {
  discount: null,
  couponCode: null,
  totalWholedayDiscount: null,
  wholedayDiscountPercentage: null,
};

export default Summary;
