export default Object.freeze([
  {
    value: '12 volt',
    label: '12 volt',
  },
  {
    value: 'ABU Droppen',
    label: 'ABU Droppen',
  },
  {
    value: 'ABU Reflex Red Svartur',
    label: 'ABU Reflex Red Svartur',
  },
  {
    value: 'Adam’s dry',
    label: 'Adam’s dry',
  },
  {
    value: 'Adams',
    label: 'Adams',
  },
  {
    value: 'Alder',
    label: 'Alder',
  },
  {
    value: 'Alexandra',
    label: 'Alexandra',
  },
  {
    value: "Ally's shrimp",
    label: "Ally's shrimp",
  },
  {
    value: 'Alma Rún',
    label: 'Alma Rún',
  },
  {
    value: 'Ambrose bucktail',
    label: 'Ambrose bucktail',
  },
  {
    value: 'Amlóði',
    label: 'Amlóði',
  },
  {
    value: 'Anna Sonja',
    label: 'Anna Sonja',
  },
  {
    value: 'Argentina',
    label: 'Argentina',
  },
  {
    value: 'Ari',
    label: 'Ari',
  },
  {
    value: 'Arndilly fancy',
    label: 'Arndilly fancy',
  },
  {
    value: 'Balance Lippa Kopar',
    label: 'Balance Lippa Kopar',
  },
  {
    value: 'Barbara',
    label: 'Barbara',
  },
  {
    value: 'Barbara bleik',
    label: 'Barbara bleik',
  },
  {
    value: 'Barbara Svört',
    label: 'Barbara Svört',
  },
  {
    value: 'BBB',
    label: 'BBB',
  },
  {
    value: 'BC innerpussypink',
    label: 'BC innerpussypink',
  },
  {
    value: 'BEB',
    label: 'BEB',
  },
  {
    value: 'Beik og blá',
    label: 'Beik og blá',
  },
  {
    value: 'value',
    label: 'value',
  },
  {
    value: 'Bekri',
    label: 'Bekri',
  },
  {
    value: 'Belik og blá',
    label: 'Belik og blá',
  },
  {
    value: 'Bergþór í hylnum',
    label: 'Bergþór í hylnum',
  },
  {
    value: 'bete lotto',
    label: 'bete lotto',
  },
  {
    value: 'Beygla',
    label: 'Beygla',
  },
  {
    value: 'beykir',
    label: 'beykir',
  },
  {
    value: 'bieberinn',
    label: 'bieberinn',
  },
  {
    value: 'Big black tubefly',
    label: 'Big black tubefly',
  },
  {
    value: 'Bird',
    label: 'Bird',
  },
  {
    value: 'Birna',
    label: 'Birna',
  },
  {
    value: 'Birna , flottúba',
    label: 'Birna , flottúba',
  },
  {
    value: 'Birta',
    label: 'Birta',
  },
  {
    value: 'Birta',
    label: 'Birta',
  },
  {
    value: 'Bismo blár',
    label: 'Bismo blár',
  },
  {
    value: 'Bismo Sunray Sh.',
    label: 'Bismo Sunray Sh.',
  },
  {
    value: 'Bismó',
    label: 'Bismó',
  },
  {
    value: 'Bíberinn',
    label: 'Bíberinn',
  },
  {
    value: 'Bísmó',
    label: 'Bísmó',
  },
  {
    value: 'Bjarni Svarti',
    label: 'Bjarni Svarti',
  },
  {
    value: 'Bjartur Special, Svartur.',
    label: 'Bjartur Special, Svartur.',
  },
  {
    value: 'Bl Charm',
    label: 'Bl Charm',
  },
  {
    value: 'BL FOX',
    label: 'BL FOX',
  },
  {
    value: 'Bl. Dýrbítur',
    label: 'Bl. Dýrbítur',
  },
  {
    value: 'Bl. Ghost',
    label: 'Bl. Ghost',
  },
  {
    value: 'Bl. Nobbler',
    label: 'Bl. Nobbler',
  },
  {
    value: 'Bl.streamer',
    label: 'Bl.streamer',
  },
  {
    value: 'Bla',
    label: 'Bla',
  },
  {
    value: 'BLA SKÓÁ',
    label: 'BLA SKÓÁ',
  },
  {
    value: 'Bla Toby',
    label: 'Bla Toby',
  },
  {
    value: 'Black',
    label: 'Black',
  },
  {
    value: 'Black & Blue',
    label: 'Black & Blue',
  },
  {
    value: 'black & teal',
    label: 'black & teal',
  },
  {
    value: 'Black and blue',
    label: 'Black and blue',
  },
  {
    value: 'black and blue cone',
    label: 'black and blue cone',
  },
  {
    value: 'Black and blue keila',
    label: 'Black and blue keila',
  },
  {
    value: 'Black and blue tube',
    label: 'Black and blue tube',
  },
  {
    value: 'Black and blue, örkeila',
    label: 'Black and blue, örkeila',
  },
  {
    value: 'black ant',
    label: 'black ant',
  },
  {
    value: 'black bahran',
    label: 'black bahran',
  },
  {
    value: 'black blue',
    label: 'black blue',
  },
  {
    value: 'Black Buzzer',
    label: 'Black Buzzer',
  },
  {
    value: 'Black doctor',
    label: 'Black doctor',
  },
  {
    value: 'Black eyed prawn',
    label: 'Black eyed prawn',
  },
  {
    value: 'black ghost olive skull',
    label: 'black ghost olive skull',
  },
  {
    value: 'black ghost skull',
    label: 'black ghost skull',
  },
  {
    value: 'Black ghost sunburst',
    label: 'Black ghost sunburst',
  },
  {
    value: 'Black ghost túpa',
    label: 'Black ghost túpa',
  },
  {
    value: 'Black gnat',
    label: 'Black gnat',
  },
  {
    value: 'Black Jack',
    label: 'Black Jack',
  },
  {
    value: 'Black killer púpa',
    label: 'Black killer púpa',
  },
  {
    value: 'Black leech',
    label: 'Black leech',
  },
  {
    value: 'Black Sheep',
    label: 'Black Sheep',
  },
  {
    value: 'Black spinner',
    label: 'Black spinner',
  },
  {
    value: 'black sulu',
    label: 'black sulu',
  },
  {
    value: 'Black Teal',
    label: 'Black Teal',
  },
  {
    value: 'black zulu',
    label: 'black zulu',
  },
  {
    value: 'Blámi',
    label: 'Blámi',
  },
  {
    value: 'Blár Bismo',
    label: 'Blár Bismo',
  },
  {
    value: 'Blár dýrbítur',
    label: 'Blár dýrbítur',
  },
  {
    value: 'blár göndull',
    label: 'blár göndull',
  },
  {
    value: 'Blár kúluhaus',
    label: 'Blár kúluhaus',
  },
  {
    value: 'Blár toby',
    label: 'Blár toby',
  },
  {
    value: 'blár/bleikur Dýrbítur',
    label: 'blár/bleikur Dýrbítur',
  },
  {
    value: 'Bláskeggur',
    label: 'Bláskeggur',
  },
  {
    value: 'Bllóðormur',
    label: 'Bllóðormur',
  },
  {
    value: 'Blôðormur',
    label: 'Blôðormur',
  },
  {
    value: 'Blõðorur',
    label: 'Blõðorur',
  },
  {
    value: 'blóðdropi',
    label: 'blóðdropi',
  },
  {
    value: 'Blóðmaur',
    label: 'Blóðmaur',
  },
  {
    value: 'Blóðormur',
    label: 'Blóðormur',
  },
  {
    value: 'Blue Charm',
    label: 'Blue Charm',
  },
  {
    value: 'BLUE FOX',
    label: 'BLUE FOX',
  },
  {
    value: 'Bomber',
    label: 'Bomber',
  },
  {
    value: 'Brons Toby',
    label: 'Brons Toby',
  },
  {
    value: 'Brotna túpan',
    label: 'Brotna túpan',
  },
  {
    value: 'Brunn toby',
    label: 'Brunn toby',
  },
  {
    value: 'Brunn-toby',
    label: 'Brunn-toby',
  },
  {
    value: 'brún Frances',
    label: 'brún Frances',
  },
  {
    value: 'Brún gyðla með koparhaus',
    label: 'Brún gyðla með koparhaus',
  },
  {
    value: 'brún lirfa',
    label: 'brún lirfa',
  },
  {
    value: 'Brún með appelsínumgulum haus',
    label: 'Brún með appelsínumgulum haus',
  },
  {
    value: 'brún púba',
    label: 'brún púba',
  },
  {
    value: 'brún púpa',
    label: 'brún púpa',
  },
  {
    value: 'brún púpa',
    label: 'brún púpa',
  },
  {
    value: 'brún straumfluga',
    label: 'brún straumfluga',
  },
  {
    value: 'Brún vínil rib',
    label: 'Brún vínil rib',
  },
  {
    value: 'brúnn kúluhaus',
    label: 'brúnn kúluhaus',
  },
  {
    value: 'Brúnn toby',
    label: 'Brúnn toby',
  },
  {
    value: 'bt',
    label: 'bt',
  },
  {
    value: 'Bue ray',
    label: 'Bue ray',
  },
  {
    value: 'Burton',
    label: 'Burton',
  },
  {
    value: 'Butcher',
    label: 'Butcher',
  },
  {
    value: 'Butcher votfluga',
    label: 'Butcher votfluga',
  },
  {
    value: 'Búi',
    label: 'Búi',
  },
  {
    value: 'búri',
    label: 'búri',
  },
  {
    value: 'búri hvitur',
    label: 'búri hvitur',
  },
  {
    value: 'Caddis',
    label: 'Caddis',
  },
  {
    value: 'Calemare',
    label: 'Calemare',
  },
  {
    value: 'Cascade',
    label: 'Cascade',
  },
  {
    value: 'CDC',
    label: 'CDC',
  },
  {
    value: 'Charmer',
    label: 'Charmer',
  },
  {
    value: 'Coachman',
    label: 'Coachman',
  },
  {
    value: 'Cockno',
    label: 'Cockno',
  },
  {
    value: 'Coldplay',
    label: 'Coldplay',
  },
  {
    value: 'Collie dog',
    label: 'Collie dog',
  },
  {
    value: 'Collie Dog - Áltúba:  Langur vængur',
    label: 'Collie Dog - Áltúba:  Langur vængur',
  },
  {
    value: 'Collie dog 1/2',
    label: 'Collie dog 1/2',
  },
  {
    value: 'Collie Dog Bullet',
    label: 'Collie Dog Bullet',
  },
  {
    value: 'Collie Dog keila',
    label: 'Collie Dog keila',
  },
  {
    value: 'collie dog tuba',
    label: 'collie dog tuba',
  },
  {
    value: 'Comet',
    label: 'Comet',
  },
  {
    value: 'Cone',
    label: 'Cone',
  },
  {
    value: 'Copar',
    label: 'Copar',
  },
  {
    value: 'Copper John',
    label: 'Copper John',
  },
  {
    value: 'Corixa',
    label: 'Corixa',
  },
  {
    value: 'Corixa',
    label: 'Corixa',
  },
  {
    value: 'Crazy Goat',
    label: 'Crazy Goat',
  },
  {
    value: 'Daddy long legs',
    label: 'Daddy long legs',
  },
  {
    value: 'Dam-spinner',
    label: 'Dam-spinner',
  },
  {
    value: 'Dansarinn',
    label: 'Dansarinn',
  },
  {
    value: 'Dentis',
    label: 'Dentis',
  },
  {
    value: 'Dentist',
    label: 'Dentist',
  },
  {
    value: 'Dentist',
    label: 'Dentist',
  },
  {
    value: 'Dentist túpa',
    label: 'Dentist túpa',
  },
  {
    value: 'Devon',
    label: 'Devon',
  },
  {
    value: 'Devon blár',
    label: 'Devon blár',
  },
  {
    value: 'Dimmblá',
    label: 'Dimmblá',
  },
  {
    value: 'Dimmblá keila',
    label: 'Dimmblá keila',
  },
  {
    value: 'Disco',
    label: 'Disco',
  },
  {
    value: 'Dírbítur black',
    label: 'Dírbítur black',
  },
  {
    value: 'Dírbítur bleikur',
    label: 'Dírbítur bleikur',
  },
  {
    value: 'Dírbítur orange',
    label: 'Dírbítur orange',
  },
  {
    value: 'Dírbítur red',
    label: 'Dírbítur red',
  },
  {
    value: 'Dómhildur',
    label: 'Dómhildur',
  },
  {
    value: 'Dreamfly',
    label: 'Dreamfly',
  },
  {
    value: 'dropi',
    label: 'dropi',
  },
  {
    value: 'Droppen spinner',
    label: 'Droppen spinner',
  },
  {
    value: 'drukkin rektor',
    label: 'drukkin rektor',
  },
  {
    value: 'Dryfly',
    label: 'Dryfly',
  },
  {
    value: 'Duster',
    label: 'Duster',
  },
  {
    value: 'Dýrbítur blár',
    label: 'Dýrbítur blár',
  },
  {
    value: 'Dýrbítur Bleikur',
    label: 'Dýrbítur Bleikur',
  },
  {
    value: 'Dýrbítur hvítu',
    label: 'Dýrbítur hvítu',
  },
  {
    value: 'Dýrbítur hvítur',
    label: 'Dýrbítur hvítur',
  },
  {
    value: 'Dýrbítur orange',
    label: 'Dýrbítur orange',
  },
  {
    value: 'Dýrbítur svartur',
    label: 'Dýrbítur svartur',
  },
  {
    value: 'Dýrbítur svört',
    label: 'Dýrbítur svört',
  },
  {
    value: 'Dýrbýtur bleikur',
    label: 'Dýrbýtur bleikur',
  },
  {
    value: 'Dökkur kúluhaus',
    label: 'Dökkur kúluhaus',
  },
  {
    value: 'Early Gray',
    label: 'Early Gray',
  },
  {
    value: 'Einhver með bleiku',
    label: 'Einhver með bleiku',
  },
  {
    value: 'eitthvað skærgrænt, straumfluga :)',
    label: 'eitthvað skærgrænt, straumfluga :)',
  },
  {
    value: 'el Toro',
    label: 'el Toro',
  },
  {
    value: 'Elli Sprelli',
    label: 'Elli Sprelli',
  },
  {
    value: 'Europe',
    label: 'Europe',
  },
  {
    value: 'Eva',
    label: 'Eva',
  },
  {
    value: 'Evening Dress',
    label: 'Evening Dress',
  },
  {
    value: 'Evrópa',
    label: 'Evrópa',
  },
  {
    value: 'F mus',
    label: 'F mus',
  },
  {
    value: 'Fancis rauð',
    label: 'Fancis rauð',
  },
  {
    value: 'Fesant tail',
    label: 'Fesant tail',
  },
  {
    value: 'Fiery brown',
    label: 'Fiery brown',
  },
  {
    value: 'Finland',
    label: 'Finland',
  },
  {
    value: 'Finnsk Lippa',
    label: 'Finnsk Lippa',
  },
  {
    value: 'Finnsk lippa bleik',
    label: 'Finnsk lippa bleik',
  },
  {
    value: 'Finnsk lyppa',
    label: 'Finnsk lyppa',
  },
  {
    value: 'finnsk sonnetta',
    label: 'finnsk sonnetta',
  },
  {
    value: 'Finnska lippan',
    label: 'Finnska lippan',
  },
  {
    value: 'Finnska lyppan',
    label: 'Finnska lyppan',
  },
  {
    value: 'Finnski',
    label: 'Finnski',
  },
  {
    value: 'finnski lippa',
    label: 'finnski lippa',
  },
  {
    value: 'Finnskur',
    label: 'Finnskur',
  },
  {
    value: 'Finnskur spinner',
    label: 'Finnskur spinner',
  },
  {
    value: 'Finnskur spinner bleikur',
    label: 'Finnskur spinner bleikur',
  },
  {
    value: 'Finnskur spinner. Bleik lippa.',
    label: 'Finnskur spinner. Bleik lippa.',
  },
  {
    value: 'Finnskur sponn',
    label: 'Finnskur sponn',
  },
  {
    value: 'Finsk lipa',
    label: 'Finsk lipa',
  },
  {
    value: 'Finsk Lippa',
    label: 'Finsk Lippa',
  },
  {
    value: 'Finsk Lippa rauð',
    label: 'Finsk Lippa rauð',
  },
  {
    value: 'Finsk lypa',
    label: 'Finsk lypa',
  },
  {
    value: 'Finski fjöldamorðinginn',
    label: 'Finski fjöldamorðinginn',
  },
  {
    value: 'Finski m/rauðu',
    label: 'Finski m/rauðu',
  },
  {
    value: 'fishskull',
    label: 'fishskull',
  },
  {
    value: 'Fishskull túba',
    label: 'Fishskull túba',
  },
  {
    value: 'Fjóla',
    label: 'Fjóla',
  },
  {
    value: 'Fjóla',
    label: 'Fjóla',
  },
  {
    value: 'Flatterstick',
    label: 'Flatterstick',
  },
  {
    value: 'Flæðarmús',
    label: 'Flæðarmús',
  },
  {
    value: 'Foam fluga',
    label: 'Foam fluga',
  },
  {
    value: 'Fox fly',
    label: 'Fox fly',
  },
  {
    value: 'Fox fly Hitched',
    label: 'Fox fly Hitched',
  },
  {
    value: 'Frances black',
    label: 'Frances black',
  },
  {
    value: 'Frances blá',
    label: 'Frances blá',
  },
  {
    value: 'Frances Cone',
    label: 'Frances Cone',
  },
  {
    value: 'Frances D.E.',
    label: 'Frances D.E.',
  },
  {
    value: 'Frances keila',
    label: 'Frances keila',
  },
  {
    value: 'Frances Orange',
    label: 'Frances Orange',
  },
  {
    value: 'Frances R',
    label: 'Frances R',
  },
  {
    value: 'Frances R cone',
    label: 'Frances R cone',
  },
  {
    value: 'frances R keila',
    label: 'frances R keila',
  },
  {
    value: 'Frances R kúluhaus',
    label: 'Frances R kúluhaus',
  },
  {
    value: 'Frances rauð',
    label: 'Frances rauð',
  },
  {
    value: 'Frances rauð',
    label: 'Frances rauð',
  },
  {
    value: 'Frances rauð DE',
    label: 'Frances rauð DE',
  },
  {
    value: 'Frances rauð keila',
    label: 'Frances rauð keila',
  },
  {
    value: 'frances rauð keilutuba',
    label: 'frances rauð keilutuba',
  },
  {
    value: 'frances rauð keilutúba',
    label: 'frances rauð keilutúba',
  },
  {
    value: 'Frances rauð micro kónn',
    label: 'Frances rauð micro kónn',
  },
  {
    value: 'Frances rauð tuba',
    label: 'Frances rauð tuba',
  },
  {
    value: 'Frances red',
    label: 'Frances red',
  },
  {
    value: 'Frances svört',
    label: 'Frances svört',
  },
  {
    value: 'Frances Svört keila',
    label: 'Frances Svört keila',
  },
  {
    value: 'Frances svört micro cone',
    label: 'Frances svört micro cone',
  },
  {
    value: 'Frances túba',
    label: 'Frances túba',
  },
  {
    value: 'Frances þríkrækja',
    label: 'Frances þríkrækja',
  },
  {
    value: 'Frances, rauð',
    label: 'Frances, rauð',
  },
  {
    value: 'Franken Snælda',
    label: 'Franken Snælda',
  },
  {
    value: 'freddi',
    label: 'freddi',
  },
  {
    value: 'Friggi',
    label: 'Friggi',
  },
  {
    value: 'Friggi',
    label: 'Friggi',
  },
  {
    value: 'Friggi orance',
    label: 'Friggi orance',
  },
  {
    value: 'Friggi rauð',
    label: 'Friggi rauð',
  },
  {
    value: 'Friggi rauður',
    label: 'Friggi rauður',
  },
  {
    value: 'Frikki',
    label: 'Frikki',
  },
  {
    value: 'Frikki R',
    label: 'Frikki R',
  },
  {
    value: 'frikki rauður',
    label: 'frikki rauður',
  },
  {
    value: 'frnaces',
    label: 'frnaces',
  },
  {
    value: 'Frú. Ormur',
    label: 'Frú. Ormur',
  },
  {
    value: 'fry',
    label: 'fry',
  },
  {
    value: 'galdra læknirinn',
    label: 'galdra læknirinn',
  },
  {
    value: 'Galdralopp',
    label: 'Galdralopp',
  },
  {
    value: 'Galdralöpp',
    label: 'Galdralöpp',
  },
  {
    value: 'Gander',
    label: 'Gander',
  },
  {
    value: 'Garpur',
    label: 'Garpur',
  },
  {
    value: 'Garry',
    label: 'Garry',
  },
  {
    value: 'Gassi',
    label: 'Gassi',
  },
  {
    value: 'Gártutúba',
    label: 'Gártutúba',
  },
  {
    value: 'Gárutúba',
    label: 'Gárutúba',
  },
  {
    value: 'Gárutúpa',
    label: 'Gárutúpa',
  },
  {
    value: 'Gáryfluga',
    label: 'Gáryfluga',
  },
  {
    value: 'Geit',
    label: 'Geit',
  },
  {
    value: 'general practioner',
    label: 'general practioner',
  },
  {
    value: 'Ghost',
    label: 'Ghost',
  },
  {
    value: 'Ghost Black',
    label: 'Ghost Black',
  },
  {
    value: 'Ghost red',
    label: 'Ghost red',
  },
  {
    value: 'Gjöf',
    label: 'Gjöf',
  },
  {
    value: 'Glóð',
    label: 'Glóð',
  },
  {
    value: 'Glóð kúlupúpa',
    label: 'Glóð kúlupúpa',
  },
  {
    value: 'Glóðin',
    label: 'Glóðin',
  },
  {
    value: 'Glóðin',
    label: 'Glóðin',
  },
  {
    value: 'Glóðin Púpa',
    label: 'Glóðin Púpa',
  },
  {
    value: 'glóðin rauð',
    label: 'glóðin rauð',
  },
  {
    value: 'glóðin rauð',
    label: 'glóðin rauð',
  },
  {
    value: 'Glóðin, 12',
    label: 'Glóðin, 12',
  },
  {
    value: 'Glóðin, bleik',
    label: 'Glóðin, bleik',
  },
  {
    value: 'Glóðin, gyllt',
    label: 'Glóðin, gyllt',
  },
  {
    value: 'Glóðin, rauð',
    label: 'Glóðin, rauð',
  },
  {
    value: 'Glóðinrauð',
    label: 'Glóðinrauð',
  },
  {
    value: 'Glóðin-rauð',
    label: 'Glóðin-rauð',
  },
  {
    value: 'Glókollur',
    label: 'Glókollur',
  },
  {
    value: 'Glödhäck',
    label: 'Glödhäck',
  },
  {
    value: 'Golden Bullet',
    label: 'Golden Bullet',
  },
  {
    value: 'gr. púpa',
    label: 'gr. púpa',
  },
  {
    value: 'gr. toby',
    label: 'gr. toby',
  },
  {
    value: 'Gr.Highlander',
    label: 'Gr.Highlander',
  },
  {
    value: 'Gray ghoast',
    label: 'Gray ghoast',
  },
  {
    value: 'Gray ghost',
    label: 'Gray ghost',
  },
  {
    value: 'gray goast',
    label: 'gray goast',
  },
  {
    value: 'gray gohst',
    label: 'gray gohst',
  },
  {
    value: 'gray gost',
    label: 'gray gost',
  },
  {
    value: 'grayghost',
    label: 'grayghost',
  },
  {
    value: 'Grá Flæðarmús',
    label: 'Grá Flæðarmús',
  },
  {
    value: 'grá rækja',
    label: 'grá rækja',
  },
  {
    value: 'Green Braham',
    label: 'Green Braham',
  },
  {
    value: 'Green brahan',
    label: 'Green brahan',
  },
  {
    value: 'Green Brahan keila',
    label: 'Green Brahan keila',
  },
  {
    value: 'Green But',
    label: 'Green But',
  },
  {
    value: 'Green But keila',
    label: 'Green But keila',
  },
  {
    value: 'Green but túpa',
    label: 'Green but túpa',
  },
  {
    value: 'Green butt',
    label: 'Green butt',
  },
  {
    value: 'green butt túba',
    label: 'green butt túba',
  },
  {
    value: 'Green Highlander',
    label: 'Green Highlander',
  },
  {
    value: 'Green tag',
    label: 'Green tag',
  },
  {
    value: 'GreenBrahan',
    label: 'GreenBrahan',
  },
  {
    value: 'Greenbut',
    label: 'Greenbut',
  },
  {
    value: 'grettir',
    label: 'grettir',
  },
  {
    value: 'Grey  Ghost',
    label: 'Grey  Ghost',
  },
  {
    value: 'Grey ghost',
    label: 'Grey ghost',
  },
  {
    value: 'Grey Ghost',
    label: 'Grey Ghost',
  },
  {
    value: 'Grey gost',
    label: 'Grey gost',
  },
  {
    value: 'Grey leech',
    label: 'Grey leech',
  },
  {
    value: 'Griffins Gnat',
    label: 'Griffins Gnat',
  },
  {
    value: 'Gringo',
    label: 'Gringo',
  },
  {
    value: 'Gríma-rauð 12 (þríkrækja)',
    label: 'Gríma-rauð 12 (þríkrækja)',
  },
  {
    value: 'Grýla',
    label: 'Grýla',
  },
  {
    value: 'græn og svört túba',
    label: 'græn og svört túba',
  },
  {
    value: 'Græn Stirða',
    label: 'Græn Stirða',
  },
  {
    value: 'Grænhöfði',
    label: 'Grænhöfði',
  },
  {
    value: 'grænleit og silfruð með augu',
    label: 'grænleit og silfruð með augu',
  },
  {
    value: 'Grænn Francis',
    label: 'Grænn Francis',
  },
  {
    value: 'Grænn Moli',
    label: 'Grænn Moli',
  },
  {
    value: 'Grænn Nobbler',
    label: 'Grænn Nobbler',
  },
  {
    value: 'Grænn Nobbler',
    label: 'Grænn Nobbler',
  },
  {
    value: 'Grænn Squirmy',
    label: 'Grænn Squirmy',
  },
  {
    value: 'Grænn toby',
    label: 'Grænn toby',
  },
  {
    value: 'Grænn Toby 20 gr',
    label: 'Grænn Toby 20 gr',
  },
  {
    value: 'Grænn Toby 20 gr.',
    label: 'Grænn Toby 20 gr.',
  },
  {
    value: 'GSG',
    label: 'GSG',
  },
  {
    value: 'GSG túba',
    label: 'GSG túba',
  },
  {
    value: 'gudmann',
    label: 'gudmann',
  },
  {
    value: 'Guðmann',
    label: 'Guðmann',
  },
  {
    value: 'Guðmann',
    label: 'Guðmann',
  },
  {
    value: 'Gugga',
    label: 'Gugga',
  },
  {
    value: 'Gul',
    label: 'Gul',
  },
  {
    value: 'gul frances',
    label: 'gul frances',
  },
  {
    value: 'Gul snælda',
    label: 'Gul snælda',
  },
  {
    value: 'Gul straumfluga',
    label: 'Gul straumfluga',
  },
  {
    value: 'gula hættan',
    label: 'gula hættan',
  },
  {
    value: 'Gula hættan',
    label: 'Gula hættan',
  },
  {
    value: 'gulleit fluga ??',
    label: 'gulleit fluga ??',
  },
  {
    value: 'Gullin m. kúluhaus',
    label: 'Gullin m. kúluhaus',
  },
  {
    value: 'Gulljó',
    label: 'Gulljó',
  },
  {
    value: 'Gullna Hliðið',
    label: 'Gullna Hliðið',
  },
  {
    value: 'Gulltoppur',
    label: 'Gulltoppur',
  },
  {
    value: 'Gulur mais',
    label: 'Gulur mais',
  },
  {
    value: 'Gulur nobbler',
    label: 'Gulur nobbler',
  },
  {
    value: 'Gulur Peacok',
    label: 'Gulur Peacok',
  },
  {
    value: 'Gulur spinner',
    label: 'Gulur spinner',
  },
  {
    value: 'gulur streamer',
    label: 'gulur streamer',
  },
  {
    value: 'Gúmí spinner',
    label: 'Gúmí spinner',
  },
  {
    value: 'Gylfi belgur',
    label: 'Gylfi belgur',
  },
  {
    value: 'Gyllt  púpa',
    label: 'Gyllt  púpa',
  },
  {
    value: 'gylltur kúluhaus',
    label: 'gylltur kúluhaus',
  },
  {
    value: 'Gylltur toby',
    label: 'Gylltur toby',
  },
  {
    value: 'Gyltur og rauður hammer',
    label: 'Gyltur og rauður hammer',
  },
  {
    value: 'Gæran',
    label: 'Gæran',
  },
  {
    value: 'Göngustaðir',
    label: 'Göngustaðir',
  },
  {
    value: 'Hadda Padda nr 10',
    label: 'Hadda Padda nr 10',
  },
  {
    value: 'Hairy Mary',
    label: 'Hairy Mary',
  },
  {
    value: 'Hairy Mary hitch',
    label: 'Hairy Mary hitch',
  },
  {
    value: 'hairy mary red',
    label: 'hairy mary red',
  },
  {
    value: 'halldóra',
    label: 'halldóra',
  },
  {
    value: 'Haltur Armstrong',
    label: 'Haltur Armstrong',
  },
  {
    value: 'Hammer',
    label: 'Hammer',
  },
  {
    value: 'Hammer neongulur',
    label: 'Hammer neongulur',
  },
  {
    value: 'Hammer orance',
    label: 'Hammer orance',
  },
  {
    value: 'Handtekinn',
    label: 'Handtekinn',
  },
  {
    value: 'Hannes á náttfötum',
    label: 'Hannes á náttfötum',
  },
  {
    value: 'Hannes Boy',
    label: 'Hannes Boy',
  },
  {
    value: 'Harpa',
    label: 'Harpa',
  },
  {
    value: 'Hary Mary',
    label: 'Hary Mary',
  },
  {
    value: 'Haugur',
    label: 'Haugur',
  },
  {
    value: 'Haugur hitch',
    label: 'Haugur hitch',
  },
  {
    value: 'Haugur micro',
    label: 'Haugur micro',
  },
  {
    value: 'Haugurinn',
    label: 'Haugurinn',
  },
  {
    value: 'Hauskúpa',
    label: 'Hauskúpa',
  },
  {
    value: 'Hábrækjan',
    label: 'Hábrækjan',
  },
  {
    value: 'Hálendingur',
    label: 'Hálendingur',
  },
  {
    value: 'Hears ear',
    label: 'Hears ear',
  },
  {
    value: 'Hears ear nymph',
    label: 'Hears ear nymph',
  },
  {
    value: 'Heiðbjört',
    label: 'Heiðbjört',
  },
  {
    value: 'Heiðdís',
    label: 'Heiðdís',
  },
  {
    value: 'Heima tilbúinn',
    label: 'Heima tilbúinn',
  },
  {
    value: 'heimageð',
    label: 'heimageð',
  },
  {
    value: 'heimagerð',
    label: 'heimagerð',
  },
  {
    value: 'Heimagerð straumfluga',
    label: 'Heimagerð straumfluga',
  },
  {
    value: 'Heimagerð straumfluga bleikur Ísi',
    label: 'Heimagerð straumfluga bleikur Ísi',
  },
  {
    value: 'Heimagerð Straumfluga svört og silfur langt skott',
    label: 'Heimagerð Straumfluga svört og silfur langt skott',
  },
  {
    value: 'Heimagerðstraumfluga',
    label: 'Heimagerðstraumfluga',
  },
  {
    value: 'heimagerður kúluhaus',
    label: 'heimagerður kúluhaus',
  },
  {
    value: 'Heimasaeta',
    label: 'Heimasaeta',
  },
  {
    value: 'Heimasaeti',
    label: 'Heimasaeti',
  },
  {
    value: 'Heimasaeti',
    label: 'Heimasaeti',
  },
  {
    value: 'Heimasaumuð',
    label: 'Heimasaumuð',
  },
  {
    value: 'heimasmíðuð',
    label: 'heimasmíðuð',
  },
  {
    value: 'Heimasæta',
    label: 'Heimasæta',
  },
  {
    value: 'Heimasæta',
    label: 'Heimasæta',
  },
  {
    value: 'Heimasæta 10',
    label: 'Heimasæta 10',
  },
  {
    value: 'heimasæta kúluhaus',
    label: 'heimasæta kúluhaus',
  },
  {
    value: 'Heimasæta kúlúhaus',
    label: 'Heimasæta kúlúhaus',
  },
  {
    value: 'Heimasæta nr. 8',
    label: 'Heimasæta nr. 8',
  },
  {
    value: 'heimasætan',
    label: 'heimasætan',
  },
  {
    value: 'Heimasætan',
    label: 'Heimasætan',
  },
  {
    value: 'Heimasætan straumfluga',
    label: 'Heimasætan straumfluga',
  },
  {
    value: 'Heimasæti',
    label: 'Heimasæti',
  },
  {
    value: 'Heimasæts',
    label: 'Heimasæts',
  },
  {
    value: 'Heimasætupúpa',
    label: 'Heimasætupúpa',
  },
  {
    value: 'Heimatilbúinn',
    label: 'Heimatilbúinn',
  },
  {
    value: 'Heimsætan',
    label: 'Heimsætan',
  },
  {
    value: 'Heitir nú Hörgá AAA+',
    label: 'Heitir nú Hörgá AAA+',
  },
  {
    value: 'Hemasæta',
    label: 'Hemasæta',
  },
  {
    value: 'hendrix',
    label: 'hendrix',
  },
  {
    value: 'heraeyra',
    label: 'heraeyra',
  },
  {
    value: 'Herdís',
    label: 'Herdís',
  },
  {
    value: 'Héraeyra',
    label: 'Héraeyra',
  },
  {
    value: 'Hérareyra',
    label: 'Hérareyra',
  },
  {
    value: 'Héri',
    label: 'Héri',
  },
  {
    value: 'Hérinn',
    label: 'Hérinn',
  },
  {
    value: 'Highlander keila',
    label: 'Highlander keila',
  },
  {
    value: 'Hillaray',
    label: 'Hillaray',
  },
  {
    value: 'Himagerðstraumfluga',
    label: 'Himagerðstraumfluga',
  },
  {
    value: 'Himasæta',
    label: 'Himasæta',
  },
  {
    value: 'Hitch',
    label: 'Hitch',
  },
  {
    value: 'Hitch blá/svört',
    label: 'Hitch blá/svört',
  },
  {
    value: 'Hitch Gul og svört',
    label: 'Hitch Gul og svört',
  },
  {
    value: 'Hitch Svört og Gul',
    label: 'Hitch Svört og Gul',
  },
  {
    value: 'Hits',
    label: 'Hits',
  },
  {
    value: 'Hlussan app. ( heimagerð )',
    label: 'Hlussan app. ( heimagerð )',
  },
  {
    value: 'Hommi',
    label: 'Hommi',
  },
  {
    value: 'Homminn',
    label: 'Homminn',
  },
  {
    value: 'Hopper',
    label: 'Hopper',
  },
  {
    value: 'Horga  Fly',
    label: 'Horga  Fly',
  },
  {
    value: 'Horga Fly',
    label: 'Horga Fly',
  },
  {
    value: 'Hófi',
    label: 'Hófi',
  },
  {
    value: 'Hólmfíður',
    label: 'Hólmfíður',
  },
  {
    value: 'Hólmfríður',
    label: 'Hólmfríður',
  },
  {
    value: 'Hómfríður Púpa',
    label: 'Hómfríður Púpa',
  },
  {
    value: 'Hr. Ormur',
    label: 'Hr. Ormur',
  },
  {
    value: 'Hrafna',
    label: 'Hrafna',
  },
  {
    value: 'Hrafna',
    label: 'Hrafna',
  },
  {
    value: 'Hrafna     no 12',
    label: 'Hrafna     no 12',
  },
  {
    value: 'Hrafna    no 12',
    label: 'Hrafna    no 12',
  },
  {
    value: 'Hrafna    no12',
    label: 'Hrafna    no12',
  },
  {
    value: 'Hrafna púpa nr 1',
    label: 'Hrafna púpa nr 1',
  },
  {
    value: 'Hrefna',
    label: 'Hrefna',
  },
  {
    value: 'Hugget',
    label: 'Hugget',
  },
  {
    value: 'hv',
    label: 'hv',
  },
  {
    value: 'Hv Nobbler',
    label: 'Hv Nobbler',
  },
  {
    value: 'Hv wolly',
    label: 'Hv wolly',
  },
  {
    value: 'hv. nobbler',
    label: 'hv. nobbler',
  },
  {
    value: 'hv.nobbler',
    label: 'hv.nobbler',
  },
  {
    value: 'Hv.nobler',
    label: 'Hv.nobler',
  },
  {
    value: 'hvit stirða',
    label: 'hvit stirða',
  },
  {
    value: 'Hvít Krafla',
    label: 'Hvít Krafla',
  },
  {
    value: 'Hvít kúpa',
    label: 'Hvít kúpa',
  },
  {
    value: 'hvít og blá',
    label: 'hvít og blá',
  },
  {
    value: 'Hvít og rauð',
    label: 'Hvít og rauð',
  },
  {
    value: 'Hvít og rauð krafla',
    label: 'Hvít og rauð krafla',
  },
  {
    value: 'hvít púpa',
    label: 'hvít púpa',
  },
  {
    value: 'hvít stirða',
    label: 'hvít stirða',
  },
  {
    value: 'Hvíta Perlan',
    label: 'Hvíta Perlan',
  },
  {
    value: 'Hvíta perlan nr 12',
    label: 'Hvíta perlan nr 12',
  },
  {
    value: 'Hvíta Perlan nr. 12',
    label: 'Hvíta Perlan nr. 12',
  },
  {
    value: 'Hvíta perlan nr.12',
    label: 'Hvíta perlan nr.12',
  },
  {
    value: 'Hvíta Perlan, púpa',
    label: 'Hvíta Perlan, púpa',
  },
  {
    value: 'Hvít-fjólublár nobbler',
    label: 'Hvít-fjólublár nobbler',
  },
  {
    value: 'Hvítir nobbler',
    label: 'Hvítir nobbler',
  },
  {
    value: 'Hvítur dýrbítur',
    label: 'Hvítur dýrbítur',
  },
  {
    value: 'Hvítur Frances',
    label: 'Hvítur Frances',
  },
  {
    value: 'hvítur í holdið',
    label: 'hvítur í holdið',
  },
  {
    value: 'Hvítur Nobber',
    label: 'Hvítur Nobber',
  },
  {
    value: 'hvítur nobbler',
    label: 'hvítur nobbler',
  },
  {
    value: 'hvítur nobbler',
    label: 'hvítur nobbler',
  },
  {
    value: 'Hvítur nobbler með bleiku skotti',
    label: 'Hvítur nobbler með bleiku skotti',
  },
  {
    value: 'hvítur nobblerr',
    label: 'hvítur nobblerr',
  },
  {
    value: 'hvítur nobler',
    label: 'hvítur nobler',
  },
  {
    value: 'Hvítur Reflex',
    label: 'Hvítur Reflex',
  },
  {
    value: 'hvítur/bleikur nobbler',
    label: 'hvítur/bleikur nobbler',
  },
  {
    value: 'hvíturnobler',
    label: 'hvíturnobler',
  },
  {
    value: 'HVK Spinner',
    label: 'HVK Spinner',
  },
  {
    value: 'Höddi',
    label: 'Höddi',
  },
  {
    value: 'Höddi',
    label: 'Höddi',
  },
  {
    value: 'Hörg',
    label: 'Hörg',
  },
  {
    value: 'Hörgárgrýla',
    label: 'Hörgárgrýla',
  },
  {
    value: 'Iđa',
    label: 'Iđa',
  },
  {
    value: 'Iða',
    label: 'Iða',
  },
  {
    value: 'Iða lítil túba',
    label: 'Iða lítil túba',
  },
  {
    value: 'Iða túba',
    label: 'Iða túba',
  },
  {
    value: 'Iða túba',
    label: 'Iða túba',
  },
  {
    value: 'Iða.',
    label: 'Iða.',
  },
  {
    value: 'iðan',
    label: 'iðan',
  },
  {
    value: 'Iðan',
    label: 'Iðan',
  },
  {
    value: 'Imba rauð',
    label: 'Imba rauð',
  },
  {
    value: 'Intruder',
    label: 'Intruder',
  },
  {
    value: 'Intruder',
    label: 'Intruder',
  },
  {
    value: 'IPB',
    label: 'IPB',
  },
  {
    value: 'is1082',
    label: 'is1082',
  },
  {
    value: 'ímó',
    label: 'ímó',
  },
  {
    value: 'Ísl. spónn',
    label: 'Ísl. spónn',
  },
  {
    value: 'Ísland',
    label: 'Ísland',
  },
  {
    value: 'Íslandsspónn',
    label: 'Íslandsspónn',
  },
  {
    value: 'íslandsspúnn',
    label: 'íslandsspúnn',
  },
  {
    value: 'íslendingurinn',
    label: 'íslendingurinn',
  },
  {
    value: 'J.G Shrimp',
    label: 'J.G Shrimp',
  },
  {
    value: 'Jack Frost',
    label: 'Jack Frost',
  },
  {
    value: 'Jehova Dog ek no 6',
    label: 'Jehova Dog ek no 6',
  },
  {
    value: 'Jongi',
    label: 'Jongi',
  },
  {
    value: 'Jón í góðu skapi',
    label: 'Jón í góðu skapi',
  },
  {
    value: 'Jónas',
    label: 'Jónas',
  },
  {
    value: 'K.púpa',
    label: 'K.púpa',
  },
  {
    value: 'K.púpa',
    label: 'K.púpa',
  },
  {
    value: 'Katla',
    label: 'Katla',
  },
  {
    value: 'Keiluhaus',
    label: 'Keiluhaus',
  },
  {
    value: "Kelley's Duster",
    label: "Kelley's Duster",
  },
  {
    value: 'Kibbi',
    label: 'Kibbi',
  },
  {
    value: 'kibbi ormur',
    label: 'kibbi ormur',
  },
  {
    value: 'Kiddi',
    label: 'Kiddi',
  },
  {
    value: 'KIiller',
    label: 'KIiller',
  },
  {
    value: 'Killer',
    label: 'Killer',
  },
  {
    value: 'Killer',
    label: 'Killer',
  },
  {
    value: 'Killer bleikur',
    label: 'Killer bleikur',
  },
  {
    value: 'Killer kúla',
    label: 'Killer kúla',
  },
  {
    value: 'Killer no 12 einkrækja',
    label: 'Killer no 12 einkrækja',
  },
  {
    value: 'killer rauð krag',
    label: 'killer rauð krag',
  },
  {
    value: 'killer rauðkrag',
    label: 'killer rauðkrag',
  },
  {
    value: 'klinghammer',
    label: 'klinghammer',
  },
  {
    value: 'Klinkhammer',
    label: 'Klinkhammer',
  },
  {
    value: 'Klinkhammer',
    label: 'Klinkhammer',
  },
  {
    value: 'Klængur',
    label: 'Klængur',
  },
  {
    value: 'knobbler',
    label: 'knobbler',
  },
  {
    value: 'Kolamoli',
    label: 'Kolamoli',
  },
  {
    value: 'Kolskeggur',
    label: 'Kolskeggur',
  },
  {
    value: 'Kolskeggur hitch',
    label: 'Kolskeggur hitch',
  },
  {
    value: 'Kolskeggur keila',
    label: 'Kolskeggur keila',
  },
  {
    value: 'Kolskeggur túba',
    label: 'Kolskeggur túba',
  },
  {
    value: 'Kolskeggur-keila',
    label: 'Kolskeggur-keila',
  },
  {
    value: 'Kolskekkur',
    label: 'Kolskekkur',
  },
  {
    value: 'konan i hinu husinu hlær',
    label: 'konan i hinu husinu hlær',
  },
  {
    value: 'konan i hinu husinu hlær!',
    label: 'konan i hinu husinu hlær!',
  },
  {
    value: 'Koníakstúba',
    label: 'Koníakstúba',
  },
  {
    value: 'Kopar',
    label: 'Kopar',
  },
  {
    value: 'Kopar moli',
    label: 'Kopar moli',
  },
  {
    value: 'Kopar rmoli',
    label: 'Kopar rmoli',
  },
  {
    value: 'Kopar toby',
    label: 'Kopar toby',
  },
  {
    value: 'Koparlitaður Toby',
    label: 'Koparlitaður Toby',
  },
  {
    value: 'kotturinn',
    label: 'kotturinn',
  },
  {
    value: 'Kóbra',
    label: 'Kóbra',
  },
  {
    value: 'Kóflaska',
    label: 'Kóflaska',
  },
  {
    value: 'Kókflaska',
    label: 'Kókflaska',
  },
  {
    value: 'Kókflaskan',
    label: 'Kókflaskan',
  },
  {
    value: 'kókur',
    label: 'kókur',
  },
  {
    value: 'krafla',
    label: 'krafla',
  },
  {
    value: 'Krafla',
    label: 'Krafla',
  },
  {
    value: 'Krafla app',
    label: 'Krafla app',
  },
  {
    value: 'Krafla app.',
    label: 'Krafla app.',
  },
  {
    value: 'Krafla bleik',
    label: 'Krafla bleik',
  },
  {
    value: 'Krafla bleik no 12 einkrækja',
    label: 'Krafla bleik no 12 einkrækja',
  },
  {
    value: 'Krafla keila',
    label: 'Krafla keila',
  },
  {
    value: 'Krafla orange',
    label: 'Krafla orange',
  },
  {
    value: 'Krafla rauđ',
    label: 'Krafla rauđ',
  },
  {
    value: 'krafla rauð',
    label: 'krafla rauð',
  },
  {
    value: 'Krafla svört',
    label: 'Krafla svört',
  },
  {
    value: 'Krafla, appelsínugul',
    label: 'Krafla, appelsínugul',
  },
  {
    value: 'Krafla, orange',
    label: 'Krafla, orange',
  },
  {
    value: 'KR-ingurinn',
    label: 'KR-ingurinn',
  },
  {
    value: 'Kristín á Bakka',
    label: 'Kristín á Bakka',
  },
  {
    value: 'krokur',
    label: 'krokur',
  },
  {
    value: 'krokur5',
    label: 'krokur5',
  },
  {
    value: 'Krokurinn',
    label: 'Krokurinn',
  },
  {
    value: 'Krókinn',
    label: 'Krókinn',
  },
  {
    value: 'Krókirinn',
    label: 'Krókirinn',
  },
  {
    value: 'króku',
    label: 'króku',
  },
  {
    value: 'Krókuinn',
    label: 'Krókuinn',
  },
  {
    value: 'Krókur',
    label: 'Krókur',
  },
  {
    value: 'Krókur',
    label: 'Krókur',
  },
  {
    value: 'krókur      no 12',
    label: 'krókur      no 12',
  },
  {
    value: 'krókur     no 12',
    label: 'krókur     no 12',
  },
  {
    value: 'krókur net',
    label: 'krókur net',
  },
  {
    value: 'Krókur nr 10',
    label: 'Krókur nr 10',
  },
  {
    value: 'Krókur nr. 14',
    label: 'Krókur nr. 14',
  },
  {
    value: 'Krókur nr10',
    label: 'Krókur nr10',
  },
  {
    value: 'Krókur SE',
    label: 'Krókur SE',
  },
  {
    value: 'Krókuri',
    label: 'Krókuri',
  },
  {
    value: 'Krókuriinn',
    label: 'Krókuriinn',
  },
  {
    value: 'krókurin',
    label: 'krókurin',
  },
  {
    value: 'Krókurinn',
    label: 'Krókurinn',
  },
  {
    value: 'Krókurinn',
    label: 'Krókurinn',
  },
  {
    value: 'Krókurinn  #10',
    label: 'Krókurinn  #10',
  },
  {
    value: 'Krókurinn #12',
    label: 'Krókurinn #12',
  },
  {
    value: 'Krókurinn afbrygði',
    label: 'Krókurinn afbrygði',
  },
  {
    value: 'Krókurinn nr 12',
    label: 'Krókurinn nr 12',
  },
  {
    value: 'Krókurinn nr. 14',
    label: 'Krókurinn nr. 14',
  },
  {
    value: 'Krókurinn nr.12',
    label: 'Krókurinn nr.12',
  },
  {
    value: 'Krókurinn olive',
    label: 'Krókurinn olive',
  },
  {
    value: 'Królur kúluhaus',
    label: 'Królur kúluhaus',
  },
  {
    value: 'Krónur',
    label: 'Krónur',
  },
  {
    value: 'Króur',
    label: 'Króur',
  },
  {
    value: 'Krrókurinn',
    label: 'Krrókurinn',
  },
  {
    value: 'Krystal Dancer',
    label: 'Krystal Dancer',
  },
  {
    value: 'Któkur',
    label: 'Któkur',
  },
  {
    value: 'Kuðungur/Lirfa?',
    label: 'Kuðungur/Lirfa?',
  },
  {
    value: 'kulhaus',
    label: 'kulhaus',
  },
  {
    value: 'Kùluhaus',
    label: 'Kùluhaus',
  },
  {
    value: 'Kúlipúpur',
    label: 'Kúlipúpur',
  },
  {
    value: 'Kúlipúpur nr. 10 og 12',
    label: 'Kúlipúpur nr. 10 og 12',
  },
  {
    value: 'kúluhaus',
    label: 'kúluhaus',
  },
  {
    value: 'Kúluhaus',
    label: 'Kúluhaus',
  },
  {
    value: 'Kúluhaus  nr. 10',
    label: 'Kúluhaus  nr. 10',
  },
  {
    value: 'Kúluhaus - veitt upstream',
    label: 'Kúluhaus - veitt upstream',
  },
  {
    value: 'kúluhaus (blár og bleikur)',
    label: 'kúluhaus (blár og bleikur)',
  },
  {
    value: 'kúluhaus brúnn',
    label: 'kúluhaus brúnn',
  },
  {
    value: 'Kúluhaus nr. 16',
    label: 'Kúluhaus nr. 16',
  },
  {
    value: 'Kúluhaus svart',
    label: 'Kúluhaus svart',
  },
  {
    value: 'Kúluhaus, Naggur',
    label: 'Kúluhaus, Naggur',
  },
  {
    value: 'Kúlupúpa',
    label: 'Kúlupúpa',
  },
  {
    value: 'Kúlupúpa',
    label: 'Kúlupúpa',
  },
  {
    value: 'Kúlupúpa svört nr. 14',
    label: 'Kúlupúpa svört nr. 14',
  },
  {
    value: 'Kúrfan',
    label: 'Kúrfan',
  },
  {
    value: 'KVH Spinner',
    label: 'KVH Spinner',
  },
  {
    value: 'Köttur',
    label: 'Köttur',
  },
  {
    value: 'Kötturinn',
    label: 'Kötturinn',
  },
  {
    value: 'la gusso',
    label: 'la gusso',
  },
  {
    value: 'lady Ga Ga',
    label: 'lady Ga Ga',
  },
  {
    value: 'lady gaga',
    label: 'lady gaga',
  },
  {
    value: 'lagusso',
    label: 'lagusso',
  },
  {
    value: 'langskeggur',
    label: 'langskeggur',
  },
  {
    value: 'Larry Bird',
    label: 'Larry Bird',
  },
  {
    value: 'latex',
    label: 'latex',
  },
  {
    value: 'latex púpa',
    label: 'latex púpa',
  },
  {
    value: 'Laxa Blá',
    label: 'Laxa Blá',
  },
  {
    value: 'Laxá blá',
    label: 'Laxá blá',
  },
  {
    value: 'lena',
    label: 'lena',
  },
  {
    value: 'Libba',
    label: 'Libba',
  },
  {
    value: 'lippa',
    label: 'lippa',
  },
  {
    value: 'Lippa',
    label: 'Lippa',
  },
  {
    value: 'Lippa (Finninn)',
    label: 'Lippa (Finninn)',
  },
  {
    value: 'LIPPA BLÁ',
    label: 'LIPPA BLÁ',
  },
  {
    value: 'Lippa bleik',
    label: 'Lippa bleik',
  },
  {
    value: 'Lippa frá Ellingsen',
    label: 'Lippa frá Ellingsen',
  },
  {
    value: 'lippan',
    label: 'lippan',
  },
  {
    value: 'lippan',
    label: 'lippan',
  },
  {
    value: 'lirfa',
    label: 'lirfa',
  },
  {
    value: 'Lissa nimph',
    label: 'Lissa nimph',
  },
  {
    value: 'Lissa nymph',
    label: 'Lissa nymph',
  },
  {
    value: 'Litil svört fló',
    label: 'Litil svört fló',
  },
  {
    value: 'Litli Ormur',
    label: 'Litli Ormur',
  },
  {
    value: 'lítil orangs Krafla',
    label: 'lítil orangs Krafla',
  },
  {
    value: 'lítill púpa stærð 12',
    label: 'lítill púpa stærð 12',
  },
  {
    value: 'lkiller',
    label: 'lkiller',
  },
  {
    value: 'Loðmundur',
    label: 'Loðmundur',
  },
  {
    value: 'Loðmundur',
    label: 'Loðmundur',
  },
  {
    value: 'Longarinn',
    label: 'Longarinn',
  },
  {
    value: 'Longi',
    label: 'Longi',
  },
  {
    value: 'Longi GH',
    label: 'Longi GH',
  },
  {
    value: 'Lónbúinn',
    label: 'Lónbúinn',
  },
  {
    value: 'Lubbi',
    label: 'Lubbi',
  },
  {
    value: 'Lubbi.',
    label: 'Lubbi.',
  },
  {
    value: 'Lucky John',
    label: 'Lucky John',
  },
  {
    value: 'Ludda',
    label: 'Ludda',
  },
  {
    value: 'Lummulassi',
    label: 'Lummulassi',
  },
  {
    value: 'Lúti',
    label: 'Lúti',
  },
  {
    value: 'Lúti - kúpa',
    label: 'Lúti - kúpa',
  },
  {
    value: 'Lympa',
    label: 'Lympa',
  },
  {
    value: 'lyppa',
    label: 'lyppa',
  },
  {
    value: 'Lyppa - silfruð/appelsínugul',
    label: 'Lyppa - silfruð/appelsínugul',
  },
  {
    value: 'M Finn',
    label: 'M Finn',
  },
  {
    value: 'Madalain',
    label: 'Madalain',
  },
  {
    value: 'Madam x þurrfluga',
    label: 'Madam x þurrfluga',
  },
  {
    value: 'maðk',
    label: 'maðk',
  },
  {
    value: 'Maðkur',
    label: 'Maðkur',
  },
  {
    value: 'maðkur á sökku í miklum staumi',
    label: 'maðkur á sökku í miklum staumi',
  },
  {
    value: 'Maðkur,makríll',
    label: 'Maðkur,makríll',
  },
  {
    value: 'Magdalena',
    label: 'Magdalena',
  },
  {
    value: 'Maggi maðkur',
    label: 'Maggi maðkur',
  },
  {
    value: 'Magic hopper',
    label: 'Magic hopper',
  },
  {
    value: 'Magnus https://www.youtube.com/watch?v=y9LEK5hWznE',
    label: 'Magnus https://www.youtube.com/watch?v=y9LEK5hWznE',
  },
  {
    value: 'Magnús',
    label: 'Magnús',
  },
  {
    value: 'Makríll',
    label: 'Makríll',
  },
  {
    value: 'MAN EKKI',
    label: 'MAN EKKI',
  },
  {
    value: 'MANEKKI',
    label: 'MANEKKI',
  },
  {
    value: 'March Brown',
    label: 'March Brown',
  },
  {
    value: 'marfló',
    label: 'marfló',
  },
  {
    value: 'Marfló orange',
    label: 'Marfló orange',
  },
  {
    value: 'marfló/púpa',
    label: 'marfló/púpa',
  },
  {
    value: 'Marflóin',
    label: 'Marflóin',
  },
  {
    value: 'marglitaður spinner',
    label: 'marglitaður spinner',
  },
  {
    value: 'María',
    label: 'María',
  },
  {
    value: 'Melshorn',
    label: 'Melshorn',
  },
  {
    value: 'Mepps',
    label: 'Mepps',
  },
  {
    value: 'Meps',
    label: 'Meps',
  },
  {
    value: 'Meps 8 gr',
    label: 'Meps 8 gr',
  },
  {
    value: 'Merktur sbr. við hliðina',
    label: 'Merktur sbr. við hliðina',
  },
  {
    value: 'Messi',
    label: 'Messi',
  },
  {
    value: 'Metalica',
    label: 'Metalica',
  },
  {
    value: 'Metallica',
    label: 'Metallica',
  },
  {
    value: 'Metallica',
    label: 'Metallica',
  },
  {
    value: 'Mickey Finn',
    label: 'Mickey Finn',
  },
  {
    value: 'Micro Frances',
    label: 'Micro Frances',
  },
  {
    value: 'Mikael Frödin',
    label: 'Mikael Frödin',
  },
  {
    value: 'mikro tuba',
    label: 'mikro tuba',
  },
  {
    value: 'Mí',
    label: 'Mí',
  },
  {
    value: 'Mísla',
    label: 'Mísla',
  },
  {
    value: 'MJ23',
    label: 'MJ23',
  },
  {
    value: 'Mjög bleik fluga',
    label: 'Mjög bleik fluga',
  },
  {
    value: 'MME',
    label: 'MME',
  },
  {
    value: 'Mobuto',
    label: 'Mobuto',
  },
  {
    value: 'Moli',
    label: 'Moli',
  },
  {
    value: 'Moli',
    label: 'Moli',
  },
  {
    value: 'Moli - kopar',
    label: 'Moli - kopar',
  },
  {
    value: 'Moli púpa',
    label: 'Moli púpa',
  },
  {
    value: 'Monkey',
    label: 'Monkey',
  },
  {
    value: 'Monky fly',
    label: 'Monky fly',
  },
  {
    value: 'Montana',
    label: 'Montana',
  },
  {
    value: 'Monty Python',
    label: 'Monty Python',
  },
  {
    value: 'Móri',
    label: 'Móri',
  },
  {
    value: 'muddler',
    label: 'muddler',
  },
  {
    value: 'muddler muddler',
    label: 'muddler muddler',
  },
  {
    value: 'Mudler GH',
    label: 'Mudler GH',
  },
  {
    value: 'Mudler Green',
    label: 'Mudler Green',
  },
  {
    value: 'Munro killer',
    label: 'Munro killer',
  },
  {
    value: 'Munro Killer longtail',
    label: 'Munro Killer longtail',
  },
  {
    value: 'Munroe Killer',
    label: 'Munroe Killer',
  },
  {
    value: 'Muroe killer',
    label: 'Muroe killer',
  },
  {
    value: 'Mús',
    label: 'Mús',
  },
  {
    value: 'Myrans Spinner',
    label: 'Myrans Spinner',
  },
  {
    value: 'Mysla',
    label: 'Mysla',
  },
  {
    value: "M'ysla",
    label: "M'ysla",
  },
  {
    value: 'Myslan',
    label: 'Myslan',
  },
  {
    value: 'Mý',
    label: 'Mý',
  },
  {
    value: 'Mýpúpa',
    label: 'Mýpúpa',
  },
  {
    value: 'mýsla',
    label: 'mýsla',
  },
  {
    value: 'Mýsla',
    label: 'Mýsla',
  },
  {
    value: 'Mýsla bleik',
    label: 'Mýsla bleik',
  },
  {
    value: 'Mýsla nr 10',
    label: 'Mýsla nr 10',
  },
  {
    value: 'Mýsla nr 8',
    label: 'Mýsla nr 8',
  },
  {
    value: 'Mýsla nr. 14',
    label: 'Mýsla nr. 14',
  },
  {
    value: 'Mýsla nr.14',
    label: 'Mýsla nr.14',
  },
  {
    value: 'Mýslan',
    label: 'Mýslan',
  },
  {
    value: 'Mödler nr. 8',
    label: 'Mödler nr. 8',
  },
  {
    value: 'N0BBLER',
    label: 'N0BBLER',
  },
  {
    value: 'naðkur',
    label: 'naðkur',
  },
  {
    value: 'Nafnlaus',
    label: 'Nafnlaus',
  },
  {
    value: 'Nafnlaus Kúluhaus',
    label: 'Nafnlaus Kúluhaus',
  },
  {
    value: 'Nafnlaus streamer',
    label: 'Nafnlaus streamer',
  },
  {
    value: 'Naggur',
    label: 'Naggur',
  },
  {
    value: 'Nagli kúla',
    label: 'Nagli kúla',
  },
  {
    value: 'nancy',
    label: 'nancy',
  },
  {
    value: 'náfíkja',
    label: 'náfíkja',
  },
  {
    value: 'Neðan við Grund',
    label: 'Neðan við Grund',
  },
  {
    value: 'neðra sv fh.  veitt á stirðu',
    label: 'neðra sv fh.  veitt á stirðu',
  },
  {
    value: 'Nick í náttfötum',
    label: 'Nick í náttfötum',
  },
  {
    value: 'Night Hawk',
    label: 'Night Hawk',
  },
  {
    value: 'nn',
    label: 'nn',
  },
  {
    value: 'No name',
    label: 'No name',
  },
  {
    value: 'No name fluga',
    label: 'No name fluga',
  },
  {
    value: 'No shame',
    label: 'No shame',
  },
  {
    value: 'Nobber',
    label: 'Nobber',
  },
  {
    value: 'Nobbler',
    label: 'Nobbler',
  },
  {
    value: 'Nobbler',
    label: 'Nobbler',
  },
  {
    value: 'nobbler - appelsínugulur',
    label: 'nobbler - appelsínugulur',
  },
  {
    value: 'Nobbler - bleikur',
    label: 'Nobbler - bleikur',
  },
  {
    value: 'Nobbler - orange',
    label: 'Nobbler - orange',
  },
  {
    value: 'Nobbler (bleikur)',
    label: 'Nobbler (bleikur)',
  },
  {
    value: 'Nobbler (orange)',
    label: 'Nobbler (orange)',
  },
  {
    value: 'Nobbler app. gulur',
    label: 'Nobbler app. gulur',
  },
  {
    value: 'Nobbler appelsinugul',
    label: 'Nobbler appelsinugul',
  },
  {
    value: 'nobbler appelsínugulur',
    label: 'nobbler appelsínugulur',
  },
  {
    value: 'Nobbler black',
    label: 'Nobbler black',
  },
  {
    value: 'Nobbler black ghoost',
    label: 'Nobbler black ghoost',
  },
  {
    value: 'Nobbler bleik',
    label: 'Nobbler bleik',
  },
  {
    value: 'nobbler bleikur',
    label: 'nobbler bleikur',
  },
  {
    value: 'Nobbler bleikur UV',
    label: 'Nobbler bleikur UV',
  },
  {
    value: 'Nobbler hvítur',
    label: 'Nobbler hvítur',
  },
  {
    value: 'Nobbler orange',
    label: 'Nobbler orange',
  },
  {
    value: 'Nobbler orans',
    label: 'Nobbler orans',
  },
  {
    value: 'Nobbler orenge',
    label: 'Nobbler orenge',
  },
  {
    value: 'Nobbler rauð',
    label: 'Nobbler rauð',
  },
  {
    value: 'Nobbler rauður',
    label: 'Nobbler rauður',
  },
  {
    value: 'Nobbler red',
    label: 'Nobbler red',
  },
  {
    value: 'Nobbler sv.',
    label: 'Nobbler sv.',
  },
  {
    value: 'nobbler svartur',
    label: 'nobbler svartur',
  },
  {
    value: 'Nobbler, black ghost',
    label: 'Nobbler, black ghost',
  },
  {
    value: 'Nobbler, black ghost afbrigði',
    label: 'Nobbler, black ghost afbrigði',
  },
  {
    value: 'Nobbler,black ghost',
    label: 'Nobbler,black ghost',
  },
  {
    value: 'Nobbler,diskó',
    label: 'Nobbler,diskó',
  },
  {
    value: 'Nobbler/bleikur',
    label: 'Nobbler/bleikur',
  },
  {
    value: 'Nober',
    label: 'Nober',
  },
  {
    value: 'Nobler',
    label: 'Nobler',
  },
  {
    value: 'Nobler',
    label: 'Nobler',
  },
  {
    value: 'Nobler appelsínugulur',
    label: 'Nobler appelsínugulur',
  },
  {
    value: 'nobler black',
    label: 'nobler black',
  },
  {
    value: 'Nobler bleikur',
    label: 'Nobler bleikur',
  },
  {
    value: 'Nobler orange',
    label: 'Nobler orange',
  },
  {
    value: 'nobler orans',
    label: 'nobler orans',
  },
  {
    value: 'nobler pink',
    label: 'nobler pink',
  },
  {
    value: 'Nobler rauður',
    label: 'Nobler rauður',
  },
  {
    value: 'nobles',
    label: 'nobles',
  },
  {
    value: 'Noel',
    label: 'Noel',
  },
  {
    value: 'Noel tuba',
    label: 'Noel tuba',
  },
  {
    value: 'Nymph',
    label: 'Nymph',
  },
  {
    value: 'Nymph',
    label: 'Nymph',
  },
  {
    value: 'Nymphs',
    label: 'Nymphs',
  },
  {
    value: 'Nymt með kúluhaus',
    label: 'Nymt með kúluhaus',
  },
  {
    value: 'ný fluga frá sveini',
    label: 'ný fluga frá sveini',
  },
  {
    value: 'næla svört',
    label: 'næla svört',
  },
  {
    value: 'O. Nobbler',
    label: 'O. Nobbler',
  },
  {
    value: 'Ofsaboom',
    label: 'Ofsaboom',
  },
  {
    value: 'Olive',
    label: 'Olive',
  },
  {
    value: 'Olive ghost',
    label: 'Olive ghost',
  },
  {
    value: 'Olive killer',
    label: 'Olive killer',
  },
  {
    value: 'Olive nobbler',
    label: 'Olive nobbler',
  },
  {
    value: 'Olive púpa',
    label: 'Olive púpa',
  },
  {
    value: 'Olive púpa',
    label: 'Olive púpa',
  },
  {
    value: 'Olive skull',
    label: 'Olive skull',
  },
  {
    value: 'Olive stirða',
    label: 'Olive stirða',
  },
  {
    value: 'Olive zonker',
    label: 'Olive zonker',
  },
  {
    value: 'Olís Snælda',
    label: 'Olís Snælda',
  },
  {
    value: 'olly',
    label: 'olly',
  },
  {
    value: 'onefnd',
    label: 'onefnd',
  },
  {
    value: 'orange dyrbitur',
    label: 'orange dyrbitur',
  },
  {
    value: 'Orange Dýrbítur',
    label: 'Orange Dýrbítur',
  },
  {
    value: 'Orange Frances',
    label: 'Orange Frances',
  },
  {
    value: 'Orange Hammer',
    label: 'Orange Hammer',
  },
  {
    value: 'Orange Krafla',
    label: 'Orange Krafla',
  },
  {
    value: 'Orange kúla',
    label: 'Orange kúla',
  },
  {
    value: 'Orange Marfló',
    label: 'Orange Marfló',
  },
  {
    value: 'orange nobbler',
    label: 'orange nobbler',
  },
  {
    value: 'Orange nobbler',
    label: 'Orange nobbler',
  },
  {
    value: 'orange nobbler nr 10',
    label: 'orange nobbler nr 10',
  },
  {
    value: 'Orange nobler',
    label: 'Orange nobler',
  },
  {
    value: 'orange nobler nr 10',
    label: 'orange nobler nr 10',
  },
  {
    value: 'Orange Noblet',
    label: 'Orange Noblet',
  },
  {
    value: 'orange noppler',
    label: 'orange noppler',
  },
  {
    value: 'orange púpa',
    label: 'orange púpa',
  },
  {
    value: 'orange púpa',
    label: 'orange púpa',
  },
  {
    value: 'Orange stirða',
    label: 'Orange stirða',
  },
  {
    value: 'Orange streamer',
    label: 'Orange streamer',
  },
  {
    value: 'orange toby',
    label: 'orange toby',
  },
  {
    value: 'Orangenobler',
    label: 'Orangenobler',
  },
  {
    value: 'Orangutang sunray',
    label: 'Orangutang sunray',
  },
  {
    value: 'Orka',
    label: 'Orka',
  },
  {
    value: 'Ormur',
    label: 'Ormur',
  },
  {
    value: 'Ossa',
    label: 'Ossa',
  },
  {
    value: 'Ólífugræn púpa með gullhaus',
    label: 'Ólífugræn púpa með gullhaus',
  },
  {
    value: 'Ólífugræn púpa með gullhaus',
    label: 'Ólífugræn púpa með gullhaus',
  },
  {
    value: 'ónefnd',
    label: 'ónefnd',
  },
  {
    value: 'ónefnd fluga',
    label: 'ónefnd fluga',
  },
  {
    value: 'Ónefnd Púpa',
    label: 'Ónefnd Púpa',
  },
  {
    value: 'ónefnd túpa',
    label: 'ónefnd túpa',
  },
  {
    value: 'óskírð túba',
    label: 'óskírð túba',
  },
  {
    value: 'Óva',
    label: 'Óva',
  },
  {
    value: 'óþekkt',
    label: 'óþekkt',
  },
  {
    value: 'Óþekkt púpa',
    label: 'Óþekkt púpa',
  },
  {
    value: 'P.t',
    label: 'P.t',
  },
  {
    value: 'P.t.',
    label: 'P.t.',
  },
  {
    value: 'Pasenth',
    label: 'Pasenth',
  },
  {
    value: 'Pasenthail',
    label: 'Pasenthail',
  },
  {
    value: 'Páfagaukur',
    label: 'Páfagaukur',
  },
  {
    value: 'Peach með bleikri slikju',
    label: 'Peach með bleikri slikju',
  },
  {
    value: 'Peacock',
    label: 'Peacock',
  },
  {
    value: 'Peacock',
    label: 'Peacock',
  },
  {
    value: 'Peacock án kúluhaus',
    label: 'Peacock án kúluhaus',
  },
  {
    value: 'peacock púpa nr. 10',
    label: 'peacock púpa nr. 10',
  },
  {
    value: 'peacock, 10',
    label: 'peacock, 10',
  },
  {
    value: 'peacockk nr 10',
    label: 'peacockk nr 10',
  },
  {
    value: 'Peacok',
    label: 'Peacok',
  },
  {
    value: 'Peacokk',
    label: 'Peacokk',
  },
  {
    value: 'peacook',
    label: 'peacook',
  },
  {
    value: 'Peakcock',
    label: 'Peakcock',
  },
  {
    value: 'Peasant Tail',
    label: 'Peasant Tail',
  },
  {
    value: 'Peasnat tail',
    label: 'Peasnat tail',
  },
  {
    value: 'Perka',
    label: 'Perka',
  },
  {
    value: 'Perla',
    label: 'Perla',
  },
  {
    value: 'Perla',
    label: 'Perla',
  },
  {
    value: 'Perla kúla',
    label: 'Perla kúla',
  },
  {
    value: 'Pervert',
    label: 'Pervert',
  },
  {
    value: 'Pesant',
    label: 'Pesant',
  },
  {
    value: 'PESANTALE',
    label: 'PESANTALE',
  },
  {
    value: 'pesantel',
    label: 'pesantel',
  },
  {
    value: 'Pesent tail',
    label: 'Pesent tail',
  },
  {
    value: 'Pesenttail',
    label: 'Pesenttail',
  },
  {
    value: 'Peshant',
    label: 'Peshant',
  },
  {
    value: 'Pete Ross',
    label: 'Pete Ross',
  },
  {
    value: 'Peter Ross',
    label: 'Peter Ross',
  },
  {
    value: 'Peter Ross 10 einkrækja',
    label: 'Peter Ross 10 einkrækja',
  },
  {
    value: 'Peter Ross 12',
    label: 'Peter Ross 12',
  },
  {
    value: 'Peter Ross 12 einkrækja',
    label: 'Peter Ross 12 einkrækja',
  },
  {
    value: 'Peter Ross nr 14',
    label: 'Peter Ross nr 14',
  },
  {
    value: 'Peter Ross púpa',
    label: 'Peter Ross púpa',
  },
  {
    value: 'PH',
    label: 'PH',
  },
  {
    value: 'Phasan tail',
    label: 'Phasan tail',
  },
  {
    value: 'phasant tail',
    label: 'phasant tail',
  },
  {
    value: 'phasantail',
    label: 'phasantail',
  },
  {
    value: 'Phasantail afbrygði',
    label: 'Phasantail afbrygði',
  },
  {
    value: 'Phasanttail',
    label: 'Phasanttail',
  },
  {
    value: 'Phasent',
    label: 'Phasent',
  },
  {
    value: 'Phasent',
    label: 'Phasent',
  },
  {
    value: 'Phasentail',
    label: 'Phasentail',
  },
  {
    value: 'Pheacock',
    label: 'Pheacock',
  },
  {
    value: 'Pheas.tail',
    label: 'Pheas.tail',
  },
  {
    value: 'pheasant',
    label: 'pheasant',
  },
  {
    value: 'Pheasant tail',
    label: 'Pheasant tail',
  },
  {
    value: 'Pheasant tail',
    label: 'Pheasant tail',
  },
  {
    value: 'pheasant tail  nr 12',
    label: 'pheasant tail  nr 12',
  },
  {
    value: 'Pheasant tail kúlup',
    label: 'Pheasant tail kúlup',
  },
  {
    value: 'Pheasant tail kúlup.',
    label: 'Pheasant tail kúlup.',
  },
  {
    value: 'pheasant tail nr 12',
    label: 'pheasant tail nr 12',
  },
  {
    value: 'Pheasant tail nr.10',
    label: 'Pheasant tail nr.10',
  },
  {
    value: 'pheasant tail nymph',
    label: 'pheasant tail nymph',
  },
  {
    value: 'Pheasant tail pupated',
    label: 'Pheasant tail pupated',
  },
  {
    value: 'pheasant tail!!!',
    label: 'pheasant tail!!!',
  },
  {
    value: 'Pheasanttail',
    label: 'Pheasanttail',
  },
  {
    value: 'PheasantTail/Teal and Black',
    label: 'PheasantTail/Teal and Black',
  },
  {
    value: 'Pheasat Tail',
    label: 'Pheasat Tail',
  },
  {
    value: 'Pheasent Tail',
    label: 'Pheasent Tail',
  },
  {
    value: 'phesaint tail',
    label: 'phesaint tail',
  },
  {
    value: 'phesant',
    label: 'phesant',
  },
  {
    value: 'Phesant tail',
    label: 'Phesant tail',
  },
  {
    value: 'Phesant tail',
    label: 'Phesant tail',
  },
  {
    value: 'Phesant tail - kúluhaus nr. 14',
    label: 'Phesant tail - kúluhaus nr. 14',
  },
  {
    value: 'Phesant tail nr. 10',
    label: 'Phesant tail nr. 10',
  },
  {
    value: 'Phesant tail pupated',
    label: 'Phesant tail pupated',
  },
  {
    value: 'Phesant tail púpa',
    label: 'Phesant tail púpa',
  },
  {
    value: 'phesant tail, 10',
    label: 'phesant tail, 10',
  },
  {
    value: 'phesant tale',
    label: 'phesant tale',
  },
  {
    value: 'phesant teil',
    label: 'phesant teil',
  },
  {
    value: 'Phesanttail',
    label: 'Phesanttail',
  },
  {
    value: 'Pheseant tail',
    label: 'Pheseant tail',
  },
  {
    value: 'Phesent',
    label: 'Phesent',
  },
  {
    value: 'Phesent tail',
    label: 'Phesent tail',
  },
  {
    value: 'pheshenn tail',
    label: 'pheshenn tail',
  },
  {
    value: 'Phs.tail',
    label: 'Phs.tail',
  },
  {
    value: 'Pht',
    label: 'Pht',
  },
  {
    value: 'PHT kúla',
    label: 'PHT kúla',
  },
  {
    value: 'Pht.',
    label: 'Pht.',
  },
  {
    value: 'pieacock nr 10',
    label: 'pieacock nr 10',
  },
  {
    value: 'Pink Bird',
    label: 'Pink Bird',
  },
  {
    value: 'pink cat',
    label: 'pink cat',
  },
  {
    value: 'Pink Craziness',
    label: 'Pink Craziness',
  },
  {
    value: 'pink dyrbitar',
    label: 'pink dyrbitar',
  },
  {
    value: 'pink Dýrbítur',
    label: 'pink Dýrbítur',
  },
  {
    value: 'Pink ghost',
    label: 'Pink ghost',
  },
  {
    value: 'pink streamer',
    label: 'pink streamer',
  },
  {
    value: 'píkokk',
    label: 'píkokk',
  },
  {
    value: 'Pínulítil púpa',
    label: 'Pínulítil púpa',
  },
  {
    value: 'Pínulítil púpa - grá',
    label: 'Pínulítil púpa - grá',
  },
  {
    value: 'plesant tail',
    label: 'plesant tail',
  },
  {
    value: 'Polar Shrimp',
    label: 'Polar Shrimp',
  },
  {
    value: 'Prestur',
    label: 'Prestur',
  },
  {
    value: 'Presturinn',
    label: 'Presturinn',
  },
  {
    value: 'Presturinn',
    label: 'Presturinn',
  },
  {
    value: 'Prince',
    label: 'Prince',
  },
  {
    value: 'Prince',
    label: 'Prince',
  },
  {
    value: 'Pruple rain',
    label: 'Pruple rain',
  },
  {
    value: 'PS',
    label: 'PS',
  },
  {
    value: 'Psycho Killer',
    label: 'Psycho Killer',
  },
  {
    value: 'PT',
    label: 'PT',
  },
  {
    value: 'PT',
    label: 'PT',
  },
  {
    value: 'PT afbrigði',
    label: 'PT afbrigði',
  },
  {
    value: 'pth',
    label: 'pth',
  },
  {
    value: 'PTN',
    label: 'PTN',
  },
  {
    value: 'pupa',
    label: 'pupa',
  },
  {
    value: 'Pupur',
    label: 'Pupur',
  },
  {
    value: 'Purple rain',
    label: 'Purple rain',
  },
  {
    value: 'Púba',
    label: 'Púba',
  },
  {
    value: 'púpa',
    label: 'púpa',
  },
  {
    value: 'Púpa',
    label: 'Púpa',
  },
  {
    value: 'púpa (orange)',
    label: 'púpa (orange)',
  },
  {
    value: 'Púpa kibbi',
    label: 'Púpa kibbi',
  },
  {
    value: 'Púpa knoll',
    label: 'Púpa knoll',
  },
  {
    value: 'Púpa kúluhaus',
    label: 'Púpa kúluhaus',
  },
  {
    value: 'púpa lítil',
    label: 'púpa lítil',
  },
  {
    value: 'púpa með kúluhaus',
    label: 'púpa með kúluhaus',
  },
  {
    value: 'púpa nr 12',
    label: 'púpa nr 12',
  },
  {
    value: 'púpa nr 14',
    label: 'púpa nr 14',
  },
  {
    value: 'Púpa spes',
    label: 'Púpa spes',
  },
  {
    value: 'púpa, svört og rauð',
    label: 'púpa, svört og rauð',
  },
  {
    value: 'Púpur',
    label: 'Púpur',
  },
  {
    value: 'Púpur/Þurrflugur',
    label: 'Púpur/Þurrflugur',
  },
  {
    value: 'Púpur/Þurrflugur',
    label: 'Púpur/Þurrflugur',
  },
  {
    value: 'Queen',
    label: 'Queen',
  },
  {
    value: 'R Frances',
    label: 'R Frances',
  },
  {
    value: 'r frances cone',
    label: 'r frances cone',
  },
  {
    value: 'R Francis',
    label: 'R Francis',
  },
  {
    value: 'R frans',
    label: 'R frans',
  },
  {
    value: 'R. FR',
    label: 'R. FR',
  },
  {
    value: 'R. FR.',
    label: 'R. FR.',
  },
  {
    value: 'r. frances',
    label: 'r. frances',
  },
  {
    value: 'R. frances cone',
    label: 'R. frances cone',
  },
  {
    value: 'R. Frances keila',
    label: 'R. Frances keila',
  },
  {
    value: 'R. Frances keilutúba',
    label: 'R. Frances keilutúba',
  },
  {
    value: 'R. Frances míkrókeila',
    label: 'R. Frances míkrókeila',
  },
  {
    value: 'r. francés',
    label: 'r. francés',
  },
  {
    value: 'R. Smokkur',
    label: 'R. Smokkur',
  },
  {
    value: 'R.Frances',
    label: 'R.Frances',
  },
  {
    value: 'R.Francis',
    label: 'R.Francis',
  },
  {
    value: 'r.fransis',
    label: 'r.fransis',
  },
  {
    value: 'R.S',
    label: 'R.S',
  },
  {
    value: 'R.S.',
    label: 'R.S.',
  },
  {
    value: 'RA Snail',
    label: 'RA Snail',
  },
  {
    value: 'Radioactive snail',
    label: 'Radioactive snail',
  },
  {
    value: 'Rainbow Ghost',
    label: 'Rainbow Ghost',
  },
  {
    value: 'randa',
    label: 'randa',
  },
  {
    value: 'Randa Sveins Þórs',
    label: 'Randa Sveins Þórs',
  },
  {
    value: 'Randalín',
    label: 'Randalín',
  },
  {
    value: 'Randy Candy',
    label: 'Randy Candy',
  },
  {
    value: 'Rappala',
    label: 'Rappala',
  },
  {
    value: 'raud frances',
    label: 'raud frances',
  },
  {
    value: 'Raud frances 1/4" keila',
    label: 'Raud frances 1/4" keila',
  },
  {
    value: 'Rauð',
    label: 'Rauð',
  },
  {
    value: 'Rauð  Frances',
    label: 'Rauð  Frances',
  },
  {
    value: 'Rauð ????',
    label: 'Rauð ????',
  },
  {
    value: 'rauð fances',
    label: 'rauð fances',
  },
  {
    value: 'Rauð Flæðamús.',
    label: 'Rauð Flæðamús.',
  },
  {
    value: 'Rauð Fnances',
    label: 'Rauð Fnances',
  },
  {
    value: 'Rauð fr',
    label: 'Rauð fr',
  },
  {
    value: 'Rauð Fr.',
    label: 'Rauð Fr.',
  },
  {
    value: 'Rauð Fr.1',
    label: 'Rauð Fr.1',
  },
  {
    value: 'Rauð Franc',
    label: 'Rauð Franc',
  },
  {
    value: 'Rauð Frances',
    label: 'Rauð Frances',
  },
  {
    value: 'Rauð Frances',
    label: 'Rauð Frances',
  },
  {
    value: 'Rauð Frances - Míkró með keilu',
    label: 'Rauð Frances - Míkró með keilu',
  },
  {
    value: 'Rauð frances  keila',
    label: 'Rauð frances  keila',
  },
  {
    value: 'Rauð Frances (DE)',
    label: 'Rauð Frances (DE)',
  },
  {
    value: 'rauð frances 1/2" keila',
    label: 'rauð frances 1/2" keila',
  },
  {
    value: 'Rauð frances cone',
    label: 'Rauð frances cone',
  },
  {
    value: 'Rauð frances DE',
    label: 'Rauð frances DE',
  },
  {
    value: 'Rauð frances keila',
    label: 'Rauð frances keila',
  },
  {
    value: 'Rauð frances micro',
    label: 'Rauð frances micro',
  },
  {
    value: 'Rauð frances túba',
    label: 'Rauð frances túba',
  },
  {
    value: 'Rauð frances túba (De)',
    label: 'Rauð frances túba (De)',
  },
  {
    value: 'Rauð Frances túba (Diskó edition)',
    label: 'Rauð Frances túba (Diskó edition)',
  },
  {
    value: 'Rauð frances túpa',
    label: 'Rauð frances túpa',
  },
  {
    value: 'Rauð francis',
    label: 'Rauð francis',
  },
  {
    value: 'Rauð francis',
    label: 'Rauð francis',
  },
  {
    value: 'Rauð Francis keila',
    label: 'Rauð Francis keila',
  },
  {
    value: 'Rauð francis túba',
    label: 'Rauð francis túba',
  },
  {
    value: 'Rauð Francis túpa',
    label: 'Rauð Francis túpa',
  },
  {
    value: 'Rauð francistúba',
    label: 'Rauð francistúba',
  },
  {
    value: 'rauð franses',
    label: 'rauð franses',
  },
  {
    value: 'Rauð franses keila',
    label: 'Rauð franses keila',
  },
  {
    value: 'Rauð Fransis',
    label: 'Rauð Fransis',
  },
  {
    value: 'Rauð freances keila',
    label: 'Rauð freances keila',
  },
  {
    value: 'rauð krafla',
    label: 'rauð krafla',
  },
  {
    value: 'rauð Lippa',
    label: 'rauð Lippa',
  },
  {
    value: 'rauð lyppa',
    label: 'rauð lyppa',
  },
  {
    value: 'Rauð nobler',
    label: 'Rauð nobler',
  },
  {
    value: 'Rauð Perla',
    label: 'Rauð Perla',
  },
  {
    value: 'Rauð púpa',
    label: 'Rauð púpa',
  },
  {
    value: 'Rauð Snælda',
    label: 'Rauð Snælda',
  },
  {
    value: 'Rauð Stirða',
    label: 'Rauð Stirða',
  },
  {
    value: 'Rauð straumfluga',
    label: 'Rauð straumfluga',
  },
  {
    value: 'Rauð túpa',
    label: 'Rauð túpa',
  },
  {
    value: 'Rauða frances',
    label: 'Rauða frances',
  },
  {
    value: 'Rauð-brún Frances',
    label: 'Rauð-brún Frances',
  },
  {
    value: 'Rauð-brún Francis',
    label: 'Rauð-brún Francis',
  },
  {
    value: 'rauðdraugur',
    label: 'rauðdraugur',
  },
  {
    value: 'Rauððr Frances cone',
    label: 'Rauððr Frances cone',
  },
  {
    value: 'Rauð-Frances',
    label: 'Rauð-Frances',
  },
  {
    value: 'Rauð-Francis',
    label: 'Rauð-Francis',
  },
  {
    value: 'Rauðhetta',
    label: 'Rauðhetta',
  },
  {
    value: 'Rauðhetta, straumfluga nr 8',
    label: 'Rauðhetta, straumfluga nr 8',
  },
  {
    value: 'Rauðlippa',
    label: 'Rauðlippa',
  },
  {
    value: 'rauðskeggur',
    label: 'rauðskeggur',
  },
  {
    value: 'Rauðu Frances',
    label: 'Rauðu Frances',
  },
  {
    value: 'Rauður Dýrbítur',
    label: 'Rauður Dýrbítur',
  },
  {
    value: 'Rauður fr',
    label: 'Rauður fr',
  },
  {
    value: 'Rauður Fr.',
    label: 'Rauður Fr.',
  },
  {
    value: 'Rauður Fran',
    label: 'Rauður Fran',
  },
  {
    value: 'Rauður frances',
    label: 'Rauður frances',
  },
  {
    value: 'Rauður Frances ( DE)',
    label: 'Rauður Frances ( DE)',
  },
  {
    value: 'Rauður frances (DE)',
    label: 'Rauður frances (DE)',
  },
  {
    value: 'Rauður frances cone',
    label: 'Rauður frances cone',
  },
  {
    value: 'Rauður frances cone',
    label: 'Rauður frances cone',
  },
  {
    value: 'Rauður frances DE',
    label: 'Rauður frances DE',
  },
  {
    value: 'rauður frances gullkrókur',
    label: 'rauður frances gullkrókur',
  },
  {
    value: 'Rauður frances keila',
    label: 'Rauður frances keila',
  },
  {
    value: 'Rauður frances keilutúpa',
    label: 'Rauður frances keilutúpa',
  },
  {
    value: 'rauður frances kúluhaus',
    label: 'rauður frances kúluhaus',
  },
  {
    value: 'Rauður frances m/keilu',
    label: 'Rauður frances m/keilu',
  },
  {
    value: 'Rauður frances micro',
    label: 'Rauður frances micro',
  },
  {
    value: 'Rauður Frances túba',
    label: 'Rauður Frances túba',
  },
  {
    value: 'Rauður frances,örkeila',
    label: 'Rauður frances,örkeila',
  },
  {
    value: 'Rauður francis',
    label: 'Rauður francis',
  },
  {
    value: 'Rauður Francis',
    label: 'Rauður Francis',
  },
  {
    value: 'Rauður fransces',
    label: 'Rauður fransces',
  },
  {
    value: 'Rauður Friggi',
    label: 'Rauður Friggi',
  },
  {
    value: 'Rauður Frns',
    label: 'Rauður Frns',
  },
  {
    value: 'Rauður Ghost',
    label: 'Rauður Ghost',
  },
  {
    value: 'Rauður góst',
    label: 'Rauður góst',
  },
  {
    value: 'Rauður góstari',
    label: 'Rauður góstari',
  },
  {
    value: 'Rauður góstari með augum',
    label: 'Rauður góstari með augum',
  },
  {
    value: 'Rauður Killer',
    label: 'Rauður Killer',
  },
  {
    value: 'Rauður Krókur',
    label: 'Rauður Krókur',
  },
  {
    value: 'Rauður Mepps',
    label: 'Rauður Mepps',
  },
  {
    value: 'Rauður nobbler',
    label: 'Rauður nobbler',
  },
  {
    value: 'rauður nobler',
    label: 'rauður nobler',
  },
  {
    value: 'rauður nobler m. kögri',
    label: 'rauður nobler m. kögri',
  },
  {
    value: 'rauður nobler no.10',
    label: 'rauður nobler no.10',
  },
  {
    value: 'Rauður Reflex',
    label: 'Rauður Reflex',
  },
  {
    value: 'Rauður smokkur',
    label: 'Rauður smokkur',
  },
  {
    value: 'rauður spinner',
    label: 'rauður spinner',
  },
  {
    value: 'Rauður spinner',
    label: 'Rauður spinner',
  },
  {
    value: 'Rauður Sq.',
    label: 'Rauður Sq.',
  },
  {
    value: 'Rauður Squirmy',
    label: 'Rauður Squirmy',
  },
  {
    value: 'Rauður Toby',
    label: 'Rauður Toby',
  },
  {
    value: 'Rauður, blue fox',
    label: 'Rauður, blue fox',
  },
  {
    value: 'rauður/hvítur/svartur toby',
    label: 'rauður/hvítur/svartur toby',
  },
  {
    value: 'Rauur Frances',
    label: 'Rauur Frances',
  },
  {
    value: 'Read ghost',
    label: 'Read ghost',
  },
  {
    value: 'read gost',
    label: 'read gost',
  },
  {
    value: 'readghost',
    label: 'readghost',
  },
  {
    value: 'recktor',
    label: 'recktor',
  },
  {
    value: 'Recommended Trout flies for Iceland',
    label: 'Recommended Trout flies for Iceland',
  },
  {
    value: 'rector',
    label: 'rector',
  },
  {
    value: 'Red Bugger',
    label: 'Red Bugger',
  },
  {
    value: 'Red fr.',
    label: 'Red fr.',
  },
  {
    value: 'Red Frances',
    label: 'Red Frances',
  },
  {
    value: 'Red Frances',
    label: 'Red Frances',
  },
  {
    value: 'Red Frances DE',
    label: 'Red Frances DE',
  },
  {
    value: 'Red francis tube',
    label: 'Red francis tube',
  },
  {
    value: 'Red ghost',
    label: 'Red ghost',
  },
  {
    value: 'Red goast',
    label: 'Red goast',
  },
  {
    value: 'Red Nobbler',
    label: 'Red Nobbler',
  },
  {
    value: 'red nobler',
    label: 'red nobler',
  },
  {
    value: 'Red Stirða',
    label: 'Red Stirða',
  },
  {
    value: 'reddarinn',
    label: 'reddarinn',
  },
  {
    value: 'RedFox',
    label: 'RedFox',
  },
  {
    value: 'redgoast',
    label: 'redgoast',
  },
  {
    value: 'Redgost',
    label: 'Redgost',
  },
  {
    value: 'reflex',
    label: 'reflex',
  },
  {
    value: 'Reflex Red Silver',
    label: 'Reflex Red Silver',
  },
  {
    value: 'Reflex silfur',
    label: 'Reflex silfur',
  },
  {
    value: 'rektoe',
    label: 'rektoe',
  },
  {
    value: 'Rektor',
    label: 'Rektor',
  },
  {
    value: 'Rektor',
    label: 'Rektor',
  },
  {
    value: 'Reuð Frances',
    label: 'Reuð Frances',
  },
  {
    value: 'r-frances',
    label: 'r-frances',
  },
  {
    value: 'Rfrancis',
    label: 'Rfrancis',
  },
  {
    value: 'R-góst',
    label: 'R-góst',
  },
  {
    value: 'Risa Streamer',
    label: 'Risa Streamer',
  },
  {
    value: 'Ristill',
    label: 'Ristill',
  },
  {
    value: 'RM tandem shiner',
    label: 'RM tandem shiner',
  },
  {
    value: 'Rolla',
    label: 'Rolla',
  },
  {
    value: 'Rolla',
    label: 'Rolla',
  },
  {
    value: 'Rolla græn',
    label: 'Rolla græn',
  },
  {
    value: 'Rollan',
    label: 'Rollan',
  },
  {
    value: 'Rollan',
    label: 'Rollan',
  },
  {
    value: 'Rollan 10',
    label: 'Rollan 10',
  },
  {
    value: 'Rollan, 10',
    label: 'Rollan, 10',
  },
  {
    value: 'Rollann',
    label: 'Rollann',
  },
  {
    value: 'Rollu afbrigði',
    label: 'Rollu afbrigði',
  },
  {
    value: 'Roman Moser Tandem Shiner',
    label: 'Roman Moser Tandem Shiner',
  },
  {
    value: 'rotta',
    label: 'rotta',
  },
  {
    value: 'Royal Coachman',
    label: 'Royal Coachman',
  },
  {
    value: 'Royal Wulff',
    label: 'Royal Wulff',
  },
  {
    value: 'rusty klinkhammer',
    label: 'rusty klinkhammer',
  },
  {
    value: 'rækja',
    label: 'rækja',
  },
  {
    value: 'Rækja túpa',
    label: 'Rækja túpa',
  },
  {
    value: 'Rönd',
    label: 'Rönd',
  },
  {
    value: 'Röndin',
    label: 'Röndin',
  },
  {
    value: 'Röndin amber',
    label: 'Röndin amber',
  },
  {
    value: 'Röndin græn',
    label: 'Röndin græn',
  },
  {
    value: 'S',
    label: 'S',
  },
  {
    value: 's. worm',
    label: 's. worm',
  },
  {
    value: 'S. Wormy',
    label: 'S. Wormy',
  },
  {
    value: 's.s stirða',
    label: 's.s stirða',
  },
  {
    value: 's.s.stirða',
    label: 's.s.stirða',
  },
  {
    value: 'S.w',
    label: 'S.w',
  },
  {
    value: 's.worm',
    label: 's.worm',
  },
  {
    value: 'S.wormy',
    label: 'S.wormy',
  },
  {
    value: 'Salamandra',
    label: 'Salamandra',
  },
  {
    value: 'Salmo-Magnus',
    label: 'Salmo-Magnus',
  },
  {
    value: 'sama',
    label: 'sama',
  },
  {
    value: 'Sand silk',
    label: 'Sand silk',
  },
  {
    value: 'Sandlóan',
    label: 'Sandlóan',
  },
  {
    value: 'Sandlóan',
    label: 'Sandlóan',
  },
  {
    value: 'Sandlóan straumfluga',
    label: 'Sandlóan straumfluga',
  },
  {
    value: 'Sandsíli',
    label: 'Sandsíli',
  },
  {
    value: 'Scullhead',
    label: 'Scullhead',
  },
  {
    value: 'Scwörly wormly',
    label: 'Scwörly wormly',
  },
  {
    value: 'Settum í ca. 20 bleikjur undir ..',
    label: 'Settum í ca. 20 bleikjur undir ..',
  },
  {
    value: 'Seyðingur',
    label: 'Seyðingur',
  },
  {
    value: 'SF cone',
    label: 'SF cone',
  },
  {
    value: 'SF cone',
    label: 'SF cone',
  },
  {
    value: 's-frances',
    label: 's-frances',
  },
  {
    value: 'Siggi',
    label: 'Siggi',
  },
  {
    value: 'Sigginn',
    label: 'Sigginn',
  },
  {
    value: 'sigló sjúk',
    label: 'sigló sjúk',
  },
  {
    value: 'silfraður salar',
    label: 'silfraður salar',
  },
  {
    value: 'Silfraður Toby',
    label: 'Silfraður Toby',
  },
  {
    value: 'silfrið',
    label: 'silfrið',
  },
  {
    value: 'Silfur dyrbittur',
    label: 'Silfur dyrbittur',
  },
  {
    value: 'Silfur kopar Toby',
    label: 'Silfur kopar Toby',
  },
  {
    value: 'Silfur perla',
    label: 'Silfur perla',
  },
  {
    value: 'Silfur perlan',
    label: 'Silfur perlan',
  },
  {
    value: 'silfur toby',
    label: 'silfur toby',
  },
  {
    value: 'Silfurbleik',
    label: 'Silfurbleik',
  },
  {
    value: 'Silfurperla',
    label: 'Silfurperla',
  },
  {
    value: 'silunga maðkur',
    label: 'silunga maðkur',
  },
  {
    value: 'Silver blue',
    label: 'Silver blue',
  },
  {
    value: 'silver doctor',
    label: 'silver doctor',
  },
  {
    value: 'Silver Doktor',
    label: 'Silver Doktor',
  },
  {
    value: 'Silver Fox',
    label: 'Silver Fox',
  },
  {
    value: 'Silver Grey',
    label: 'Silver Grey',
  },
  {
    value: 'Silver Sheep',
    label: 'Silver Sheep',
  },
  {
    value: 'Silver Wilkinson',
    label: 'Silver Wilkinson',
  },
  {
    value: 'Silvur Toby',
    label: 'Silvur Toby',
  },
  {
    value: 'Silvur Toby 12g',
    label: 'Silvur Toby 12g',
  },
  {
    value: 'Simalina',
    label: 'Simalina',
  },
  {
    value: 'Simms',
    label: 'Simms',
  },
  {
    value: 'Simms orange',
    label: 'Simms orange',
  },
  {
    value: 'Sirða',
    label: 'Sirða',
  },
  {
    value: 'Síli',
    label: 'Síli',
  },
  {
    value: 'Síli frances.is',
    label: 'Síli frances.is',
  },
  {
    value: 'Símalína',
    label: 'Símalína',
  },
  {
    value: 'Síma-Lína, straumfluga nr. 8',
    label: 'Síma-Lína, straumfluga nr. 8',
  },
  {
    value: 'Sjá að ofan',
    label: 'Sjá að ofan',
  },
  {
    value: 'Skarauta',
    label: 'Skarauta',
  },
  {
    value: 'Skáldalækur',
    label: 'Skáldalækur',
  },
  {
    value: 'Skíða',
    label: 'Skíða',
  },
  {
    value: 'Skíði',
    label: 'Skíði',
  },
  {
    value: 'Skjalda',
    label: 'Skjalda',
  },
  {
    value: 'Skotta',
    label: 'Skotta',
  },
  {
    value: 'Skógá',
    label: 'Skógá',
  },
  {
    value: 'Skrauta',
    label: 'Skrauta',
  },
  {
    value: 'skröggur',
    label: 'skröggur',
  },
  {
    value: 'Skröggur hitch',
    label: 'Skröggur hitch',
  },
  {
    value: 'Skröggur túba',
    label: 'Skröggur túba',
  },
  {
    value: 'Skröggur túba',
    label: 'Skröggur túba',
  },
  {
    value: 'Skuggi',
    label: 'Skuggi',
  },
  {
    value: 'Skuggi cut face sunray shadow',
    label: 'Skuggi cut face sunray shadow',
  },
  {
    value: 'Skvörmi',
    label: 'Skvörmi',
  },
  {
    value: 'Skvörmí',
    label: 'Skvörmí',
  },
  {
    value: 'Skörmy',
    label: 'Skörmy',
  },
  {
    value: 'slandsspónn',
    label: 'slandsspónn',
  },
  {
    value: 'Smokkur',
    label: 'Smokkur',
  },
  {
    value: 'Smokkurinn',
    label: 'Smokkurinn',
  },
  {
    value: 'Smokkurinn (appelsínugulur)',
    label: 'Smokkurinn (appelsínugulur)',
  },
  {
    value: 'snaedel',
    label: 'snaedel',
  },
  {
    value: 'Snaelda',
    label: 'Snaelda',
  },
  {
    value: 'Snaelda black',
    label: 'Snaelda black',
  },
  {
    value: 'Snaelda german',
    label: 'Snaelda german',
  },
  {
    value: 'Snaelda red',
    label: 'Snaelda red',
  },
  {
    value: 'Snaæld',
    label: 'Snaæld',
  },
  {
    value: 'Snákur',
    label: 'Snákur',
  },
  {
    value: 'Snjóhvít',
    label: 'Snjóhvít',
  },
  {
    value: 'snñlda',
    label: 'snñlda',
  },
  {
    value: 'Snúður',
    label: 'Snúður',
  },
  {
    value: 'Snúningspónn',
    label: 'Snúningspónn',
  },
  {
    value: 'snæla',
    label: 'snæla',
  },
  {
    value: 'Snælda',
    label: 'Snælda',
  },
  {
    value: 'snælda',
    label: 'snælda',
  },
  {
    value: 'Snælda  Þýsk',
    label: 'Snælda  Þýsk',
  },
  {
    value: 'Snælda 1/2" svört',
    label: 'Snælda 1/2" svört',
  },
  {
    value: 'Snælda Bl/Sv',
    label: 'Snælda Bl/Sv',
  },
  {
    value: 'Snælda blá',
    label: 'Snælda blá',
  },
  {
    value: 'Snælda blá, keila',
    label: 'Snælda blá, keila',
  },
  {
    value: 'Snælda bleik/gul',
    label: 'Snælda bleik/gul',
  },
  {
    value: 'Snælda græn',
    label: 'Snælda græn',
  },
  {
    value: 'Snælda micro  cone',
    label: 'Snælda micro  cone',
  },
  {
    value: 'Snælda micro cone',
    label: 'Snælda micro cone',
  },
  {
    value: 'Snælda rauð',
    label: 'Snælda rauð',
  },
  {
    value: 'Snælda rsvört',
    label: 'Snælda rsvört',
  },
  {
    value: 'Snælda Sv',
    label: 'Snælda Sv',
  },
  {
    value: 'Snælda svört',
    label: 'Snælda svört',
  },
  {
    value: 'Snælda svört 1',
    label: 'Snælda svört 1',
  },
  {
    value: 'snælda túba',
    label: 'snælda túba',
  },
  {
    value: 'Snælda þísk',
    label: 'Snælda þísk',
  },
  {
    value: 'snælda þýsk',
    label: 'snælda þýsk',
  },
  {
    value: 'snælda, þýsk',
    label: 'snælda, þýsk',
  },
  {
    value: 'Snær',
    label: 'Snær',
  },
  {
    value: 'Solla stirða',
    label: 'Solla stirða',
  },
  {
    value: 'Sonetta',
    label: 'Sonetta',
  },
  {
    value: 'sonja',
    label: 'sonja',
  },
  {
    value: 'Sonneta',
    label: 'Sonneta',
  },
  {
    value: 'sonnetta',
    label: 'sonnetta',
  },
  {
    value: 'Sp',
    label: 'Sp',
  },
  {
    value: 'SPECAL',
    label: 'SPECAL',
  },
  {
    value: 'spinner',
    label: 'spinner',
  },
  {
    value: 'Spinner',
    label: 'Spinner',
  },
  {
    value: 'Spinner orance',
    label: 'Spinner orance',
  },
  {
    value: 'spinner rauður',
    label: 'spinner rauður',
  },
  {
    value: 'Spinnet',
    label: 'Spinnet',
  },
  {
    value: 'Spinning',
    label: 'Spinning',
  },
  {
    value: 'spintube',
    label: 'spintube',
  },
  {
    value: 'Sponn',
    label: 'Sponn',
  },
  {
    value: 'Spónn',
    label: 'Spónn',
  },
  {
    value: 'SpónnHelgafellshylur',
    label: 'SpónnHelgafellshylur',
  },
  {
    value: 'Spun',
    label: 'Spun',
  },
  {
    value: 'spunn',
    label: 'spunn',
  },
  {
    value: 'Spún',
    label: 'Spún',
  },
  {
    value: 'spúnn',
    label: 'spúnn',
  },
  {
    value: 'spúnn - Lippa',
    label: 'spúnn - Lippa',
  },
  {
    value: 'spúnn síli',
    label: 'spúnn síli',
  },
  {
    value: 'Spúnn spinner',
    label: 'Spúnn spinner',
  },
  {
    value: 'Squermy worm',
    label: 'Squermy worm',
  },
  {
    value: 'Squirmy',
    label: 'Squirmy',
  },
  {
    value: 'Squirmy',
    label: 'Squirmy',
  },
  {
    value: 'Squirmy rauður',
    label: 'Squirmy rauður',
  },
  {
    value: 'Squirmy worm',
    label: 'Squirmy worm',
  },
  {
    value: 'Squirmy worm',
    label: 'Squirmy worm',
  },
  {
    value: 'Squirmy Wormy',
    label: 'Squirmy Wormy',
  },
  {
    value: 'Squirmy wormy',
    label: 'Squirmy wormy',
  },
  {
    value: 'squirne worm',
    label: 'squirne worm',
  },
  {
    value: 'Squirny',
    label: 'Squirny',
  },
  {
    value: 'squrmy',
    label: 'squrmy',
  },
  {
    value: 'Squrmy',
    label: 'Squrmy',
  },
  {
    value: 'Squryworm',
    label: 'Squryworm',
  },
  {
    value: 'Sqw',
    label: 'Sqw',
  },
  {
    value: 'Sqwirmy',
    label: 'Sqwirmy',
  },
  {
    value: 'SRS',
    label: 'SRS',
  },
  {
    value: 'SRS keilutúba',
    label: 'SRS keilutúba',
  },
  {
    value: 's-snælda',
    label: 's-snælda',
  },
  {
    value: 'Steamer',
    label: 'Steamer',
  },
  {
    value: 'Steinar Logi',
    label: 'Steinar Logi',
  },
  {
    value: 'Stirđa',
    label: 'Stirđa',
  },
  {
    value: 'Stirða',
    label: 'Stirða',
  },
  {
    value: 'Stirða',
    label: 'Stirða',
  },
  {
    value: 'Stirða bleik',
    label: 'Stirða bleik',
  },
  {
    value: 'Stirða Hvít',
    label: 'Stirða Hvít',
  },
  {
    value: 'Stirða orange',
    label: 'Stirða orange',
  },
  {
    value: 'Stirða rauð',
    label: 'Stirða rauð',
  },
  {
    value: 'Stirða svört',
    label: 'Stirða svört',
  },
  {
    value: 'Stirða, bleik',
    label: 'Stirða, bleik',
  },
  {
    value: 'Stirða/ orange',
    label: 'Stirða/ orange',
  },
  {
    value: 'S-toby',
    label: 'S-toby',
  },
  {
    value: 'Stormy Daniels',
    label: 'Stormy Daniels',
  },
  {
    value: 'Stórkríli',
    label: 'Stórkríli',
  },
  {
    value: 'straum fluga',
    label: 'straum fluga',
  },
  {
    value: 'Straumfl',
    label: 'Straumfl',
  },
  {
    value: 'straumfluga',
    label: 'straumfluga',
  },
  {
    value: 'straumfluga nr 8',
    label: 'straumfluga nr 8',
  },
  {
    value: 'straumfluga, silfur og blá',
    label: 'straumfluga, silfur og blá',
  },
  {
    value: 'Straumflugan mín',
    label: 'Straumflugan mín',
  },
  {
    value: 'Straumfuga',
    label: 'Straumfuga',
  },
  {
    value: 'Streamer',
    label: 'Streamer',
  },
  {
    value: 'streamer nr 8',
    label: 'streamer nr 8',
  },
  {
    value: 'streaner',
    label: 'streaner',
  },
  {
    value: 'streemer',
    label: 'streemer',
  },
  {
    value: 'stremer',
    label: 'stremer',
  },
  {
    value: 'Strumpurinn',
    label: 'Strumpurinn',
  },
  {
    value: 'Sturta Rauð',
    label: 'Sturta Rauð',
  },
  {
    value: 'Sturtan',
    label: 'Sturtan',
  },
  {
    value: 'Styrða',
    label: 'Styrða',
  },
  {
    value: 'Subrey S',
    label: 'Subrey S',
  },
  {
    value: 'Suddi',
    label: 'Suddi',
  },
  {
    value: 'Sun ray',
    label: 'Sun ray',
  },
  {
    value: 'Sun ray Bl al',
    label: 'Sun ray Bl al',
  },
  {
    value: 'Sun Ray Shadow',
    label: 'Sun Ray Shadow',
  },
  {
    value: 'Sun ray shadows',
    label: 'Sun ray shadows',
  },
  {
    value: 'Sunmonkey',
    label: 'Sunmonkey',
  },
  {
    value: 'Sunmonkey 1´',
    label: 'Sunmonkey 1´',
  },
  {
    value: 'Sunna',
    label: 'Sunna',
  },
  {
    value: 'Sunnrey',
    label: 'Sunnrey',
  },
  {
    value: 'Sunrau Shadow',
    label: 'Sunrau Shadow',
  },
  {
    value: 'Sunray',
    label: 'Sunray',
  },
  {
    value: 'Sunray',
    label: 'Sunray',
  },
  {
    value: 'Sunray (gul)',
    label: 'Sunray (gul)',
  },
  {
    value: 'Sunray 1/2',
    label: 'Sunray 1/2',
  },
  {
    value: 'Sunray AKA',
    label: 'Sunray AKA',
  },
  {
    value: 'Sunray balck',
    label: 'Sunray balck',
  },
  {
    value: 'Sunray black blue',
    label: 'Sunray black blue',
  },
  {
    value: 'Sunray Fishskull',
    label: 'Sunray Fishskull',
  },
  {
    value: 'Sunray Green',
    label: 'Sunray Green',
  },
  {
    value: 'Sunray gulur',
    label: 'Sunray gulur',
  },
  {
    value: 'Sunray hitch',
    label: 'Sunray hitch',
  },
  {
    value: 'Sunray keila',
    label: 'Sunray keila',
  },
  {
    value: 'Sunray Micro',
    label: 'Sunray Micro',
  },
  {
    value: 'Sunray Monkey',
    label: 'Sunray Monkey',
  },
  {
    value: 'Sunray Monkey - micro',
    label: 'Sunray Monkey - micro',
  },
  {
    value: 'Sunray monkey Micro',
    label: 'Sunray monkey Micro',
  },
  {
    value: 'Sunray orange',
    label: 'Sunray orange',
  },
  {
    value: 'Sunray sadow',
    label: 'Sunray sadow',
  },
  {
    value: 'Sunray scull',
    label: 'Sunray scull',
  },
  {
    value: 'Sunray scull',
    label: 'Sunray scull',
  },
  {
    value: 'Sunray Sh.',
    label: 'Sunray Sh.',
  },
  {
    value: 'Sunray Shadov',
    label: 'Sunray Shadov',
  },
  {
    value: 'Sunray shadow',
    label: 'Sunray shadow',
  },
  {
    value: 'Sunray Shadow',
    label: 'Sunray Shadow',
  },
  {
    value: 'Sunray Shadow keila',
    label: 'Sunray Shadow keila',
  },
  {
    value: 'Sunray shadow tube',
    label: 'Sunray shadow tube',
  },
  {
    value: 'Sunray skull',
    label: 'Sunray skull',
  },
  {
    value: 'Sunray skullhead',
    label: 'Sunray skullhead',
  },
  {
    value: 'Sunray, keila',
    label: 'Sunray, keila',
  },
  {
    value: 'sunrey',
    label: 'sunrey',
  },
  {
    value: 'Sunrey keila',
    label: 'Sunrey keila',
  },
  {
    value: 'sunrey s',
    label: 'sunrey s',
  },
  {
    value: 'Sunrey Shadow',
    label: 'Sunrey Shadow',
  },
  {
    value: 'Sunrey skull',
    label: 'Sunrey skull',
  },
  {
    value: 'sunrise shadow',
    label: 'sunrise shadow',
  },
  {
    value: 'Super Tinsel',
    label: 'Super Tinsel',
  },
  {
    value: 'Superpúpa',
    label: 'Superpúpa',
  },
  {
    value: 'suray',
    label: 'suray',
  },
  {
    value: 'Surnay shadow',
    label: 'Surnay shadow',
  },
  {
    value: 'Sú',
    label: 'Sú',
  },
  {
    value: 'súdda',
    label: 'súdda',
  },
  {
    value: 'Súddi',
    label: 'Súddi',
  },
  {
    value: 'súddinn',
    label: 'súddinn',
  },
  {
    value: 'Súperpúpa',
    label: 'Súperpúpa',
  },
  {
    value: 'sv dýrbítur',
    label: 'sv dýrbítur',
  },
  {
    value: 'SV Frances',
    label: 'SV Frances',
  },
  {
    value: 'sv Francses',
    label: 'sv Francses',
  },
  {
    value: 'Sv Nobler',
    label: 'Sv Nobler',
  },
  {
    value: 'Sv Snælda',
    label: 'Sv Snælda',
  },
  {
    value: 'Sv toby',
    label: 'Sv toby',
  },
  {
    value: 'Sv. dýrbítur',
    label: 'Sv. dýrbítur',
  },
  {
    value: 'Sv. Frances',
    label: 'Sv. Frances',
  },
  {
    value: 'sv. frances',
    label: 'sv. frances',
  },
  {
    value: 'Sv. frances cone',
    label: 'Sv. frances cone',
  },
  {
    value: 'Sv. frances conehead',
    label: 'Sv. frances conehead',
  },
  {
    value: 'Sv. frances túpa',
    label: 'Sv. frances túpa',
  },
  {
    value: 'sv. kúluhaus',
    label: 'sv. kúluhaus',
  },
  {
    value: 'Sv. Smokkur',
    label: 'Sv. Smokkur',
  },
  {
    value: 'sv. snælda',
    label: 'sv. snælda',
  },
  {
    value: 'Sv. Toby',
    label: 'Sv. Toby',
  },
  {
    value: 'Sv. Toby 20gr.',
    label: 'Sv. Toby 20gr.',
  },
  {
    value: 'Sv. Tóbý',
    label: 'Sv. Tóbý',
  },
  {
    value: 'Sv.dýrbítur',
    label: 'Sv.dýrbítur',
  },
  {
    value: 'Sv.Frances',
    label: 'Sv.Frances',
  },
  {
    value: 'Sv.Francestúba',
    label: 'Sv.Francestúba',
  },
  {
    value: 'Sv.Krafla',
    label: 'Sv.Krafla',
  },
  {
    value: 'Sv.nobbler',
    label: 'Sv.nobbler',
  },
  {
    value: 'Sv.Toby',
    label: 'Sv.Toby',
  },
  {
    value: 'Svargulur nobbler nr 8',
    label: 'Svargulur nobbler nr 8',
  },
  {
    value: 'Svart Toby',
    label: 'Svart Toby',
  },
  {
    value: 'Svart vínyl',
    label: 'Svart vínyl',
  },
  {
    value: 'svart/hvít pheseant tail',
    label: 'svart/hvít pheseant tail',
  },
  {
    value: 'svarta tikka',
    label: 'svarta tikka',
  },
  {
    value: 'Svartagull',
    label: 'Svartagull',
  },
  {
    value: 'Svartan Nobler',
    label: 'Svartan Nobler',
  },
  {
    value: 'Svarthöfði',
    label: 'Svarthöfði',
  },
  {
    value: 'Svartu nobler',
    label: 'Svartu nobler',
  },
  {
    value: 'Svartur',
    label: 'Svartur',
  },
  {
    value: 'svartur  nobler',
    label: 'svartur  nobler',
  },
  {
    value: 'svartur frances',
    label: 'svartur frances',
  },
  {
    value: 'svartur francis',
    label: 'svartur francis',
  },
  {
    value: 'Svartur Friggi',
    label: 'Svartur Friggi',
  },
  {
    value: 'svartur killer',
    label: 'svartur killer',
  },
  {
    value: 'svartur killer',
    label: 'svartur killer',
  },
  {
    value: 'svartur klinkhammer',
    label: 'svartur klinkhammer',
  },
  {
    value: 'svartur klinkhammer.',
    label: 'svartur klinkhammer.',
  },
  {
    value: 'Svartur kúluhaus',
    label: 'Svartur kúluhaus',
  },
  {
    value: 'Svartur Kúluhaus m rauðu skotti',
    label: 'Svartur Kúluhaus m rauðu skotti',
  },
  {
    value: 'Svartur kúluhaus með rauðu',
    label: 'Svartur kúluhaus með rauðu',
  },
  {
    value: 'Svartur Nobbler',
    label: 'Svartur Nobbler',
  },
  {
    value: 'svartur og gylltur, röndóttur Toby',
    label: 'svartur og gylltur, röndóttur Toby',
  },
  {
    value: 'svartur og gylltur, röndóttur Toby',
    label: 'svartur og gylltur, röndóttur Toby',
  },
  {
    value: 'Svartur og hvítur kúluhaus',
    label: 'Svartur og hvítur kúluhaus',
  },
  {
    value: 'svartur streamer',
    label: 'svartur streamer',
  },
  {
    value: 'Svartur streamer',
    label: 'Svartur streamer',
  },
  {
    value: 'Svartur tobi',
    label: 'Svartur tobi',
  },
  {
    value: 'Svartur tobí',
    label: 'Svartur tobí',
  },
  {
    value: 'Svartur Toby',
    label: 'Svartur Toby',
  },
  {
    value: 'Svartur Toby',
    label: 'Svartur Toby',
  },
  {
    value: 'Svartur Toby 10gr',
    label: 'Svartur Toby 10gr',
  },
  {
    value: 'Svartur Toby 10gr.',
    label: 'Svartur Toby 10gr.',
  },
  {
    value: 'Svartur Toby 18.gr.',
    label: 'Svartur Toby 18.gr.',
  },
  {
    value: 'svartur toby eftirlíking',
    label: 'svartur toby eftirlíking',
  },
  {
    value: 'Svartur Toby.',
    label: 'Svartur Toby.',
  },
  {
    value: 'Svartur Toby10gr',
    label: 'Svartur Toby10gr',
  },
  {
    value: 'Svartur Tobý',
    label: 'Svartur Tobý',
  },
  {
    value: 'svartur Todi',
    label: 'svartur Todi',
  },
  {
    value: 'Svartur Tody',
    label: 'Svartur Tody',
  },
  {
    value: 'svartur topy',
    label: 'svartur topy',
  },
  {
    value: 'svartur tóbí',
    label: 'svartur tóbí',
  },
  {
    value: 'Svartur Tóby',
    label: 'Svartur Tóby',
  },
  {
    value: 'Svartur tóby lítil',
    label: 'Svartur tóby lítil',
  },
  {
    value: 'Svartur tóby lítill',
    label: 'Svartur tóby lítill',
  },
  {
    value: 'Svartur Tóbý',
    label: 'Svartur Tóbý',
  },
  {
    value: 'Svartur zonker',
    label: 'Svartur zonker',
  },
  {
    value: 'Svartur/grænn Marabou streamer',
    label: 'Svartur/grænn Marabou streamer',
  },
  {
    value: 'Svartur/gylltur Toby',
    label: 'Svartur/gylltur Toby',
  },
  {
    value: 'Svarturdýrbítur',
    label: 'Svarturdýrbítur',
  },
  {
    value: 'Svarturnobler',
    label: 'Svarturnobler',
  },
  {
    value: 'Svartus toby',
    label: 'Svartus toby',
  },
  {
    value: 'svatur toby',
    label: 'svatur toby',
  },
  {
    value: 'Svínahirðirinn',
    label: 'Svínahirðirinn',
  },
  {
    value: 'svorfuður',
    label: 'svorfuður',
  },
  {
    value: 'Svört',
    label: 'Svört',
  },
  {
    value: 'Svört',
    label: 'Svört',
  },
  {
    value: 'Svört flæðamús',
    label: 'Svört flæðamús',
  },
  {
    value: 'Svört francec',
    label: 'Svört francec',
  },
  {
    value: 'Svört Frances',
    label: 'Svört Frances',
  },
  {
    value: 'Svört Frances (kúluhaus)',
    label: 'Svört Frances (kúluhaus)',
  },
  {
    value: 'Svört frances keila',
    label: 'Svört frances keila',
  },
  {
    value: 'Svört frances túba',
    label: 'Svört frances túba',
  },
  {
    value: 'Svört Frances túpa',
    label: 'Svört Frances túpa',
  },
  {
    value: 'Svört Francis',
    label: 'Svört Francis',
  },
  {
    value: 'Svört keila',
    label: 'Svört keila',
  },
  {
    value: 'Svört keila',
    label: 'Svört keila',
  },
  {
    value: 'Svört krafla',
    label: 'Svört krafla',
  },
  {
    value: 'Svört Mýsla',
    label: 'Svört Mýsla',
  },
  {
    value: 'svört of hvít',
    label: 'svört of hvít',
  },
  {
    value: 'svört og rauð lirfa',
    label: 'svört og rauð lirfa',
  },
  {
    value: 'Svört púpa',
    label: 'Svört púpa',
  },
  {
    value: 'Svört snælda',
    label: 'Svört snælda',
  },
  {
    value: 'svört viníl púpa',
    label: 'svört viníl púpa',
  },
  {
    value: 'Sweeney todd',
    label: 'Sweeney todd',
  },
  {
    value: 'Sweep',
    label: 'Sweep',
  },
  {
    value: 'sydrix',
    label: 'sydrix',
  },
  {
    value: 'Sykurtútta',
    label: 'Sykurtútta',
  },
  {
    value: 'sæko stirða',
    label: 'sæko stirða',
  },
  {
    value: 'sækó stirða',
    label: 'sækó stirða',
  },
  {
    value: 'Sælda',
    label: 'Sælda',
  },
  {
    value: 'Sölvperlen',
    label: 'Sölvperlen',
  },
  {
    value: 'Tail & Black',
    label: 'Tail & Black',
  },
  {
    value: 'Tail and black',
    label: 'Tail and black',
  },
  {
    value: 'Tannhauser',
    label: 'Tannhauser',
  },
  {
    value: 'tannlæknirinn',
    label: 'tannlæknirinn',
  },
  {
    value: 'Teal and Black',
    label: 'Teal and Black',
  },
  {
    value: 'Thordis',
    label: 'Thordis',
  },
  {
    value: 'Thunder and lightning',
    label: 'Thunder and lightning',
  },
  {
    value: 'Tinsel',
    label: 'Tinsel',
  },
  {
    value: 'TOBI',
    label: 'TOBI',
  },
  {
    value: 'Tobu',
    label: 'Tobu',
  },
  {
    value: 'toby',
    label: 'toby',
  },
  {
    value: 'Toby',
    label: 'Toby',
  },
  {
    value: 'Toby 18g',
    label: 'Toby 18g',
  },
  {
    value: 'Toby 20 gr',
    label: 'Toby 20 gr',
  },
  {
    value: 'Toby 7 gr - kopar',
    label: 'Toby 7 gr - kopar',
  },
  {
    value: 'Toby blár',
    label: 'Toby blár',
  },
  {
    value: 'Toby Brons',
    label: 'Toby Brons',
  },
  {
    value: 'Toby Brúnn',
    label: 'Toby Brúnn',
  },
  {
    value: 'Toby grænn',
    label: 'Toby grænn',
  },
  {
    value: 'Toby koparlitur',
    label: 'Toby koparlitur',
  },
  {
    value: 'Toby rauður',
    label: 'Toby rauður',
  },
  {
    value: 'Toby Silver',
    label: 'Toby Silver',
  },
  {
    value: 'Toby Sv',
    label: 'Toby Sv',
  },
  {
    value: 'Toby sv.',
    label: 'Toby sv.',
  },
  {
    value: 'Toby svartur',
    label: 'Toby svartur',
  },
  {
    value: 'Toby Svartur',
    label: 'Toby Svartur',
  },
  {
    value: 'Toby T',
    label: 'Toby T',
  },
  {
    value: 'Toby Tiger',
    label: 'Toby Tiger',
  },
  {
    value: 'Toby Z',
    label: 'Toby Z',
  },
  {
    value: 'Toby, svartur',
    label: 'Toby, svartur',
  },
  {
    value: 'Toby.',
    label: 'Toby.',
  },
  {
    value: 'Toby-silver',
    label: 'Toby-silver',
  },
  {
    value: 'Tobý',
    label: 'Tobý',
  },
  {
    value: 'Topi',
    label: 'Topi',
  },
  {
    value: 'Topp',
    label: 'Topp',
  },
  {
    value: 'Toppfluga',
    label: 'Toppfluga',
  },
  {
    value: 'Topy',
    label: 'Topy',
  },
  {
    value: 'Toril´s Killer',
    label: 'Toril´s Killer',
  },
  {
    value: 'Torills Killer',
    label: 'Torills Killer',
  },
  {
    value: 'tóbí',
    label: 'tóbí',
  },
  {
    value: 'tóbí silfur',
    label: 'tóbí silfur',
  },
  {
    value: 'Tóby',
    label: 'Tóby',
  },
  {
    value: 'tóby silfur',
    label: 'tóby silfur',
  },
  {
    value: 'Tóbý',
    label: 'Tóbý',
  },
  {
    value: 'Tóbý',
    label: 'Tóbý',
  },
  {
    value: 'tólf volt',
    label: 'tólf volt',
  },
  {
    value: 'Tólf volt nr 10',
    label: 'Tólf volt nr 10',
  },
  {
    value: 'tólfvolt',
    label: 'tólfvolt',
  },
  {
    value: 'tólfvolt kúlupúpa nr. 10',
    label: 'tólfvolt kúlupúpa nr. 10',
  },
  {
    value: 'Tólfvolt nr 10',
    label: 'Tólfvolt nr 10',
  },
  {
    value: 'Tólfvolt nr. 10',
    label: 'Tólfvolt nr. 10',
  },
  {
    value: 'Trésíli',
    label: 'Trésíli',
  },
  {
    value: 'Trophy slee',
    label: 'Trophy slee',
  },
  {
    value: 'Trúður',
    label: 'Trúður',
  },
  {
    value: 'Tröllabarn',
    label: 'Tröllabarn',
  },
  {
    value: 'TS Shrimp',
    label: 'TS Shrimp',
  },
  {
    value: 'Tuba',
    label: 'Tuba',
  },
  {
    value: 'Tubes',
    label: 'Tubes',
  },
  {
    value: 'Tungstein púpa',
    label: 'Tungstein púpa',
  },
  {
    value: 'Tungsten Iða Túba',
    label: 'Tungsten Iða Túba',
  },
  {
    value: 'Tungsten streamer',
    label: 'Tungsten streamer',
  },
  {
    value: 'TURO',
    label: 'TURO',
  },
  {
    value: 'túba',
    label: 'túba',
  },
  {
    value: 'Túpa',
    label: 'Túpa',
  },
  {
    value: 'Túpa lítil',
    label: 'Túpa lítil',
  },
  {
    value: 'Tvistur a la Matti',
    label: 'Tvistur a la Matti',
  },
  {
    value: 'Töfralæknir',
    label: 'Töfralæknir',
  },
  {
    value: 'Töfralæknirinn',
    label: 'Töfralæknirinn',
  },
  {
    value: 'Ullar ormur',
    label: 'Ullar ormur',
  },
  {
    value: 'Ullarormur',
    label: 'Ullarormur',
  },
  {
    value: 'Undertaker',
    label: 'Undertaker',
  },
  {
    value: 'Úa túpa.',
    label: 'Úa túpa.',
  },
  {
    value: 'ÚFA',
    label: 'ÚFA',
  },
  {
    value: 'úva',
    label: 'úva',
  },
  {
    value: 'Vantar númer??',
    label: 'Vantar númer??',
  },
  {
    value: 'Var skýrð Hörgá',
    label: 'Var skýrð Hörgá',
  },
  {
    value: 'Varði',
    label: 'Varði',
  },
  {
    value: 'Veiðivatnagull',
    label: 'Veiðivatnagull',
  },
  {
    value: 'Vera',
    label: 'Vera',
  },
  {
    value: 'Vers',
    label: 'Vers',
  },
  {
    value: 'Vibrax',
    label: 'Vibrax',
  },
  {
    value: 'Vibrax 14Gr SFR',
    label: 'Vibrax 14Gr SFR',
  },
  {
    value: 'Vibrex',
    label: 'Vibrex',
  },
  {
    value: 'Við golfvöll',
    label: 'Við golfvöll',
  },
  {
    value: 'Villo Vallo',
    label: 'Villo Vallo',
  },
  {
    value: 'vinilripp',
    label: 'vinilripp',
  },
  {
    value: 'viníl púpa rauð',
    label: 'viníl púpa rauð',
  },
  {
    value: 'vinyl-púpa',
    label: 'vinyl-púpa',
  },
  {
    value: 'Vinylrib',
    label: 'Vinylrib',
  },
  {
    value: 'Víbrax',
    label: 'Víbrax',
  },
  {
    value: 'Víkingur',
    label: 'Víkingur',
  },
  {
    value: 'Vínil púpa',
    label: 'Vínil púpa',
  },
  {
    value: 'vorfluga',
    label: 'vorfluga',
  },
  {
    value: 'Vorflugapúpa',
    label: 'Vorflugapúpa',
  },
  {
    value: 'Vorflugupúpa',
    label: 'Vorflugupúpa',
  },
  {
    value: 'vorflugupúpa nr 10',
    label: 'vorflugupúpa nr 10',
  },
  {
    value: 'Vorflugupúpa nr. 10',
    label: 'Vorflugupúpa nr. 10',
  },
  {
    value: 'Vorflugupúpa nr.10',
    label: 'Vorflugupúpa nr.10',
  },
  {
    value: 'vorflugupúpa, kúluhaus nr.10',
    label: 'vorflugupúpa, kúluhaus nr.10',
  },
  {
    value: 'Vorpúpufluga',
    label: 'Vorpúpufluga',
  },
  {
    value: 'votfluga',
    label: 'votfluga',
  },
  {
    value: 'vynil púpa rauð',
    label: 'vynil púpa rauð',
  },
  {
    value: 'vynil, 12',
    label: 'vynil, 12',
  },
  {
    value: 'vynil. 12',
    label: 'vynil. 12',
  },
  {
    value: 'W Fancy',
    label: 'W Fancy',
  },
  {
    value: 'Watson Fancy',
    label: 'Watson Fancy',
  },
  {
    value: 'Watson Fancy 12',
    label: 'Watson Fancy 12',
  },
  {
    value: 'Watson´s Fancy',
    label: 'Watson´s Fancy',
  },
  {
    value: "watson's fancy",
    label: "watson's fancy",
  },
  {
    value: 'WF',
    label: 'WF',
  },
  {
    value: 'Whiskey',
    label: 'Whiskey',
  },
  {
    value: 'Whiskey fly afbr.',
    label: 'Whiskey fly afbr.',
  },
  {
    value: 'White Bird',
    label: 'White Bird',
  },
  {
    value: 'white line',
    label: 'white line',
  },
  {
    value: 'Willy gun',
    label: 'Willy gun',
  },
  {
    value: 'Wiskey raða afbr.',
    label: 'Wiskey raða afbr.',
  },
  {
    value: 'WN',
    label: 'WN',
  },
  {
    value: 'Wolfowich',
    label: 'Wolfowich',
  },
  {
    value: 'Wolfowits',
    label: 'Wolfowits',
  },
  {
    value: 'Wooly bugger',
    label: 'Wooly bugger',
  },
  {
    value: 'Wooly Worm',
    label: 'Wooly Worm',
  },
  {
    value: 'Yellow ghost',
    label: 'Yellow ghost',
  },
  {
    value: 'Yellow streamer',
    label: 'Yellow streamer',
  },
  {
    value: 'yrja',
    label: 'yrja',
  },
  {
    value: 'ýmsir kúluhausar',
    label: 'ýmsir kúluhausar',
  },
  {
    value: 'Zebra Ghostari',
    label: 'Zebra Ghostari',
  },
  {
    value: 'Zebra midge',
    label: 'Zebra midge',
  },
  {
    value: 'Zelda',
    label: 'Zelda',
  },
  {
    value: 'zetor',
    label: 'zetor',
  },
  {
    value: 'Zlatan',
    label: 'Zlatan',
  },
  {
    value: 'Zonker',
    label: 'Zonker',
  },
  {
    value: 'Zulu',
    label: 'Zulu',
  },
  {
    value: 'Þingeyingur',
    label: 'Þingeyingur',
  },
  {
    value: 'Þurrfluga',
    label: 'Þurrfluga',
  },
  {
    value: 'þyrilspúnn',
    label: 'þyrilspúnn',
  },
  {
    value: 'Þyrla með rauðum smokki',
    label: 'Þyrla með rauðum smokki',
  },
  {
    value: 'þyrluspónn',
    label: 'þyrluspónn',
  },
  {
    value: 'þyrluspúnn',
    label: 'þyrluspúnn',
  },
  {
    value: 'Þýsk Snælda',
    label: 'Þýsk Snælda',
  },
]);
