import React from 'react';
import { AlertCircle } from 'react-feather';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './select.scss';

export default function CSelect({
  label, required, error, onChange, onBlur, value, options, creatable, onInputChange, isClearable, ...rest
}) {
  let SelectComponent = null;
  if (creatable) {
    SelectComponent = CreatableSelect;
  } else {
    SelectComponent = Select;
  }

  return (
    <div className='select-wrapper'>
      <label htmlFor={rest.name}>{`${label} ${required ? '*' : ''}`}</label>
      <span className='error'>
        {error}
        {error && (
          <AlertCircle className='svg-error' />
        )}
      </span>
      <SelectComponent
        isClearable={isClearable}
        formatCreateLabel={(val) => (
          <>
            <span className='bold'>Skrá annað: </span>
            <span>{` ${val}`}</span>
          </>
        )}
        className='select'
        classNamePrefix='select'
        placeholder={label}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        onInputChange={onInputChange}
        value={value}
      />
    </div>
  );
}

CSelect.defaultProps = {
  error: '',
  label: '',
  required: false,
  creatable: false,
  isClearable: false,
  onInputChange: null,
};

CSelect.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  creatable: PropTypes.bool,
  isClearable: PropTypes.bool,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};
