/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  setTokens,
  getAccessTokenObj,
  getRefreshTokenObj,
  removeTokens,
} from '../services/token.service';
import history from '../router/history';
import routeConstants from '../constants/route.constants';

const API_URL = process.env.REACT_APP_API_URL;
const DEBUG = process.env.REACT_APP_CURR_ENV === 'staging' || process.env.NODE_ENV === 'development';
const instance = axios.create({ baseURL: API_URL, timeout: 30000, params: {} });

const GET = (action, params = {}) => {
  const url = `${API_URL}${action}`;
  if (DEBUG) {
    console.info(`GET ${url}`, params);
  }
  return instance.get(url, { params });
};

const POST = (action, data, params = {}) => {
  const url = `${API_URL}${action}`;
  if (DEBUG) {
    console.info(`POST ${url}`, data, params);
  }
  return instance.post(url, data, { params });
};

const PATCH = (action, data) => {
  const url = `${API_URL}${action}`;
  if (DEBUG) {
    console.info(`PATCH ${url}`, data);
  }
  return instance.patch(url, data);
};

const PUT = (action, data) => {
  const url = `${API_URL}${action}`;
  if (DEBUG) {
    console.info(`PUT ${url}`, data);
  }
  return instance.put(url, data);
};

const DELETE = (action, data = {}) => {
  const url = `${API_URL}${action}`;
  if (DEBUG) {
    console.info(`DELETE ${url}`, data);
  }
  return instance.delete(url, data);
};

instance.interceptors.request.use(
  async (config) => {
    const { token } = getAccessTokenObj();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config.url.includes('permits')) {
      config.headers.common['Cache-Control'] = 'no-cache';
      config.headers.common.Pragma = 'no-cache';
      config.headers.common.Expires = '0';
    }
    config.headers.common['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  (error) => {
    // Do something with request error
    throw error;
  },
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.message === 'Network Error') {
      return Promise.reject(new Error('Náði ekki samband við vefþjón vinsamlegast reyndu aftur síðar'));
    }
    const { code } = error.response.data;
    // Reject promise if error is not 401.
    if (code !== 401 || error.config.url.includes('login')) {
      return Promise.reject(error.response.data);
    }
    const originalRequest = error.config;
    if (code === 401 && originalRequest.url.includes('refresh-tokens')) {
      await removeTokens();
      history.push(routeConstants.HOME);
      return Promise.reject(error.response.data);
    }
    if (code === 401 && !originalRequest._retry) {
      // Try to refresh tokens.
      originalRequest._retry = true;
      const refreshTokenObj = getRefreshTokenObj();
      const { data } = await POST('/auth/refresh-tokens', { refreshToken: refreshTokenObj.token || '' }, {});
      await setTokens(data);
      axios.defaults.headers.common.Authorization = `Bearer ${data.access}`;
      return instance(originalRequest);
    }
    return Promise.reject(error.response.data);
  },
);

export default {
  GET, POST, PATCH, DELETE, PUT,
};
