import {
  take, call, put, all, select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import moment from 'moment';
import bookingApi from '../../api/bookings';
import couponsApi from '../../api/coupons';
import message from '../../services/message.service';
import routeConstants from '../../constants/route.constants';
import modalConstants from '../../constants/modal.constants';
import { bookingStatuses } from '../../constants/booking.constants';
import { load, ready } from '../init/init.sagas';
import * as modalTypes from '../modal/modal.types';
import * as bookingTypes from './bookings.types';
import { calculateCartPrice, calculateDiscount, getTotalPriceEntitledForWholedayDiscount } from '../../utils/bookings';
import {
  getCart, getUser, getArea, getParams, getCoupon, getComment,
} from './bookings.selectors';

const mapCartToPermits = (cart) => cart.map(({ id, quantity }) => ({ permit: id, quantity }));

const mapUserToCheckoutUser = (user) => ({
  email: user.email,
  name: user.name,
  phone: user.phone,
  kennitala: user.kennitala,
  address: user.address,
});

const getItemsByDay = (cart) => {
  const itemsByDay = [];
  cart.forEach((item) => {
    const formattedDate = moment(item.dateFrom).format('DD.MM.yyyy');
    const index = itemsByDay.findIndex(({ date }) => date === formattedDate);
    if (index >= 0) {
      itemsByDay[index].items.push(item);
    } else {
      itemsByDay.push({
        date: formattedDate,
        items: [item],
      });
    }
  });
  return itemsByDay;
};

const calculatePrice = (cart, coupon, wholedayDiscount) => {
  const cartPrice = calculateCartPrice(cart);
  const wholeDayDiscountValue = wholedayDiscount.enabled && wholedayDiscount.value > 0
    ? getItemsByDay(cart).reduce((r, a) => r + (getTotalPriceEntitledForWholedayDiscount(a.items) * (wholedayDiscount.value / 100)), 0)
    : 0;
  const discount = calculateDiscount(coupon, cartPrice);
  const price = cartPrice - discount - wholeDayDiscountValue;
  return price > 0 ? price : 0;
};

const mapCartToBody = (area, cart) => {
  const permits = mapCartToPermits(cart);
  return {
    area: area.id,
    permits,
  };
};

function* createCart() {
  while (true) {
    yield take(bookingTypes.CREATE_CART_REQUEST);
    try {
      const { user } = yield select(getUser);
      if (!user) {
        yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.LOGIN });
      } else if (!user.isEmailVerified) {
        yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.VERIFY_EMAIL });
      } else if (!user.address || !user.phone) {
        yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.COMPLETE_PROFILE });
      } else {
        const { cart } = yield select(getCart);
        const { area } = yield select(getArea);
        const body = mapCartToBody(area, cart);
        const { time } = yield call(bookingApi.createCart, body);
        message.success(`Leyfi hafa verið frátekin fyrir þig í ${time} sek.`);
        yield put({ type: bookingTypes.CREATE_CART_SUCCESS, time: time * 1000 });
        yield put(push(routeConstants.CHECKOUT));
      }
    } catch (error) {
      message.error(error.message);
      yield put({
        type: bookingTypes.CREATE_CART_FAILURE,
        error,
      });
    }
  }
}

function* checkout() {
  while (true) {
    yield take(bookingTypes.CHECKOUT_REQUEST);
    try {
      const { user } = yield select(getUser);
      if (!user) {
        yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.LOGIN });
      } else if (!user.isEmailVerified) {
        yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.VERIFY_EMAIL });
      } else if (!user.address || !user.phone) {
        yield put({ type: modalTypes.SET_MODAL_VISIBLE, modal: modalConstants.COMPLETE_PROFILE });
      } else {
        const { cart } = yield select(getCart);
        const { coupon } = yield select(getCoupon);

        const { area } = yield select(getArea);
        const { comment } = yield select(getComment);
        const body = {
          user: mapUserToCheckoutUser(user),
          area: area.id,
          comment,
          coupon: coupon ? coupon.coupon : null,
          price: calculatePrice(cart, coupon, area.wholedayDiscount),
          permits: mapCartToPermits(cart),
        };
        const { url: valitorUrl, orderId, status } = yield call(bookingApi.checkout, body);
        yield put({ type: bookingTypes.CHECKOUT_SUCCESS, valitorUrl, orderId });
        if (status === bookingStatuses.PAID) {
          yield put(push(`${routeConstants.BOOKINGS}/${orderId}`));
        }
      }
    } catch (error) {
      message.error(error.message);
      yield put({
        type: bookingTypes.CHECKOUT_FAILURE,
        error,
      });
    }
  }
}

function* getMyBookings() {
  while (true) {
    yield take(bookingTypes.GET_MY_BOOKINGS_REQUEST);
    try {
      yield call(load);
      const params = yield select(getParams);
      const {
        results, page, limit, totalPages, totalResults,
      } = yield call(bookingApi.getMyBookings, params);
      yield put({
        type: bookingTypes.GET_MY_BOOKINGS_SUCCESS, results, page, limit, totalPages, totalResults,
      });
    } catch (error) {
      message.error(error.message);
      yield put({
        type: bookingTypes.GET_MY_BOOKINGS_FAILURE,
        error,
      });
    } finally {
      yield call(ready);
    }
  }
}

function* cancelBooking() {
  while (true) {
    const { orderId } = yield take(bookingTypes.CANCEL_BOOKING_REQUEST);
    try {
      yield call(load);
      yield call(bookingApi.cancelBooking, orderId);
      yield put({ type: bookingTypes.CANCEL_BOOKING_SUCCESS });
    } catch (error) {
      // message.error(error.message);
      yield put({
        type: bookingTypes.CANCEL_BOOKING_FAILURE,
        error,
      });
    } finally {
      yield call(ready);
    }
  }
}

function* getBookingByOrderId() {
  while (true) {
    const { orderId } = yield take(bookingTypes.GET_BOOKING_BY_ORDER_ID_REQUEST);
    try {
      yield call(load);
      const booking = yield call(bookingApi.getBookingByOrderId, orderId);
      yield put({ type: bookingTypes.GET_BOOKING_BY_ORDER_ID_SUCCESS, booking });
    } catch (error) {
      message.error(error.message);
      yield put({
        type: bookingTypes.GET_BOOKING_BY_ORDER_ID_FAILURE,
        error,
      });
    } finally {
      yield call(ready);
    }
  }
}

function* validateCoupon() {
  while (true) {
    const { coupon } = yield take(bookingTypes.VALIDATE_COUPON_REQUEST);
    try {
      const { area } = yield select(getArea);
      const data = yield call(couponsApi.validateCoupon, { coupon, area: area.slug });
      message.success('Afsláttarkóði virkur');
      yield put({ type: bookingTypes.VALIDATE_COUPON_SUCCESS, coupon: data });
    } catch (error) {
      message.error(error.message);
      yield put({
        type: bookingTypes.VALIDATE_COUPON_FAILURE,
        error,
      });
    }
  }
}

function* resendPdfPermitsByOrderId() {
  while (true) {
    const { orderId, email } = yield take(bookingTypes.RESEND_PDF_PERMITS_EMAIL_REQUEST);
    try {
      yield call(bookingApi.resendPdfPermitsByOrderId, orderId, { email });
      yield put({ type: bookingTypes.RESEND_PDF_PERMITS_EMAIL_SUCCESS });
      message.success(`Veiðileyfi send á ${email}`);
    } catch (error) {
      message.error(error.message);
      yield put({
        type: bookingTypes.RESEND_PDF_PERMITS_EMAIL_FAILURE,
        error,
      });
    }
  }
}

export default function* bookingSaga() {
  yield all([
    createCart(),
    checkout(),
    validateCoupon(),
    getMyBookings(),
    getBookingByOrderId(),
    cancelBooking(),
    resendPdfPermitsByOrderId(),
  ]);
}
