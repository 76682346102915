import api from './api';
import catchAsync from './error';

const getClubs = catchAsync(async (params) => {
  const response = await api.GET('/fishing-clubs/my-clubs', params);
  return response.data;
});

const getClubBySlug = catchAsync(async (slug, params) => {
  const response = await api.GET(`/fishing-clubs/my-clubs/${slug}`, params);
  return response.data;
});

export default {
  getClubs,
  getClubBySlug,
};
