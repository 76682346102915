import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getAccessTokenInfo } from '../../services/token.service';
import routeConstants from '../../constants/route.constants';

const PrivateRoute = ({ children, ...rest }) => {
  const { user } = useSelector((state) => state.auth);
  if (user === null) return null;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        const token = getAccessTokenInfo();
        if (token) {
          return children;
        }
        return <Redirect to={routeConstants.AUTH} from={location} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]).isRequired,
};

export default PrivateRoute;
