import { all, put, call } from 'redux-saga/effects';
import * as appTypes from './app.types';

export function* siderCollapsed(collapsed) {
  // yield put({ type: appTypes.SIDER_COLLAPSED, collapsed });
}

export default function* appSaga() {
  yield all([siderCollapsed()]);
}
