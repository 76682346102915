/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

const UserIcon = ({ inverse }) => (
  <div className='user-icon'>
    <svg xmlns='http://www.w3.org/2000/svg' width='17.572' height='17.236' viewBox='0 0 17.572 17.236'>
      <g id='Group_159' data-name='Group 159' transform='translate(0.607 0.604)'>
        <path id='Path_384' data-name='Path 384' d='M33.3,92.377a2.449,2.449,0,0,1,.375-2.141,2.093,2.093,0,0,1,.993-.44c1.493-.42,2.071-.1,3.094-.6a2.8,2.8,0,0,0,.879-.651,3.009,3.009,0,0,0,.667-1.274' transform='translate(-33.21 -76.455)' fill='none' stroke={inverse ? '#fff' : '#3D4D6C'} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.2' />
        <line id='Line_27' data-name='Line 27' x2='4.085' transform='translate(4.094 3.365)' fill='none' stroke={inverse ? '#fff' : '#3D4D6C'} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.2' />
        <path id='Path_385' data-name='Path 385' d='M64.546,92.377a2.447,2.447,0,0,0-.375-2.141,2.093,2.093,0,0,0-.993-.44c-1.494-.42-2.071-.1-3.094-.6a2.8,2.8,0,0,1-.88-.651,3.009,3.009,0,0,1-.667-1.274' transform='translate(-48.276 -76.455)' fill='none' stroke={inverse ? '#fff' : '#3D4D6C'} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.2' />
        <path id='Path_386' data-name='Path 386' d='M51.424,76.805a3.658,3.658,0,0,1-.069,1.068,4.6,4.6,0,0,1-1.368,2.67,3.8,3.8,0,0,1-1.6.912,3.664,3.664,0,0,1-2.275,0,3.8,3.8,0,0,1-1.6-.912,4.6,4.6,0,0,1-1.368-2.67,3.659,3.659,0,0,1-.069-1.068' transform='translate(-39.075 -70.231)' fill='none' stroke={inverse ? '#fff' : '#3D4D6C'} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.2' />
        <path id='Path_387' data-name='Path 387' d='M44.884,60.579,48.1,60.6l.868,3.343,1.715,1.672a1.932,1.932,0,0,1-.868,1.324,1.479,1.479,0,0,1-.955.2,1.341,1.341,0,0,1-.412-.152,3.526,3.526,0,0,0-1.585-.369c-.7.029-1.16.426-1.979.405-.82.021-1.277-.376-1.979-.405a3.527,3.527,0,0,0-1.585.369,1.337,1.337,0,0,1-.412.152,1.48,1.48,0,0,1-.955-.2,1.932,1.932,0,0,1-.868-1.324L40.8,63.944l.869-3.343,3.214-.022' transform='translate(-36.703 -60.579)' fill='none' stroke={inverse ? '#fff' : '#3D4D6C'} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.2' />
        <line id='Line_28' data-name='Line 28' x1='4.085' transform='translate(8.179 3.365)' fill='none' stroke={inverse ? '#fff' : '#3D4D6C'} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.2' />
      </g>
    </svg>
  </div>
);

UserIcon.propTypes = {
  inverse: PropTypes.bool,
};

UserIcon.defaultProps = {
  inverse: false,
};

export default UserIcon;
