import PermitAction from './permit-action/permit-action';
import PermitSection from './permit-section/permit-section';
import PermitView from './permit-view/permit-view';
import PermitViewHeader from './permit-view-header/permit-view-header';
import PermitActionHeader from './permit-action-header/permit-action-header';
import PermitWrapper from './permit-wrapper/permit-wrapper';
import PermitSubarea from './permit-subarea/permit-subarea';
import Summary from './summary/summary';

const Header = {
  View: PermitViewHeader,
  Action: PermitActionHeader,
};

const Permit = {
  View: PermitView,
  Action: PermitAction,
  Section: PermitSection,
  Subarea: PermitSubarea,
  Header,
  Summary,
  Wrapper: PermitWrapper,
};

export default Permit;
