import React from 'react';
import { useSelector } from 'react-redux';
import { mapRegion } from '../../../../../utils/location';
import { fishTypeMappings } from '../../../../../mappings';
import { CloudinaryImage } from '../../../../../utils/cloudinary';
import './area-info.scss';

const AreaInfo = () => {
  const { currentArea, getAreaBySlugPending } = useSelector((state) => state.areas);
  const Info = () => {
    if (currentArea) {
      const { fishTypes, region, fishingHouseAvailable } = currentArea;
      const arr = [];
      if (region !== '' && region !== null) {
        arr.push(mapRegion(region));
      }
      arr.push(...Object.keys(fishTypes).filter((k) => fishTypes[k]).map((k) => fishTypeMappings[k]));
      if (fishingHouseAvailable) {
        arr.push('Veiðihús');
      }
      return (
        <div className='row-wrap jc-fs info-points'>
          {arr.map((v) => (
            <p key={v} className='info-point'>{v}</p>
          ))}
        </div>
      );
    }
    return <></>;
  };

  if (getAreaBySlugPending) return null;
  if (currentArea === null) return null;

  return (
    <div className='area-permit-info-wrapper'>
      <div className='area-img' style={{ backgroundImage: `url(${CloudinaryImage(currentArea.image.imagePublicId)})` }} />
      <h2>{currentArea.name}</h2>
      <Info />
    </div>
  );
};

export default AreaInfo;
