import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '../../components/ui';
import { login } from '../../redux/auth/auth.actions';
import { setModalVisible } from '../../redux/modal/modal.actions';
import modalConstants from '../../constants/modal.constants';
import { LoginForm } from './components';

const LoginModal = ({ ...rest }) => {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(login(data));
  };

  const onRegister = () => {
    dispatch(setModalVisible(modalConstants.SIGNUP));
  };

  const onForgotPasswordClicked = () => {
    dispatch(setModalVisible(modalConstants.FORGOT_PASSWORD));
  };

  return (
    <Modal title='Innskráning' {...rest}>
      <LoginForm
        onSubmit={onSubmit}
        onForgotPasswordClicked={onForgotPasswordClicked}
      />
      <div className='pointer mv-1' onClick={onRegister}>
        <p>
          Áttu ekki aðgang ?
          {' '}
          <span className='medium'>Nýskráðu þig hér</span>
        </p>
      </div>
    </Modal>
  );
};

export default LoginModal;
