import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { checkout } from '../../../../../redux/bookings/bookings.actions';
import routeConstants from '../../../../../constants/route.constants';
import { Button } from '../../../../../components/ui';

const ValitorCheckout = ({ toPay }) => {
  const dispatch = useDispatch();
  const { checkoutPending, checkoutError, valitorUrl } = useSelector((state) => state.bookings);

  useEffect(() => {
    if (valitorUrl) {
      window.location.href = valitorUrl;
    }
  }, [valitorUrl]);

  const handleCheckout = () => {
    if (!checkoutPending) {
      dispatch(checkout());
    }
  };

  return (
    <>
      <div className='line mv-1' />
      <p className='mv-1'>
        Með því að velja
        {' '}
        <span className='bold'>{toPay > 0 ? 'Bóka og greiða með greiðslukorti' : 'Staðfesta bókun'}</span>
        , samþykkir þú
        {' '}
        <Link target='_blank' to={routeConstants.TERMS_AND_CONDITIONS}>notkunarskilmála Veiðitorgs</Link>
        {' '}
        og veiðireglur viðkomandi veiðisvæðis.
      </p>
      <Button className='mt-1' block onClick={handleCheckout} disabled={checkoutPending}>
        {toPay > 0 ? 'Bóka og greiða með greiðslukorti' : 'Staðfesta bókun'}
      </Button>
    </>
  );
};

ValitorCheckout.propTypes = {
  toPay: PropTypes.number.isRequired,
};

export default ValitorCheckout;
