import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircle, XOctagon } from 'react-feather';
import { Button } from '../../../../components/ui';
import './booking.scss';

const BookingResult = ({
  title,
  buttonOneText,
  buttonOneClick,
  buttonTwoText,
  buttonTwoClick,
  success,
  children,
}) => (
  <div className='result-container'>
    <div className='result-inner'>
      {success ? <CheckCircle color='#8f9d45' /> : <XOctagon color='#EF7B62' />}
      <div className='mt-3'>
        <h2 className='ta-left mt-3 mb-1'>{title}</h2>
        {children}
        <div className='column jc-c mt-3 f-width'>
          <Button medium block onClick={buttonOneClick}>
            {buttonOneText}
          </Button>
          {buttonTwoClick
            && (
            <Button medium block onClick={buttonTwoClick} inverse className='mt-1'>
              {buttonTwoText}
            </Button>
            )}
        </div>
      </div>
    </div>
  </div>
);

BookingResult.propTypes = {
  title: PropTypes.string.isRequired,
  buttonOneText: PropTypes.string.isRequired,
  buttonOneClick: PropTypes.func.isRequired,
  buttonTwoText: PropTypes.string,
  success: PropTypes.bool.isRequired,
  buttonTwoClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

BookingResult.defaultProps = {
  buttonTwoText: null,
  buttonTwoClick: undefined,
};

export default BookingResult;
