import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
// import TagManager from 'react-gtm-module';

const development = process.env.NODE_ENV === 'development';

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GA_TAGMANGER,
// };

// export const initTagManager = () => {
//   if (development) {
//     console.info('Init tag manager');
//     return;
//   }
//   TagManager.initialize(tagManagerArgs);
// };

export const initGA = () => {
  if (development) {
    console.info('INITGA CALLED');
    return;
  }
  ReactGA.initialize(process.env.REACT_APP_GA_TAG);
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
  ReactPixel.pageView();
};

export const initPixel = () => {
  if (development) {
    console.info('INIT PIXEL CALLED');
    return;
  }
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL);
  ReactPixel.pageView();
};

export const logPageView = () => {
  if (development) {
    console.info(`Log Page View :: ${window.location.pathname}`);
    return;
  }
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
  ReactPixel.pageView();
};

export const logEvent = (category = '', action = '') => {
  if (development) {
    console.info(`Log event :: ${category}\t${action}`);
    return;
  }
  if (category && action) {
    ReactGA.event({ category, action });
  }
};

export const logException = (description = '', fatal = false) => {
  if (development) {
    console.info(`Log exception :: ${description}\t${fatal}`);
    return;
  }
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};

export const trackPurchase = (num) => {
  if (development) {
    console.info(`Track purchase :: ${num}`);
    return;
  }
  if (num) {
    window.dataLayer.push({ event: 'purchase', purchaseAmount: num });
    ReactPixel.track('Purchase', {
      value: num,
      currency: 'ISK',
    });
  }
};
