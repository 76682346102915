import * as cartTypes from './cart.types';

export const addToCart = (permit) => ({
  type: cartTypes.ADD_PERMIT_TO_CART,
  permit,
});

export const removeFromCart = (permit) => ({
  type: cartTypes.REMOVE_PERMIT_FROM_CART,
  permit,
});

export const clearCart = () => ({
  type: cartTypes.CLEAR_CART,
});
