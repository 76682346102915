/* eslint-disable max-len */
import * as React from 'react';

const Hero = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={696.362}
    height={541.583}
    viewBox='0 0 696.362 541.583'
    {...props}
  >
    <defs>
      <clipPath id='prefix__a'>
        <path
          data-name='Path 1896'
          d='M771.206 219.609c91.637 4.425 165.328 28.149 171.965 161.978 6.668 134.72-96.492 196.918-185.272 203.679s-211.331-60.293-313.631-50.643S246.951 497.5 248.365 378.3c.891-74.244 52.794-193.722 180.6-194.921 78.884-.738 104.574 41.547 162.839 42.285 90.561 1.136 106.664-9.558 179.402-6.055z'
          transform='translate(-248.349 -183.369)'
        />
      </clipPath>
    </defs>
    <g data-name='Group 463'>
      <g
        data-name='Group 460'
        clipPath='url(#prefix__a)'
        transform='translate(1.233 22.69)'
      >
        <path
          data-name='Rectangle 378'
          fill='#c6d1f5'
          d='M-3.58-29.276h744.067v479.389H-3.58z'
        />
        <g data-name='Group 459'>
          <path
            data-name='Path 1882'
            d='M648.02 257.65v127.5l-.123.185a283.099 283.099 0 00-5.163-3.841c-195.9-142.065-416.177-176.667-446.323-179.156s-93.6-18.5-100.364-22.125-10.6-12.538 0-13.275 204.355-.246 228.232 0c3.042.031 10.387.184 20.9.461-8.02 4.671-28.3 19.79 33.158 36.538 61.395 16.714 224.818 39.239 269.683 53.713z'
            fill='#c6d1f5'
          />
          <path
            data-name='Path 1883'
            d='M694.854 175.847l35.186 94.1c-44.9-14.474-290.306-49.26-351.736-66.008-61.429-16.717-41.178-31.836-33.158-36.538 71.724 1.808 337.907 8.231 349.708 8.446z'
            fill='#b4c7e7'
          />
          <path
            data-name='Path 1884'
            d='M646.546 67.431l75.043 60.323c-136.717 15.58-368.054 22.341-401.089 23.324-33.035.953-20.466-9.864-16.778-13.091 73.137-20.651 157.614-51.473 239.6-71.2-8.021 4.517-13.583 15.3 9.618 11.37 23.265-3.904 35.802-8.144 93.606-10.726z'
            fill='#8f9d45'
          />
          <path
            data-name='Path 1885'
            d='M646.299 49.208l.246 18.223c-57.834 2.581-70.372 6.791-93.573 10.725-23.2 3.9-17.639-6.853-9.618-11.37 35.124-8.451 69.819-14.878 102.945-17.578z'
            fill='#fff'
          />
          <path
            data-name='Path 1886'
            d='M389.151 53.295c13.736 1.444 67.545 10.94 65.393 19.575-2.182 8.635-53.562 2.7-69.726 0s-61.03-7.99-59.34-16.441c1.69-8.418 49.937-4.578 63.673-3.134z'
            fill='#fff'
          />
          <path
            data-name='Path 1887'
            d='M287.588 86.361c.738 11.831-63.8 4.333-108.569 4.333-43.36 0-123.043 7.252-122.091-4.087s103.007-12.784 103.007-12.784c16.441 0 126.946.707 127.653 12.538z'
            fill='#fff'
          />
          <path
            data-name='Path 1888'
            d='M285.59 142.935c-53.808 14.2-100.456 21.572-133.983 12.876 10.387-.8 13.583-1.752 22.341-3.749 14.9-3.38 30.515-8.328 46.1-9.434 15.611-1.137 25.26-2.366 31.775-6.884 7.862 2.121 18.678 4.548 33.767 7.191z'
            fill='#455e8d'
          />
          <path
            data-name='Path 1889'
            d='M207.506 39.07c13.767.922 53.562 2.643 51.872 10.141-1.69 7.468-44.4 6.761-51.872 5.562s-42.93.768-42.715-7.467c.246-8.236 27.78-9.222 42.715-8.236z'
            fill='#fff'
          />
          <path
            data-name='Path 1890'
            d='M226.897 127.813c3.872-.461 5.623 2.8 24.891 7.959-6.515 4.518-16.164 5.777-31.775 6.884-15.611 1.137-31.191 6.054-46.1 9.434-8.789 2-11.985 2.95-22.341 3.749-1.168-.307-2.3-.615-3.442-.953a686.922 686.922 0 01-28.425-9.158c5.316-2.581 10.479-5.132 18.192-4.609s12.538 4.824 22.095 4.609 33.25-5.04 42.9-9.1c9.653-4.115 18.535-8.167 24.005-8.815z'
            fill='#fff'
          />
          <path
            data-name='Path 1891'
            d='M149.763 279.56c16.563 13.183 19.637 31.836 8.205 37.952s-41.332-.154-56.3-3.227c-14.965-3.042-35.862-11.893-36.845-22.832-.953-10.94 10.356-16.563 23.324-22.986 23.665-11.739 45.053-2.088 61.616 11.093z'
            fill='#8f9d45'
          />
          <path
            data-name='Path 1892'
            d='M119.709 145.7a687.413 687.413 0 0028.425 9.158c1.137.338 2.274.676 3.442.953-7.252.553-17.977 1.045-36.569 1.782-45.2 1.782-100.672-7.56-110.628-11.247 7.529-11.124 15.334-18.807 23.14-21.88 4.763-1.875 8.635-3.35 11.862-4.487 1.045 1.875 9.127 16.594 47.632 12.876 8.266 3.718 18.837 8.024 32.696 12.845z'
            fill='#455e8d'
          />
          <path
            data-name='Path 1893'
            d='M39.381 120.01c18.161-6.269 16.809-.891 47.632 12.876-38.505 3.719-46.587-11.032-47.632-12.876z'
            fill='#fff'
          />
          <path
            data-name='Path 1894'
            d='M58.711 189.767c14.32.953 23.324 11.585 21.234 16.564s-18.991 4.825-32.328 2.581-21.388-5.961-19.3-11.739c1.292-3.531 16.104-8.359 30.394-7.406z'
            fill='#8f9d45'
          />
          <path
            data-name='Path 1895'
            d='M-18.33 36.917c48.984-2.889 111.95-3.38 111.95 7.959S3.642 52.835-18.329 52.589V36.917z'
            fill='#fff'
          />
        </g>
      </g>
      <g
        data-name='Group 462'
        clipPath='url(#prefix__a)'
        transform='translate(1.233 22.69)'
      >
        <path
          data-name='Path 1899'
          d='M151.573 155.841c33.557 8.7 80.205 1.321 133.983-12.876q8.943-2.351 18.162-4.978c-3.718 3.227-16.256 14.044 16.779 13.091s264.376-7.713 401.091-23.324l8.451 60.385c-11.77-.215-313.17-18.93-384.863-20.743-10.51-.277-17.854-.43-20.9-.461-23.877-.246-217.6-.738-228.232 0-10.6.738-6.761 9.649 0 13.275s70.218 19.636 100.364 22.126 250.419 37.091 446.323 179.125c1.782 1.291 3.5 2.581 5.163 3.841 180.785 137.855-344.515 137.7-429.3 135.8-85.552-1.936-231.551 10.387-241.876-87.488-8.2-77.839 6.177-216.739 28.886-286.834 9.957 3.688 64.226 12.6 109.43 10.848 18.561-.738 29.316-1.229 36.538-1.782m6.401 161.666c11.432-6.115 8.359-24.768-8.2-37.952s-37.952-22.832-61.614-11.093c-12.968 6.423-24.277 12.077-23.324 22.986.953 10.94 21.88 19.79 36.845 22.832 14.957 3.069 44.888 9.342 56.293 3.227zM79.945 206.331c2.09-4.978-6.914-15.611-21.234-16.564s-29.1 3.841-30.392 7.406c-2.09 5.777 5.962 9.5 19.3 11.739 13.365 2.274 30.236 2.428 32.326-2.581z'
          fill='#a7b55c'
        />
      </g>
    </g>
    <path
      data-name='Path 1901'
      d='M152.809 178.531c33.557 8.7 80.205 1.322 133.983-12.876q8.942-2.351 18.161-4.978c-3.718 3.227-16.256 14.044 16.779 13.091s222.916-1.26 359.631-16.871l.215 30.976c-11.77-.215-263.479 4.026-335.2 2.213-10.51-.277-17.854-.43-20.9-.461-23.877-.246-217.6-.737-228.232 0-10.6.738-6.761 9.649 0 13.275s70.218 19.636 100.364 22.125 321.194 52.918 451.49 182.972c106.3 106.08-173.932 101.44-354.379 84.139-103.9-9.957-224.237-46.648-277.707-177.251-23.078-56.42-19.083-114.377-9.035-145.414 9.956 3.688 63.089 12.63 108.262 10.848 18.592-.737 29.347-1.229 36.569-1.782m6.391 161.66c11.432-6.115 8.359-24.768-8.2-37.951s-37.952-22.832-61.614-11.094c-12.968 6.422-24.277 12.077-23.324 22.986.953 10.94 21.88 19.79 36.845 22.833 14.961 3.077 44.892 9.346 56.293 3.226zM81.178 229.02c2.09-4.978-6.914-15.611-21.234-16.564s-29.1 3.841-30.392 7.406c-2.09 5.777 5.962 9.5 19.3 11.739 13.365 2.275 30.236 2.428 32.326-2.581z'
      fill='#a7b55c'
    />
    <g data-name='Group 464'>
      <path
        data-name='Path 1902'
        d='M562.963 471.081c-10.325 4.056-3.35 15.55 7.068 18.07 19.482 4.7 19.175-12.385 19.175-12.385a10.006 10.006 0 00-.952-1.075v-.031c-2.951-2.951-8.729-5.593-15.028-6.023a23.242 23.242 0 00-10.263 1.444z'
        fill='#8f9d45'
      />
      <path
        data-name='Path 1903'
        d='M573.473 453.165c-3.442 5.255-1.875 13.183-.246 16.471 6.3.461 12.047 3.1 15.028 6.023-.614-.891 1.751-10.541 3.749-18.684-1.014-5.347-4.363-8.052-8.082-8.574a10.9 10.9 0 00-8.881 2.888 9.934 9.934 0 00-1.567 1.875z'
        fill='#8f9d45'
      />
      <path
        data-name='Path 1904'
        d='M594.184 378.676a184.43 184.43 0 00-3.75 8.789c-2.888 7.252-5.347 14.535-7.406 21.511a306.569 306.569 0 00-9.557 44.159 10.74 10.74 0 0110.417-4.733 255.184 255.184 0 0110.384-41.514q2.4-7.053 5.04-13.46c1.414-3.5 2.889-6.853 4.364-10.049a230.859 230.859 0 0115.242-27.841h.031c-.892-3.719-1.813-7.221-2.766-10.448-7.313 7.344-13.49 16.594-18.623 26.581-1.163 2.334-2.301 4.639-3.376 7.005z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 1905'
        d='M553.959 332.611c.369.553 23.417 35.585 29.072 76.364 2.059-6.976 4.517-14.259 7.406-21.511-5.932-23.477-16.718-45.388-36.478-54.853z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 1906'
        d='M602.544 437.155a9.26 9.26 0 00-5.163 3.626 19.8 19.8 0 00-3.319 7.652c-.461 2.09-1.26 5.193-2.09 8.543-2.028 8.112-4.394 17.793-3.749 18.684v.03a10.07 10.07 0 01.953 1.076 18.79 18.79 0 018.543-2.182c2.981-11.062 6.085-22.064 13.645-28.272-.37-.891-.738-1.751-1.076-2.581-1.506-3.718-2.828-6.73-6.33-6.73a7.363 7.363 0 00-1.414.154z'
        fill='#8f9d45'
      />
      <path
        data-name='Path 1907'
        d='M583.89 448.433c3.718.522 7.1 3.226 8.082 8.574.829-3.35 1.6-6.453 2.09-8.544a19.783 19.783 0 013.319-7.651 312.446 312.446 0 00-3.1-33.865 256.449 256.449 0 00-10.391 41.486z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 1908'
        d='M589.207 476.766a52.3 52.3 0 0118.807 20.4c4.979 9.618 15.058 8.6 16.625 3.288 1.167-3.933 1.352-7.867-2.428-13.275a40.487 40.487 0 00-5.132-5.87 24.124 24.124 0 00-12.446-6.238 28.01 28.01 0 00-6.883-.461 18.211 18.211 0 00-8.543 2.156z'
        fill='#8f9d45'
      />
      <path
        data-name='Path 1909'
        d='M579.557 349.359a195.474 195.474 0 0114.627 29.316 206.042 206.042 0 013.381-6.975 111.609 111.609 0 00-18.008-22.341z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 1910'
        d='M620.151 441.98a21.464 21.464 0 00-8.788 4.332c-7.56 6.208-10.664 17.209-13.645 28.272a29.706 29.706 0 016.884.461c5.47-2.919 14.2-6.423 22-7.068v-.031c3.964-1.045 16.318-27.9-1.812-26.674a29.981 29.981 0 00-4.639.708z'
        fill='#8f9d45'
      />
      <path
        data-name='Path 1911'
        d='M602.547 437.155a7.394 7.394 0 011.443-.153c3.5 0 4.825 3.011 6.331 6.729.093-.307 3.872-14.443 1.013-34.049a100.816 100.816 0 00-7.62-26.305c-1.475 3.2-2.95 6.546-4.364 10.049 4.146 14.167 6.42 29.778 3.197 43.729z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 1912'
        d='M635.209 468.807a21.22 21.22 0 00-8.6-.8c-7.775.645-16.5 4.148-22 7.068a24 24 0 0112.445 6.238 42.439 42.439 0 015.132 5.87s7.406-.645 13.06 2.551c3.688 2.09 13.521 4.517 15.766-1.936 2.212-6.453-5.686-14.351-14.475-18.469a11.611 11.611 0 00-1.328-.522z'
        fill='#8f9d45'
      />
      <path
        data-name='Path 1913'
        d='M607.797 393.059l-.368.122a103.457 103.457 0 013.872 16.533l.4-.062c.922-9.557 5.593-21.48 11.309-32.973a209.172 209.172 0 00-2.121-12.2 178.5 178.5 0 00-13.092 28.58z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 1914'
        d='M620.152 441.98a30.026 30.026 0 014.641-.707 219.812 219.812 0 0119.914-38.6c3.841-23.293 27.411-61.89 27.779-62.505a172.705 172.705 0 00-38.105 55.56 218.158 218.158 0 00-9.465 26.827 372.876 372.876 0 00-4.764 19.425z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 1915'
        d='M626.604 467.978a21.769 21.769 0 018.6.83c12.6-70.71 43.729-105.435 44.22-105.988-14.013 11.432-25.628 25.875-34.694 39.888a214.628 214.628 0 00-19.913 38.6c18.105-1.264 5.752 25.625 1.787 26.67z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 1916'
        d='M620.921 364.478c.767 3.872 1.474 7.96 2.119 12.2a220.754 220.754 0 011.937 45.91 212.833 212.833 0 019.465-26.827 171.7 171.7 0 00-4.794-31.867c-.738-2.95-1.568-5.992-2.551-9.065a118.27 118.27 0 00-2.612-7.406c-.922-2.367-1.936-4.7-3.012-7.006a71.407 71.407 0 00-14.443-20.866 181.506 181.506 0 019.25 25.6c.922 3.227 1.845 6.7 2.765 10.448.585 2.795 1.26 5.776 1.876 8.879z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 1917'
        d='M621.411 340.386a86.61 86.61 0 013.012 7.007 133.582 133.582 0 0113.153-16.2 53.243 53.243 0 00-16.165 9.193z'
        fill='#a7b55c'
      />
      <path
        data-name='Path 1918'
        d='M627.035 354.828a127.214 127.214 0 012.55 9.065l.246-.061c9.28-16.411 18.653-29.44 18.653-29.44-8.266 4.242-15.487 11.955-21.449 20.436z'
        fill='#a7b55c'
      />
    </g>
    <g data-name='Group 465' fill='#3e3e3e'>
      <path
        data-name='Path 1919'
        d='M619.322 505.807c-3.872.368-9.219-1.537-12.568-7.99a51.63 51.63 0 00-18.222-19.821 1.445 1.445 0 01-.739-1.168 1.36 1.36 0 01.646-1.229c2.028-1.322 5.5-2.213 9.219-2.4a29.758 29.758 0 017.191.492 25.543 25.543 0 0113.152 6.607 41.883 41.883 0 015.285 6.054c4.272 6.085 3.78 10.571 2.644 14.474a7.2 7.2 0 01-6.392 4.917.565.565 0 01-.216.064zm-27.2-28.825a55.065 55.065 0 0117.117 19.544c2.7 5.224 6.822 6.76 9.8 6.484 2.151-.184 3.749-1.321 4.21-2.95 1.137-3.872 1.168-7.221-2.242-12.077a40.349 40.349 0 00-4.949-5.654 22.665 22.665 0 00-11.708-5.839 29.228 29.228 0 00-6.545-.461c-.677.031-1.321.093-1.906.154a17.572 17.572 0 00-3.774.798z'
      />
      <path
        data-name='Path 1920'
        d='M645.627 493.267a9.6 9.6 0 002.335-.553 7.137 7.137 0 004.364-4.456c1.2-3.5.031-7.467-3.38-11.462a36.159 36.159 0 00-11.831-8.727c-.461-.215-.922-.4-1.444-.584a23.177 23.177 0 00-9.157-.891c-9.833.829-19.73 5.746-22.525 7.221a1.41 1.41 0 101.321 2.49c5.532-2.95 14.075-6.3 21.45-6.914a19.8 19.8 0 018.02.737c.431.154.8.307 1.167.461 8.267 3.872 15.642 11.185 13.737 16.717a4.279 4.279 0 01-2.7 2.735c-3.227 1.229-8.482-.123-11.033-1.567-5.961-3.38-13.552-2.766-13.859-2.735a1.419 1.419 0 10.246 2.827c.061 0 7.038-.553 12.231 2.366a19.9 19.9 0 0011.058 2.335z'
      />
      <path
        data-name='Path 1921'
        d='M569.693 490.533c-7.038-1.69-12.815-7.283-13.184-12.692-.153-2.089.43-5.9 5.931-8.051a24.535 24.535 0 0110.878-1.537c6.177.461 12.416 2.981 15.918 6.453l.062.061a8.784 8.784 0 011.014 1.168 1.345 1.345 0 01.277.83c0 .307.062 7.559-5.224 11.77a13.028 13.028 0 01-6.821 2.735 25.325 25.325 0 01-8.851-.737zm17.577-13.828l-.093-.092c-2.519-2.459-7.8-5.132-14.074-5.562a21.689 21.689 0 00-9.649 1.352c-2 .8-4.363 2.335-4.179 5.255.276 4.21 5.255 8.758 11.063 10.172s10.294.922 13.275-1.476c3.534-2.826 4.087-7.62 4.149-9.1a4.141 4.141 0 00-.492-.549z'
      />
      <path
        data-name='Path 1922'
        d='M597.903 475.994a1.406 1.406 0 001.2-1.045c2.7-10.079 5.777-21.48 13.183-27.565a20.119 20.119 0 018.236-4.056 25.179 25.179 0 014.394-.646c4.548-.307 7.1 1.229 7.867 4.7 1.6 7.345-4.887 18.592-6.515 19.207a1.381 1.381 0 00-.984 1.721 1.439 1.439 0 001.721 1.014c3.688-.953 9.987-13.8 8.667-21.911-.492-3.073-2.582-8.112-10.909-7.528a30.228 30.228 0 00-4.856.738 23 23 0 00-9.342 4.609c-8.082 6.638-11.278 18.53-14.1 29.009a1.409 1.409 0 00.984 1.721 1.041 1.041 0 00.454.032z'
      />
      <path
        data-name='Path 1923'
        d='M587.424 476.828a1.4 1.4 0 01-.368-.369c-.738-1.076-.615-3.012 3.534-19.821l.369-1.475c.675-2.7 1.291-5.286 1.72-7.068a22.064 22.064 0 013.565-8.2 10.563 10.563 0 015.961-4.148 8.45 8.45 0 011.752-.185c4.548 0 6.176 4.057 7.621 7.621a1.412 1.412 0 01-2.612 1.075c-1.6-3.964-2.643-5.839-5.041-5.869a4.943 4.943 0 00-1.137.123 7.7 7.7 0 00-4.332 3.1 18.535 18.535 0 00-3.074 7.129c-.4 1.813-1.014 4.271-1.72 7.129l-.369 1.475c-1.383 5.594-3.933 15.949-3.81 17.793a1.411 1.411 0 01-.154 1.383 1.437 1.437 0 01-.983.584 1.4 1.4 0 01-.922-.277zm1.967-1.967z'
      />
      <path
        data-name='Path 1924'
        d='M611.547 447.726c.123-.03.277-.062.4-.092a1.412 1.412 0 00.738-1.844c-.308-.707-.584-1.383-.861-2.059l-.185-.492a1.412 1.412 0 10-2.612 1.076l.184.46c.278.677.585 1.414.891 2.121a1.385 1.385 0 001.445.83z'
      />
      <path
        data-name='Path 1925'
        d='M592.156 458.39h.093a1.39 1.39 0 001.106-1.629c-1.014-5.378-4.487-9-9.281-9.711a12.292 12.292 0 00-10.048 3.258 12.889 12.889 0 00-1.752 2.089 1.4 1.4 0 002.336 1.536 8.3 8.3 0 011.321-1.567 9.488 9.488 0 017.714-2.52c3.6.523 6.115 3.227 6.914 7.436a1.478 1.478 0 001.597 1.108z'
      />
      <path
        data-name='Path 1926'
        d='M573.381 471.022a2.554 2.554 0 00.461-.123 1.4 1.4 0 00.644-1.874c-1.444-2.95-2.888-10.387.185-15.089a1.4 1.4 0 00-2.335-1.536c-3.965 6.054-1.936 14.658-.338 17.854a1.3 1.3 0 001.383.768z'
      />
      <path
        data-name='Path 1927'
        d='M635.362 470.19a1.436 1.436 0 001.23-1.2c12.384-69.512 42.591-103.807 43.851-105.22a1.414 1.414 0 00-2.09-1.906c-1.291 1.444-32.052 36.354-44.558 106.664a1.012 1.012 0 000 .43 1.413 1.413 0 001.567 1.232z'
      />
      <path
        data-name='Path 1928'
        d='M624.976 442.686a1.416 1.416 0 001.137-.891 213.684 213.684 0 0119.79-38.32c10.479-16.164 22.034-29.47 34.388-39.58a.834.834 0 00.215-.246 1.364 1.364 0 00.277-1.045 1.414 1.414 0 00-1.567-1.229 1.359 1.359 0 00-.769.338c-12.568 10.264-24.338 23.785-34.94 40.2a216.669 216.669 0 00-20.066 38.843 1.408 1.408 0 00.8 1.813 1.3 1.3 0 00.735.117z'
      />
      <path
        data-name='Path 1929'
        d='M644.89 404.12a1.4 1.4 0 001.228-1.168c3.78-22.925 27.38-61.614 27.6-61.983a1.409 1.409 0 10-2.4-1.475c-.062.092-6.176 10.08-12.66 22.832-8.543 16.84-13.706 30.361-15.334 40.164a1.44 1.44 0 001.167 1.629z'
      />
      <path
        data-name='Path 1930'
        d='M625.13 423.971a1.376 1.376 0 001.167-1.014 215.647 215.647 0 019.4-26.643 171.163 171.163 0 0137.8-55.1l.092-.092a1.445 1.445 0 00-.154-2 1.393 1.393 0 00-1.936.092 174.675 174.675 0 00-38.381 55.963 216.55 216.55 0 00-9.526 27.012 1.423 1.423 0 00.952 1.752 1.264 1.264 0 00.586.03z'
      />
      <path
        data-name='Path 1931'
        d='M620.337 443.394a1.434 1.434 0 001.229-1.168c1.537-7.037 3.073-13.337 4.764-19.237a1.41 1.41 0 00-2.7-.8 317.736 317.736 0 00-4.825 19.483 2.32 2.32 0 00-.031.461 1.416 1.416 0 001.563 1.261z'
      />
      <path
        data-name='Path 1932'
        d='M625.13 423.971a1.393 1.393 0 001.229-1.383 220.805 220.805 0 00-1.936-46.156c-.584-4.087-1.322-8.2-2.12-12.261-.583-2.95-1.26-5.992-1.967-8.973a276.513 276.513 0 00-2.765-10.509 219.145 219.145 0 00-6.022-17.977 78.783 78.783 0 018.6 14.259 108.67 108.67 0 012.98 6.914c.922 2.4 1.783 4.856 2.581 7.314a159.613 159.613 0 012.55 8.973 169.338 169.338 0 014.764 31.59 1.4 1.4 0 002.8-.092 171.259 171.259 0 00-4.856-32.175 143.47 143.47 0 00-2.581-9.157c-.8-2.52-1.691-5.04-2.643-7.5s-1.967-4.824-3.073-7.129a73.145 73.145 0 00-14.72-21.265 1.437 1.437 0 00-1.812-.123 1.39 1.39 0 00-.431 1.751 179.724 179.724 0 019.158 25.383c.983 3.38 1.906 6.884 2.735 10.387a238.51 238.51 0 014.027 20.988 217.878 217.878 0 011.906 45.634v.277a1.461 1.461 0 001.596 1.23z'
      />
      <path
        data-name='Path 1933'
        d='M584.075 449.815a1.4 1.4 0 001.229-1.167 253.288 253.288 0 0110.326-41.271 280.995 280.995 0 015.009-13.4c1.352-3.349 2.827-6.7 4.333-9.987a1.41 1.41 0 00-2.552-1.2 244.688 244.688 0 00-4.393 10.11c-1.814 4.487-3.535 9.035-5.071 13.552a259.132 259.132 0 00-10.448 41.732 1.44 1.44 0 001.167 1.629.877.877 0 00.4.002z'
      />
      <path
        data-name='Path 1934'
        d='M603.864 384.791a1.414 1.414 0 001.106-.8 229.948 229.948 0 0115.15-27.688 1.369 1.369 0 00-.43-1.936 1.394 1.394 0 00-1.935.431 233.214 233.214 0 00-15.334 28.026 1.4 1.4 0 00.676 1.875 1.705 1.705 0 00.767.092z'
      />
      <path
        data-name='Path 1935'
        d='M624.606 348.807a1.5 1.5 0 00.984-.584 129.689 129.689 0 0113-16.01 1.438 1.438 0 00.214-1.751 1.414 1.414 0 00-1.659-.584 54.116 54.116 0 00-16.625 9.434 1.4 1.4 0 001.753 2.182 53.127 53.127 0 0110.017-6.515c-2.3 2.7-5.439 6.607-9 11.647a1.4 1.4 0 00.338 1.967 1.6 1.6 0 00.978.214z'
      />
      <path
        data-name='Path 1936'
        d='M590.59 388.878a1.382 1.382 0 001.137-.86c1.2-3.043 2.458-5.962 3.749-8.727 1.076-2.367 2.213-4.7 3.35-6.914 5.531-10.725 11.707-19.576 18.376-26.244a1.414 1.414 0 10-2-2c-6.853 6.884-13.213 15.949-18.9 26.95a174.04 174.04 0 00-3.411 7.037c-1.29 2.8-2.551 5.778-3.78 8.85a1.4 1.4 0 00.8 1.813 1.724 1.724 0 00.679.095z'
      />
      <path
        data-name='Path 1937'
        d='M573.657 454.548a1.37 1.37 0 001.2-1.137.115.115 0 01.03-.092 302.242 302.242 0 019.5-43.944c2.244-7.559 4.7-14.75 7.345-21.388a1.392 1.392 0 00-2.58-1.045c-2.675 6.73-5.194 14.013-7.438 21.635a307.119 307.119 0 00-9.589 44.343.9.9 0 000 .4 1.349 1.349 0 001.532 1.228z'
      />
      <path
        data-name='Path 1938'
        d='M583.182 410.389a1.438 1.438 0 001.23-1.6c-5.624-40.686-28.333-75.473-29.286-76.948a2.756 2.756 0 00-.277-.307 1.4 1.4 0 00-1.966.184 1.347 1.347 0 00-.031 1.721c1.413 2.151 23.323 36.108 28.824 75.719a1.355 1.355 0 001.506 1.231z'
      />
      <path
        data-name='Path 1939'
        d='M590.59 388.879a1.414 1.414 0 001.228-1.567c0-.061-.03-.123-.03-.185-7.222-28.64-19.76-47.417-37.215-55.775a1.41 1.41 0 10-1.2 2.551c16.686 7.99 28.7 26.121 35.708 53.9a1.413 1.413 0 001.509 1.076z'
      />
      <path
        data-name='Path 1940'
        d='M597.566 442.195a1.407 1.407 0 001.229-1.444 308.282 308.282 0 00-3.135-34.018 1.408 1.408 0 00-1.567-1.2h-.031a1.393 1.393 0 00-1.167 1.6 309.812 309.812 0 013.1 33.711 1.411 1.411 0 001.474 1.352z'
      />
      <path
        data-name='Path 1941'
        d='M601.13 437.37v-.338a.271.271 0 01.031-.153c2.766-11.955 1.69-26.428-3.2-42.992a1.41 1.41 0 012.705-.8c5.009 17.024 6.114 31.928 3.257 44.374a1.438 1.438 0 01-1.229 1.167 1.48 1.48 0 01-1.564-1.258zm0-.031z'
      />
      <path
        data-name='Path 1942'
        d='M594.431 380.212a1.624 1.624 0 00.369-.092 1.413 1.413 0 00.768-1.844l-.061-.154a197.451 197.451 0 00-14.75-29.562.03.03 0 00-.031-.031 1.416 1.416 0 00-2.581.953 1.475 1.475 0 00.216.615 197.163 197.163 0 0114.5 29.07l.061.154a1.414 1.414 0 001.509.891z'
      />
      <path
        data-name='Path 1943'
        d='M597.749 373.114a1.97 1.97 0 00.553-.185 1.4 1.4 0 00.461-1.936 116.724 116.724 0 00-18.219-22.647 1.413 1.413 0 00-1.967 2.028 112.254 112.254 0 0117.792 22.095 1.484 1.484 0 001.38.645z'
      />
      <path
        data-name='Path 1944'
        d='M610.471 445.146a1.373 1.373 0 001.2-1.107c.338-1.291 3.841-15.3 1.045-34.54a102.541 102.541 0 00-7.713-26.643 1.464 1.464 0 00-1.476-.86 1.413 1.413 0 00-1.229 1.567 1.491 1.491 0 00.123.4 95.779 95.779 0 013.688 9.618 99.027 99.027 0 013.809 16.318c2.8 19.176-.83 32.881-.983 33.465a1.132 1.132 0 00-.031.554 1.414 1.414 0 001.567 1.228z'
      />
      <path
        data-name='Path 1945'
        d='M611.917 411.035a1.407 1.407 0 001.229-1.26c.8-8.267 4.547-19.175 11.155-32.482 0-.03.03-.03.03-.061a.108.108 0 01.032-.062 1.41 1.41 0 00-2.49-1.321c-.031.062-.061.154-.093.215-6.76 13.613-10.6 24.86-11.431 33.434a1.413 1.413 0 001.26 1.537.526.526 0 00.308 0z'
      />
      <path
        data-name='Path 1946'
        d='M629.188 365.032a1.372 1.372 0 01-.522-1.905c5.593-9.865 11.247-18.561 14.842-23.938-5.224 3.871-10.325 9.4-15.3 16.44-.032.031-.032.062-.062.092a1.381 1.381 0 01-1.966.246 1.41 1.41 0 01-.307-1.936l.03-.031c7.007-9.956 14.413-16.993 21.973-20.9a1.414 1.414 0 011.721.338 1.432 1.432 0 01.092 1.752c-.092.123-9.4 13.183-18.561 29.316a1.4 1.4 0 01-1.045.707 1.516 1.516 0 01-.895-.181zM625.899 354zm0 0z'
      />
      <path
        data-name='Path 1947'
        d='M607.982 394.44a1.432 1.432 0 001.167-.953 178.171 178.171 0 0113-28.333l.093-.185.092-.153a1.413 1.413 0 10-2.428-1.445 1.351 1.351 0 00-.123.215l-.092.153a180.918 180.918 0 00-13.246 28.856 1.412 1.412 0 00.892 1.783 1.594 1.594 0 00.645.062z'
      />
    </g>
    <g>
      <g data-name='Group 466'>
        <path
          data-name='Path 1948'
          d='M428.12 414.354a1.644 1.644 0 011.014 2.8l-34.51 32.512a5.957 5.957 0 01-3.933 1.752l-34.694 1.69-.031-.553 41.086-27.718a2.48 2.48 0 001.076-2.059v-10.975z'
          fill='#ddd7ca'
          opacity={0.4}
        />
        <path
          data-name='Path 1949'
          d='M574.58 1.25c-110.136 50.827-201.343 259.607-243.6 368.269-1.167 3.042-2.335 5.992-3.441 8.881a9.418 9.418 0 00-2.612-.369 16.872 16.872 0 00-2.766.277C362.235 271.55 457.99 48.943 574.58 1.25'
          fill='#707070'
        />
        <path
          data-name='Path 1950'
          d='M398.158 411.219v10.971a2.48 2.48 0 01-1.075 2.059l-41.086 27.718-.215-16.9-.062-5.5 42.469-23.693v5.347z'
          fill='#8f9d45'
        />
        <path
          data-name='Path 1951'
          d='M398.158 396.776v9.1l-42.47 23.693-.123-9.342z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1952'
          d='M398.159 396.776l-42.592 23.447-12.569-1.137s40.01-10.386 37.122-24.062z'
          fill='#455e8d'
        />
        <path
          data-name='Path 1953'
          d='M380.12 394.994c2.888 13.675-37.122 24.062-37.122 24.062l-29.194-2.669c.185-.523.369-1.076.584-1.629a114.26 114.26 0 0142.868-6.638 24.762 24.762 0 001.26-3.565l13.03-3.166s.584-3.012 1.291-7.1z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1954'
          d='M226.438 529.776c8.113-1.69 10.94-7.13 14.935-14.689 3.257-6.115 14.075-38.843 20.5-55.5h.154c-.307 3.5-.614 7.068 3.257 10.694 2.243 2.12 10.418 4.855 19.791 7.067-8.113 22.371-16.748 45.573-18.131 47.324a11.007 11.007 0 002.766 2.274c3.165 1.69 5.839.307 6.3-.461l7.375-20.189a4.3 4.3 0 002.8 3.565c.615.246 6.084-.861 7.006-1.014a.179.179 0 00.123-.031 7.138 7.138 0 006.238-7.222c.277-8.881-10.725-11.278-13.429-2.827l7.16-19.667c4.7.891 9.742 1.628 14.136 2.09 16.441 1.751 16.318-6.423 15.642-14.966a42.7 42.7 0 00-1.321-6.361c31.806 4.118 53.778 14.935 55.191 29.532 2.151 22.34-44.62 45.111-104.451 50.858s-110.075-7.683-112.226-30.023c-.43-4.364 1.045-8.758 4.118-13.061a2.958 2.958 0 001.167 1.444c10.387 6.884 51.2 32.6 59.248 31.468a27.112 27.112 0 001.651-.305z'
          fill='#ddd7ca'
          opacity={0.4}
        />
        <path
          data-name='Path 1955'
          d='M372.837 394.287c-.708 4.087-1.291 7.1-1.291 7.1l-13.03 3.165c1.29-4.578 2.243-12.385-4.609-15.887 1.936-.092 2.858-.8 3.319-1.875l.246.093c6.361 3.6 6.484 9.065 6.269 11.216a.557.557 0 00.769.584 15.487 15.487 0 002.182-1.076c1.629-1.014 2.981-17.209 3.165-19.851.184-2.582-1.567-3.35-1.26-5.685s4.456-1.567 4.456-1.567a6.945 6.945 0 011.844 3.5c.583 3.412-.89 13.37-2.06 20.283z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 1956'
          d='M357.472 378.922v-.645a13.723 13.723 0 011.967-6.576c3.749-6.023 10.356-4.671 13.582-1.2 0 0-4.118-.768-4.456 1.567-.307 2.3 1.444 3.073 1.26 5.685-.184 2.643-1.506 18.807-3.165 19.852a12.692 12.692 0 01-2.182 1.075.557.557 0 01-.769-.584c.216-2.121.093-7.621-6.268-11.217l-.246-.092a6.406 6.406 0 00.369-2.428c.031-1.566-.154-3.44-.092-5.437z'
          fill='#707070'
        />
        <path
          data-name='Path 1957'
          d='M353.907 388.665c6.884 3.534 5.932 11.309 4.61 15.887a30.309 30.309 0 01-1.26 3.565 114.473 114.473 0 00-42.868 6.638c1.874-5.286 4.056-11.37 6.545-18.069.492-.061.953-.154 1.414-.246a26.317 26.317 0 002.859-.676c1.843-.584 4.578-2.8 6.361-5.562 2.8-.461 5.623-.891 8.235-1.26.461-.061.891-.123 1.322-.185 5.439-.768 9.557-1.29 9.557-1.29a14.122 14.122 0 013.225 1.198z'
          fill='#d9c9b8'
        />
        <path
          data-name='Path 1958'
          d='M355.752 435.094l.215 16.9-37.982-4.948a856.331 856.331 0 00-5.316-16.256z'
          fill='#a7b55c'
        />
        <path
          data-name='Path 1959'
          d='M355.689 429.565l.061 5.5-43.084-4.3c-.676-1.967-1.259-3.81-1.843-5.5l.184-.061 44.528 4.394z'
          fill='#8f9d45'
        />
        <path
          data-name='Path 1960'
          d='M355.562 420.223l.123 9.373-.123.061-44.527-4.394-.184.061a2.106 2.106 0 01-.092-.246c.891-2.551 1.905-5.47 3.042-8.7l29.194 2.673z'
          fill='#455e8d'
        />
        <path
          data-name='Path 1961'
          d='M342.137 372.254s1.014 11.431-1.014 16.471v.061c-.43.062-.861.123-1.321.185-2.244-.983-3.135-2.582-2.12-5.87 1.014-3.319-.738-8.851-2.243-11.432-1.383-2.335 1.752-3.81 2.367-4.087 1.934.186 3.685 1.353 4.331 4.672z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 1962'
          d='M335.469 371.67c1.506 2.581 3.258 8.112 2.243 11.431s-.123 4.886 2.12 5.869c-2.612.369-5.439.8-8.235 1.26 1.874-2.889 2.643-6.331.215-9.035a9.27 9.27 0 00-4.21-2.8 962.126 962.126 0 013.442-8.881l.215.092s3.534-2.3 6.607-2c-.645.223-3.78 1.729-2.397 4.064z'
          fill='#707070'
        />
        <path
          data-name='Path 1963'
          d='M331.781 381.17c2.428 2.7 1.66 6.177-.215 9.035-1.782 2.735-4.518 4.947-6.361 5.562a20.646 20.646 0 01-2.858.676c-.891-1.783 1.291-3.472 4.456-7.16 3.165-3.657-1.844-11.309-1.844-11.309a9.579 9.579 0 012.612.369 9.794 9.794 0 014.21 2.827z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1964'
          d='M324.959 378s5.009 7.621 1.844 11.309-5.347 5.378-4.456 7.16c-.461.093-.921.185-1.413.246a44.285 44.285 0 01-4.917.492c2.827-9.311-4.487-15.058-4.487-15.058a9.135 9.135 0 015.347-2.8c1.752-.246 3.5-.769 5.316-1.075a14.017 14.017 0 012.766-.274z'
          fill='#455e8d'
        />
        <path
          data-name='Path 1965'
          d='M323.023 466.195c.676 8.543.8 16.717-15.641 14.965-4.394-.461-9.434-1.2-14.136-2.089l2-5.439a111.406 111.406 0 0014.136 2.059c5.346 0 7.068-4.149 7.9-7.222.246-1.014-.369-4.7-1.383-9.312 1.967.185 3.934.431 5.839.676a41.7 41.7 0 011.285 6.362z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1966'
          d='M317.984 447.048c1.475 4.7 2.828 9.189 3.749 12.753a167.6 167.6 0 00-5.839-.676c-.891-4-2.059-8.7-3.2-12.906-1.536-5.655-2.98-10.417-3.564-11.463a10.209 10.209 0 00-1.2-1.536c.707-2.028 1.629-4.763 2.827-8.174a2.073 2.073 0 01.092.246c.553 1.659 1.168 3.5 1.844 5.5 1.665 5.012 3.57 10.789 5.291 16.256z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1967'
          d='M315.894 459.158c1.013 4.609 1.659 8.3 1.382 9.311-.8 3.073-2.551 7.222-7.9 7.222a116.762 116.762 0 01-14.136-2.059l5.624-15.457c5.196.154 10.205.492 15.03.983z'
          fill='#ddd7ca'
          opacity={0.4}
        />
        <path
          data-name='Path 1968'
          d='M316.016 397.176a44.292 44.292 0 004.917-.491 1615.787 1615.787 0 00-6.545 18.069c-.185.553-.4 1.076-.584 1.629-1.137 3.227-2.182 6.146-3.042 8.7-1.168 3.38-2.12 6.116-2.827 8.174-.861 2.551-1.321 3.964-1.383 4.118a9.51 9.51 0 00-4.425-2.182v-.093s.43-1.321 1.29-3.78c.953-2.827 2.49-7.222 4.456-12.876 2.059-5.808 4.579-13 7.559-21.265z'
          fill='#707070'
        />
        <path
          data-name='Path 1969'
          d='M311.531 382.118s7.313 5.747 4.486 15.058h-.553a7.406 7.406 0 01-2.12-.123c-.031-.829-.062-1.751-.154-2.734-.307-3.442-1.137-7.468-3.134-10.172a9.977 9.977 0 011.475-2.029z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1970'
          d='M313.343 397.053a7.532 7.532 0 002.12.123c-2.981 8.267-5.5 15.457-7.56 21.266l-.154-.062a4.464 4.464 0 00-2.642.369l-38.2 17.331c.492-.584 11.278-13.244 10.786-20.159.645-.245 1.321-.491 2.028-.8 7.252-3.042 27.565-11.739 32.451-13.828a1.849 1.849 0 001.137-1.659c.034-.645.034-1.536.034-2.581z'
          fill='#d9c9b8'
        />
        <path
          data-name='Path 1971'
          d='M313.343 397.053v2.582a1.849 1.849 0 01-1.137 1.659c-4.886 2.12-25.2 10.786-32.451 13.828-.706.308-1.383.553-2.028.8a25.178 25.178 0 01-7.805 1.752 16.733 16.733 0 01-7.805-1.629c-4.825-2.12-15.211-22.586-15.211-22.586-3.073-12.323-5.962-23.939-7.376-30.208a23.9 23.9 0 00-.645-2.367l3.38-.953c3.6 12.354 14.136 49.2 22.28 49.6 9.065.461 23.447-4.732 28.979-7.344 5.377-2.551 12.077-10.663 19.667-7.867.093 1.011.121 1.934.152 2.733z'
          fill='#8f9d45'
        />
        <path
          data-name='Path 1972'
          d='M313.19 394.349c-7.621-2.8-14.32 5.316-19.7 7.867-5.531 2.612-19.882 7.805-28.978 7.344-8.174-.43-18.684-37.245-22.279-49.6-.584-2.028-.984-3.38-1.168-3.841a11.344 11.344 0 00-4.456-4.979c.83-5.408 2.766-8.543 5.132-10.264 3.78 3.411 7.313 6.884 8.881 9.25 10.325 15.334 10.878 54.761 22.925 53.685 12.046-1.106 12.231-11.954 7.683-13.244l20.681-9.065c3.872-.922 6.423.4 8.144 2.7 1.999 2.676 2.798 6.705 3.135 10.147z'
          fill='#a7b55c'
        />
        <path
          data-name='Path 1973'
          d='M312.668 446.22l-7.13-.953 1.936-5.285a2.2 2.2 0 00-.553-2.366c-.123-.092-.246-.215-.369-.307.062-.154.523-1.567 1.383-4.118a12.617 12.617 0 011.2 1.536 103.743 103.743 0 013.533 11.493z'
          fill='#a7b55c'
        />
        <path
          data-name='Path 1974'
          d='M280.155 441.919c-5.286 2.735-10.049 5.347-12.507 7.006a12.892 12.892 0 00-1.444 1.106c-.769.768-2.274 4.21-4.179 9.127l-.184.461c-1.383.123-2.766.307-4.118.461-.922-11.124-16.318-68.589-23.662-95.632a3138.523 3138.523 0 00-3.872-14.105l.093-.154a15.872 15.872 0 015.9 5.194 21.008 21.008 0 012.643 5.531 23.968 23.968 0 01.646 2.367c1.444 6.269 4.3 17.885 7.375 30.208a7495.14 7495.14 0 0010.786 42.684 3.177 3.177 0 001.506 1.967 3.121 3.121 0 002.95.153l4.794-2.182 38.2-17.332a4.718 4.718 0 012.642-.369l.154.062c-2 5.654-3.5 10.049-4.456 12.876h-.062c-2.093.276-13.525 5.592-23.205 10.571z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1975'
          d='M306.921 437.647a2.2 2.2 0 01.553 2.366l-1.936 5.286-4.671 12.876a232.885 232.885 0 00-4.64-.123c3.749-9.741 6.392-16.687 6.577-17.147.614-1.722.983-3.166 4.117-3.258z'
          fill='#d9c9b8'
        />
        <path
          data-name='Path 1976'
          d='M306.578 437.341c.123.092.246.215.369.307-3.135.092-3.5 1.537-4.087 3.227-.184.492-2.828 7.406-6.576 17.147-1.383-.031-2.8-.031-4.21-.031 1.906-5.316 3.626-10.049 4.978-13.828a1266.25 1266.25 0 003.011-8.451.852.852 0 01.768-.584 6.149 6.149 0 011.321.061 9.349 9.349 0 014.426 2.152z'
          fill='#ddd7ca'
        />
        <path
          data-name='Path 1977'
          d='M303.417 431.286h.062c-.86 2.458-1.291 3.78-1.291 3.78v.093a6.232 6.232 0 00-1.322-.062.821.821 0 00-.768.584 851.605 851.605 0 00-3.011 8.451l-16.87-2.246c9.618-4.946 21.05-10.262 23.2-10.6z'
          fill='#a7b55c'
        />
        <path
          data-name='Path 1978'
          d='M300.898 458.145l-5.623 15.457-2 5.439-7.16 19.667-2.735 7.529-7.375 20.19c-.461.8-3.134 2.182-6.3.461l.215-.4s16.748-43.483 26.366-68.5c1.509.065 3.075.096 4.612.157z'
          fill='#d9c9b8'
        />
        <path
          data-name='Path 1979'
          d='M286.116 498.739c2.7-8.451 13.706-6.085 13.429 2.827a7.137 7.137 0 01-6.238 7.222.3.3 0 00-.123.031c-3.872.491-8.051-1.936-7.59-7.252a11.01 11.01 0 01.522-2.828z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1980'
          d='M285.041 477.351c.676-1.812 1.322-3.626 1.967-5.408 1.751-4.855 3.473-9.588 5.04-13.951 1.414 0 2.827 0 4.21.03-9.619 25.014-26.366 68.5-26.366 68.5l-.215.4a9.922 9.922 0 01-2.766-2.274c1.382-1.725 10.017-24.926 18.13-47.297z'
          fill='#ddd7ca'
        />
        <path
          data-name='Path 1981'
          d='M285.563 501.566c-.43 5.316 3.718 7.743 7.59 7.252-.922.184-6.422 1.26-7.006 1.014a4.353 4.353 0 01-2.8-3.564l2.735-7.529a14.5 14.5 0 00-.519 2.827z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1982'
          d='M267.31 459.096c8.481-.737 16.778-1.106 24.738-1.075a3030.956 3030.956 0 01-5.04 13.952l-.154-.062c-7.314-1.659-13.921-3.595-15.642-5.1a10.887 10.887 0 01-3.902-7.715z'
          fill='#ddd7ca'
          opacity={0.4}
        />
        <path
          data-name='Path 1983'
          d='M286.854 471.88l.154.061c-.645 1.783-1.321 3.6-1.966 5.408-9.373-2.212-17.516-4.947-19.79-7.067-3.841-3.626-3.534-7.16-3.258-10.694.891-.123 1.783-.184 2.674-.276s1.752-.154 2.612-.246a10.691 10.691 0 003.9 7.683c1.784 1.536 8.391 3.472 15.674 5.131z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1984'
          d='M274.162 393.642l7.068-3.1c4.548 1.291 4.364 12.139-7.683 13.245s-12.6-38.32-22.925-53.685c-1.6-2.367-5.132-5.839-8.881-9.25 6.023-4.455 14.781.369 14.781.369a13.416 13.416 0 011.321 1.321c2.214 2.577 11.433 13.917 16.319 51.1z'
          fill='#8f9d45'
        />
        <path
          data-name='Path 1985'
          d='M277.696 415.952c.492 6.914-10.264 19.575-10.786 20.159l-4.794 2.182a3.122 3.122 0 01-2.95-.154s10.54-15.918 10.725-20.4a27.287 27.287 0 007.805-1.787z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1986'
          d='M262.086 416.075a16.731 16.731 0 007.805 1.628c-.185 4.487-10.725 20.4-10.725 20.4a3.2 3.2 0 01-1.506-1.967c-1.936-7.621-6.576-25.813-10.786-42.684 0 .036 10.387 20.505 15.212 22.623z'
          fill='#d9c9b8'
        />
        <path
          data-name='Path 1987'
          d='M267.309 459.097c-.86.061-1.751.154-2.612.246l-2.674.276c0-.154.031-.307.031-.43 1.906-4.947 3.411-8.359 4.18-9.127a12.9 12.9 0 011.444-1.106 44.511 44.511 0 00-.369 10.141z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1988'
          d='M257.753 460.08c1.352-.154 2.735-.308 4.118-.461-6.423 16.656-17.24 49.383-20.5 55.5-4.026 7.529-6.822 12.968-14.935 14.689 11.155-5.162 29.563-62.32 31.314-68.067a7.16 7.16 0 00.003-1.661z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1989'
          d='M257.753 460.08a5.393 5.393 0 01-.031 1.66c-1.751 5.747-20.128 62.9-31.314 68.067a13.684 13.684 0 01-1.628.277c-8.082 1.137-48.861-24.615-59.248-31.467a3.108 3.108 0 01-1.168-1.445 41.252 41.252 0 018.051-8.235c-.922 2.458-1.352 4.3-1.106 5.224 1.536 5.44 25.813 18.162 28.056 19.36a130.467 130.467 0 0017.762 7.406.094.094 0 00.061.031 1.75 1.75 0 00.369.123c5.07 1.721 8.051 4.732 15.273-6.883 5.1-8.2 15.4-34.725 20.988-49.629a8.878 8.878 0 00.522-4.056 97.92 97.92 0 013.413-.433z'
          fill='#455e8d'
        />
        <path
          data-name='Path 1990'
          d='M257.753 460.08c-1.137.154-2.3.277-3.411.43a8.836 8.836 0 00-7.59-7.805l-44.866-5.962a6.331 6.331 0 00-3.38.492c-.369-.184-4.026-1.752-6.761 3.964-2.366 4.917-15.334 26.95-19.329 37.705a42.959 42.959 0 00-8.051 8.236 3.084 3.084 0 01.184-2.643c5.409-9.465 24.277-42.868 24.615-48 .4-6.023-19.7-62.475-17.823-79.314a7.818 7.818 0 01.123-.891 28.86 28.86 0 011.352-5.9l2.489.83a24.429 24.429 0 00-.676 9.834c.861 8.2 13.429 63.4 17.209 68.036 3.749 4.64 7.775-.922 7.775-.922v-.092l1.352.092 41.854 2.4a3.846 3.846 0 003.995-4.609c-1.26-6.3-3.626-17.916-6.146-30.023l-1.66-7.928c-3.073-14.72-6.115-28.579-7.345-32.82l2.458-.676c7.314 26.946 22.71 84.411 23.632 95.566z'
          fill='#455e8d'
        />
        <path
          data-name='Path 1991'
          d='M254.342 460.48a8.488 8.488 0 01-.523 4.056c-5.624 14.874-15.887 41.394-20.989 49.63-7.221 11.616-10.2 8.6-15.273 6.883-.123-.03-.246-.092-.369-.123l-.061-.031c-4.886-2.059-28.21-16.41-38.72-23.6a6.665 6.665 0 01-2.213-8.482l2.028-4.118c16.872-10.97 44.222-19.974 76.12-24.215z'
          fill='#ddd7ca'
          opacity={0.4}
        />
        <path
          data-name='Path 1992'
          d='M243.805 334.517c6.638 2.827 10.325 4.609 12.722 6.7 0 0-8.758-4.825-14.781-.369-6.146-5.562-12.937-10.909-12.937-10.909l.031-.215c.461.062.922.154 1.352.215 6.08 1.106 9.031 2.612 13.613 4.578z'
          fill='#d9c9b8'
        />
        <path
          data-name='Path 1993'
          d='M246.78 435.896a3.846 3.846 0 01-3.995 4.609l-41.854-2.4c-1.229-.891-3.933-6.054-6.853-12.108l.092-.061c3.2-3.473 10.233-16.041 24.83-16.072s18.192.43 21.572-4.025h.031a3314.039 3314.039 0 016.177 30.057z'
          fill='#ddd7ca'
        />
        <path
          data-name='Path 1994'
          d='M242.234 359.962l-3.38.952a21.026 21.026 0 00-2.643-5.531 40.874 40.874 0 01.4-4.241 11.538 11.538 0 014.456 4.979c.184.43.583 1.782 1.167 3.841z'
          fill='#8f9d45'
        />
        <path
          data-name='Path 1995'
          d='M244.723 326.527s-8.3 1.383-12.261-1.383a2.348 2.348 0 002.028-2.3v-.153s7.099.179 10.233 3.836z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 1996'
          d='M240.636 405.872c-3.38 4.425-7.007 3.995-21.6 4.026-14.627.031-21.634 12.6-24.83 16.072l-.093.061c-4.7-9.925-9.9-22.217-9.9-22.217-.215-.8-.43-1.6-.615-2.427l3.319-.215 52.026-3.227c.587 2.642 1.14 5.315 1.693 7.927z'
          fill='#d9c9b8'
        />
        <path
          data-name='Path 1997'
          d='M228.805 329.939s6.761 5.347 12.937 10.909c-2.366 1.721-4.3 4.886-5.132 10.264a41.53 41.53 0 00-.4 4.24 15.876 15.876 0 00-5.9-5.193c-7.006-3.626-17.055-3.012-28.487-2.274-12.814.83-21.48 1.659-26.274 7.5l-.031-.031c-3.749-4.271-4.364-11.739-4.364-19.79 3.165-1.813 11.4-5.5 23.631-6.945a94.163 94.163 0 0114.781-.553c7.406.307 12.753.737 16.871 1.26.86.123 1.69.215 2.458.369z'
          fill='#ddd7ca'
        />
        <path
          data-name='Path 1998'
          d='M238.977 397.944l-52.057 3.227s-9.311-29.224-2.366-34.663 40.011-3.38 42.161-8.881c2.981 1.72 3.442 4.18 4.487 6.33a9.457 9.457 0 01.4 1.168c1.26 4.24 4.271 18.101 7.375 32.819z'
          fill='#455e8d'
        />
        <path
          data-name='Path 1999'
          d='M233.415 266.296l2.336 10.755-2.766.8-3.012-9.833z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 2000'
          d='M234.828 289.651c.922 6.238.369 10.448-3.288 17.3a10.048 10.048 0 01-2.8 3.319 27.334 27.334 0 01-3.1-2.428l.092-.092a31.792 31.792 0 003.841-16.563 17.281 17.281 0 005.255-1.536z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2001'
          d='M232.83 314.358s2.674 2.428 1.659 8.328a2.327 2.327 0 00-2.335-2.181 2.244 2.244 0 00-.922.184c-.031.03.461-5.563 1.598-6.331z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 2002'
          d='M234.459 322.687v.153a2.324 2.324 0 01-2.029 2.3c-.123 0-.215.031-.307.031a2.32 2.32 0 11-.031-4.64 2.268 2.268 0 012.367 2.156z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 2003'
          d='M234.091 364.453l-2.458.677c-.184-.554-.307-.953-.4-1.168-1.014-2.151-1.506-4.609-4.487-6.33a11.852 11.852 0 00-2.489-1.076s-.123-4.179-10.325-4.026c-10.2.185-31.437.646-36.077 4.671a8.808 8.808 0 00-2.52 3.965l-2.489-.83a15.909 15.909 0 012.735-4.948c4.794-5.839 13.46-6.668 26.274-7.5 11.463-.737 21.511-1.352 28.487 2.274l-.092.153c-.031.028 1.506 5.56 3.841 14.138z'
          fill='#455e8d'
        />
        <path
          data-name='Path 2004'
          d='M233.415 282.153c.614 2.888 1.106 5.316 1.413 7.5a18.51 18.51 0 01-5.285 1.567 57.537 57.537 0 00-3.5-17.7c1.045-.4 1.66-.615 1.66-.615l2.55 5.747 2.121-.615a48.787 48.787 0 011.041 4.116z'
          fill='#ef7b62'
        />
        <path
          data-name='Path 2005'
          d='M227.479 243.126c2.336.062 7.744.676 5.347 7.345-.615 1.69-4.149 4.609-9.1 7.99a140.979 140.979 0 01-5.87 3.811c-14.812 9.281-35.493 20.036-37.214 20.4a2.369 2.369 0 01-.461.062h-.154c-2.244 0-3.2-2.735-3.258-3.2 0 0 40.133-22.187 44.651-25.629s2.889-10.94 2.889-10.94c1.67.099 2.775.161 3.17.161z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 2006'
          d='M229.973 268.017l3.011 9.834-.584.154-2.151.615-2.551-5.747-1.383-3.073 2.52-1.229z'
          fill='#455e8d'
        />
        <path
          data-name='Path 2007'
          d='M222.506 315.526c2.213.707 8.02 3.626 7.682 14.443-.43-.062-.891-.154-1.352-.215-.768-.123-1.567-.246-2.458-.369-1.813-7.313-8.451-13.521-8.451-13.521 2.09-.399 3.78-.614 4.579-.338z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 2008'
          d='M229.538 291.218a31.794 31.794 0 01-3.841 16.564l-.092.092a35.058 35.058 0 01-10.971-17.547l2.213-3.534a15.713 15.713 0 0012.691 4.425z'
          fill='#707070'
        />
        <path
          data-name='Path 2009'
          d='M228.836 268.57l-2.52 1.26 1.383 3.073s-.614.215-1.659.615a65.738 65.738 0 00-2.551-6.115 1.892 1.892 0 00-2.612-.8 377.305 377.305 0 00-9.127 5.47 33.321 33.321 0 004.763-5.193c1.905-3.012 1.291-4.548 1.291-4.548a254.78 254.78 0 005.87-3.811 79.377 79.377 0 015.162 10.049z'
          fill='#ef7b62'
        />
        <path
          data-name='Path 2010'
          d='M211.78 280.955l-.645-1.321c4.7-2.3 11.739-4.979 14.9-6.146a56.827 56.827 0 013.5 17.7 15.543 15.543 0 01-12.691-4.425l-2.212 3.534c-5.987-1.936-6.048-7.898-2.852-9.342z'
          fill='#ea9b72'
        />
        <path
          data-name='Path 2011'
          d='M212.028 311.133a4.386 4.386 0 00-.369 5.378l.123.584a35.682 35.682 0 01-5.224.707 21.414 21.414 0 01-5.931-.553s-8.574-7.313-13.276-14.351c-.184-.246-.338-.523-.522-.8 6.607-.4 14.6-5.1 16.256-7.559 1.69-2.489 1.936-5.562 2.09-8.574h.276a5.487 5.487 0 012.182-4.364 27.49 27.49 0 013.5-1.967l.646 1.322c-3.165 1.444-3.1 7.406 2.857 9.373a35.057 35.057 0 0010.971 17.547 30.736 30.736 0 003.1 2.427 11.905 11.905 0 01-6.361 2.244 25.278 25.278 0 01-9.588-1.352z'
          fill='#ea9b72'
        />
        <path
          data-name='Path 2012'
          d='M224.257 356.55a11.845 11.845 0 012.489 1.076c-2.151 5.5-35.216 3.441-42.161 8.881s2.366 34.663 2.366 34.663l-3.319.215c-4.917-19.759-8.512-39.734-4.364-41.7 7.222-3.442 16.994-3.995 33.557-4.271 5.286-.093 8.912.337 11.432 1.136z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 2013'
          d='M224.318 243.001s1.629 7.5-2.888 10.94c-4.548 3.442-44.651 25.629-44.651 25.629-1.475-10.295-.246-19.76 3.6-23.724 12.379-12.72 35.396-13.089 43.939-12.845z'
          fill='#455e8d'
        />
        <path
          data-name='Path 2014'
          d='M223.488 267.372c.645 1.383 1.6 3.5 2.551 6.115-3.165 1.168-10.2 3.841-14.9 6.146a27.457 27.457 0 00-3.5 1.967 5.531 5.531 0 00-2.182 4.363h-.284c.154-3.226.215-6.423 1.844-8.758a41.5 41.5 0 014.732-5.132 298.002 298.002 0 019.127-5.47 1.857 1.857 0 012.612.769z'
          fill='#ea9b72'
        />
        <path
          data-name='Path 2015'
          d='M222.352 312.515l.154 3.012a12.253 12.253 0 00-4.579.338c-1.874.338-4.026.83-6.146 1.2l-.123-.584a4.343 4.343 0 01.369-5.378l.738.031a24.322 24.322 0 009.587 1.381z'
          fill='#ef7b62'
        />
        <path
          data-name='Path 2016'
          d='M217.927 315.864s6.637 6.239 8.451 13.522c-4.118-.553-9.465-.953-16.871-1.26a87.28 87.28 0 00-14.781.553s-2.274-6.515-1.414-8.6c.86-2.12 1.383-2.827 3.35-3.258 1.045-.246 2.274.123 3.933.461a21.447 21.447 0 005.931.553 40.479 40.479 0 005.224-.707c2.151-.464 4.333-.926 6.177-1.264z'
          fill='#455e8d'
        />
        <path
          data-name='Path 2017'
          d='M217.836 262.302s.615 1.567-1.291 4.548a33.423 33.423 0 01-4.763 5.193c-23.6 14.628-28.64 21.757-28.64 21.757a90.958 90.958 0 01-3.1-11.063h.154a2.375 2.375 0 00.461-.061c1.715-.338 22.365-11.093 37.179-20.374z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2018'
          d='M224.257 356.55c-2.52-.8-6.115-1.26-11.462-1.167-16.563.276-26.336.829-33.557 4.271-4.118 1.967-.553 21.941 4.364 41.7.184.8.4 1.6.615 2.428 3.2 12.569 6.822 24.768 9.25 31.867a3.206 3.206 0 002.889 2.182l3.257.184v.093s-3.995 5.562-7.775.922-16.349-59.8-17.209-68.037a24.464 24.464 0 01.676-9.833 8.8 8.8 0 012.52-3.965c4.64-4.025 25.875-4.486 36.077-4.671 10.232-.153 10.355 4.026 10.355 4.026z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 2019'
          d='M217.128 520.894a130.477 130.477 0 01-17.762-7.406c-2.243-1.2-26.52-13.921-28.057-19.36-.246-.922.184-2.766 1.106-5.224a69.127 69.127 0 015.808-4.179l-2.028 4.118a6.665 6.665 0 002.213 8.481c10.54 7.161 33.834 21.512 38.72 23.57z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 2020'
          d='M211.78 272.074a37.331 37.331 0 00-4.732 5.132c-1.629 2.366-1.69 5.531-1.844 8.758-.154 3.012-.4 6.085-2.09 8.574-1.629 2.428-9.618 7.16-16.256 7.56a45.756 45.756 0 01-3.749-8.236c.031-.031 5.07-7.16 28.671-21.788z'
          fill='#707070'
        />
        <path
          data-name='Path 2021'
          d='M191.744 451.168c2.735-5.716 6.392-4.149 6.761-3.964a6.424 6.424 0 00-3.1 2.95l-17.178 34.51a70.967 70.967 0 00-5.808 4.179c3.996-10.694 16.99-32.759 19.325-37.675z'
          fill='#3d4d6c'
        />
        <path
          data-name='Path 2022'
          d='M175.488 355.383l.031.031a15.913 15.913 0 00-2.735 4.948 26.94 26.94 0 00-1.352 5.9l-.246-.03c-6.73-9.373-3.165-28.3-1.321-29.808a14.846 14.846 0 011.26-.83c-.001 8.05.645 15.518 4.363 19.789z'
          fill='#a7b55c'
        />
        <path
          data-name='Path 2023'
          d='M180.19 283.997h-.154c-2.95-.031-4.3-3.134-4.486-4.24-1.6-11.155-.092-20.62 3.933-24.769 5.132-5.286 17.824-14.013 44.866-13.183 1.168.031 2.059.092 2.643.123.246 0 .43.03.522.03q4.563.139 6.269 2.582c1.076 1.567 1.137 3.718.185 6.392-.43 1.168-1.875 3.35-9.557 8.574-1.69 1.168-3.688 2.458-5.9 3.841-14.535 9.1-35.493 20.128-37.613 20.558l-.123.031a2.4 2.4 0 01-.461.061zm.43-1.291zm40.744-38.504c-10.233 0-29.5 1.567-40.133 12.507-3.442 3.534-4.732 12.446-3.258 22.679.062.246.738 2.151 2.059 2.151h.092c.092 0 .153-.031.246-.031 1.844-.492 21.787-10.817 36.815-20.221a270.455 270.455 0 005.839-3.779c7.16-4.886 8.42-6.792 8.635-7.406.676-1.875.707-3.288.092-4.179-.461-.645-1.567-1.444-4.3-1.536a4.556 4.556 0 01-.584-.031c-.553-.031-1.444-.061-2.581-.092-.859-.062-1.842-.062-2.922-.062z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2024'
          d='M200.656 318.445a1.191 1.191 0 01-.8-.307c-.369-.307-8.758-7.529-13.49-14.6a19.835 19.835 0 01-.553-.861 43.323 43.323 0 01-3.841-8.481 87.726 87.726 0 01-3.134-11.216 1.225 1.225 0 112.4-.491 87.441 87.441 0 003.042 10.909 44.131 44.131 0 003.626 8.02c.154.277.338.522.492.768 4.548 6.792 12.968 14.043 13.03 14.105a1.19 1.19 0 01.123 1.721 1.051 1.051 0 01-.895.433z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2025'
          d='M220.876 313.805a26.15 26.15 0 01-8.543-1.506 1.216 1.216 0 01.861-2.274 23.773 23.773 0 009.065 1.29 10.6 10.6 0 005.716-2 1.229 1.229 0 111.474 1.967 13.149 13.149 0 01-7.006 2.489c-.526.001-1.044.034-1.567.034z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2026'
          d='M228.744 311.501a1.194 1.194 0 01-.983-.492 1.229 1.229 0 01.246-1.721 8.545 8.545 0 002.459-2.95c3.749-7.006 3.995-10.909 3.165-16.563-.307-2.09-.768-4.395-1.413-7.406-.307-1.383-.646-2.858-1.014-4.056a1.216 1.216 0 112.335-.676c.369 1.26.737 2.766 1.045 4.241.646 3.073 1.106 5.408 1.444 7.559 1.014 6.914.184 11.278-3.442 18.069a11.2 11.2 0 01-3.135 3.719.9.9 0 01-.707.276z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2027'
          d='M228.839 269.795a1.2 1.2 0 01-1.107-.738 103.489 103.489 0 00-5.07-9.957 1.233 1.233 0 012.12-1.26 100.464 100.464 0 015.193 10.233 1.239 1.239 0 01-.614 1.629 3.141 3.141 0 01-.522.093z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2028'
          d='M186.858 303.296a1.231 1.231 0 01-.092-2.458c6.269-.369 13.859-4.886 15.3-7.007 1.475-2.182 1.721-4.886 1.875-7.928l.031-.614c.154-3.1.307-6.3 2.028-8.82a42.293 42.293 0 014.886-5.316l.215-.215a30.445 30.445 0 004.364-4.763c1.475-2.336 1.168-3.5 1.168-3.534l1.168-.369 1.137-.43c.123.369.707 2.3-1.413 5.654a31.988 31.988 0 01-4.733 5.224l-.215.215a36.666 36.666 0 00-4.579 4.948c-1.321 1.9-1.444 4.64-1.6 7.528l-.03.615c-.154 3.288-.461 6.484-2.3 9.188-1.967 2.889-10.448 7.682-17.178 8.082z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2029'
          d='M274.78 399.788a1.254 1.254 0 01-1.229-1.076c-.185-1.6-.4-3.318-.614-4.917-4.3-32.543-11.893-45.573-16.041-50.458a10.452 10.452 0 00-1.168-1.167c-2.274-1.967-5.962-3.749-12.415-6.515-.553-.246-1.106-.461-1.629-.707a45.582 45.582 0 00-11.708-3.78c-.4-.061-.86-.153-1.321-.215-.83-.153-1.69-.246-2.428-.338-4.21-.553-9.526-.953-16.779-1.26a91.518 91.518 0 00-14.566.522c-13.614 1.629-21.665 5.962-23.14 6.791-.615.369-.953.584-1.076.676-1.506 1.813-4.763 19.391 1.536 28.149a1.234 1.234 0 01-2 1.445c-6.822-9.5-3.81-29.255-1.106-31.468a11.336 11.336 0 011.414-.922c1.537-.891 9.987-5.408 24.092-7.129a93.121 93.121 0 0114.966-.553c7.314.307 12.692.707 16.963 1.291.737.092 1.629.215 2.489.369.461.062.922.154 1.352.215a47.456 47.456 0 0112.261 3.964c.522.215 1.045.461 1.6.707 6.822 2.919 10.51 4.7 13.03 6.914a10.673 10.673 0 011.444 1.444c7.468 8.789 13.368 27.166 16.594 51.719.215 1.629.43 3.35.615 4.948a1.2 1.2 0 01-1.076 1.352z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2030'
          d='M228.743 311.5a1.239 1.239 0 01-.676-.184 29.384 29.384 0 01-3.257-2.52 36.549 36.549 0 01-11.37-18.192 1.17 1.17 0 01.154-.953l2.213-3.534a1.314 1.314 0 01.922-.584 1.256 1.256 0 011.014.4 14.407 14.407 0 0011.647 4.056 16.664 16.664 0 004.917-1.475 1.223 1.223 0 111.045 2.212 18.818 18.818 0 01-5.655 1.66 16.537 16.537 0 01-12.6-3.749l-1.137 1.844a34 34 0 0010.479 16.44 32.9 32.9 0 002.919 2.274 1.236 1.236 0 01-.615 2.3z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2031'
          d='M214.638 291.556a1.552 1.552 0 01-.369-.061c-4.087-1.322-5.655-4.3-5.777-6.638a5.008 5.008 0 012.8-4.979 5.452 5.452 0 013.718-.154 1.234 1.234 0 01.86 1.506 1.259 1.259 0 01-1.506.86 3.028 3.028 0 00-2.028.031 2.623 2.623 0 00-1.352 2.612c.092 1.536 1.2 3.5 4.118 4.456a1.2 1.2 0 01.768 1.536 1.408 1.408 0 01-1.232.831z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2032'
          d='M206.832 294.413a1.24 1.24 0 01-1.137-.737 19.312 19.312 0 01-1.444-7.775 6.759 6.759 0 012.673-5.286 29.425 29.425 0 013.688-2.059c4.886-2.4 12.138-5.132 15-6.177 1.045-.4 1.69-.615 1.69-.615a1.246 1.246 0 011.567.738 1.259 1.259 0 01-.737 1.567s-.615.215-1.629.615c-2.858 1.045-9.987 3.749-14.781 6.085a26.157 26.157 0 00-3.35 1.874 4.4 4.4 0 00-1.659 3.472 17.627 17.627 0 001.26 6.638 1.207 1.207 0 01-.645 1.6 3.1 3.1 0 01-.496.06z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2033'
          d='M230.253 279.848a1.2 1.2 0 01-1.106-.738l-3.934-8.82a1.227 1.227 0 01.584-1.6l7.1-3.5a1.2 1.2 0 011.075 0 1.231 1.231 0 01.676.83l2.335 10.725a1.263 1.263 0 01-.861 1.444l-5.5 1.6a.777.777 0 01-.369.059zm-2.336-9.465l.891 2 2.12 4.763 3.35-.983-1.755-8.087z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2034'
          d='M230.219 331.167h-.031a1.254 1.254 0 01-1.2-1.26c.307-9.864-4.7-12.538-6.853-13.214-.338-.093-1.291-.123-4 .369-.922.184-1.9.369-2.95.584s-2.12.431-3.2.615a39.607 39.607 0 01-5.378.738 23.873 23.873 0 01-6.238-.554l-.707-.153a7.9 7.9 0 00-2.7-.307c-1.444.307-1.721.614-2.489 2.52-.43 1.045.4 4.824 1.444 7.744a1.234 1.234 0 01-2.335.8c-.4-1.168-2.366-7.068-1.383-9.465.953-2.366 1.752-3.442 4.21-3.995a8.918 8.918 0 013.749.307l.707.154a21.312 21.312 0 005.623.522 38.413 38.413 0 005.04-.676c1.045-.184 2.12-.4 3.134-.614 1.045-.215 2.059-.43 2.981-.584 2.766-.522 4.21-.584 5.163-.307 8.3 2.674 8.635 12.63 8.543 15.642a1.1 1.1 0 01-1.13 1.134z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2035'
          d='M222.505 316.755a1.221 1.221 0 01-1.229-1.168l-.154-3.011a1.231 1.231 0 012.459-.123l.154 3.011a1.235 1.235 0 01-1.168 1.291z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2036'
          d='M232.155 326.404a3.564 3.564 0 01-1.444-6.822 3.654 3.654 0 011.414-.307 3.544 3.544 0 013.534 3.226 1.276 1.276 0 01.031.307 3.575 3.575 0 01-3.1 3.534h-.031l-.123-1.229.092 1.229a.722.722 0 01-.373.062zm.123-2.458l.184 1.2-.184-1.2a1.146 1.146 0 00.983-1.076v-.095a1.107 1.107 0 00-1.536-.922 1.145 1.145 0 00-.677 1.014 1.107 1.107 0 001.23 1.079zm1.014-.953z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2037'
          d='M240.114 328.094c-2.766 0-6.115-.369-8.328-1.936a1.216 1.216 0 011.383-2c2 1.383 5.623 1.6 8.389 1.475a19.429 19.429 0 00-7.1-1.721 1.229 1.229 0 01-.922-.461 1.264 1.264 0 01-.246-.984 10.618 10.618 0 00-.277-5.47 25.161 25.161 0 00-.553 3.81 1.219 1.219 0 11-2.428-.215c.246-2.827.8-6.361 2.151-7.252a1.252 1.252 0 011.506.122c.123.123 2.735 2.551 2.213 8.113 2.366.246 7.191 1.137 9.772 4.179a1.264 1.264 0 01.215 1.229 1.324 1.324 0 01-.953.8c-.182-.027-2.271.311-4.822.311zm-6.545-12.753'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2038'
          d='M286.886 473.109a.846.846 0 01-.276-.031c-9-2.059-14.443-3.872-16.194-5.378a11.908 11.908 0 01-4.3-8.482 46.744 46.744 0 01.338-10.448 1.235 1.235 0 011.352-1.076 1.212 1.212 0 011.076 1.352 43.722 43.722 0 00-.338 9.9 9.488 9.488 0 003.5 6.914c.492.43 3.073 2.09 15.088 4.825a1.235 1.235 0 01.922 1.475 1.181 1.181 0 01-1.168.949z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2039'
          d='M309.41 476.92c-2.489 0-9.281-1.137-14.381-2.09a1.235 1.235 0 11.461-2.427 113.384 113.384 0 0013.921 2.058c3.5 0 5.562-1.936 6.7-6.3.031-.123.215-1.413-1.413-8.727-.8-3.657-1.936-8.236-3.2-12.845-1.813-6.668-3.042-10.479-3.442-11.185a7.075 7.075 0 00-.8-1.014 3.321 3.321 0 00-.276-.338 1.224 1.224 0 111.905-1.536 1.979 1.979 0 00.246.277 10.315 10.315 0 011.075 1.444c.891 1.628 3.012 9.372 3.657 11.708 1.26 4.671 2.4 9.25 3.226 12.968 1.813 8.175 1.506 9.4 1.383 9.865-1.411 5.407-4.48 8.142-9.062 8.142z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2040'
          d='M224.318 531.312c-11.124 0-58.94-31.375-59.432-31.714a4.438 4.438 0 01-1.659-2.028 4.322 4.322 0 01.277-3.688c14.873-26.09 24.246-44.282 24.461-47.478.123-1.967-2.612-11.462-5.531-21.542-5.716-19.759-13.521-46.833-12.292-57.834l.123-.922a30.009 30.009 0 011.414-6.177 17.639 17.639 0 012.919-5.316c4.978-6.084 13.583-7.067 27.135-7.928 11.708-.738 21.818-1.414 29.132 2.427a17.394 17.394 0 016.361 5.593 21.676 21.676 0 012.8 5.869c.277.892.492 1.691.676 2.49 1.26 5.47 3.719 15.611 7.375 30.177a6865.26 6865.26 0 0010.786 42.653 1.9 1.9 0 00.922 1.2 2.058 2.058 0 001.844.123l4.794-2.182a1.231 1.231 0 111.014 2.243l-4.794 2.182a4.46 4.46 0 01-6.146-2.919 6981.172 6981.172 0 01-10.786-42.684c-3.657-14.566-6.146-24.737-7.406-30.208-.154-.707-.369-1.444-.615-2.274a19.407 19.407 0 00-2.489-5.193 14.42 14.42 0 00-5.47-4.794c-6.73-3.5-16.533-2.858-27.841-2.151-12.907.829-21.05 1.721-25.414 7.037a14.859 14.859 0 00-2.52 4.579 27.429 27.429 0 00-1.291 5.655l-.123.891c-1.168 10.51 6.576 37.307 12.23 56.882 3.688 12.784 5.777 20.1 5.624 22.371-.338 5.009-15.672 32.574-24.768 48.523a1.831 1.831 0 00-.123 1.6 2.036 2.036 0 00.707.891c15.427 10.2 51.6 32.236 58.418 31.283.492-.061.983-.154 1.536-.276 7.467-1.568 10.049-6.423 13.952-13.767l.153-.276c1.875-3.534 6.607-16.84 11.155-29.716 3.257-9.25 6.637-18.807 9.28-25.629l.184-.492c2.827-7.314 3.842-8.912 4.456-9.526a11.828 11.828 0 011.475-1.168c.031-.031.092-.061.123-.092a141.064 141.064 0 0112.63-7.068c7.99-4.087 21.019-10.325 23.632-10.725a1.225 1.225 0 01.338 2.427c-1.813.277-12.691 5.255-22.863 10.479a139.746 139.746 0 00-12.262 6.853.42.42 0 01-.123.092 7.09 7.09 0 00-1.26.983c-.246.246-1.137 1.6-3.872 8.7l-.184.492c-2.612 6.761-5.992 16.318-9.25 25.537-4.794 13.552-9.342 26.366-11.308 30.054l-.154.277c-3.933 7.375-7.037 13.214-15.611 15-.615.123-1.2.215-1.721.307a1.367 1.367 0 00-.618-.103z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2041'
          d='M266.911 437.309a1.22 1.22 0 01-1.106-.707 1.238 1.238 0 01.615-1.629l38.2-17.363a5.674 5.674 0 013.5-.43 1.216 1.216 0 11-.676 2.335 3.592 3.592 0 00-1.813.307l-38.2 17.332a.861.861 0 01-.52.155z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2042'
          d='M311.499 482.606a42.009 42.009 0 01-4.21-.246c-4.425-.461-9.465-1.229-14.228-2.12a1.236 1.236 0 01.461-2.428c4.7.892 9.68 1.629 14.044 2.09 6.115.645 10.141-.061 12.292-2.151 2.489-2.4 2.366-6.638 2-11.524a41.271 41.271 0 00-1.26-6.146c-.83-3.257-2.09-7.528-3.719-12.691-1.782-5.747-3.78-11.677-5.286-16.226l-.737-2.212a98.793 98.793 0 00-1.107-3.257l-.03-.062c-.031-.061-.031-.123-.062-.185 0-.03-.031-.061-.031-.092a1.322 1.322 0 01-.061-.338 1.233 1.233 0 011.229-1.229 1.254 1.254 0 011.2.922v.031c.031.061.031.122.062.154.338 1.014.707 2.12 1.106 3.257l.738 2.213a924.396 924.396 0 015.316 16.287c1.659 5.224 2.92 9.557 3.749 12.814a44.125 44.125 0 011.352 6.576c.43 5.5.522 10.325-2.735 13.46-2.157 2.085-5.473 3.103-10.083 3.103z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2043'
          d='M285.041 478.58a1.034 1.034 0 01-.307-.031c-3.9-.922-16.963-4.18-20.344-7.375-4.271-3.995-3.933-8.082-3.626-11.646l.031-.461a1.229 1.229 0 011.321-1.106 1.21 1.21 0 011.106 1.321l-.031.43c-.276 3.5-.552 6.484 2.859 9.68 1.936 1.813 9.465 4.456 19.206 6.761a1.248 1.248 0 01.984 1.2 1.189 1.189 0 01-1.199 1.227z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2044'
          d='M243.033 441.735h-.307l-46.464-2.674a4.431 4.431 0 01-3.964-3.012c-2.09-6.084-5.777-18.131-9.281-31.959l-.123-.431a45.887 45.887 0 01-.492-2c-9.987-40.1-5.255-42.377-3.718-43.114 7.775-3.687 18.53-4.149 34.049-4.395 5.132-.092 9 .307 11.862 1.229a12.4 12.4 0 012.735 1.168 10.2 10.2 0 014.425 5.623c.185.43.338.83.553 1.229a9.236 9.236 0 01.461 1.352c1.475 5.009 5.224 22.617 7.344 32.759.031.061.031.123.061.154l.338 1.659c.43 2.09.86 4.179 1.321 6.268a7797.537 7797.537 0 016.146 30.054 5.036 5.036 0 01-1.137 4.333 4.92 4.92 0 01-3.809 1.757zm-28.98-85.123h-1.26c-17.424.277-26.3.952-33.035 4.149-1.659.8-2.458 10.172 5.04 40.318.154.676.338 1.322.492 1.967l.123.43c3.5 13.767 7.16 25.721 9.219 31.775a2.016 2.016 0 001.782 1.353l4.61.277 41.854 2.4a2.6 2.6 0 002.12-.891 2.687 2.687 0 00.584-2.243 7885.714 7885.714 0 00-6.146-30.023c-.43-2.09-.891-4.18-1.321-6.269l-.307-1.506c-.031-.061-.031-.123-.062-.154-3.657-17.454-6.269-29.07-7.314-32.727-.215-.676-.307-.922-.338-.984-.215-.43-.4-.891-.584-1.352-.738-1.752-1.352-3.258-3.411-4.456a11.732 11.732 0 00-2.243-.953 33.816 33.816 0 00-9.803-1.111z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2045'
          d='M223.365 523.937a13.575 13.575 0 01-4.609-1.106c-.523-.215-1.045-.4-1.629-.615a2.16 2.16 0 00-.246-.092l-.154-.062c-.061-.031-.092-.031-.154-.061-5.1-2.182-28.179-16.379-38.9-23.724a7.942 7.942 0 01-2.612-10.048l2.028-4.118 17.178-34.51a7.441 7.441 0 013.718-3.534 7.563 7.563 0 014.026-.583l44.866 5.961a10.023 10.023 0 018.666 8.881 9.875 9.875 0 01-.584 4.61c-5.685 15.057-15.979 41.547-21.112 49.844-4.366 7.068-7.378 9.157-10.482 9.157zm-5.685-4.148l.277.092c.584.215 1.168.43 1.721.645 3.9 1.506 6.269 2.428 12.107-7.006 5.04-8.113 15.242-34.418 20.9-49.414a7.106 7.106 0 00.43-3.472 7.514 7.514 0 00-6.546-6.7l-44.866-5.962a5.059 5.059 0 00-2.7.369 5.3 5.3 0 00-2.52 2.4l-19.206 38.6a5.5 5.5 0 001.813 6.945c10.51 7.191 33.711 21.48 38.5 23.478.029-.006.06.025.09.025z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2046'
          d='M183.601 402.615a1.237 1.237 0 01-1.229-1.137 1.21 1.21 0 011.137-1.291l55.375-3.442a1.254 1.254 0 011.322 1.229 1.233 1.233 0 01-1.229 1.229l-55.314 3.442c-.031-.03-.031-.03-.062-.03z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2047'
          d='M200.964 439.338a1.045 1.045 0 01-.707-.246c-.922-.646-2.489-2.673-7.221-12.568-4.671-9.834-9.865-22.156-9.926-22.28a1.219 1.219 0 012.243-.952c.061.123 5.224 12.384 9.864 22.156 4.609 9.619 6.054 11.339 6.453 11.646a1.243 1.243 0 01-.707 2.244z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2048'
          d='M175.489 356.612a1.243 1.243 0 01-.922-.43c-4.087-4.7-4.671-12.661-4.671-20.589a1.229 1.229 0 012.458 0c0 7.437.491 14.9 4.056 18.991a1.216 1.216 0 01-.123 1.72 1.088 1.088 0 01-.798.308z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2049'
          d='M236.211 356.613h-.061a1.207 1.207 0 01-1.168-1.26 34.9 34.9 0 01.43-4.394c.8-5.224 2.7-8.942 5.593-11.094 6.576-4.855 15.7.062 16.1.277a1.224 1.224 0 01-1.168 2.152c-.123-.062-8.174-4.364-13.46-.461-2.366 1.751-3.933 4.947-4.64 9.464a36.061 36.061 0 00-.4 4.118 1.247 1.247 0 01-1.226 1.198z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2050'
          d='M269.184 418.932a18.14 18.14 0 01-7.59-1.751c-.83-.369-3.38-1.506-9.957-12.6-3.2-5.347-5.808-10.51-5.839-10.571a1.23 1.23 0 112.182-1.137c3.933 7.774 11.4 20.589 14.6 22.033a15.609 15.609 0 007.252 1.536 24.644 24.644 0 007.437-1.69c.615-.215 1.291-.492 2-.768 7.344-3.073 27.965-11.923 32.451-13.829a.6.6 0 00.4-.584c.031-.583.031-1.382 0-2.52a80.006 80.006 0 00-.154-2.643c-.4-4.21-1.383-7.529-2.889-9.557-1.629-2.182-3.841-2.92-6.76-2.274l-27.627 12.107a1.231 1.231 0 01-1.014-2.244l27.749-12.169a.576.576 0 01.215-.061c3.933-.922 7.19.184 9.4 3.165 1.782 2.428 2.92 6.054 3.35 10.786.092.952.123 1.874.154 2.8v2.673a3.036 3.036 0 01-1.905 2.735c-4.487 1.936-25.137 10.755-32.451 13.828-.738.307-1.444.584-2.09.83a27.212 27.212 0 01-8.174 1.844 3 3 0 01-.74.061z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2051'
          d='M266.91 437.34a1.233 1.233 0 01-1.229-1.23 1.187 1.187 0 01.338-.829c4.087-4.794 10.847-14.167 10.479-19.237a1.233 1.233 0 112.458-.184c.522 7.067-9.127 18.776-11.063 21.05l-.092.092a1.687 1.687 0 01-.891.338z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2052'
          d='M358.516 405.78a.659.659 0 01-.338-.062 1.234 1.234 0 01-.86-1.506c2.551-8.973-.768-12.815-4-14.474a13.406 13.406 0 00-2.735-1.015c-.953.123-4.671.584-9.311 1.26l-.184.03c-.369.062-.738.092-1.106.154-2.612.369-5.47.8-8.236 1.26a1.23 1.23 0 11-.4-2.428c2.8-.461 5.654-.891 8.266-1.259.369-.062.738-.123 1.137-.154l.184-.031a588.622 588.622 0 019.588-1.291 1.173 1.173 0 01.461.031 16.364 16.364 0 013.473 1.29c5.716 2.92 7.56 9.1 5.224 17.332a1.164 1.164 0 01-1.163.863z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2053'
          d='M314.387 415.983a1.237 1.237 0 01-.43-2.4 115 115 0 0142.469-6.73 21.474 21.474 0 00.891-2.642 1.226 1.226 0 112.366.645 24.231 24.231 0 01-1.352 3.749 1.226 1.226 0 01-1.168.707 112.76 112.76 0 00-42.407 6.576.729.729 0 01-.369.095z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2054'
          d='M358.516 405.78a1.232 1.232 0 01-1.228-1.228 1.2 1.2 0 011.013-1.2l12.169-2.981c.215-1.137.646-3.473 1.137-6.3 1.751-10.357 2.459-17.425 2.059-19.883a5.379 5.379 0 00-1.537-2.859 8.02 8.02 0 00-6.392-2.519 7 7 0 00-5.255 3.565 12.187 12.187 0 00-1.782 5.992 2.628 2.628 0 000 .492v.123c-.031 1.137 0 2.213.062 3.257.03.769.062 1.475.062 2.151a7.806 7.806 0 01-.461 2.858c-.676 1.66-2.12 2.52-4.394 2.643a1.232 1.232 0 01-.123-2.46c1.813-.092 2.089-.768 2.243-1.137a5.123 5.123 0 00.277-1.966c0-.615-.031-1.29-.061-2.028-.061-1.014-.092-2.182-.061-3.38a5.559 5.559 0 01.03-.738 14.868 14.868 0 012.151-7.159 9.321 9.321 0 017.1-4.7 10.366 10.366 0 018.42 3.289 7.9 7.9 0 012.151 4.118c.492 2.827-.216 9.772-2.059 20.712-.707 4.056-1.291 7.1-1.291 7.13a1.265 1.265 0 01-.922.952l-13.029 3.165c-.063.091-.187.091-.279.091z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2055'
          d='M361.191 380.551a20.545 20.545 0 01-3.933-.43 1.219 1.219 0 01-1.014-1.2 1.233 1.233 0 011.23-1.229.6.6 0 01.245.03c4.733.953 5.931-.092 6.054-.184a1.259 1.259 0 011.6-.43 1.22 1.22 0 01.522 1.659c-.279.493-1.293 1.784-4.704 1.784zm2.52-2.95zm0 0z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2056'
          d='M361.251 385.99a20.5 20.5 0 01-3.9-.43 1.188 1.188 0 01-.953-1.444 1.209 1.209 0 011.444-.953c4 .8 5.593.154 5.9-.154a1.224 1.224 0 012.182 1.106c-.248.492-1.2 1.875-4.673 1.875zm2.428-2.888zm0 0z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2057'
          d='M314.914 398.405a6.175 6.175 0 01-1.875-.184 1.254 1.254 0 01-.922-1.2 1.233 1.233 0 011.229-1.229 1.546 1.546 0 01.369.061 8.462 8.462 0 001.751.062h.492a41.532 41.532 0 004.794-.492c.492-.062.922-.154 1.383-.215a25.414 25.414 0 002.7-.645 13.419 13.419 0 005.717-5.039c1.106-1.721 2.642-4.979.337-7.56a7.9 7.9 0 00-3.657-2.428 1.235 1.235 0 01.707-2.366 10.425 10.425 0 014.763 3.135c2.49 2.766 2.459 6.607-.092 10.54-1.813 2.8-4.763 5.347-7.006 6.054a23.307 23.307 0 01-3.011.707c-.492.092-.983.184-1.475.246a45.387 45.387 0 01-5.01.491h-.614a2.09 2.09 0 01-.58.062z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2058'
          d='M310.055 385.406a1.087 1.087 0 01-.522-.123 1.227 1.227 0 01-.584-1.629 9.653 9.653 0 011.721-2.366 10.333 10.333 0 016.053-3.165c.891-.123 1.813-.338 2.766-.553.8-.185 1.659-.369 2.489-.523a18.59 18.59 0 012.981-.276 10.218 10.218 0 012.95.43 1.235 1.235 0 01-.707 2.366 7.609 7.609 0 00-2.243-.307 17.2 17.2 0 00-2.582.246c-.8.123-1.6.307-2.366.492a45.92 45.92 0 01-2.95.584 7.97 7.97 0 00-4.64 2.427 8.218 8.218 0 00-1.26 1.721 1.283 1.283 0 01-1.106.676z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2059'
          d='M272.779 528.977a7.914 7.914 0 01-3.657-.953 11.545 11.545 0 01-3.1-2.52 1.175 1.175 0 01-.031-1.567c.522-.676 3.288-6.638 17.946-46.955l.307-.861c.553-1.536 1.106-3.042 1.66-4.579 1.751-4.855 3.472-9.587 5.039-13.952 1.291-3.564 2.49-6.883 3.534-9.833l4.455-12.477a2.108 2.108 0 011.875-1.413 6.741 6.741 0 011.536.092 10.617 10.617 0 015.04 2.459 3.439 3.439 0 00.338.277l.062.061a3.455 3.455 0 01.891 3.688l-31.5 86.444c-.031.062-.061.154-.092.215a5.227 5.227 0 01-4.303 1.874zm-4.364-4.486a9.024 9.024 0 001.844 1.383 4.309 4.309 0 004.64.03l31.437-86.259a1.014 1.014 0 00-.246-1.045c-.123-.092-.184-.184-.277-.246a8.223 8.223 0 00-3.841-1.905c-.308-.031-.584-.061-.83-.061l-4.395 12.261c-1.045 2.95-2.244 6.269-3.534 9.834a2990.168 2990.168 0 01-5.04 13.951 708.5 708.5 0 00-1.659 4.579l-.308.86c-12.935 35.52-16.592 44.22-17.79 46.62zm32.481-88.165z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2060'
          d='M292.2 510.079a8.114 8.114 0 01-5.623-2.151 7.653 7.653 0 01-2.213-6.484 15.9 15.9 0 01.584-3.1c1.568-4.917 5.624-6.545 8.973-5.992 3.442.584 7.037 3.718 6.884 9.219a8.313 8.313 0 01-7.314 8.389h-.031c-.031 0-.092.031-.123.031a7.4 7.4 0 01-1.137.088zm.43-15.365c-1.966 0-4.3 1.106-5.347 4.395a12.492 12.492 0 00-.492 2.55 5.366 5.366 0 001.475 4.517 5.832 5.832 0 004.763 1.414c.031 0 .062-.031.123-.031a5.9 5.9 0 005.193-6.054c.123-4.056-2.336-6.3-4.825-6.729a8.884 8.884 0 00-.889-.063z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2061'
          d='M286.423 511.092a1.55 1.55 0 01-.737-.123 5.513 5.513 0 01-3.534-4.456 1.232 1.232 0 01.953-1.444 1.249 1.249 0 011.444.953 3.084 3.084 0 001.906 2.612 46.474 46.474 0 006.391-1.045c.031 0 .092-.03.123-.03a1.221 1.221 0 011.475.891 1.234 1.234 0 01-.86 1.506.745.745 0 01-.215.031 43.339 43.339 0 01-6.946 1.105zm.154-2.4zm-.185-.061z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2062'
          d='M259.166 439.337a1.1 1.1 0 01-.676-.215 1.23 1.23 0 01-.338-1.69c4.179-6.269 10.418-16.748 10.54-19.791a1.215 1.215 0 012.428.092c-.184 4.7-9.834 19.391-10.94 21.051a1.194 1.194 0 01-1.014.553z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2063'
          d='M327.571 379.598a1.233 1.233 0 01-1.229-1.229.979.979 0 01.092-.43c.8-2.059 1.6-4.118 2.428-6.269l1.014-2.612c31.129-80.052 63.549-149.348 96.4-205.983C476.089 77.246 525.811 22.393 574.088.114a1.224 1.224 0 011.045 2.213c-47.786 22.064-97.168 76.549-146.706 161.947-32.789 56.513-65.178 125.686-96.246 205.646l-1.015 2.612c-.83 2.12-1.629 4.179-2.4 6.207a1.314 1.314 0 01-1.195.859z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2064'
          d='M306.583 438.569a1.233 1.233 0 01-1.229-1.229 1.8 1.8 0 01.062-.4l.061-.216c.184-.492.615-1.813 1.291-3.872.706-2.059 1.659-4.794 2.827-8.174.768-2.243 1.659-4.763 2.643-7.56l.4-1.137.368-1.014.215-.584c2.12-5.931 4.333-12.047 6.545-18.1a1.225 1.225 0 012.3.83 1189.382 1189.382 0 00-6.545 18.069l-.216.615-.369 1.015-.4 1.137a708.984 708.984 0 00-2.643 7.56c-1.167 3.38-2.12 6.115-2.827 8.144-.676 2.028-1.137 3.38-1.291 3.872l-.061.184a1.162 1.162 0 01-1.131.86z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2065'
          d='M322.194 379.506a1.081 1.081 0 01-.43-.092 1.2 1.2 0 01-.707-1.567c20.712-55.191 54.454-136.564 97.6-210.839C470.835 77.122 523.168 20.978 574.147.113a1.227 1.227 0 01.922 2.274c-50.522 20.62-102.422 76.426-154.322 165.85-43.053 74.121-76.733 155.371-97.414 210.47a1.22 1.22 0 01-1.139.799z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2066'
          d='M302.158 436.326a2.076 2.076 0 01-.4-.062 1.236 1.236 0 01-.769-1.567s.43-1.322 1.29-3.78c.677-2.028 2.182-6.392 4.456-12.876 2.213-6.33 4.671-13.275 7.56-21.265a1.2 1.2 0 011.567-.737 1.219 1.219 0 01.738 1.567c-2.889 7.99-5.316 14.935-7.56 21.235-2.274 6.484-3.78 10.848-4.456 12.876-.83 2.458-1.29 3.78-1.29 3.78a1.163 1.163 0 01-1.136.829z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2067'
          d='M341.123 389.954a1.224 1.224 0 01-.461-.093 1.247 1.247 0 01-.676-1.6c1.844-4.517 1.014-14.9.953-15.856-.615-3.012-2.151-3.5-3.227-3.627-2.182-.215-4.948 1.23-5.839 1.814a1.223 1.223 0 01-1.321-2.059c.154-.092 3.934-2.519 7.406-2.213 2 .185 4.548 1.322 5.408 5.655a.185.185 0 00.031.123c.031.491 1.015 11.8-1.106 17.055a1.361 1.361 0 01-1.168.801z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2068'
          d='M332.611 378.646a14.308 14.308 0 01-4.763-1.076 1.219 1.219 0 01.953-2.243c1.137.492 3.38 1.107 4.548.8a1.227 1.227 0 11.645 2.367 5.322 5.322 0 01-1.383.152z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2069'
          d='M355.566 421.453h-.123l-41.762-3.842a1.237 1.237 0 01-1.137-1.229 1.207 1.207 0 011.2-1.229h.154l41.393 3.811 38.812-21.358-21.388-2.089a1.234 1.234 0 11.215-2.459l25.321 2.489a1.242 1.242 0 011.076.984 1.217 1.217 0 01-.615 1.322L356.12 421.3a1.061 1.061 0 01-.554.153z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2070'
          d='M355.968 453.227a1.153 1.153 0 01-.553-.154 1.207 1.207 0 01-.646-1.076l-.4-31.775a1.206 1.206 0 011.2-1.229h.031a1.226 1.226 0 011.229 1.2l.369 29.5 39.211-26.458a1.282 1.282 0 00.553-1.045v-25.445a1.23 1.23 0 012.459 0v25.445a3.707 3.707 0 01-1.629 3.073l-41.117 27.719a1.232 1.232 0 01-.707.245z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2071'
          d='M297.026 445.389h-.154l-16.84-2.243a1.235 1.235 0 01-1.106-1.229 1.207 1.207 0 011.2-1.229h.184l16.871 2.243a1.219 1.219 0 011.045 1.383 1.2 1.2 0 01-1.2 1.075z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2072'
          d='M312.666 447.45h-.154l-7.129-.953a1.225 1.225 0 11.337-2.427l7.13.952a1.22 1.22 0 01-.185 2.428z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2073'
          d='M355.966 453.228h-.154l-37.982-4.948a1.219 1.219 0 01-1.045-1.383 1.2 1.2 0 011.383-1.045l37.982 4.948a1.218 1.218 0 011.045 1.383 1.255 1.255 0 01-1.229 1.045z'
          fill='#3e3e3e'
        />
        <path
          data-name='Path 2074'
          d='M355.567 430.859h-.123l-44.528-4.4a1.235 1.235 0 11.246-2.458l44.159 4.363 42.254-23.57a1.218 1.218 0 011.2 2.12l-42.654 23.785a.94.94 0 01-.554.16z'
          fill='#3e3e3e'
        />
      </g>
    </g>
  </svg>
);

export default Hero;
