import * as bookingTypes from './bookings.types';

const defaultParams = {
  sortBy: 'createdAt:desc',
  limit: 25,
  page: 0,
  totalPages: 0,
  totalResults: 0,
};

const initBookingState = {
  message: null,
  createCartPending: false,
  createCartError: null,
  resendPdfEmailPermitsPending: false,
  resendPdfEmailPermitsError: null,
  coupon: null,
  validateCouponPending: false,
  validateCouponError: null,
  cancelBookingPending: false,
  cancelBookingError: null,
  time: null,
  bookings: [],
  getMyBookingsPending: false,
  getMyBookingsError: null,
  currentBooking: null,
  getBookingByOrderIdPending: false,
  getBookingByOrderIdError: null,
  checkoutPending: null,
  checkoutError: null,
  valitorUrl: null,
  orderId: null,
  ...defaultParams,
};

const bookingReducer = (state = initBookingState, action) => {
  const {
    type,
    results: bookings,
    booking: currentBooking,
    page,
    limit,
    totalPages,
    totalResults,
    error,
    coupon,
    message,
    time,
    valitorUrl,
    orderId,
  } = action;
  switch (type) {
    case bookingTypes.RESET_BOOKING_BY_ORDER_ID:
      return {
        ...state,
        currentBooking: null,
      };
    case bookingTypes.RESET_BOOKING_PARAMS:
      return {
        ...state,
        ...defaultParams,
      };
    case bookingTypes.PAGINATE_BOOKINGS:
      return {
        ...state,
        page,
        limit,
      };
    case bookingTypes.CANCEL_BOOKING_REQUEST:
      return {
        ...state,
        cancelBookingPending: true,
        cancelBookingError: null,
      };
    case bookingTypes.CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        cancelBookingPending: false,
        cancelBookingError: null,
      };
    case bookingTypes.CANCEL_BOOKING_FAILURE:
      return {
        ...state,
        cancelBookingPending: false,
        cancelBookingError: error,
      };
    case bookingTypes.GET_BOOKING_BY_ORDER_ID_REQUEST:
      return {
        ...state,
        getBookingByOrderIdPending: true,
        getBookingByOrderIdError: null,
      };
    case bookingTypes.GET_BOOKING_BY_ORDER_ID_SUCCESS:
      return {
        ...state,
        currentBooking,
        getBookingByOrderIdPending: false,
        getBookingByOrderIdError: null,
      };
    case bookingTypes.GET_BOOKING_BY_ORDER_ID_FAILURE:
      return {
        ...state,
        getBookingByOrderIdPending: false,
        getBookingByOrderIdError: error,
      };
    case bookingTypes.GET_MY_BOOKINGS_REQUEST:
      return {
        ...state,
        getMyBookingsPending: true,
        getMyBookingsError: null,
      };
    case bookingTypes.GET_MY_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings,
        page,
        limit,
        totalPages,
        totalResults,
        getMyBookingsPending: false,
        getMyBookingsError: null,
      };
    case bookingTypes.GET_MY_BOOKINGS_FAILURE:
      return {
        ...state,
        getMyBookingsPending: false,
        getMyBookingsError: error,
      };
    case bookingTypes.SET_BOOKING_MESSAGE:
      return {
        ...state,
        message,
      };
    case bookingTypes.CLEAR_COUPON:
      return {
        ...state,
        coupon: null,
      };
    case bookingTypes.TICK:
      return {
        ...state,
        time,
      };
    case bookingTypes.CLEAR_BOOKING:
      return {
        ...state,
        coupon: null,
        time: null,
      };
    case bookingTypes.VALIDATE_COUPON_REQUEST:
      return {
        ...state,
        validateCouponPending: true,
        validateCouponError: null,
      };
    case bookingTypes.VALIDATE_COUPON_SUCCESS:
      return {
        ...state,
        coupon,
        validateCouponPending: false,
        validateCouponError: null,
      };
    case bookingTypes.VALIDATE_COUPON_FAILURE:
      return {
        ...state,
        validateCouponPending: false,
        validateCouponError: error,
      };
    case bookingTypes.CHECKOUT_REQUEST:
      return {
        ...state,
        checkoutPending: true,
        checkoutError: null,
      };
    case bookingTypes.CHECKOUT_SUCCESS:
      return {
        ...state,
        valitorUrl,
        orderId,
        checkoutPending: false,
        checkoutError: null,
      };
    case bookingTypes.CHECKOUT_FAILURE:
      return {
        ...state,
        checkoutPending: false,
        checkoutError: error,
      };
    case bookingTypes.CREATE_CART_REQUEST:
      return {
        ...state,
        createCartPending: true,
        createCartError: null,
      };
    case bookingTypes.CREATE_CART_SUCCESS:
      return {
        ...state,
        time,
        createCartPending: false,
        createCartError: null,
      };
    case bookingTypes.CREATE_CART_FAILURE:
      return {
        ...state,
        createCartPending: false,
        createCartError: error,
      };
    case bookingTypes.RESEND_PDF_PERMITS_EMAIL_REQUEST:
      return {
        ...state,
        resendPdfEmailPermitsPending: null,
        resendPdfEmailPermitsError: null,
      };
    case bookingTypes.RESEND_PDF_PERMITS_EMAIL_SUCCESS:
      return {
        ...state,
        resendPdfEmailPermitsPending: false,
        resendPdfEmailPermitsError: null,
      };
    case bookingTypes.RESEND_PDF_PERMITS_EMAIL_FAILURE:
      return {
        ...state,
        resendPdfEmailPermitsPending: false,
        resendPdfEmailPermitsError: error,
      };
    default:
      return state;
  }
};

export default bookingReducer;
