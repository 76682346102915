import api from './api';
import catchAsync from './error';

const getMe = catchAsync(async () => {
  const response = await api.GET('/users/me');
  return response.data;
});

const updateMe = catchAsync(async (userId, body) => {
  const response = await api.PATCH(`/users/${userId}`, body);
  return response.data;
});

export default {
  getMe,
  updateMe,
};
