export const getParams = (state) => ({
  limit: state.logs.limit,
  page: state.logs.page,
  sortBy: state.logs.sortBy,
  subarea: state.logs.subarea ? encodeURI(state.logs.subarea) : null,
  subplace: state.logs.subplace ? encodeURI(state.logs.subplace) : null,
  year: state.logs.year ? encodeURI(state.logs.year) : null,
  fishType: state.logs.fishType ? encodeURI(state.logs.fishType) : null,
});

export const getUser = (state) => ({
  user: state.auth.user,
});
