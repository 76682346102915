import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resendCode, verifyEmail } from '../../redux/auth/auth.actions';
import { Modal } from '../../components/ui';
import { VerifyEmailForm } from './components';

const VerifyEmailModal = ({ ...rest }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const onSubmit = ({ code }) => {
    dispatch(verifyEmail(code));
  };

  const onResend = () => {
    dispatch(resendCode());
  };

  return (
    <Modal title='Nýskráning' {...rest}>
      <p>Til þess að klára skráningu þarftu að staðfesta netfangið þitt</p>
      <br />
      <p>Þú hefur fengið staðfestingarkóða sendan á netfangið:</p>
      <br />
      <p className='ta-center medium mv-1'>{user?.email}</p>
      <VerifyEmailForm onSubmit={onSubmit} />
      <div className='pointer mv-1' onClick={onResend}>
        <p>
          Fékkstu ekki póst ?
          {' '}
          <span className='medium'>Ýttu hér til að endursenda</span>
        </p>
      </div>
    </Modal>
  );
};

export default VerifyEmailModal;
