import React from 'react';
import PropTypes from 'prop-types';
import './toast.scss';

const Toast = ({ title, message, type }) => (
  <div className='toast-container'>
    <div>
      {title && <h2>{title}</h2>}
      <p className='mv-05 default'>{message}</p>
    </div>
    <div className='row jc-fe ai-fe'>
      <p className={`button__${type}`}>Loka</p>
    </div>
  </div>
);

Toast.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default Toast;
