import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/is';

import './booking.scss';
import routeConstants from '../../../../../constants/route.constants';

const Booking = ({
  name,
  url,
  createdAt,
  totalPrice,
  line,
}) => (
  <>
    <section className='column jc-sb full-width mv-1'>
      <div className='row jc-sb ai-fe full-width'>
        <h4>{name}</h4>
        <Link to={url}>
          <p className='pointer primary bold'>Skoða</p>
        </Link>
      </div>
      <p className='mb-05'>{createdAt}</p>
      <p className='mb-05'>
        {totalPrice}
      </p>
    </section>
    {line && <div className='line' />}
  </>
);

Booking.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  totalPrice: PropTypes.string.isRequired,
  line: PropTypes.bool,
};

Booking.defaultProps = {
  line: true,
};

export default Booking;
