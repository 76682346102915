import * as authTypes from './auth.types';

const initAuthState = {
  user: null,
  loginPending: false,
  loginError: null,
  logoutPending: false,
  registerPending: false,
  registerError: null,
  getMePending: false,
  getMeError: null,
  resendVerifyEmailCodePending: false,
  resendVerifyEmailCodeError: null,
  verifyEmailPending: false,
  verifyEmailError: null,
  forgotPasswordEmail: null,
  forgotPasswordPending: true,
  forgotPasswordError: null,
  resetPasswordPending: true,
  resetPasswordError: null,
  signupData: null,
};

const auth = (state = initAuthState, action) => {
  const {
    type,
    user,
    email: forgotPasswordEmail,
    signupData,
    error,
  } = action;
  switch (type) {
    case authTypes.SET_SIGNUP_DATA:
      return {
        ...state,
        signupData,
      };
    case authTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordPending: true,
        resetPasswordError: null,
      };
    case authTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordEmail: null,
        resetPasswordPending: false,
        resetPasswordError: null,
      };
    case authTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordPending: false,
        resetPasswordError: error,
      };
    case authTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordEmail,
        forgotPasswordPending: true,
        forgotPasswordError: null,
      };
    case authTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordPending: false,
        forgotPasswordError: null,
      };
    case authTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPasswordPending: false,
        forgotPasswordError: error,
      };
    case authTypes.RESEND_VERIFY_EMAIL_CODE_REQUEST:
      return {
        ...state,
        resendVerifyEmailCodePending: true,
        resendVerifyEmailCodeError: null,
      };
    case authTypes.RESEND_VERIFY_EMAIL_CODE_SUCCESS:
      return {
        ...state,
        resendVerifyEmailCodePending: false,
        resendVerifyEmailCodeError: null,
      };
    case authTypes.RESEND_VERIFY_EMAIL_CODE_FAILURE:
      return {
        ...state,
        resendVerifyEmailCodePending: false,
        resendVerifyEmailCodeError: error,
      };
    case authTypes.VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        verifyEmailPending: true,
        verifyEmailError: null,
      };
    case authTypes.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        user,
        verifyEmailPending: false,
        verifyEmailError: null,
      };
    case authTypes.VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        verifyEmailPending: false,
        verifyEmailError: error,
      };
    case authTypes.LOGIN_REQUEST:
      return {
        ...state,
        loginPending: true,
        loginError: null,
      };
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user,
        loginError: null,
        loginPending: false,
      };
    case authTypes.LOGIN_FAILURE:
      return {
        ...state,
        loginPending: false,
        loginError: error,
      };
    case authTypes.LOGOUT_REQUEST:
      return {
        ...state,
        logoutPending: true,
      };
    case authTypes.LOGOUT_SUCCESS:
      return {
        initAuthState,
      };
    case authTypes.REGISTER_REQUEST:
      return {
        ...state,
        registerPending: true,
        registerError: null,
      };
    case authTypes.REGISTER_SUCCESS:
      return {
        ...state,
        user,
        registerError: null,
        registerPending: false,
      };
    case authTypes.REGISTER_FAILURE:
      return {
        ...state,
        registerPending: false,
        registerError: error,
      };
    case authTypes.GET_ME_REQUEST:
      return {
        ...state,
        getMePending: true,
        getMeError: null,
      };
    case authTypes.GET_ME_SUCCESS:
      return {
        ...state,
        user,
        getMeError: null,
        getMePending: false,
      };
    case authTypes.GET_ME_FAILURE:
      return {
        ...state,
        getMePending: false,
        getMeError: error,
      };
    case authTypes.UPDATE_ME_SUCCESS:
      return { ...state, user: action.user };
    default:
      return state;
  }
};

export default auth;
