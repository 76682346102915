/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { getBookingByOrderId, resetBookingByOrderId, resendPdfPermitsByOrderId } from '../../../redux/bookings/bookings.actions';
import { HeaderBack, Button } from '../../../components/ui';
import Result from '../../../components/result';
import { formatIsk } from '../../../utils/currency';
import { reduceBookedPermits } from '../../../utils/permits';
import Permit from '../../../components/permit';
import { EmailForm } from './components';

import './booking.scss';
import routeConstants from '../../../constants/route.constants';

const BookingPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const {
    currentBooking,
    getBookingByOrderIdPending,
    getBookingByOrderIdError,
  } = useSelector((state) => state.bookings);

  const { user } = useSelector((state) => state.auth);

  const handleRetry = () => {
    dispatch(getBookingByOrderId(orderId));
  };

  const handleGoBack = () => {
    history.push(routeConstants.BOOKINGS);
  };

  useEffect(() => {
    dispatch(getBookingByOrderId(orderId));
    return () => {
      dispatch(resetBookingByOrderId());
    };
  }, [dispatch, orderId]);

  const handleSendReceipt = (email) => {
    // TODO: Send receipt to email;
    // dispatch(resendPdfPermitsByOrderId(email, orderId));
  };

  const handleSendPermits = (email) => {
    dispatch(resendPdfPermitsByOrderId(email, orderId));
  };

  if (getBookingByOrderIdPending) return null;
  if (getBookingByOrderIdError) {
    return (
      <Result.Empty
        title='Villa kom upp'
        message='Gat ekki sótt bókun'
        buttonText='Reyna aftur'
        onClick={handleRetry}
      />
    );
  }
  if (!currentBooking && !getBookingByOrderIdPending) {
    return (
      <Result.Empty
        title='Bókun'
        message='Ekkert veiddist, fann ekki bókun'
        buttonText='Til baka'
        onClick={() => history.goBack()}
      />
    );
  }

  const hasSubareas = currentBooking.permits.some((p) => !!p.subarea);
  const results = reduceBookedPermits(currentBooking.permits);

  return (
    <div>
      <HeaderBack title={currentBooking.area.name} goBack={handleGoBack} />
      <h1>{moment(currentBooking.createdAt).format('ll')}</h1>
      <div className='single-booking'>
        <Permit.Wrapper>
          <Permit.Header.View />
          {Object.keys(results).map((date, i) => (
            <Permit.Section key={date} date={date}>
              {Object.keys(results[date]).map((subarea) => (
                <>
                  <Permit.Subarea subarea={subarea} displayEmptySubareas={hasSubareas} />
                  {results[date][subarea].map((p, index) => (
                    <Permit.View
                      key={p.id}
                      permit={p}
                      quantity={p.quantity}
                    />
                  ))}
                </>
              ))}
            </Permit.Section>
          ))}
        </Permit.Wrapper>
        <div className='booking-actions'>
          <div className='booking-info'>
            <div className='mb-2'>
              <div className='info-item'>
                <p>Pöntunarnúmer</p>
                <p className='muted'>
                  {currentBooking.orderId}
                </p>
              </div>
            </div>
            <div className='info-item'>
              <p>Samtals</p>
              <p>
                {formatIsk(currentBooking.priceWithoutDiscount)}
              </p>
            </div>
            {(currentBooking.fishingClubDiscount > 0) && (
              <div className='info-item mv-1 row ai-fe'>
                <div className='column'>
                  <p>Afsláttur Veiðifélags</p>
                  <span className='medium small'>
                    {currentBooking.fishingClub.name}
                  </span>
                </div>
                <p>
                  -
                  {' '}
                  {formatIsk(currentBooking.fishingClubDiscount)}
                </p>
              </div>
            )}
            {(currentBooking.couponDiscount > 0) && (
              <div className='info-item mv-1'>
                <div className='column'>
                  <p>Afsláttarkóði</p>
                  <span className='medium small'>
                    {currentBooking.coupon.coupon}
                  </span>
                </div>
                <p>
                  -
                  {' '}
                  {formatIsk(currentBooking.couponDiscount)}
                </p>
              </div>
            )}
            <div className='info-item mv-1'>
              <p className='bold'>
                Greitt
              </p>
              <p className='bold'>
                {formatIsk(currentBooking.totalPrice)}
              </p>
            </div>
            {currentBooking.paymentInformation && (
              <div className='info-item mv-1'>
                <p className='muted smaller'>
                  Greitt með
                  {' '}
                  {currentBooking.paymentInformation.cardType}
                </p>
                <p className='muted smaller'>
                  {currentBooking.paymentInformation.cardNumberMasked}
                </p>
              </div>
            )}
            <div className='line mv-2' />
            <div className='actions'>
              <Button onClick={() => handleSendPermits(user.email)} block className='mv-1'>
                Senda veiðileyfi og kvittun á mitt netfang
              </Button>
              <EmailForm onSubmit={handleSendPermits} text='Senda veiðileyfi' />
              {/* <div className='line mv-2' /> */}
              {/* <p>Mundu að skrá í veiðibók að veiði lokinni!</p>
              <Button block className='mv-1' color='success'>Skrá í veiðibók</Button>
              <div className='line mv-2' /> */}
              {/* <Button onClick={() => handleSendReceipt(user.email)} block className='mv-1'>Sækja kvittun</Button> */}
              {/* <EmailForm onSubmit={handleSendReceipt} text='Senda kvittun' /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
