import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { MessageSchema } from '../../../../../schemas/message.schema';
import { setBookingMessage } from '../../../../../redux/bookings/bookings.actions';
import { Input } from '../../../../../components/ui';
import { getError } from '../../../../../utils/formik';
import './message-form.scss';

const MessageForm = () => {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        message: '',
      }}
      validationSchema={MessageSchema}
      onSubmit={({ message }) => {
        dispatch(setBookingMessage(message));
      }}
    >
      {({ errors, touched, handleSubmit }) => (
        <Form noValidate>
          <Input
            as='textarea'
            label='Skilaboð'
            name='message'
            onBlur={handleSubmit}
            error={getError(errors, touched, 'message')}
          />
        </Form>
      )}
    </Formik>
  );
};

export default MessageForm;
