import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircle } from 'react-feather';
import { Button } from '../../ui';
import './success-result.scss';

const SucessResult = ({
  title,
  buttonOneText,
  buttonOneClick,
  buttonTwoText,
  buttonTwoClick,
  children,
}) => (
  <div className='success-result-container'>
    <CheckCircle color='#8f9d45' />
    <div>
      <h2 className='ta-left mt-3 mb-1'>{title}</h2>
      {children}
      <div className='column jc-c mt-2 f-width'>
        <Button block onClick={buttonOneClick}>
          {buttonOneText}
        </Button>
        <Button block onClick={buttonTwoClick} inverse className='mt-1'>
          {buttonTwoText}
        </Button>
      </div>
    </div>
  </div>
);

SucessResult.propTypes = {
  title: PropTypes.string.isRequired,
  buttonOneText: PropTypes.string.isRequired,
  buttonOneClick: PropTypes.func.isRequired,
  buttonTwoText: PropTypes.string.isRequired,
  buttonTwoClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default SucessResult;
