import React from 'react';
import PropTypes from 'prop-types';
import './page-header.scss';

const PageHeader = ({ title, children }) => (
  <div className='row jc-sb ai-fs mb-2'>
    <h1>{title}</h1>
    {children}
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

PageHeader.defaultProps = {
  children: <></>,
};

export default PageHeader;
