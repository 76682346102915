import * as cartTypes from './cart.types';
import { addToCart, removeFromCart } from './cart.helpers';

const initCartState = [];

const cartReducer = (state = initCartState, action) => {
  const {
    type,
    permit,
  } = action;
  switch (type) {
    case cartTypes.ADD_PERMIT_TO_CART:
      return addToCart(state, permit);
    case cartTypes.REMOVE_PERMIT_FROM_CART:
      return removeFromCart(state, permit);
    case cartTypes.CLEAR_CART:
      return initCartState;
    default:
      return state;
  }
};

export default cartReducer;
