import React from 'react';
import PropTypes from 'prop-types';
import './area-seller-section.scss';

const AreaSellerSection = ({
  title, name, email, url, phone, line,
}) => (
  <section>
    {title && <h2 className='mb-1'>{title}</h2>}
    {name && <p className='mb-05 muted'>{name}</p>}
    {phone
      && (
      <p className='mb-05 muted'>
        <a href={`tel:${phone}`} className='mb-05 muted'>{phone}</a>
      </p>
      )}
    {email
      && (
      <p className='mb-05 muted'>
        <a href={`mailto:${email}`} className='mb-05 muted'>{email}</a>
      </p>
      )}
    {url
      && (
      <p className='mb-05 muted'>
        <a href={url} className='mb-05 muted'>{url}</a>
      </p>
      )}
    {line && <div className='line mv-2' />}
  </section>
);

AreaSellerSection.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  url: PropTypes.string,
  phone: PropTypes.string,
  line: PropTypes.bool,
};

AreaSellerSection.defaultProps = {
  title: null,
  name: null,
  email: null,
  url: null,
  phone: null,
  line: true,
};

export default AreaSellerSection;
