import React from 'react';
import PropTypes from 'prop-types';
import './marker.scss';
import { Home } from 'react-feather';
import Pin from '../../../../../assets/Pin.svg';

const Marker = ({ children, onClick }) => (
  <>
    <img src={Pin} alt='pin' onClick={onClick} />
    {/* <Pin className='marker' height={32} width={32} onClick={onClick} /> */}
    {/* <Home className='marker' height={32} width={32} onClick={onClick} /> */}
    {children}
  </>
);

Marker.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Marker.defaultProps = {
  children: <></>,
  onClick: () => {},
};

export default Marker;
