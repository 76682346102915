/* eslint-disable no-param-reassign */
import moment from 'moment';

const NO_SUBAREA = 'NO_SUBAREA';

const alphabetically = (a, b) => {
  // equal items sort equally
  if (a.subarea === b.subarea) {
    return 0;
  }
  // nulls sort after anything else
  if (a.subarea === null) {
    return 1;
  }
  if (b.subarea === null) {
    return -1;
  }
  return a.subarea < b.subarea ? -1 : 1;
};

// Check if permit is inbetween the two dates queried by.
const isPermitInbetween = (p, dateFrom, dateTo) => {
  let inbetween = true;
  if (moment(p.dateFrom).isBefore(dateFrom) || moment(p.dateTo).isBefore(dateFrom)) {
    inbetween = false;
  }
  if (moment(p.dateFrom).isAfter(dateTo) || moment(p.dateTo).isAfter(dateTo)) {
    inbetween = false;
  }
  return inbetween;
};

const reducePermits = (permits) => {
  const p = permits.reduce((r, a) => {
    const date = moment(a.dateFrom).format('YYYY-MM-DD');
    if (a.available && a.available % a.minOrder === 0) {
      r[date] = r[date] || [];
      if (a.subarea) {
        r[date][a.subarea] = r[date][a.subarea] || [];
        r[date][a.subarea].push(a);
      } else {
        r[date][NO_SUBAREA] = r[date][NO_SUBAREA] || [];
        r[date][NO_SUBAREA].push(a);
      }
    }
    return r;
  }, Object.create(null));

  // Sort the rest of the keys by their key  value.
  Object.keys(p).forEach((date) => {
    p[date] = Object.keys(p[date])
      .sort()
      .reduce((obj, key) => {
        obj[key] = p[date][key];
        return obj;
      }, {});
  });

  return p;
};

const reduceBookedPermits = (permits) => {
  const p = permits.reduce((r, a) => {
    const date = moment(a.dateFrom).format('YYYY-MM-DD');
    r[date] = r[date] || [];
    if (a.subarea) {
      r[date][a.subarea] = r[date][a.subarea] || [];
      r[date][a.subarea].push(a);
    } else {
      r[date][NO_SUBAREA] = r[date][NO_SUBAREA] || [];
      r[date][NO_SUBAREA].push(a);
    }
    return r;
  }, Object.create(null));

  // Sort the rest of the keys by their key  value.
  Object.keys(p).forEach((date) => {
    p[date] = Object.keys(p[date])
      .sort()
      .reduce((obj, key) => {
        obj[key] = p[date][key];
        return obj;
      }, {});
  });

  return p;
};

export {
  NO_SUBAREA,
  reducePermits,
  reduceBookedPermits,
  isPermitInbetween,
};
