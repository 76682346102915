export const GET_AREAS_REQUEST = 'GET_MY_AREAS_REQUEST';
export const GET_AREAS_SUCCESS = 'GET_MY_AREAS_SUCCESS';
export const GET_AREAS_FAILURE = 'GET_MY_AREAS_FAILURE';
export const GET_AREA_BY_SLUG_REQUEST = 'GET_AREA_BY_SLUG_REQUEST';
export const GET_AREA_BY_SLUG_SUCCESS = 'GET_AREA_BY_SLUG_SUCCESS';
export const GET_AREA_BY_SLUG_FAILURE = 'GET_AREA_BY_SLUG_FAILURE';

export const CLEAR_CURRENT_AREA = 'CLEAR_CURRENT_AREA';
export const PAGINATE_AREAS = 'PAGINATE_AREAS';
export const SORT_AREAS = 'SORT_AREAS';
export const QUERY_AREA_BY_DATES = 'QUERY_AREA_BY_DATES';

export const RESET_PARAMS = 'RESET_PARAMS';
