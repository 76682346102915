import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { ForgotPasswordSchema } from '../../../schemas/auth.schema';
import { Input, Button } from '../../../components/ui';
import { getError } from '../../../utils/formik';

const ForgotPasswordForm = ({ onSubmit }) => (
  <Formik
    initialValues={{
      email: '',
    }}
    validationSchema={ForgotPasswordSchema}
    onSubmit={onSubmit}
  >
    {({ errors, touched }) => (
      <Form noValidate>
        <Input required label='Netfang' name='email' type='email' error={getError(errors, touched, 'email')} />
        <Button type='submit' block>Fá sendan kóða</Button>
      </Form>
    )}
  </Formik>
);

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
