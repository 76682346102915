import moment from 'moment';
/* eslint-disable no-param-reassign */

const calculateCartPrice = (cart) => cart.reduce((sum, p) => {
  let add = 0;
  if (p.discountPrice) {
    add = p.discountPrice * p.quantity;
  } else {
    add = p.price * p.quantity;
  }
  return sum + add;
}, 0);

const getTotalPriceEntitledForWholedayDiscount = (permitsForAreaOnSameDay) => {
  let numberOfPermitsBeforeNoon = 0;
  let numberOfPermitsAfterNoon = 0;
  let singlePriceBeforeNoon = 0;
  let singlePriceAfterNoon = 0;

  permitsForAreaOnSameDay.forEach(({
    price, arrangement, quantity,
  }) => {
    const isPermitBeforeNoon = arrangement === 1;
    const isPermitAfterNoon = arrangement === 2;
    numberOfPermitsBeforeNoon += isPermitBeforeNoon ? quantity : 0;
    numberOfPermitsAfterNoon += isPermitAfterNoon ? quantity : 0;

    if (singlePriceBeforeNoon === 0 && isPermitBeforeNoon) {
      singlePriceBeforeNoon = price;
    } if (singlePriceAfterNoon === 0 && isPermitAfterNoon) {
      singlePriceAfterNoon = price;
    }
  });

  return numberOfPermitsBeforeNoon > 0 && numberOfPermitsAfterNoon > 0
    ? Math.min(numberOfPermitsBeforeNoon, numberOfPermitsAfterNoon) * (singlePriceBeforeNoon + singlePriceAfterNoon) : 0;
};

const getTotalWholeDayDiscount = (wholedayDiscount, permitObject) => {
  let result = 0;
  if (wholedayDiscount && wholedayDiscount.enabled && wholedayDiscount.value > 0) {
    Object.keys(permitObject).forEach((date, i) => {
      const subAreasByDay = permitObject[date];
      Object.keys(subAreasByDay).forEach((subarea) => {
        const permitsForAreaOnSameDay = subAreasByDay[subarea];

        result += getTotalPriceEntitledForWholedayDiscount(permitsForAreaOnSameDay) * (wholedayDiscount.value / 100);
      });
    });
  }
  return result;
};

const calculateDiscount = (coupon, totalPrice) => {
  if (coupon) {
    if (coupon.type === 'amount') {
      return coupon.amount;
    }
    return Math.ceil(totalPrice * (coupon.amount / 100));
  }
  return 0;
};

const calculateToPay = (toPay) => {
  if (toPay < 0) {
    return 0;
  }
  return toPay;
};

const calculateCart = (cart, coupon) => {
  const total = calculateCartPrice(cart);
  const discount = calculateDiscount(coupon, total);
  return total - discount;
};

export {
  calculateCartPrice,
  calculateDiscount,
  getTotalWholeDayDiscount,
  getTotalPriceEntitledForWholedayDiscount,
  calculateCart,
  calculateToPay,
};
