import * as Yup from 'yup';

const ValidateReceiptEmailSchema = Yup.object().shape({
  email: Yup
    .string()
    .required('Þú þarft að fylla út netfang')
    .email('Netfang ekki á réttu formi'),
});

export {
  ValidateReceiptEmailSchema,
};
