import React from 'react';
import PropTypes from 'prop-types';
import LoadingLayout from './loading.layout';

const BaseLayout = ({ children }) => (
  <LoadingLayout>
    <div className='main-wrapper'>
      <div className='main-container'>
        {children}
      </div>
    </div>
  </LoadingLayout>
);

BaseLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default BaseLayout;
