import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createCart } from '../../../../../redux/bookings/bookings.actions';
import { clearCart } from '../../../../../redux/cart/cart.actions';
import { calculateCartPrice } from '../../../../../utils/bookings';
import { formatIsk } from '../../../../../utils/currency';
import { Button } from '../../../../../components/ui';
import './checkout-button.scss';

const CheckoutButton = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const handleCheckout = () => {
    if (cart.length) {
      dispatch(createCart(cart));
    }
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };
  const paymentsdisabled = false;
  // if (cart.length === 0) return null;

  return (
    <div className='row checkout-button-container'>
      <Button className='smaller mr-1 clear-cart-button' inverse onClick={handleClearCart}>
        <span className='medium muted'>
          Tæma körfu
        </span>
      </Button>
      { !paymentsdisabled
        ? (
          <Button block onClick={handleCheckout} color={cart.length ? 'primary' : 'borderColor'}>
            {cart.length
              ? (
                <span className='white medium'>
                  Bóka
                  {' '}
                  <span className='white book'>
                    {`(${formatIsk(calculateCartPrice(cart))})`}
                  </span>
                </span>
              )
              : 'Veldu veiðileyfi til að bóka'}
          </Button>
        ) : (
          <div style={{
            fontWeight: 'bold', padding: '16px', borderRadius: '8px', background: '#ffadad',
          }}
          >
            Því miður er ekki er hægt að kaupa leyfi þessa stundina vegna bilunar í greiðslugátt.
          </div>
        ) }
    </div>
  );
};

export default CheckoutButton;
