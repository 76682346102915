import * as areaTypes from './areas.types';

export const getAreas = () => ({
  type: areaTypes.GET_AREAS_REQUEST,
});

export const getAreaBySlug = (slug) => ({
  type: areaTypes.GET_AREA_BY_SLUG_REQUEST,
  slug,
});

export const clearCurrentArea = () => ({
  type: areaTypes.CLEAR_CURRENT_AREA,
});

export const paginateAreas = (page, limit) => ({
  type: areaTypes.PAGINATE_AREAS,
  page,
  limit,
});

export const queryAreasByDates = (dateFrom, dateTo) => ({
  type: areaTypes.QUERY_AREA_BY_DATES,
  dateFrom,
  dateTo,
});

export const sortAreas = (sortBy) => ({
  type: areaTypes.SORT_AREAS,
  sortBy,
});

export const resetParams = () => ({
  type: areaTypes.RESET_PARAMS,
});
