import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { AlertCircle } from 'react-feather';
import PropTypes from 'prop-types';
import './date-picker.scss';

export default function CDatePicker({
  label, required, error, onChange, onBlur, value, ...rest
}) {
  return (
    <div className='date-picker-wrapper'>
      <label htmlFor={rest.name}>{`${label} ${required ? '*' : ''}`}</label>
      <span className='error'>
        {error}
        {error && (
          <AlertCircle className='svg-error' />
        )}
      </span>
      <DatePicker
        className='custom-date-picker'
        selected={value}
        onChange={onChange}
        onBlur={onBlur}
        dateFormat='dd.MM.yyyy'
        filterDate={(date) => moment() > date}
      />
    </div>
  );
}

CDatePicker.defaultProps = {
  error: '',
  label: '',
  required: false,
  value: null,
};

CDatePicker.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};
