import React from 'react';
import './permit-view-header.scss';

const PermitActionHeader = () => (
  <div className='row ai-c mv-1 permit-view-header-container'>
    <p className='permit-view-header-description-container small medium primary'>Dags./ lýsing</p>
    <p className='permit-view-header-price-container small medium primary ta-left'>Verð</p>
    <p className='permit-view-header-quantity-container small medium primary ta-center'>Fjöldi</p>
    <p className='permit-view-header-total-price-container small medium primary ta-right'>Samtals</p>
  </div>
);

export default PermitActionHeader;
