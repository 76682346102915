import React from 'react';
import Lottie from 'react-lottie';
import Loader from '../../../../assets/loader.json';
import './booking.scss';
import { useWindowDimensions } from '../../../../hooks';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const BookingLoading = () => {
  const { width, height } = useWindowDimensions();

  const getDimensions = () => {
    if (width < 768) {
      return {
        width: '55%',
        height: '55%',
      };
    }
    return {
      width: '25%',
      height: '25%',
    };
  };

  return (
    <div className='result-container'>
      <Lottie
        options={defaultOptions}
        height={getDimensions().height}
        width={getDimensions().width}
      />
      <h2 className='ta-center mt-3 mb-1'>Erum að klára greiðslu</h2>
    </div>
  );
};

export default BookingLoading;
