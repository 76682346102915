const bookingTypes = Object.freeze({
  RESERVED: 'reserved',
  BOOKED: 'booked',
});

const bookingStatuses = Object.freeze({
  ABANDONED: 'abandoned',
  PENDING: 'pending',
  PAID: 'paid',
  RESERVED: 'reserved',
  VOIDED: 'voided',
});

export {
  bookingTypes,
  bookingStatuses,
};
