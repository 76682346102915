import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';
import IconButton from '../icon-button/icon-button';
import './header-back.scss';

const HeaderBack = ({ title, goBack }) => {
  const history = useHistory();

  const handleGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      history.goBack();
    }
  };

  return (
    <div className='row mb-1 jc-sb ai-fe'>
      <div className='row ai-c header-back-title'>
        <IconButton onClick={handleGoBack} primary>
          <ArrowLeft color='#fff' />
        </IconButton>
        <h3>
          {title}
        </h3>
      </div>
    </div>
  );
};

HeaderBack.propTypes = {
  title: PropTypes.string.isRequired,
  goBack: PropTypes.func,
};

HeaderBack.defaultProps = {
  goBack: null,
};

export default HeaderBack;
