import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAreas, queryAreasByDates } from '../../../redux/areas/areas.actions';
import { Hero } from '../../../components/img';
import front1 from '../../../assets/front1.svg';
import front2 from '../../../assets/front2.svg';
import front3 from '../../../assets/front3.svg';
import routeConstants from '../../../constants/route.constants';
import { Button, RangePicker } from '../../../components/ui';
import './home.scss';

const HomePage = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onAllAreasClicked = () => {
    history.push(routeConstants.ALL_AREAS);
  };

  const onSearch = ({ startDate, endDate }) => {
    dispatch(queryAreasByDates(startDate, endDate));
    dispatch(getAreas());
    history.push(routeConstants.AREAS);
  };

  return (
    <div>
      <div className='front-container'>
        <div className='front-inner'>
          <div className='hero'>
            <div className='mb-2'>
              <h1 className='inverse'>
                Bókaðu veiðileyfið
                {' '}
                <br />
                á Veiðitorgi
              </h1>
            </div>
            <RangePicker onSearch={onSearch} title='Finna veiðisvæði' />
            <Button smaller bold className='mv-2 all-areas-button' inverse onClick={() => onAllAreasClicked()}>
              Öll veiðisvæði
            </Button>
          </div>
          <div className='hero-image-container'>
            <Hero className='heroimg' />
          </div>
        </div>
      </div>
      <section className='bottom-hero'>
        <h1>
          Fjölbreytt úrval
          {' '}
          <br />
          af veiðlileyfum
        </h1>
        <p className='hero-larger mt-3 mb-3'>
          Veiðitorg býður upp á glæsilegt úrval af veiðileyfum, allt á einum stað!
        </p>
      </section>
      <section className='info-section'>
        <div className='info-inner'>
          <h1 className='mb-3 mt-3'>Það er auðvelt að bóka</h1>
          <div className='row ai-fs jc-sb mv-3 mh-3 info-section-items'>
            <div className='column ai-c about-item mv-1 mh-1 front-item'>
              <img src={front1} alt='find' />
              <h3 className='mt-3'>Finndu veiðisvæði sem hentar þér</h3>
            </div>
            <div className='column ai-c about-item mv-1 mh-1'>
              <img src={front2} alt='book' />
              <h3 className='mt-3'>Sjáðu veiðileyfi sem eru í boði og veldu fjölda stanga</h3>
            </div>
            <div className='column ai-c about-item mv-1 mh-1'>
              <img src={front3} alt='see' />
              <h3 className='mt-3'>Bókaðu og kláraðu greiðsluna á öruggan máta</h3>
            </div>
          </div>
        </div>
      </section>
      <section className='bottom-hero'>
        <h1 className='mb-3 mt-3'>Um veiðitorg</h1>
        <p className='hero-larger mt-3 mb-3'>
          Veiðitorg er sölukerfi fyrir veiðileyfi sem mætir þörfum söluaðila og kaupenda veiðileyfa. Veiditorg er óháð -
          er ekki á vegum stangveiðifélags, landeigenda, veiðifélags, leigutaka, veiðileyfasala, veiðivöruverslanar eða
          annarra hagsmunaaðlila í veiði- eða ferðaþjónustu.
        </p>
      </section>
      <section className='bottom-hero'>
        <h1 className='mb-3 mt-3'>Viltu selja á Veiðitorgi?</h1>
        <p className='hero-larger mt-3 mb-3'>
          Hafðu samband við veiðitorg í netfangið
          {' '}
          <a href={routeConstants.MAILTO_VEIDITORG}>veiditorg@veiditorg.is</a>
          {' '}
          og við komum þér af stað.
        </p>
      </section>
    </div>
  );
};

HomePage.propTypes = {};

export default HomePage;
