import React from 'react';
import './button.scss';
import PropTypes from 'prop-types';

export default function Button({
  children, className, block, smaller, inverse, onClick, color, disabled, bold, medium,
}) {
  return (
    <div className={`
      button-container
      ${block && 'button-block'}
      ${smaller && 'button-smaller'}
      ${className}
    `}
    >
      <button
        disabled={disabled}
        className={`
          button
          ${inverse && 'inverse'}
          ${bold && 'bold'}
          ${medium && 'medium'}
          ${color ? `bg-${color}` : ''}
        `}
        type='submit'
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  inverse: PropTypes.bool,
  block: PropTypes.bool,
  className: PropTypes.string,
  smaller: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  bold: PropTypes.bool,
  medium: PropTypes.bool,
};

Button.defaultProps = {
  inverse: false,
  disabled: false,
  block: false,
  smaller: false,
  onClick: () => {},
  className: '',
  color: null,
  bold: false,
  medium: false,
};
