export const getCart = (state) => ({
  cart: state.cart,
});

export const getUser = (state) => ({
  user: state.auth.user,
});

export const getArea = (state) => ({
  area: state.areas.currentArea,
});

export const getCoupon = (state) => ({
  coupon: state.bookings.coupon,
});

export const getComment = (state) => ({
  comment: state.bookings.message,
});

export const getParams = (state) => ({
  page: state.bookings.page,
  limit: state.bookings.limit,
  dateFrom: state.bookings.dateFrom,
  dateTo: state.bookings.dateTo,
  sortBy: state.bookings.sortBy,
});
