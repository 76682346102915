import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import routeConstants from '../../../../constants/route.constants';
import BookingResult from './booking-result';

const BookingFailure = ({ orderId }) => {
  const history = useHistory();
  return (
    <BookingResult
      title='Bókun tókst ekki'
      buttonOneClick={() => history.replace(routeConstants.HOME)}
      buttonOneText='Aftur á forsíðu'
      success={false}
    >
      <p className='mv-1'>
        Eitthvað mistókst við bókun.
        <br />
        Greiðsla tókst ekki. Þú hefur ekki verið rukkaður.
      </p>
    </BookingResult>
  );
};

BookingFailure.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default BookingFailure;
