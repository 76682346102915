import * as clubTypes from './clubs.types';

export const getClubs = () => ({
  type: clubTypes.GET_CLUBS_REQUEST,
});

export const getClubBySlug = (slug) => ({
  type: clubTypes.GET_CLUB_BY_SLUG_REQUEST,
  slug,
});

export const paginateClubs = (page, limit) => ({
  type: clubTypes.PAGINATE_CLUBS,
  page,
  limit,
});

export const sortClubs = (sortBy) => ({
  type: clubTypes.SORT_CLUBS,
  sortBy,
});

export const resetParams = () => ({
  type: clubTypes.RESET_PARAMS,
});
