import { useEffect } from 'react';

/**
 * Hook that calls callback on clicks outside of the passed ref
 */
function useOnClickOutside(ref, callback, closeOnClickOutside) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && closeOnClickOutside) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, closeOnClickOutside]);
}

export default useOnClickOutside;
