import {
  SIDER_COLLAPSED,
} from './app.types';

const initAppState = {
  collapsed: false,
};

const appReducer = (state = initAppState, action) => {
  const {
    type,
    collapsed,
  } = action;
  switch (type) {
    case SIDER_COLLAPSED:
      return { ...state, collapsed };
    default:
      return state;
  }
};

export default appReducer;
