import React from 'react';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SignupSchema } from '../../../schemas/auth.schema';
import { Input, Button } from '../../../components/ui';
import { getError } from '../../../utils/formik';

const SignupForm = ({ onSubmit }) => {
  const { signupData } = useSelector((state) => state.auth);
  return (
    <Formik
      initialValues={{
        name: signupData ? signupData.name : '',
        email: signupData ? signupData.email : '',
        password: signupData ? signupData.password : '',
      }}
      validationSchema={SignupSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form noValidate>
          <Input required label='Nafn' name='name' error={getError(errors, touched, 'name')} />
          <Input required label='Netfang' name='email' type='email' error={getError(errors, touched, 'email')} />
          <Input required label='Lykilorð' name='password' type='password' error={getError(errors, touched, 'password')} />
          <Button type='submit' block>Áfram</Button>
        </Form>
      )}
    </Formik>
  );
};

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SignupForm;
