/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination, useSortBy } from 'react-table';
import './table.scss';
import { Pagination } from '../../../../components/ui';

const Table = ({
  data,
  columns,
  fetchData,
  loading,
  onChangeSort,
  pageCount: controlledPageCount,
  totalResults,
  initialSortBy,
}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 25, sortBy: initialSortBy },
      pageCount: controlledPageCount,
      manualPagination: true,
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
  );

  React.useEffect(() => {
    fetchData({ pageIndex: pageIndex + 1, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  // Store new sort state in reducer and call API to fetch new data from server
  React.useEffect(() => {
    onChangeSort(sortBy);
  }, [onChangeSort, sortBy]);

  // Render the UI for your table
  return (
    <>
      <div className='table-container'>
        <div className='tableWrap'>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? column.isSortedDesc ? ' ⌄' : ' ⌃' : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => <td {...cell.getCellProps()}>{cell.render('Cell')}</td>)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className='table-pagin'>
        <Pagination
          onPageChange={(v) => gotoPage(v - 1)}
          page={pageIndex + 1}
          totalPages={pageOptions.length}
          title={`Samtals: ${totalResults} skráningar`}
        />
      </div>
    </>
  );
};

Table.defaultProps = {
  loading: true,
};

Table.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  pageCount: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  totalResults: PropTypes.number.isRequired,
  initialSortBy: PropTypes.string.isRequired,
  onChangeSort: PropTypes.func.isRequired,
};

export default Table;
