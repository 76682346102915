import Profile from './profile/profile.page';
import Bookings from './bookings/bookings.page';
import CancelBooking from './cancel-booking/cancel-booking.page';
import ConfirmBooking from './confirm-booking/confirm-booking.page';
import Checkout from './checkout/checkout.page';
import Booking from './booking/booking.page';
import Clubs from './clubs/clubs.page';
import ExpiredBookingPage from './expired-booking/expired-booking.page';

export default {
  Profile,
  Bookings,
  Booking,
  CancelBooking,
  ConfirmBooking,
  ExpiredBookingPage,
  Checkout,
  Clubs,
};
