import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { cancelBooking } from '../../../redux/bookings/bookings.actions';
import routeConstants from '../../../constants/route.constants';
import Result from '../../../components/result';

const CancelBookingPage = () => {
  const { orderId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cancelBooking(orderId));
  }, [dispatch, orderId]);

  const handleGoBack = () => {
    history.push(routeConstants.HOME);
  };

  return (
    <Result.Empty
      title='Hætt var við bókun'
      message='Þú hefur ekki verið rukkaður'
      buttonText='Aftur heim'
      onClick={handleGoBack}
    />
  );
};

export default CancelBookingPage;
