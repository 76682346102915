import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { VerifyEmailSchema } from '../../../schemas/auth.schema';
import { Input, Button } from '../../../components/ui';
import { getError } from '../../../utils/formik';

const VerifyEmailForm = ({ onSubmit }) => (
  <Formik
    initialValues={{
      code: '',
    }}
    validationSchema={VerifyEmailSchema}
    onSubmit={onSubmit}
  >
    {({ errors, touched }) => (
      <Form noValidate>
        <Input autoComplete='off' required label='Staðfestingarkóði' name='code' error={getError(errors, touched, 'code')} />
        <Button type='submit' block>Staðfesta</Button>
      </Form>
    )}
  </Formik>
);

VerifyEmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default VerifyEmailForm;
