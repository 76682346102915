import * as permitTypes from './permits.types';

const defaultParams = {
  limit: 50,
  page: 0,
  totalPages: 0,
  totalResults: 0,
  dateFrom: null,
  dateTo: null,
  sortBy: 'dateFrom:asc',
};

const initPermitsState = {
  permits: [],
  getPermitsPending: false,
  getPermitsError: null,
  ...defaultParams,
};

const permitsReducer = (state = initPermitsState, action) => {
  const {
    type,
    results: permits,
    page,
    limit,
    dateFrom,
    dateTo,
    totalPages,
    totalResults,
    error,
    sortBy,
  } = action;
  switch (type) {
    case permitTypes.RESET_PARAMS:
      return {
        ...state,
        ...defaultParams,
      };
    case permitTypes.PAGINATE_PERMITS:
      return {
        ...state,
        page,
        limit,
      };
    case permitTypes.QUERY_DATE_PERMITS:
      return {
        ...state,
        dateFrom,
        dateTo,
      };
    case permitTypes.GET_PERMITS_BY_AREA_SLUG_REQUEST:
      return {
        ...state,
        getPermitsError: null,
        getPermitsPending: true,
      };
    case permitTypes.GET_PERMITS_BY_AREA_SLUG_SUCCESS:
      return {
        ...state,
        permits,
        page,
        limit,
        totalPages,
        totalResults,
        getPermitsError: null,
        getPermitsPending: false,
      };
    case permitTypes.GET_PERMITS_BY_AREA_SLUG_FAILURE:
      return {
        ...state,
        getPermitsPending: false,
        getPermitsError: error,
      };
    default:
      return state;
  }
};

export default permitsReducer;
