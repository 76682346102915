import React from 'react';
import PropTypes from 'prop-types';
import BaseLayout from './base.layout';
import { Header, Footer } from '../nav';
import './layout.scss';

const DefaultLayout = ({ children, inverse }) => (
  <BaseLayout>
    <Header inverse={inverse} />
    {inverse && <div className='gradient' />}
    <div className='main-content mb-8'>
      {children}
    </div>
    <Footer />
  </BaseLayout>
);

DefaultLayout.defaultProps = {
  inverse: false,
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  inverse: PropTypes.bool,
};

export default DefaultLayout;
