import {
  take, call, put, all, select,
} from 'redux-saga/effects';
import clubsApi from '../../api/clubs';
import message from '../../services/message.service';
import { load, ready } from '../init/init.sagas';
import * as clubTypes from './clubs.types';
import { getParams } from './clubs.selectors';

function* getClubs() {
  while (true) {
    yield take(clubTypes.GET_CLUBS_REQUEST);
    try {
      yield call(load);
      const params = yield select(getParams);
      const {
        results, page, limit, totalPages, totalResults,
      } = yield call(clubsApi.getClubs, params);
      yield put({
        type: clubTypes.GET_CLUBS_SUCCESS,
        results,
        page,
        limit,
        totalPages,
        totalResults,
      });
    } catch (error) {
      message.error(error.message);
      yield put({
        type: clubTypes.GET_CLUBS_FAILURE,
        error,
      });
    } finally {
      yield call(ready);
    }
  }
}

function* getClubBySlug() {
  while (true) {
    const { slug } = yield take(clubTypes.GET_CLUB_BY_SLUG_REQUEST);
    try {
      yield call(load);
      const club = yield call(clubsApi.getClubBySlug, slug);
      yield put({
        type: clubTypes.GET_CLUB_BY_SLUG_SUCCESS,
        club,
      });
    } catch (error) {
      message.error(error.message);
      yield put({
        type: clubTypes.GET_CLUB_BY_SLUG_FAILURE,
        error,
      });
    } finally {
      yield call(ready);
    }
  }
}

export default function* clubSaga() {
  yield all([
    getClubs(),
    getClubBySlug(),
  ]);
}
