import * as logAreasTypes from './log-areas.types';

export const getLogAreas = () => ({
  type: logAreasTypes.GET_LOG_AREAS_REQUEST,
});

export const paginateLogAreas = (page, limit) => ({
  type: logAreasTypes.PAGINATE_LOG_AREAS,
  page,
  limit,
});

export const changeLogAreaYear = (year) => ({
  type: logAreasTypes.SET_LOG_AREA_YEAR,
  year,
});

export const getLogArea = (id) => ({
  type: logAreasTypes.GET_LOG_AREA_REQUEST,
  id,
});

export const clearCurrentArea = () => ({
  type: logAreasTypes.CLEAR_CURRENT_AREA,
});
