import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import './map.scss';
import { defaultLocation } from '../../../utils/location';
import mapstyle from '../../../styles/google.maps.style.json';

const MapView = ({
  zoom,
  defaultPosition,
  size,
  children,
  scrollwheel,
  fullscreenControl,
  draggable,
  onClick,
  ...rest
}) => (
  <GoogleMapReact
    bootstrapURLKeys={{
      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
      language: 'is',
    }}
    center={defaultPosition}
    zoom={zoom}
    onClick={onClick}
    options={{
      styles: mapstyle,
      fullscreenControl,
      scrollwheel,
      draggable,
    }}
    {...rest}
  >
    {children}
  </GoogleMapReact>
);

MapView.propTypes = {
  defaultPosition: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  zoom: PropTypes.number,
  size: PropTypes.string,
  children: PropTypes.node,
  scrollwheel: PropTypes.bool,
  fullscreenControl: PropTypes.bool,
  draggable: PropTypes.bool,
  onClick: PropTypes.func,
};

MapView.defaultProps = {
  defaultPosition: defaultLocation,
  zoom: 6,
  size: 'default',
  children: <></>,
  scrollwheel: true,
  fullscreenControl: false,
  draggable: true,
  onClick: () => {},
};

export default MapView;
