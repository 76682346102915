import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import { ScrollToTop } from './components/routes';
import { Technical, Banner } from './components/ui';
import Modals from './modals';
// import { Helmet } from './components/analytics';
import {
  initGA, initPixel, logPageView,
} from './services/analytics.service';
import Router from './router/router';
import store from './redux';
import history from './router/history';
import './utils/sentry';

initGA();
initPixel();

history.listen((location) => {
  logPageView(location.pathname + location.search);
});

// app entry test
function App() {
  // return <Technical />;
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ToastContainer />
        <ScrollToTop />
        <Modals />
        <Banner />
        <Router />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
