import React, { useState, useCallback } from 'react';
import { DateRangePicker } from 'react-dates';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useWindowDimensions } from '../../../hooks';
import './range-picker.scss';
import Button from '../button/button';
import message from '../../../services/message.service';

const RangePicker = ({
  onSearch, required, initalStartDate, onDatesChange, initialEndDate, title, hideButton, ...rest
}) => {
  const { width } = useWindowDimensions();
  const isSmall = width <= 850;
  const [startDate, setStartDate] = useState(initalStartDate ? moment(initalStartDate) : null);
  const [endDate, setEndDate] = useState(initialEndDate ? moment(initialEndDate) : null);
  const [focusedInput, setFocusedInput] = useState(null);

  const onDatesChangeExecute = (dates) => {
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
    if (onDatesChange && dates.startDate && dates.endDate) {
      onDatesChange({ startDate: dates.startDate.toISOString(), endDate: dates.endDate.toISOString() });
    }
  };

  const onSearchClicked = () => {
    if (!startDate || !endDate) {
      if (required) {
        message.warning('Þú þarft að velja dagsetningu frá og til');
      } else {
        onSearch({ startDate: moment().toISOString(), endDate: null });
      }
    } else {
      onSearch({ startDate: moment(startDate).toISOString(), endDate: moment(endDate).toISOString() });
    }
  };

  const handleFocusChange = (fInput) => {
    setFocusedInput(fInput);
    document.activeElement.blur();
  };

  return (
    <div className='range-picker-container'>
      <DateRangePicker
        noBorder
        small={isSmall}
        orientation={isSmall ? 'vertical' : 'horizontal'}
        withFullScreenPortal={isSmall}
        numberOfMonths={isSmall ? 4 : 2}
        startDatePlaceholderText='Dagsetning'
        endDatePlaceholderText='Dagsetning'
        required
        showClearDates
        startDateId='startDate'
        endDateId='endDate'
        readOnly
        startDate={startDate}
        endDate={endDate}
        onDatesChange={onDatesChangeExecute}
        focusedInput={focusedInput}
        onFocusChange={(fInput) => handleFocusChange(fInput)}
        {...rest}
      />
      <div className='from-label'>Frá</div>
      <div className='to-label'>Til</div>
      {!hideButton ? (
        <Button className='search-button' onClick={() => onSearchClicked()}>
          {width < 500 ? 'Leita' : title}
        </Button>
      ) : null}
    </div>
  );
};

RangePicker.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func,
  required: PropTypes.bool,
  initalStartDate: PropTypes.string,
  initialEndDate: PropTypes.string,
  title: PropTypes.string.isRequired,
  hideButton: PropTypes.bool,
};

RangePicker.defaultProps = {
  initalStartDate: null,
  initialEndDate: null,
  required: false,
  hideButton: false,
  onDatesChange: null,
};

export default RangePicker;
