const fishTypes = {
  SJOBIRTINGUR: 'sjobirtingur',
  LAX: 'lax',
  BLEIKJA: 'bleikja',
  URRIDI: 'urridi',
  HNUDLAX: 'hnudlax',
};

const fishLogTypes = {
  LAX: 'lax',
  BLEIKJA: 'bleikja',
  URRIDI: 'urridi',
  HNUDLAX: 'hnudlax',
};

const ecoSystemTypes = {
  STADBUNDINN: 'Staðbundinn',
  SJOGENGINN: 'Sjógenginn',
  OTHEKKT: 'Óþekkt'
};

const baitTypes = {
  FLUGA: 'fluga',
  MADKUR: 'madkur',
  SPUNN: 'spunn',
};

const fishSex = {
  MALE: 'male',
  FEMALE: 'female',
  UNKNOWN: 'unknown',
};

const fishTypesEnum = Object.freeze(['sjobirtingur', 'lax', 'bleikja', 'urridi', 'hnudlax']);
const baitTypesEnum = Object.freeze(['fluga', 'madkur', 'spunn']);
const fishSexEnum = Object.freeze(['male', 'female', 'unknown']);

const MAX_FISH_LENGTH = 150;
const MAX_FISH_WEIGHT = 25;

export {
  MAX_FISH_LENGTH,
  MAX_FISH_WEIGHT, baitTypes, baitTypesEnum, ecoSystemTypes, fishLogTypes, fishSex, fishSexEnum, fishTypes, fishTypesEnum
};

