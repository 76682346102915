import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '../../components/ui';
import { setSignupData } from '../../redux/auth/auth.actions';
import { setModalVisible } from '../../redux/modal/modal.actions';
import modalConstants from '../../constants/modal.constants';
import { SignupForm } from './components';

const SignupModal = ({ ...rest }) => {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(setSignupData(data));
    dispatch(setModalVisible(modalConstants.COMPLETE_SIGNUP));
  };

  const onLogin = () => {
    dispatch(setModalVisible(modalConstants.LOGIN));
  };

  const onBack = () => {
    dispatch(setModalVisible(modalConstants.LOGIN));
  };

  return (
    <Modal title='Nýskráning' icon='back' onIconClick={onBack} {...rest}>
      <SignupForm onSubmit={onSubmit} />
      <div className='pointer mv-1' onClick={onLogin}>
        <p>
          Áttu aðgang ?
          {' '}
          <span className='medium'>Skráðu þig inn hér</span>
        </p>
      </div>
    </Modal>
  );
};

export default SignupModal;
