import React, { useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import parse from 'html-react-parser';
import { getAreaBySlug } from '../../../redux/areas/areas.actions';
import { queryPermitsByDates } from '../../../redux/permits/permits.actions';
import { RangePicker, PageHeader, Button } from '../../../components/ui';
import Result from '../../../components/result';
import { mapLocation } from '../../../utils/location';
import routeConstants from '../../../constants/route.constants';
import {
  AreaTextSection, AreaMapSection, AreaBaseInformation, AreaImages, AreaSellerSection,
} from './components';
import './area.scss';
import { useWindowDimensions } from '../../../hooks';

const AreaPage = () => {
  const { slug } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const {
    currentArea, getAreaBySlugPending, getAreaBySlugError, dateFrom, dateTo,
  } = useSelector((state) => state.areas);

  useEffect(() => {
    if (slug) {
      dispatch(getAreaBySlug(slug));
    }
  }, [dispatch, slug]);

  const onSearch = ({ startDate, endDate }) => {
    dispatch(queryPermitsByDates(startDate, endDate));
    history.push(`${routeConstants.AREAS}/${slug}${routeConstants.PERMITS}`);
  };

  const permitLink = `${routeConstants.AREAS}/${slug}${routeConstants.PERMITS}`;

  if (getAreaBySlugPending) return null;
  if (getAreaBySlugError) {
    if (getAreaBySlugError.code && getAreaBySlugError.code === 404) {
      return (
        <Result.Empty
          title='404'
          message='Ekkert veiddist, fann ekki veiðisvæði'
          buttonText='Til baka'
          onClick={() => history.goBack()}
        />
      );
    }
    return (
      <Result.Empty
        title='Veiðisvæði'
        message='Gat ekki sótt veiðisvæði'
        buttonText='Til baka'
        onClick={() => history.goBack()}
      />
    );
  }
  if (currentArea === null && !getAreaBySlugPending) {
    return (
      <Result.Empty
        title='Veiðisvæði'
        message='Ekkert veiddist, fann ekki veiðisvæði'
        buttonText='Til baka'
        onClick={() => history.goBack()}
      />
    );
  }

  return (
    <div>
      <PageHeader title={currentArea.name} />
      <AreaImages
        image={currentArea.image}
        images={currentArea.images}
      />
      <div className='area-page-container'>
        <div className='area-right'>
          <Sticky disabled={width < 1301} stickyStyle={{ marginTop: '2rem' }}>
            <div className='area-right-inner'>
              <div>
                {currentArea && currentArea.presale.enabled
                && currentArea.presale.dateFrom && currentArea.presale.dateTo && (
                <div className='presale bottom'>
                  Athugið að Forsala stendur yfir
                </div>
                )}
                <RangePicker
                  onSearch={onSearch}
                  initalStartDate={dateFrom}
                  initialEndDate={dateTo}
                  title='Finna veiðileyfi'
                />
                <Link to={permitLink}>
                  <Button inverse smaller bold className='all-permits-button'>Öll veiðileyfi</Button>
                </Link>

                <div />
              </div>
              <div className='area-base'>
                <AreaBaseInformation
                  fishTypes={currentArea.fishTypes}
                  fishingHouseAvailable={currentArea.fishingHouseAvailable}
                  baitTypes={currentArea.baitTypes}
                  region={currentArea.region}
                />
              </div>
            </div>
          </Sticky>
        </div>
        <div className='area-left'>
          <div className='line mv-1 area-line' />
          <AreaTextSection
            isHtml
            content={parse(currentArea.is.description)}
          />
          <AreaTextSection
            title='Veiðihús'
            content={currentArea.fishingHouseAvailable ? currentArea.is.fishingHouseDescription : 'Ekkert veiðihús er í boði á svæðinu'}
          />
          <AreaTextSection
            title='Veiðireglur'
            content={currentArea.is.fishingRules}
          />
          <AreaTextSection
            title='Skilmálar'
            content={currentArea.is.fishingTerms}
          />
          <AreaTextSection
            title='Annað'
            content={currentArea.is.otherInformation}
          />
          <AreaSellerSection
            title='Söluaðili'
            {...currentArea.sellerInfo}
          />
          <AreaMapSection
            title='Staðsetning'
            {...mapLocation(currentArea.location)}
          />
        </div>
      </div>
    </div>
  );
};

export default AreaPage;
