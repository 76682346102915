import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { getClubs } from '../../../redux/clubs/clubs.actions';
import Result from '../../../components/result';
import routeConstants from '../../../constants/route.constants';
import { PageHeader } from '../../../components/ui';
import './clubs.scss';

const ClubsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { clubs, getClubsPending, getClubsError } = useSelector((state) => state.clubs);

  useEffect(() => {
    dispatch(getClubs());
  }, [dispatch]);

  const handleRetry = () => {
    dispatch(getClubs());
  };

  if (getClubsPending) return null;
  if (getClubsError) {
    return (
      <Result.Empty
        title='Villa kom upp'
        message='Gat ekki sótt veiðifélög'
        buttonText='Reyna aftur'
        onClick={handleRetry}
      />
    );
  }
  if (clubs.length === 0 && !getClubsPending) {
    return (
      <Result.Empty
        title='Veiðifélög'
        message='Ekkert veiddist, fann engin veiðifélög skráð fyrir þennan notanda'
        buttonText='Til baka'
        onClick={() => history.goBack()}
      />
    );
  }

  return (
    <div>
      <PageHeader title='Veiðifélög' />
      <div className='clubs-list'>
        <p>
          Upplýsingar um veiðifélög sem þú ert aðili að og á hvaða veiðisvæðum þau hafa afsláttarkjör.
        </p>
        <div className='line mv-2' />
        <div className='mv-1'>
          {clubs.map(({ name: clubName, areas }, index) => (
            <div key={clubName} className='column'>
              <div className='mb-1'>
                <p className='medium larger'>{clubName}</p>
              </div>
              {areas.map(({ name, slug }) => (
                <Link key={name} to={`${routeConstants.AREAS}/${slug}`} className='mb-1'>
                  {name}
                </Link>
              ))}
              {(index !== clubs.length - 1) && (<div className='line mb-2 mt-1' />)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClubsPage;
