import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../ui';
import Empty from '../../img/empty';
import './empty-result.scss';

const EmptyResult = ({
  title,
  message,
  buttonText,
  onClick,
}) => (
  <div className='empty-result-container ph-1'>
    <div className='top-left'>
      <h1 className='mt-3 mb-1'>{title}</h1>
      <p className='mv-1 warning bold'>{message}</p>
    </div>
    <div className='empty-result-content'>
      <div className='svg-container'>
        <Empty />
      </div>
      {buttonText ? (
        <Button block onClick={onClick}>
          {buttonText}
        </Button>
      ) : null}
    </div>
  </div>
);

EmptyResult.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

EmptyResult.defaultProps = {
  message: null,
};

export default EmptyResult;
