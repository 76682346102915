import {
  take, call, put, all, select,
} from 'redux-saga/effects';
import permitsApi from '../../api/permits';
import message from '../../services/message.service';
import { load, ready } from '../init/init.sagas';
import { getParams } from './permits.selectors';
import * as permitTypes from './permits.types';

function* getPermitsByAreaSlug() {
  while (true) {
    const { slug } = yield take(permitTypes.GET_PERMITS_BY_AREA_SLUG_REQUEST);
    try {
      const params = yield select(getParams);
      const {
        results, page, limit, totalPages, totalResults,
      } = yield call(permitsApi.getPermitsByAreaSlug, slug, params);
      yield put({
        type: permitTypes.GET_PERMITS_BY_AREA_SLUG_SUCCESS, results, page, limit, totalPages, totalResults,
      });
    } catch (error) {
      message.error(error.message);
      yield put({
        type: permitTypes.GET_PERMITS_BY_AREA_SLUG_FAILURE,
        error,
      });
    }
  }
}

export default function* permitsSaga() {
  yield all([
    getPermitsByAreaSlug(),
  ]);
}
