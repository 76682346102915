import * as modalTypes from './modal.types';

const initModalState = {
  openModal: null,
};

const modalReducer = (state = initModalState, action) => {
  const { type, modal } = action;
  switch (type) {
    case modalTypes.SET_MODAL_VISIBLE:
      return {
        ...state,
        openModal: modal,
      };
    case modalTypes.CLOSE_MODAL:
      return {
        ...state,
        openModal: null,
      };
    default:
      return state;
  }
};

export default modalReducer;
