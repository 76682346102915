import React from 'react';
import { useHistory } from 'react-router-dom';
import Result from '../../../components/result';
import routeConstants from '../../../constants/route.constants';

const NotFoundPage = () => {
  const history = useHistory();

  const goHome = () => {
    history.replace(routeConstants.HOME);
  };

  return (
    <Result.Empty
      title='404'
      message='Ekkert veiddist, þessi síða er ekki til.'
      buttonText='Fara á forsíðu'
      onClick={goHome}
    />
  );
};

export default NotFoundPage;
