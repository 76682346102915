import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CountDown from 'react-countdown';
import { clearBooking, tick } from '../../../../../redux/bookings/bookings.actions';

import './booking-countdown.scss';
import messageService from '../../../../../services/message.service';

const BookingCountdown = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { time } = useSelector((state) => state.bookings);

  const onTimerEnd = () => {
    // Delete valitor frame if it exists.
    const frame = document.getElementById('valitor-checkout-iframe');
    if (frame) {
      frame.parentNode.removeChild(frame);
    }
    dispatch(clearBooking());
    messageService.info('Tími rann út. Leyfin hafa verið sett aftur í sölu');
    history.goBack();
  };

  const handleOnTick = ({ total }) => {
    dispatch(tick(total));
  };

  return (
    <div className='countdown-wrapper'>
      <CountDown
        date={Date.now() + time}
        renderer={({ formatted: { minutes, seconds } }) => (
          <div className='row jc-sb ai-fs'>
            <h3>Frátekið</h3>
            <p className='warning extra-larger'>{`${minutes}:${seconds}`}</p>
          </div>
        )}
        onComplete={onTimerEnd}
        onTick={handleOnTick}
      />
      <div className='mt-1 mb-2'>
        <p>
          Veiðileyfi hafa verið frátekin fyrir þig í 2 mínútur. Kláraðu bókun til að tryggja veiðileyfin.
        </p>
      </div>
    </div>
  );
};

export default BookingCountdown;
