import React from 'react';
import PropTypes from 'prop-types';
import './permit-wrapper.scss';

const PermitWrapper = ({ children }) => (
  <div className='permit-wrapper mt-2'>
    {children}
  </div>
);

PermitWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PermitWrapper;
