import PropTypes from 'prop-types';
import React from 'react';
import './area-text-section.scss';

const AreaTextSection = ({
  title, content, line, isHtml,
}) => (
  <section>
    {title && <h2 className='mb-1'>{title}</h2>}
    {
      isHtml
        ? (
          <div
            className='muted'
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )
        : <p className='muted'>{content}</p>
    }
    {line && <div className='line mv-2' />}
  </section>
);

AreaTextSection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
  line: PropTypes.bool,
  isHtml: PropTypes.bool,
};

AreaTextSection.defaultProps = {
  title: null,
  line: true,
  isHtml: false,
};

export default AreaTextSection;
