import * as bookingTypes from './bookings.types';

export const createCart = () => ({
  type: bookingTypes.CREATE_CART_REQUEST,
});

export const cancelBooking = (orderId) => ({
  type: bookingTypes.CANCEL_BOOKING_REQUEST,
  orderId,
});

export const checkout = () => ({
  type: bookingTypes.CHECKOUT_REQUEST,
});

export const getMyBookings = () => ({
  type: bookingTypes.GET_MY_BOOKINGS_REQUEST,
});

export const getBookingByOrderId = (orderId) => ({
  type: bookingTypes.GET_BOOKING_BY_ORDER_ID_REQUEST,
  orderId,
});

export const resetBookingByOrderId = () => ({
  type: bookingTypes.RESET_BOOKING_BY_ORDER_ID,
});

export const paginateBookings = (page, limit) => ({
  type: bookingTypes.PAGINATE_BOOKINGS,
  page,
  limit,
});

export const resendPdfPermitsByOrderId = (email, orderId) => ({
  type: bookingTypes.RESEND_PDF_PERMITS_EMAIL_REQUEST,
  email,
  orderId,
});

export const resetParams = () => ({
  type: bookingTypes.RESET_BOOKING_PARAMS,
});

export const validateCoupon = (coupon) => ({
  type: bookingTypes.VALIDATE_COUPON_REQUEST,
  coupon,
});

export const clearBooking = () => ({
  type: bookingTypes.CLEAR_BOOKING,
});

export const clearCoupon = () => ({
  type: bookingTypes.CLEAR_COUPON,
});

export const tick = (time) => ({
  type: bookingTypes.TICK,
  time,
});

export const setBookingMessage = (message) => ({
  type: bookingTypes.SET_BOOKING_MESSAGE,
  message,
});
