import api from './api';
import catchAsync from './error';

const getPermitsByAreaSlug = catchAsync(async (slug, params) => {
  const response = await api.GET(`/fishing-areas/${slug}/fishing-permits`, params);
  return response.data;
});

export default {
  getPermitsByAreaSlug,
};
