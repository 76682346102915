import * as modalTypes from './modal.types';

export const setModalVisible = (modal) => ({
  type: modalTypes.SET_MODAL_VISIBLE,
  modal,
});

export const closeModal = () => ({
  type: modalTypes.CLOSE_MODAL,
});
