import api from './api';
import catchAsync from './error';

const getLogsAreas = catchAsync(async (params) => {
  const response = await api.GET('/fishing-log-areas', params);
  return response.data;
});

const getLogArea = catchAsync(async (id) => {
  const response = await api.GET(`/fishing-log-areas/${id}`);
  return response.data;
});

export default {
  getLogsAreas,
  getLogArea,
};
