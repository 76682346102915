import * as logTypes from './logs.types';

const defaultParams = {
  sortBy: 'date:desc',
  subarea: null,
  subplace: null,
  limit: 25,
  page: 1,
  totalPages: 0,
  totalResults: 0,
  year: 2024,
  fishType: 'bleikja',
};

const initLogsState = {
  logs: [],
  getLogsPending: false,
  getLogsError: null,
  areaId: null,
  ...defaultParams,
};

const logsReducer = (state = initLogsState, action) => {
  const {
    type,
    results,
    page,
    limit,
    totalPages,
    totalResults,
    error,
    sortBy,
    subarea,
    subplace,
    year,
    fishType,
  } = action;
  switch (type) {
    case logTypes.GET_LOGS_REQUEST:
      return {
        ...state,
        getLogsPending: true,
        getLogsError: null,
      };
    case logTypes.GET_LOGS_SUCCESS:
      return {
        ...state,
        logs: results,
        page,
        limit,
        totalPages,
        totalResults,
        getLogsPending: false,
        getLogsError: null,
      };
    case logTypes.GET_LOGS_FAILURE:
      return {
        ...state,
        getLogsPending: false,
        getLogsError: error,
      };
    case logTypes.RESET_PARAMS:
      return {
        ...state,
        ...defaultParams,
      };
    case logTypes.FILTER_LOGS_BY_SUBAREA:
      return {
        ...state,
        subarea,
      };
    case logTypes.FILTER_LOGS_BY_SUBPLACE:
      return {
        ...state,
        subplace,
      };

    case logTypes.FILTER_LOGS_BY_YEAR:
      return {
        ...state,
        year,
      };

    case logTypes.FILTER_LOGS_BY_FISH_TYPE:
      return {
        ...state,
        fishType,
      };
    case logTypes.SORT_LOGS:
      return {
        ...state,
        sortBy,
      };
    case logTypes.PAGINATE_LOGS:
      return {
        ...state,
        page,
        limit,
      };
    default:
      return state;
  }
};

export default logsReducer;
