export default Object.freeze({
  HOME: '/',
  AREAS: '/areas',
  PERMITS: '/permits',
  ALL_AREAS: '/all-areas',
  ABOUT: '/about',
  CLUBS: '/clubs',
  PRIVACY_POLICY: '/privacy',
  TERMS_AND_CONDITIONS: '/terms',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_EMAIL: '/verify-email',
  PROFILE: '/profile',
  BOOKINGS: '/bookings',
  CHECKOUT: '/checkout',
  BOOKING: '/booking',
  CANCEL_BOOKING: '/cancel_booking',
  EXPIRED_BOOKING: '/expired_booking',
  CONFIRM_BOOKING: '/confirm_booking',
  LOG_AREAS: '/log-areas',
  NOT_FOUND: '*',
  MAILTO_VEIDITORG: 'mailto:veiditorg@veiditorg.is',
  FACEBOOK_VEIDITORG: 'https://facebook.com/veiditorg',
  INSTAGRAM_VEIDITORG: 'https://instagram.com/veiditorg.is',
  SELLER_AREA: 'https://admin.veiditorg.is',
});
