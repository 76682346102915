import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '../../components/ui';
import { register } from '../../redux/auth/auth.actions';
import { setModalVisible } from '../../redux/modal/modal.actions';
import modalConstants from '../../constants/modal.constants';
import { CompleteSignupForm } from './components';

const CompleteSignupModal = ({ ...rest }) => {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(register(data));
  };

  const onBack = () => {
    dispatch(setModalVisible(modalConstants.SIGNUP));
  };

  return (
    <Modal title='Nýskráning' onIconClick={onBack} icon='back' {...rest}>
      <CompleteSignupForm onSubmit={onSubmit} />
    </Modal>
  );
};

export default CompleteSignupModal;
