import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../../components/ui';

import './profile-item.scss';

const ProfileItem = ({
  item, muted, value, label, onEditPress, editing, disabled, children, error,
}) => {
  if (editing) {
    return (
      <div className={`profile-item ${disabled && 'no-border'}`}>
        <div className='profile-item-header'>
          <h4>{label}</h4>
          <button className='change-profile-button bold' onClick={() => onEditPress(null)}>Loka</button>
        </div>

        <div className='profile-item-form'>
          <div className='profile-item-row'>
            { children }
          </div>
          <Button type='submit'>Vista</Button>
        </div>
      </div>
    );
  }

  return (
    <div className={`profile-item ${disabled && 'no-border'}`}>
      <div className='profile-item-header'>
        <h4>
          {label}
          {' '}
          { error ? `(${error})` : ''}
        </h4>
        {!disabled
          && (
          <button
            className='change-profile-button bold'
            disabled={muted}
            onClick={(e) => {
              e.preventDefault();
              onEditPress(item);
            }}
          >
            Breyta
          </button>
          )}
      </div>
      <div className='profile-item-body'>{value}</div>
    </div>
  );
};

ProfileItem.propTypes = {
  item: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onEditPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  muted: PropTypes.bool,
  editing: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  error: PropTypes.string,
};

ProfileItem.defaultProps = {
  disabled: false,
  muted: false,
  editing: false,
  error: '',
};

export default ProfileItem;
