import {
  take, call, put, all, select,
} from 'redux-saga/effects';
import logsApi from '../../api/logs';
import message from '../../services/message.service';
import * as logTypes from './logs.types';
import { getParams } from './logs.selectors';

function* createLog() {
  while (true) {
    const { id, body } = yield take(logTypes.CREATE_LOG_REQUEST);
    try {
      yield call(logsApi.createLog, id, body);
      message.success('Veiði hefur verið skráð í veiðibók');
      yield put({ type: logTypes.CREATE_LOG_SUCCESS });
      yield put({ type: logTypes.GET_LOGS_REQUEST, id });
    } catch (error) {
      message.error(error.message);
      yield put({
        type: logTypes.CREATE_LOG_FAILURE,
        error,
      });
    }
  }
}

function* getLogs() {
  while (true) {
    const { id } = yield take(logTypes.GET_LOGS_REQUEST);
    try {
      const params = yield select(getParams);
      const {
        results, page, limit, totalPages, totalResults,
      } = yield call(logsApi.getLogs, id, params);
      yield put({
        type: logTypes.GET_LOGS_SUCCESS,
        results,
        page,
        limit,
        totalPages,
        totalResults,
      });
    } catch (error) {
      message.error(error.message);
      yield put({
        type: logTypes.GET_LOGS_FAILURE,
        error,
      });
    }
  }
}

export default function* logsSaga() {
  yield all([
    getLogs(),
    createLog(),
  ]);
}
