import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import modalConstants from '../constants/modal.constants';
import { closeModal } from '../redux/modal/modal.actions';

import LoginModal from './login/login.modal';
import SignupModal from './signup/signup.modal';
import ForgotPasswordModal from './forgot-password/forgot-password.modal';
import VerifyEmailModal from './verify-email/verify-email.modal';
import ResetPasswordModal from './reset-password/reset-password.modal';
import CompleteSignupModal from './complete-signup/complete-signup.modal';
import CompleteProfileModal from './complete-profile/complete-profile.modal';

const Modals = () => {
  const dispatch = useDispatch();
  const { openModal } = useSelector((state) => state.modal);

  const handleClose = () => {
    dispatch(closeModal());
  };

  switch (openModal) {
    case modalConstants.LOGIN:
      return <LoginModal onClose={handleClose} closeOnClickOutside open={openModal !== null} />;
    case modalConstants.SIGNUP:
      return <SignupModal onClose={handleClose} closeOnClickOutside open={openModal !== null} />;
    case modalConstants.VERIFY_EMAIL:
      return <VerifyEmailModal onClose={handleClose} closeOnClickOutside={false} open={openModal !== null} />;
    case modalConstants.RESET_PASSWORD:
      return <ResetPasswordModal onClose={handleClose} closeOnClickOutside={false} open={openModal !== null} />;
    case modalConstants.COMPLETE_SIGNUP:
      return <CompleteSignupModal onClose={handleClose} closeOnClickOutside={false} open={openModal !== null} />;
    case modalConstants.COMPLETE_PROFILE:
      return <CompleteProfileModal onClose={handleClose} closeOnClickOutside={false} open={openModal !== null} />;
    case modalConstants.FORGOT_PASSWORD:
      return <ForgotPasswordModal onClose={handleClose} closeOnClickOutside={false} open={openModal !== null} />;
    default:
      return null;
  }
};

export default Modals;
