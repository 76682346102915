import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../loader/loader';

const LoadingLayout = ({ children }) => (
  <>
    <Loader />
    {children}
  </>
);

LoadingLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default LoadingLayout;
