/* eslint-disable no-param-reassign */
import 'moment/locale/is';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import Permit from '../../../components/permit';
import Result from '../../../components/result';
import {
  HeaderBack,
  Pagination,
  RangePicker,
} from '../../../components/ui';
import routeConstants from '../../../constants/route.constants';
import { getAreaBySlug } from '../../../redux/areas/areas.actions';
import { addToCart, clearCart, removeFromCart } from '../../../redux/cart/cart.actions';
import { getClubs } from '../../../redux/clubs/clubs.actions';
import {
  getPermitsByAreaSlug,
  paginatePermits, queryPermitsByDates,
  resetParams,
} from '../../../redux/permits/permits.actions';
import { isPermitInbetween, reducePermits } from '../../../utils/permits';
import {
  AreaInfo,
  CheckoutButton,
} from './components';
import './permits.scss';

const PermitsPage = () => {
  const history = useHistory();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const {
    permits, getPermitsPending, getPermitsError, page, totalPages, totalResults, limit,
  } = useSelector((state) => state.permits);
  const {
    currentArea, getAreaBySlugPending, getAreaBySlugError, dateFrom, dateTo,
  } = useSelector((state) => state.areas);
  const {
    user,
  } = useSelector((state) => state.auth);
  const { clubs } = useSelector((state) => state.clubs);
  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    if (slug) {
      dispatch(getAreaBySlug(slug));
      dispatch(getPermitsByAreaSlug(slug));
    }
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch, slug]);

  useEffect(() => {
    if (user) {
      dispatch(getPermitsByAreaSlug(slug));
      dispatch(getClubs());
    }
  }, [user, dispatch, slug]);

  const handleAddToCart = (p) => {
    dispatch(addToCart(p));
  };

  const handleRemoveFromCart = (p) => {
    dispatch(removeFromCart(p));
  };

  const getQuantity = (p) => {
    const item = cart.find((c) => c.permit === p.id);
    return item ? item.quantity : 0;
  };

  const onPageChange = (newPage) => {
    dispatch(paginatePermits(newPage, limit));
    dispatch(getPermitsByAreaSlug(currentArea.slug));
    window.scrollTo(0, 0);
  };

  const handleGoBack = () => {
    dispatch(clearCart());
    history.replace(`${routeConstants.AREAS}/${slug}`);
  };

  const onSearch = ({ startDate, endDate }) => {
    dispatch(queryPermitsByDates(startDate, endDate));
    onPageChange();
  };

  if (getAreaBySlugPending) return null;
  if (getPermitsError || getAreaBySlugError) return <p>Error</p>;
  if (currentArea === null) return <p>Gat ekki sótt veiðisvæði</p>;

  const { presale } = currentArea;

  const userIsInClubForArea = clubs?.find((club) => club.areas.find((area) => area.id === currentArea.id));
  const results = reducePermits(presale.enabled && !userIsInClubForArea
    ? permits.filter((p) => !isPermitInbetween(p, presale.dateFrom, presale.dateTo)) : permits);

  const renderPermits = () => {
    if (permits.length) {
      const hasSubareas = permits.some((p) => !!p.subarea);
      return (
        <>
          <Permit.Wrapper>
            <Permit.Header.Action />
            {Object.keys(results).map((date, i) => (
              <Permit.Section key={date} date={date}>
                {Object.keys(results[date]).map((subarea) => (
                  <>
                    <Permit.Subarea key={`${date}-${subarea}`} subarea={subarea} displayEmptySubareas={hasSubareas} />
                    {results[date][subarea].map((p, index) => (
                      <Permit.Action
                        key={p.id}
                        addToCart={() => handleAddToCart(p)}
                        removeFromCart={() => handleRemoveFromCart(p)}
                        quantity={getQuantity(p)}
                        permit={p}
                      />
                    ))}
                  </>
                ))}
              </Permit.Section>
            ))}
          </Permit.Wrapper>
          <div className='mobile-checkout-button'>
            <CheckoutButton />
          </div>
          <Pagination
            onPageChange={onPageChange}
            page={page}
            totalPages={totalPages}
            title={`Samtals: ${totalResults} veiðileyfi`}
          />
        </>
      );
    }
    if (!getPermitsPending) {
      return (
        <Result.Empty
          title='Veiðileyfi'
          message='Ekkert veiddist, engin veiðileyfi fundust fyrir þessar dagsetningar'
          buttonText='Til baka'
          onClick={() => history.goBack()}
        />
      );
    }
    return null;
  };

  return (
    <>
      <div className='permit-page-wrapper'>
        <div className='left f-15'>
          <div className='permit-page-header'>
            <HeaderBack title={currentArea.name} goBack={handleGoBack} />

            <RangePicker
              onSearch={onSearch}
              initalStartDate={dateFrom}
              initialEndDate={dateTo}
              title='Finna veiðileyfi'
            />

          </div>
          {currentArea && presale.enabled
            && presale.dateFrom && presale.dateTo
            && (
              <div className='presale permit-presale'>
                Athugið að Forsala stendur yfir
              </div>
            )}
          {renderPermits()}

        </div>

        <div className='right f-1 f-width area-info'>
          <div className='area-right'>
            <Sticky stickyStyle={{ marginTop: '2rem' }}>
              <AreaInfo />
              {permits.length > 0 && (
                <CheckoutButton />
              )}
            </Sticky>
          </div>
        </div>
      </div>
    </>
  );
};

export default PermitsPage;
