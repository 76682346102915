import React from 'react';
import { ArrowLeftCircle, ArrowRightCircle } from 'react-feather';
import PropTypes from 'prop-types';
import './pagination.scss';

const Pagination = ({
  onPageChange, page, totalPages, title,
}) => {
  const onForward = () => {
    if (page !== totalPages) {
      onPageChange(page + 1);
    }
  };

  const onBack = () => {
    if (page !== 0) {
      onPageChange(page - 1);
    }
  };

  return (
    <div className='column ai-c mb-1 mt-3'>
      {(totalPages > 1) && (
        <div className='row ai-c'>
          <ArrowLeftCircle className='pointer mr-05' onClick={onBack} />
          {Array(totalPages).fill(0).map((v, i) => (
            <p
              key={`pagination-${v}`}
              className={`
                medium
                mh-05
                pointer
                ${page === i + 1 && 'active-page'}
              `}
              onClick={() => onPageChange(i + 1)}
            >
              {i + 1}
            </p>
          ))}
          <ArrowRightCircle className='pointer ml-05' onClick={onForward} />
        </div>
      )}
      <p className='mv-1'>{title}</p>
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
