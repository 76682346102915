import * as Yup from 'yup';
import { isValid } from 'kennitala';

const phoneRegExp = /^[\\+\d]?(?:[\d-.\s()]*)$/;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Netfang er ekki á réttu formi').required('Þú þarft að fylla út netfang'),
  password: Yup.string()
    .min(8, 'Lykilorð þarf að vera að lágmarki 8 stafir')
    .max(32, 'Lykilorð getur að hámarki verið 32 stafir')
    .required('Þú þarft að fylla út lykilorð'),
});

const SignupSchema = Yup.object().shape({
  name: Yup.string().required('Þú þarft að fylla út nafn'),
  email: Yup.string().email('Netfang er ekki á réttu formi').required('Þú þarft að fylla út netfang'),
  password: Yup.string()
    .min(8, 'Lykilorð þarf að vera að lágmarki 8 stafir')
    .max(32, 'Lykilorð getur að hámarki verið 32 stafir')
    .required('Þú þarft að fylla út lykilorð'),
});

const CompleteSignupSchema = Yup.object().shape({
  newsletter: Yup.boolean(),
  phone: Yup.string().required('Þú þarft að fylla út símanúmer').matches(phoneRegExp, 'Símanúmer er ekki á réttu formi'),
  kennitala: Yup.string()
    .test('is-kennitala', 'Kennitala er ekki á réttu formi', (value) => {
      if (value) {
        return isValid(value);
      }
      return true;
    })
    .nullable(),
  address: Yup.object().shape({
    street: Yup.string().required('Þú þarft að fylla út götunafn'),
    city: Yup.string().required('Þú þarft að fylla út borg'),
    country: Yup.string().required('Þú þarft að fylla út land'),
    postalCode: Yup.string().required('Þú þarft að fylla út póstnúmer'),
  }),
});

const CompleteProfileSchema = Yup.object().shape({
  newsletter: Yup.boolean(),
  phone: Yup.string().required('Þú þarft að fylla út símanúmer').matches(phoneRegExp, 'Símanúmer er ekki á réttu formi'),
  address: Yup.object().shape({
    street: Yup.string().required('Þú þarft að fylla út götunafn'),
    city: Yup.string().required('Þú þarft að fylla út borg'),
    country: Yup.string().required('Þú þarft að fylla út land'),
    postalCode: Yup.string().required('Þú þarft að fylla út póstnúmer'),
  }),
});

const VerifyEmailSchema = Yup.object().shape({
  code: Yup.string()
    .required('Þú þarft að fylla út staðfestingarkóðann')
    .min(6, 'Kóðinn þarf að vera 6 stafir að lengd')
    .max(6, 'Kóðinn þarf að vera 6 stafir að lengd'),
});

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Netfang er ekki á réttu formi').required('Þú þarft að fylla út netfang'),
});

const ResetPasswordSchema = Yup.object().shape({
  code: Yup.string()
    .required('Þú þarft að fylla út staðfestingarkóðann')
    .min(6, 'Kóðinn þarf að vera 6 stafir að lengd')
    .max(6, 'Kóðinn þarf að vera 6 stafir að lengd'),
  password: Yup.string()
    .min(8, 'Lykilorð þarf að vera að lágmarki 8 stafir')
    .max(32, 'Lykilorð getur að hámarki verið 32 stafir')
    .required('Þú þarft að fylla út lykilorð'),
});

const UpdateUserSchema = Yup.object().shape({
  name: Yup.string().required('Þú þarft að fylla út nafn').min(3, 'Nafn þarf að vera lengra en 3 stafir að lengd'),
  email: Yup.string()
    .required('Þú þarft að fylla út netfang')
    .email('Netfang ekki á réttu formi')
    .min(3, 'Nafn þarf að vera lengra en 3 stafir að lengd'),
  phone: Yup.string()
    .required('Þú þarft að skrá símanúmer')
    .matches(phoneRegExp, 'Símanúmer er ekki á réttu formi'),
  street: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  postalCode: Yup.string().min(3, 'Póstnúmer þarf að vera 3 stafir'),
  newsletter: Yup.boolean(),
});

export {
  LoginSchema,
  SignupSchema,
  VerifyEmailSchema,
  ForgotPasswordSchema,
  ResetPasswordSchema,
  CompleteProfileSchema,
  CompleteSignupSchema,
  UpdateUserSchema,
};
