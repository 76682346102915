import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const config = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACER_SAMPLE_RATE,
};

Sentry.init({
  ...config,
  integrations: [new Integrations.BrowserTracing()],
});
