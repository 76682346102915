import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Map } from 'react-feather';
import { AreasMap, AreasList } from './components';
import Result from '../../../components/result';
import { getAreas, resetParams, paginateAreas } from '../../../redux/areas/areas.actions';
import { Button } from '../../../components/ui';
import { Footer } from '../../../components/nav';
import './areas.scss';

const AreasPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [mapVisible, setMapVisible] = useState(false);
  const { areas, getAreasPending, getAreasError } = useSelector((state) => state.areas);

  useEffect(() => {
    dispatch(getAreas());
  }, [dispatch]);

  const handleRetry = () => {
    dispatch(getAreas());
  };

  const handleMapChange = () => {
    if (mapVisible) {
      dispatch(resetParams());
      dispatch(getAreas());
    } else {
      dispatch(paginateAreas(0, 100));
      dispatch(getAreas());
    }
    setMapVisible(!mapVisible);
  };

  // if (getAreasPending) return null;
  // if (areas.length === 0) {
  // return (
  //   <Result.Empty title='Veiðisvæði' message='Ekkert veiddist, engin svæði fundust' buttonText='Til baka' onClick={() => history.goBack()} />
  // );
  // }
  if (getAreasError) {
    return (
      <Result.Empty
        title='Veiðisvæði'
        message='Ekkert veiddist, gat ekki sótt svæði'
        buttonText='Reyna aftur'
        onClick={handleRetry}
      />
    );
  }

  return (
    <div className='row areas-container'>
      {!mapVisible && <AreasList />}
      {areas.length ? <AreasMap visible={mapVisible} /> : null}
      <Button className='map-button' onClick={handleMapChange} style={{ zIndex: 4 }}>
        <Map height={16} width={16} onClick={() => {}} />
        {mapVisible ? 'Loka' : 'Kort'}
      </Button>
    </div>
  );
};

export default AreasPage;
