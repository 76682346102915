import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

const ORDER_STATUS_CHANGE_EVENT = 'ORDER_STATUS_CHANGE_EVENT';
const LISTEN_TO_ORDER_STATUS_CHANGE = 'LISTEN_TO_ORDER_STATUS_CHANGE';
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_IO_URL;
let socket;

const initiateSocket = (orderId) => {
  socket = io(SOCKET_SERVER_URL);
  if (socket && orderId) {
    socket.emit(LISTEN_TO_ORDER_STATUS_CHANGE, orderId);
  }
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

export const subscribe = (cb) => {
  if (!socket) return (true);
  return socket.on(ORDER_STATUS_CHANGE_EVENT, (status) => cb(null, status));
};

const useConfirmBooking = (orderId) => {
  const [status, setStatus] = useState(null);
  useEffect(() => {
    if (orderId) {
      initiateSocket(orderId);
    }

    subscribe((err, newStatus) => {
      if (err) return;
      setStatus(newStatus);
    });

    return () => {
      disconnectSocket();
    };
  }, [orderId]);

  return { status };
};

export default useConfirmBooking;
