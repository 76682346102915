import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { LoginSchema } from '../../../schemas/auth.schema';
import { Input, Button } from '../../../components/ui';
import { getError } from '../../../utils/formik';

const LoginForm = ({ onSubmit, onForgotPasswordClicked }) => (
  <Formik
    initialValues={{
      email: '',
      password: '',
    }}
    validationSchema={LoginSchema}
    onSubmit={onSubmit}
  >
    {({ errors, touched }) => (
      <Form noValidate>
        <Input required label='Netfang' name='email' type='email' error={getError(errors, touched, 'email')} />
        <Input required label='Lykilorð' name='password' type='password' error={getError(errors, touched, 'password')} />
        <div className='pointer mb-1' onClick={onForgotPasswordClicked}>
          <p className='ta-right underline'>
            Gleymt lykilorð
          </p>
        </div>
        <Button type='submit' block>Innskrá</Button>
      </Form>
    )}
  </Formik>
);

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onForgotPasswordClicked: PropTypes.func.isRequired,
};

export default LoginForm;
