import * as Yup from 'yup';

const ValidateCouponSchema = Yup.object().shape({
  coupon: Yup
    .string()
    .min(8, 'Afsláttarkóði þarf að vera 8 stafir')
    .max(24, 'Afsláttarkóði má ekki vera lengri en 24 stafir')
    .nullable(),
});

export {
  ValidateCouponSchema,
};
