import api from './api';
import catchAsync from './error';

const getAreas = catchAsync(async (params) => {
  const response = await api.GET('/fishing-areas', params);
  return response.data;
});

const getAreaBySlug = catchAsync(async (slug, params) => {
  const response = await api.GET(`/fishing-areas/${slug}`, params);
  return response.data;
});

export default {
  getAreas,
  getAreaBySlug,
};
