/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { clearCart } from '../../../redux/cart/cart.actions';
import { clearBooking } from '../../../redux/bookings/bookings.actions';
import { HeaderBack } from '../../../components/ui';
import Permit from '../../../components/permit';
import {
  calculateCartPrice, calculateDiscount, getTotalWholeDayDiscount, calculateToPay,
} from '../../../utils/bookings';
import { reducePermits } from '../../../utils/permits';
import {
  BookingCountdown, CouponForm, MessageForm, ValitorCheckout,
} from './components';
import './checkout.scss';

const BookingPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { coupon } = useSelector((state) => state.bookings);
  const results = reducePermits(cart.sort((a, b) => moment(a.dateFrom).unix() - moment(b.dateFrom).unix()));

  const { currentArea } = useSelector((state) => state.areas);
  const cartPrice = calculateCartPrice(cart);
  const discount = calculateDiscount(coupon, cartPrice);

  // getTotalWholeDayDiscount only works for a single area,
  // if multiple areas will be allowed in checkout, the permits need to be ordered by area
  const totalWholedayDiscount = getTotalWholeDayDiscount(currentArea?.wholedayDiscount, results);

  const toPay = calculateToPay(cartPrice - discount - totalWholedayDiscount);

  useEffect(() => {
    if (currentArea === null) {
      history.goBack();
    }
    if (cart.length === 0) {
      history.goBack();
    }
  }, [currentArea, cart, history]);

  // Useeffect hook to clear the cart on navigate.
  useEffect(() => () => {
    dispatch(clearCart());
    dispatch(clearBooking());
  }, [dispatch]);

  const hasSubareas = cart.some((p) => !!p.subarea);

  const getQuantity = (p) => {
    const { quantity } = cart.find((c) => c.permit === p.id);
    return quantity;
  };

  if (!currentArea) {
    return null;
  }

  return (
    <div>
      <HeaderBack title={currentArea.name} />
      <div className='checkout-wrapper mt-3'>
        <div className='f-15 left'>
          <BookingCountdown />
          <Permit.Wrapper>
            <Permit.Header.View />
            {Object.keys(results).map((date, i) => (
              <Permit.Section key={date} date={date}>
                {Object.keys(results[date]).map((subarea) => (
                  <>
                    <Permit.Subarea subarea={subarea} displayEmptySubareas={hasSubareas} />
                    {results[date][subarea].map((p, index) => (
                      <Permit.View
                        key={p.id}
                        permit={p}
                        quantity={getQuantity(p)}
                      />
                    ))}
                  </>
                ))}
              </Permit.Section>
            ))}
          </Permit.Wrapper>
        </div>
        <div className='f-1 right'>
          <Permit.Summary
            totalWholedayDiscount={totalWholedayDiscount}
            wholedayDiscountPercentage={currentArea.wholedayDiscount.value}
            couponCode={coupon?.coupon}
            cartPrice={cartPrice}
            discount={discount}
            toPay={toPay}
          />
          <div className='line summary-line mv-2' />
          <CouponForm />
          <MessageForm />
          <ValitorCheckout toPay={toPay} />
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
