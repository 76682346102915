import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import './permit-section.scss';

const getDate = (date) => moment(date).format('Do MMM YYYY');

const dayOfWeek = (date) => {
  const str = moment(date).format('ddd');
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const PermitSection = ({ date, children }) => (
  <section className='permit-section'>
    <div className='permit-section-inner'>
      <p className='medium mb-05 permit-date'>
        {getDate(date)}
        <span className='mh-05 small'>{dayOfWeek(date)}</span>
      </p>
      {children}
    </div>
  </section>
);

PermitSection.propTypes = {
  date: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PermitSection;
