const debug = process.env.NODE_ENV === 'development';

const apiError = (error) => {
  if (debug) {
    console.info(error);
  }
  if (error.message) {
    throw error;
  }
  if (error.response) {
    throw error.response.data;
  }
  throw new Error('Villa kom upp');
};

const catchAsync = (fn) => (...params) => Promise.resolve(fn(...params)).catch((err) => apiError(err));

export default catchAsync;
