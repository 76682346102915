import * as permitTypes from './permits.types';

export const getPermitsByAreaSlug = (slug) => ({
  type: permitTypes.GET_PERMITS_BY_AREA_SLUG_REQUEST,
  slug,
});

export const paginatePermits = (page, limit) => ({
  type: permitTypes.PAGINATE_PERMITS,
  page,
  limit,
});

export const resetParams = () => ({
  type: permitTypes.RESET_PARAMS,
});

export const queryPermitsByDates = (dateFrom, dateTo) => ({
  type: permitTypes.QUERY_DATE_PERMITS,
  dateFrom,
  dateTo,
});
