import React from 'react';
import { Facebook, Instagram } from 'react-feather';
import { Link } from 'react-router-dom';
import routeConstants from '../../../constants/route.constants';
import { Logo } from '../../img';
import './footer.scss';

const Footer = () => (
  <div className='footer-wrapper'>
    <div className='footer-outer'>
      <div className='footer-inner'>
        <div className='links column'>
          <Link to={routeConstants.AREAS}>Veiðisvæði</Link>
          <Link to={routeConstants.LOG_AREAS}>Veiðibækur</Link>
          <Link to={routeConstants.ABOUT}>Um veiðitorg</Link>
          <Link to={routeConstants.PRIVACY_POLICY}>Persónuvernd</Link>
          <Link to={routeConstants.TERMS_AND_CONDITIONS}>Skilmálar</Link>
          <a href={routeConstants.SELLER_AREA} rel='noreferrer' target='_blank'>
            Svæði seljanda
          </a>
        </div>
        <div className='about column'>
          <Logo className='about__logo' fill='#fff' />
          <p className='mv-1 light'>Kt: 700715-1160</p>
          <div className='social mt-1 row jc-sb ai-fe'>
            <a className='about__mailto light' href={routeConstants.MAILTO_VEIDITORG}>veiditorg@veiditorg.is</a>
            <a aria-label='facebook' href={routeConstants.FACEBOOK_VEIDITORG} className='about__social facebook-logo' rel='noreferrer' target='_blank'>
              <Facebook color='#fff' />
            </a>
            <a aria-label='instagram' href={routeConstants.INSTAGRAM_VEIDITORG} className='about__social instagram-logo' rel='noreferrer' target='_blank'>
              <Instagram color='#fff' />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
