import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dropdown from './components/dropdown';
import { Logo } from '../../img';
import routeConstants from '../../../constants/route.constants';
import './header.scss';

const Header = ({ inverse }) => (
  <div className='header'>
    <Link to={routeConstants.HOME}>
      <Logo className='header__logo' fill={inverse ? '#fff' : '#375474'} />
    </Link>
    <Dropdown inverse={inverse} />
  </div>
);

Header.defaultProps = {
  inverse: false,
};

Header.propTypes = {
  inverse: PropTypes.bool,
};

export default Header;
