import React from 'react';
import { useSelector } from 'react-redux';
import './loader.scss';

const Loader = () => {
  const { appLoad: loading } = useSelector((state) => state.init);
  if (!loading) return null;
  return (
    <div className={`loader fullscreen ${!loading && 'hidden'}`}>
      <div className='wrapper'>
        <div className='inner' />
      </div>
    </div>
  );
};

export default Loader;
