import React, { useState, useRef } from 'react';
import { Menu } from 'react-feather';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useOnClickOutside } from '../../../../hooks';
import { logout } from '../../../../redux/auth/auth.actions';
import { setModalVisible } from '../../../../redux/modal/modal.actions';
import routeConstants from '../../../../constants/route.constants';
import modalConstanats from '../../../../constants/modal.constants';
import UserIcon from './user';
import './dropdown.scss';

const Dropdown = ({ inverse }) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);

  useOnClickOutside(dropdownRef, () => setOpen(false));

  const handleLogout = () => {
    dispatch(logout());
  };

  const openMenu = () => {
    setOpen(true);
  };

  const openModal = (modal) => {
    dispatch(setModalVisible(modal));
    setOpen(false);
  };

  const navigate = (route) => {
    setOpen(false);
    history.push(route);
  };

  const openAdmin = () => {
    const win = window.open(routeConstants.SELLER_AREA, '_blank');
    win.focus();
  };

  const userDropdown = () => {
    if (user) {
      if (!user.isEmailVerified) {
        return (
          <>
            <div className='link medium warning' onClick={() => openModal(modalConstanats.VERIFY_EMAIL)}>
              Staðfesta netfang
            </div>
            <div className='line' />
          </>
        );
      }
      if (!user.address || !user.phone) {
        return (
          <>
            <div className='link medium warning' onClick={() => openModal(modalConstanats.COMPLETE_PROFILE)}>
              Klára nýskráningu
            </div>
            <div className='line' />
          </>
        );
      }
      if (user.isEmailVerified) {
        return (
          <>
            <div className='link medium' onClick={() => navigate(routeConstants.PROFILE)}>
              Mínar upplýsingar
            </div>
            <div className='link medium' onClick={() => navigate(routeConstants.BOOKINGS)}>
              Bókanir
            </div>
            <div className='link medium' onClick={() => navigate(routeConstants.CLUBS)}>
              Veiðifélög
            </div>
            <div className='line' />
          </>
        );
      }
    }
    return null;
  };

  return (
    <div>
      <div className={`icon ${inverse && 'inverse'}`} onClick={openMenu}>
        <Menu height={16} color={inverse ? '#fff' : '#3D4D6C'} />
        <UserIcon inverse={inverse} />
        {
          (user && (!user.isEmailVerified || !user.address || !user.phone)) && (
            <span className='notification' />
          )
        }
      </div>
      {open && (
        <div className='menu-container' ref={dropdownRef}>
          <div className='menu'>
            {userDropdown()}
            <div className='link' onClick={() => navigate(routeConstants.ALL_AREAS)}>
              Öll veiðisvæði
            </div>
            <div className='link' onClick={() => navigate(routeConstants.LOG_AREAS)}>
              Veiðibækur
            </div>
            <div className='link' onClick={() => openAdmin()}>
              Svæði seljanda
            </div>
            <div className='line' />
            <div className='link' onClick={() => navigate(routeConstants.ABOUT)}>
              Um veiðitorg
            </div>
            {user ? (
              <div className='link' onClick={handleLogout}>
                Útskrá
              </div>
            )
              : (
                <div className='link' onClick={() => openModal(modalConstanats.LOGIN)}>
                  Innskrá
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  inverse: PropTypes.bool,
};

Dropdown.defaultProps = {
  inverse: false,
};

export default Dropdown;
