import { all, put, call } from 'redux-saga/effects';
import { APP_START, APP_LOAD, APP_READY } from './init.types';
import { getAccessTokenInfo } from '../../services/token.service';
import { GET_ME_REQUEST } from '../auth/auth.types';

export function* init() {
  yield put({ type: APP_START });

  const token = yield call(getAccessTokenInfo);
  if (token) {
    yield put({ type: GET_ME_REQUEST });
  }
  yield put({ type: APP_READY });
}

export function* load() {
  yield put({ type: APP_LOAD });
}

export function* ready() {
  yield put({ type: APP_READY });
}

export default function* initSaga() {
  yield all([init(), load(), ready()]);
}
