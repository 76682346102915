import * as clubTypes from './clubs.types';

const defaultParams = {
  sortBy: null,
  limit: 25,
  page: 0,
  totalPages: 0,
  totalResults: 0,
};

const initClubsState = {
  clubs: [],
  getClubsPending: false,
  getClubsError: null,
  currentClub: null,
  getClubBySlugPending: false,
  getClubBySlugError: null,
  ...defaultParams,
};

const clubsReducer = (state = initClubsState, action) => {
  const {
    type,
    results: clubs,
    sortBy,
    page,
    limit,
    totalPages,
    totalResults,
    club: currentClub,
    error,
  } = action;
  switch (type) {
    case clubTypes.RESET_PARAMS:
      return {
        ...state,
        ...defaultParams,
      };
    case clubTypes.SORT_CLUBS:
      return {
        ...state,
        sortBy,
      };
    case clubTypes.PAGINATE_CLUBS:
      return {
        ...state,
        page,
        limit,
      };
    case clubTypes.GET_CLUBS_REQUEST:
      return {
        ...state,
        getClubsPending: true,
        getClubsError: null,
      };
    case clubTypes.GET_CLUBS_SUCCESS:
      return {
        ...state,
        clubs,
        page,
        limit,
        totalPages,
        totalResults,
        getClubsPending: false,
        getClubsError: null,
      };
    case clubTypes.GET_CLUBS_FAILURE:
      return {
        ...state,
        getClubsPending: false,
        getClubsError: error,
      };
    case clubTypes.GET_CLUB_BY_SLUG_REQUEST:
      return {
        ...state,
        getClubBySlugPending: true,
        getClubBySlugError: null,
      };
    case clubTypes.GET_CLUB_BY_SLUG_SUCCESS:
      return {
        ...state,
        currentClub,
        getClubBySlugPending: true,
        getClubBySlugError: null,
      };
    case clubTypes.GET_CLUB_BY_SLUG_FAILURE:
      return {
        ...state,
        getClubBySlugPending: error,
        getClubBySlugError: false,
      };
    default:
      return state;
  }
};

export default clubsReducer;
