import * as areaTypes from './areas.types';

const defaultParams = {
  limit: 25,
  page: 0,
  totalPages: 0,
  totalResults: 0,
  sortBy: null,
  dateFrom: null,
  dateTo: null,
};

const initAreaState = {
  areas: [],
  getAreasPending: false,
  getAreasError: null,
  currentArea: null,
  getAreaBySlugPending: false,
  getAreaBySlugError: null,
  ...defaultParams,
};

const areaReducer = (state = initAreaState, action) => {
  const {
    type,
    results: areas,
    page,
    limit,
    totalPages,
    totalResults,
    area: currentArea,
    error,
    sortBy,
    dateFrom,
    dateTo,
  } = action;
  switch (type) {
    case areaTypes.QUERY_AREA_BY_DATES:
      return {
        ...state,
        dateFrom,
        dateTo,
      };
    case areaTypes.RESET_PARAMS:
      return {
        ...state,
        ...defaultParams,
      };
    case areaTypes.SORT_AREAS:
      return {
        ...state,
        sortBy,
      };
    case areaTypes.PAGINATE_AREAS:
      return {
        ...state,
        page,
        limit,
      };
    case areaTypes.GET_AREAS_REQUEST:
      return {
        ...state,
        getAreasError: null,
        getAreasPending: true,
      };
    case areaTypes.GET_AREAS_SUCCESS:
      return {
        ...state,
        areas,
        page,
        limit,
        totalPages,
        totalResults,
        getAreasError: null,
        getAreasPending: false,
      };
    case areaTypes.GET_AREAS_FAILURE:
      return {
        ...state,
        getAreasError: error,
        getAreasPending: false,
      };
    case areaTypes.GET_AREA_BY_SLUG_REQUEST:
      return {
        ...state,
        getAreaBySlugError: null,
        getAreaBySlugPending: true,
      };
    case areaTypes.GET_AREA_BY_SLUG_SUCCESS:
      return {
        ...state,
        currentArea,
        getAreaBySlugError: null,
        getAreaBySlugPending: false,
      };
    case areaTypes.GET_AREA_BY_SLUG_FAILURE:
      return {
        ...state,
        getAreaBySlugError: error,
        getAreaBySlugPending: false,
      };
    case areaTypes.CLEAR_CURRENT_AREA:
      return {
        ...state,
        currentArea: null,
      };
    default:
      return state;
  }
};

export default areaReducer;
