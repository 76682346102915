import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '../../components/ui';
import { updateMe } from '../../redux/auth/auth.actions';
import { CompleteProfileForm } from './components';

const CompleteProfileModal = ({ ...rest }) => {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(updateMe(data));
  };

  return (
    <Modal title='Klára nýskráningu' icon='close' {...rest}>
      <CompleteProfileForm onSubmit={onSubmit} />
    </Modal>
  );
};

export default CompleteProfileModal;
