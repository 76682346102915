import React from 'react';
import routeConstants from '../../../constants/route.constants';
import { PageHeader } from '../../../components/ui';
import './terms.scss';

const TermsPage = () => (
  <div className='terms-container'>
    <PageHeader title='Skilmálar'>
      <p className='ta-right'>Síðast uppfært: 11.03.2021</p>
    </PageHeader>
    <div className='mb-1 mt-3 section'>
      <h3>Almennar upplýsingar um Veiðitorg</h3>
      <p>
        Veiðitorg ehf, kt: 700715-1160, netfang: veiditorg@veiditorg.is
      </p>
    </div>
    <div className='mb-1 mt-1 section'>
      <h3>Trúnaður og vernd persónuupplýsinga</h3>
      <p>
        Veiðitorg heitir kaupanda fullum trúnaði varðandi upplýsingar er kaupandi gefur upp
        í tengslum við skráningu á vefnum sem og viðskipti. Þær upplýsingar munu ekki undir
        neinum kringumstæðum verða afhentar þriðja aðila.
      </p>
    </div>
    <div className='mb-1 mt-2 section'>
      <h3>Verð og fyrirvarar</h3>
      <p>
        Verð geta breyst án fyrirvara og eru uppgefin verð án virðisaukaskatts nema annað
        sé tilgreint (veiðileyfi eru ekki virðisaukaskattskyld). Veiðitorg áskilur sér rétt
        til að afturkalla pöntun sé vara ranglega skráð á vefnum eða ef um tvíbókun er að ræða.
        Ef að um tvíbókun er að ræða, er seinni pöntunin afturkölluð.
      </p>
    </div>
    <div className='mb-1 mt-2 section'>
      <h3>Greiðslumáti</h3>
      <p>
        Greiða má fyrir veiðileyfi og þjónustu sem keypt eru á Veiðitorgi með greiðslukorti (MasterCard/Visa) í gegnum örugga greiðslugátt Valitor.
      </p>
    </div>
    <div className='mb-1 mt-2 section'>
      <h3>Skila- og endurgreiðsluréttur</h3>
      <p>
        Veiðileyfi og tengd þjónusta sem keypt er á Veiðitorgi fæst ekki undir neinum kringumstæðum endurgreidd.
        Veiðitorg getur aðstoðað kaupanda við endursölu gegn þóknun geti kaupandi ekki nýtt sér veiðileyfið af einhverjum ástæðum.
      </p>
    </div>
    <div className='mb-1 mt-2 section'>
      <h3>Skilmálar og veiðireglur veiðisvæða</h3>
      <p>
        Við skráningu á pöntun skal kaupandi hafa kynnt sér skilmála og veiðireglur þess veiðisvæðis sem keypt er veiðileyfi á.
        Skilmálar og reglur geta verið mismunandi á milli veiðisvæði og geta verið breytilegir á milli ára.
      </p>
    </div>
    <div className='mb-1 mt-2 section'>
      <h3>Afhending og afhendingartími</h3>
      <p>
        Kvittun sem jafnframt er veiðileyfi er afgreidd með rafrænum hætti um leið og staðfesting berst frá kortagátt um greiðslu.
        Veiðileyfi eru send í tölvupósti á netfang kaupanda en kaupandi getur einnig nálgast keypt veiðileyfi undir sínum aðgangi á vefnum.
      </p>
    </div>
    <div className='mb-1 mt-2 section'>
      <h3>Endursala</h3>
      <p>
        Endursala veiðileyfa er ekki leyfileg nema með sérstöku samþykki hverju sinni.
        Keypt veiðileyfi gildir einungis fyrir kaupanda og samferðarfólk nema um annað
        sé samið við Veiðitorg eða söluaðila viðeigandi veiðisvæðis.
      </p>
    </div>
    <div className='mb-1 mt-2 section'>
      <h3>Tölvupóstar til okkar</h3>
      <p>
        Netfangið
        {' '}
        <a href={routeConstants.MAILTO_VEIDITORG}>veiditorg@veiditorg.is</a>
        {' '}
        heimilar þér að vera í beinu sambandi við okkur með spurningar sem upp kunna að koma.
        Við lesum öll skilaboð og gerum okkar besta til að svara þeim hratt og örugglega.
      </p>
    </div>
    <div className='mb-1 mt-2 section'>
      <h3>Lög og varnarþing</h3>
      <p>
        Skilmála þessa ber að túlka í samræmi við íslensk lög.
        Komi upp ágreiningur verður slíkum ágreiningi eða kröfu vísað til meðferðar hjá Héraðsdómi Norðurlands eystra.
      </p>
    </div>
  </div>
);

export default TermsPage;
