import React from 'react';
import { useParams } from 'react-router-dom';
import { bookingStatuses } from '../../../constants/booking.constants';
import { useConfirmBooking } from '../../../hooks';
import Booking from './components';

const ConfirmBookingPage = () => {
  const { orderId } = useParams();
  const { status } = useConfirmBooking(orderId);

  if (status === bookingStatuses.PAID) {
    return <Booking.Success orderId={orderId} />;
  }

  if (status === bookingStatuses.VOIDED) {
    return <Booking.Failure />;
  }

  return (
    <Booking.Loading />
  );
};

export default ConfirmBookingPage;
