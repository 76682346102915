import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer } from '../nav';
import './layout.scss';
import LoadingLayout from './loading.layout';

const MapLayout = ({ children }) => (
  <LoadingLayout>
    <div className='map-wrapper'>
      <div className='header-wrapper'>
        <Header />
      </div>
      <div className='main-content'>
        {children}
        <Footer />
      </div>
    </div>
  </LoadingLayout>
);

MapLayout.defaultProps = {
};

MapLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MapLayout;
