import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import routeConstants from '../../../../constants/route.constants';
import BookingResult from './booking-result';

const BookingSuccess = ({ orderId }) => {
  const history = useHistory();
  return (
    <BookingResult
      title='Bókun staðfest'
      buttonOneClick={() => history.replace(routeConstants.HOME)}
      buttonOneText='Aftur á forsíðu'
      buttonTwoText='Skoða bókun'
      buttonTwoClick={() => history.replace(`${routeConstants.BOOKINGS}/${orderId}`)}
      success
    >
      <p className='mv-1'>
        Þú hefur fengið tölvupóst með upplýsingum um pöntunina þína.
        <br />
        Þú getur einnig séð yfirlit yfir kaup hér á síðunni.
      </p>
    </BookingResult>
  );
};

BookingSuccess.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default BookingSuccess;
