import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { getMe, updateMe } from '../../../redux/auth/auth.actions';
import Result from '../../../components/result';
import ProfileItem from './components/profile-item/profile-item';
import { Input, CheckBox } from '../../../components/ui';
import { UpdateUserSchema } from '../../../schemas/auth.schema';
import { getError } from '../../../utils/formik';
import './profile.scss';

const ProfilePage = (props) => {
  // Global state
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, getMePending, getMeError } = useSelector((state) => state.auth);

  // Local state
  const [editing, setEditing] = useState(null);
  // Functions

  const onEditing = (field) => {
    setEditing(field);
  };

  const handleRetry = () => {
    dispatch(getMe());
  };

  const onSubmit = (values) => {
    // Submit edit profile
    const payload = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      newsletter: values.newsletter,
      address: {
        street: values?.street || '',
        postalCode: values?.postalCode || '',
        city: values?.city || '',
        country: values?.country || '',
      },
    };
    setEditing(null);
    dispatch(updateMe(payload));
  };

  // Effects
  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  // Render
  if (getMePending) return null;
  if (getMeError) {
    return (
      <Result.Empty
        title='Villa kom upp'
        message='Fann ekki notanda'
        buttonText='Reyna aftur'
        onClick={handleRetry}
      />
    );
  }
  if (!user) {
    return (
      <Result.Empty
        title='Villa kom upp'
        message='Fann ekki notanda'
        buttonText='Til baka'
        onClick={() => history.goBack()}
      />
    );
  }

  return (
    <div className='profile-container'>
      <h1>Notandaupplýsingar</h1>
      <Formik
        initialValues={{
          name: user.name,
          email: user.email,
          phone: user.phone,
          street: user.address?.street || '',
          postalCode: user.address?.postalCode || '',
          city: user.address?.city || '',
          country: user.address?.country || '',
          newsletter: user.newsletter,
        }}
        validationSchema={UpdateUserSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({
          errors, touched, values, setFieldValue,
        }) => (
          <Form noValidate>
            <ProfileItem
              muted={editing !== null}
              label='Nafn'
              editing={editing === 'name'}
              onEditPress={onEditing}
              item='name'
              value={user?.name || ''}
            >
              <Input placeholder='Nafn' name='name' error={getError(errors, touched, 'name')} />
            </ProfileItem>
            <ProfileItem
              muted={editing !== null}
              label='Netfang'
              editing={editing === 'email'}
              onEditPress={onEditing}
              item='email'
              value={user?.email || ''}
            >
              <Input placeholder='Netfang' name='email' error={getError(errors, touched, 'email')} />
            </ProfileItem>
            <ProfileItem
              muted={editing !== null}
              label='Póstlisti'
              editing={editing === 'newsletter'}
              onEditPress={onEditing}
              item='newsletter'
              value={user.newsletter ? 'Þú ert skráð/ur á póstlista' : 'Þú ert ekki skráð/ur á póstlista'}
            >
              <CheckBox
                checked={!values.newsletter}
                onChange={(v) => {
                  setFieldValue('newsletter', !values.newsletter);
                }}
                className='mv-1'
                name='newsletter'
                label='Ég vil ekki póst um tilboð og fríðindi'
              />
            </ProfileItem>
            <ProfileItem
              muted={editing !== null}
              label='Símanúmer'
              editing={editing === 'phone'}
              onEditPress={onEditing}
              item='phone'
              value={values?.phone ? values.phone : 'Óútfyllt'}
            >
              <Input
                name='phone'
                error={getError(errors, touched, 'phone')}
                value={values.phone}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.target;
                  const regex = /^[\\+\d]?(?:[\d-.\s()]*)$/;
                  if (regex.test(value.toString()) || !value) {
                    setFieldValue('phone', value);
                  }
                }}
              />
            </ProfileItem>
            <ProfileItem
              muted={editing !== null}
              label='Heimilisfang'
              editing={editing === 'address'}
              onEditPress={onEditing}
              error={getError(errors, touched, 'street')}
              item='address'
              value={
                values?.street
                  ? `${values?.street}, ${values?.postalCode} ${values?.city}, ${values?.country}`
                  : 'Óútfyllt'
              }
            >
              <div className='profile-item-column'>
                <Input placeholder='Gata' name='street' error={getError(errors, touched, 'street')} />
              </div>
              <div className='profile-item-column'>
                <Input placeholder='Póstnúmer' name='postalCode' error={getError(errors, touched, 'postalCode')} />
              </div>
              <div className='profile-item-column'>
                <Input placeholder='Borg' name='city' error={getError(errors, touched, 'city')} />
              </div>
              <div className='profile-item-column'>
                <Input placeholder='Land' name='country' error={getError(errors, touched, 'country')} />
              </div>
            </ProfileItem>
            <ProfileItem
              muted={editing !== null}
              label='Kennitala'
              editing={false}
              onEditPress={() => {}}
              disabled
              value={user.kennitala || 'Óútfyllt'}
            >
              <Input placeholder='Kennitala' name='' value={user.kennitala || 'Óútfyllt'} error={false} />
            </ProfileItem>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProfilePage;
