import React from 'react';
import './banner.scss';

const Banner = () => {
  if (process.env.REACT_APP_CURR_ENV === 'staging') {
    return (
      <div className='banner'>
        STAGING
      </div>
    );
  }
  return null;
};

export default Banner;
