import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { Button } from '../../ui';
import './loading-result.scss';
import Loader from '../../../assets/loader.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const LoadingResult = ({
  title,
  message,
  buttonText,
  onClick,
  buttonVisible,
}) => (
  <div className='loading-result-container'>
    <Lottie
      options={defaultOptions}
      height='25%'
      width='25%'
    />
    <h2 className='ta-center mt-3 mb-1'>{title}</h2>
    <p className='ta-center mv-1 warning'>{message}</p>
    {buttonVisible && (
      <Button onClick={onClick}>
        {buttonText}
      </Button>
    )}
  </div>
);

LoadingResult.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.string.isRequired,
  buttonVisible: PropTypes.bool,
};

LoadingResult.defaultProps = {
  message: null,
  buttonVisible: true,
};

export default LoadingResult;
