export const GET_LOG_AREAS_REQUEST = 'GET_LOG_AREAS_REQUEST';
export const GET_LOG_AREAS_SUCCESS = 'GET_LOG_AREAS_SUCCESS';
export const GET_LOG_AREAS_FAILURE = 'GET_LOG_AREAS_FAILURE';
export const PAGINATE_LOG_AREAS = 'PAGINATE_LOG_AREAS';
export const SORT_LOG_AREAS = 'SORT_LOG_AREAS';

export const GET_LOG_AREA_REQUEST = 'GET_LOG_AREA_REQUEST';
export const GET_LOG_AREA_SUCCESS = 'GET_LOG_AREA_SUCCESS';
export const GET_LOG_AREA_FAILURE = 'GET_LOG_AREA_FAILURE';

export const SET_LOG_AREA_YEAR = 'SET_LOG_AREA_YEAR';

export const CLEAR_CURRENT_AREA = 'CLEAR_CURRENT_AREA';
