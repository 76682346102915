import React from 'react';
import PropTypes from 'prop-types';
import { Plus, Minus } from 'react-feather';
import './permit-action.scss';
import { IconButton } from '../../ui';
import { formatIsk } from '../../../utils/currency';

const permitPrice = (permit) => {
  if (permit.discountPrice) {
    return formatIsk(permit.discountPrice);
  }
  return formatIsk(permit.price);
};

const Permit = ({
  permit, addToCart, removeFromCart, quantity,
}) => (
  <div className='row jc-sb ai-c mb-05 full-width permit-info'>
    <p className='small permit-description-container'>{permit.is.description}</p>
    <div className='permit-price-container'>
      <p className='small ta-right'>{permitPrice(permit)}</p>
    </div>
    <div className='row jc-sb ai-c permit-quantity-container'>
      <IconButton
        onClick={removeFromCart}
        disabled={quantity === 0}
      >
        <Minus
          color={quantity ? '#707070' : '#DEDEDE'}
        />
      </IconButton>
      <p className={`
        bold
        quantity
        no-wrap
        ta-center
        ${quantity && 'success'}
      `}
      >
        {quantity}
        {' '}
        /
        {' '}
        {permit.available}
      </p>
      <IconButton
        onClick={addToCart}
        disabled={quantity === permit.available}
      >
        <Plus
          color={quantity === permit.available ? '#DEDEDE' : '#707070'}
        />
      </IconButton>
    </div>
  </div>
);

Permit.propTypes = {
  permit: PropTypes.shape({
    available: PropTypes.number.isRequired,
    dateFrom: PropTypes.string.isRequired,
    dateTo: PropTypes.string.isRequired,
    discountPrice: PropTypes.number,
    id: PropTypes.string.isRequired,
    is: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }),
    minOrder: PropTypes.number.isRequired,
    arrangement: PropTypes.number,
    price: PropTypes.number.isRequired,
    subarea: PropTypes.string,
  }).isRequired,
  addToCart: PropTypes.func.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default Permit;
