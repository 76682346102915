import React from 'react';
import { PageHeader } from '../../../components/ui';
import routeConstants from '../../../constants/route.constants';
import './about.scss';

const AboutPage = (props) => (
  <div className='about-container'>
    <PageHeader title='Um veiðitorg' />
    <div className='mb-1 mt-3 section'>
      <h3>Sölukerfi veiðileyfa</h3>
      <p>
        <ul>
          <li>Veiðitorg er sölukerfi sem mætir þörfum söluaðila og kaupenda veiðileyfa.</li>
          <li>Veiditorg er vettvangur sem tengir seljendur og kaupendur.</li>
          <li>Veiditorg er óháð - er ekki á vegum stangveiðifélags, landeigenda, veiðifélags, leigutaka, veiðileyfasala, veiðivöruverslanar eða annarra hagsmunaaðlila í veiði- eða ferðaþjónustu.</li>
        </ul>
      </p>
    </div>
    <div className='mb-1 mt-3 section'>
      <h3>Afhverju að velja Veiðitorg?</h3>
      <p>
        Við erum með lausn sem er öllum aðgengileg og opin allan sólarhringinn.
        <ul>
          <li>Laus veiðileyfi eins og staðan er hverju sinni og hægt að kaupa á bakkanum.</li>
          <li>Aðgengi að veiðibókum og skráningu á öllum svæðum sem eru hjá Veiðitorg.is.</li>
          <li>Við lokum aldrei - vefsalan okkar er opin 24 tíma á dag - 365 daga á ári.</li>
        </ul>
      </p>
    </div>
    <div className='mb-1 mt-3 section'>
      <h3>Fyrir söluaðila bjóðum við einnig upp á eftirfarandi.</h3>
      <p>
        <ul>
          <li>
            Rauntímaupplýsingar - um leið og viðskiptavinur
            kaupir vöru eða þjónustu í gegnum Veiditorg.is fær söluaðili senda tilkynningu.
            Söluaðilar hafa aðgang að stjórnkerfi sinna svæða á Veiditorg.is og geta þar nálgast viðskipti og þá vöru/þjónustu sem er til sölu.
          </li>
          <li>Öruggar greiðslur - Veiditorg.is geymir enga fjármuni - greiðsla fer í öllum tilvikum í gegnum örugga greiðslugátt og berst þaðan beint til söluaðila.</li>
          <li>Veiðibækur - Veiðitorg yfirfer veiðitölur og skilar inn til Veiðimálastofnunnar ef óskað er eftir því.</li>
          <li>Veiðifélög - Hægt er að hafa forsölu og afsláttarkjör fyrir félaga frá venjulegu verði.</li>
        </ul>
        Við höfum margra ára reynslu af rekstri og hönnun veiðileyfasölukerfa og auk þess menntun á sviði fiskifræða.
        <br />
        Ekki hika við að hafa samband ef þú vilt nánari upplýsingar.
      </p>
    </div>
    <div className='mb-1 mt-3 section'>
      <h3>Starfsmenn Veiðitorgs</h3>
      <p>
        Erlendur Steinar Friðriksson
        <br />
        B.Sc í sjávarútvegsfræði, M.Sc. í auðlindafræði.
        <br />
        Menntaður sjávarútvegsfræðingur með áherslu á fiskeldi og auðlindafræðingur M.Sc. þar sem viðgangsefnið var veiða og sleppa á bleikju.
        <br />
        Rekur einnig fyrirtækið Fiskirannsóknir ehf, sem tekur að sér fiskirannsóknir og veiðiráðgjöf.

      </p>
    </div>
    <div className='mb-1 mt-3 section-sell'>
      <h1 className='mb-2'>Viltu selja á veiðitorgi ?</h1>
      <p>
        Hafðu samband við veiðitorg í netfangið
        {' '}
        <a href={routeConstants.MAILTO_VEIDITORG} style={{ fontWeight: 500 }}>veiditorg@veiditorg.is</a>
        {' '}
        og við komum þér af stað.
      </p>
    </div>
  </div>
);

AboutPage.propTypes = {

};

export default AboutPage;
