import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { LOGOUT_SUCCESS } from './auth/auth.types';
import auth from './auth/auth.reducer';
import init from './init/init.reducer';
import app from './app/app.reducer';
import modal from './modal/modal.reducer';
import areas from './areas/areas.reducer';
import clubs from './clubs/clubs.reducer';
import permits from './permits/permits.reducer';
import cart from './cart/cart.reducer';
import logs from './logs/logs.reducer';
import logAreas from './log-areas/log-areas.reducer';
import bookings from './bookings/bookings.reducer';
import history from '../router/history';

const appReducer = combineReducers({
  router: connectRouter(history),
  auth,
  init,
  app,
  modal,
  areas,
  clubs,
  permits,
  cart,
  logs,
  logAreas,
  bookings,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
