import React from 'react';
import PropTypes from 'prop-types';
import {
  Map, MapView,
} from '../../../../../components/ui';
import './area-map-section.scss';

const AreaMapSection = ({ title, lat, lng }) => (
  <section>
    {title && <h2 className='mb-1'>{title}</h2>}
    <div className='map-cont'>
      <MapView
        defaultPosition={{
          lat,
          lng,
        }}
        zoom={8}
      >
        <Map.Marker
          lat={lat}
          lng={lng}
        />
      </MapView>
    </div>
  </section>
);

AreaMapSection.propTypes = {
  title: PropTypes.string,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

AreaMapSection.defaultProps = {
  title: null,
};

export default AreaMapSection;
