/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import './summary.scss';

const Summary = ({ totalPrice, discount, toPay }) => (
  <section className='column full-width mv-05'>
    <div className='row jc-sb ai-fe'>
      <p>Samtals</p>
      <p className='smaller'>{totalPrice}</p>
    </div>
    {discount && (
      <div className='row jc-sb ai-fe'>
        <p>Afsláttur</p>
        <p className='smaller'>{totalPrice}</p>
      </div>
    )}
    <div className='row jc-sb ai-fe'>
      <p>Til greiðslu</p>
      <p className='smaller'>{totalPrice}</p>
    </div>
  </section>
);

Summary.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  discount: PropTypes.number,
  toPay: PropTypes.number.isRequired,
};

Summary.defaultProps = {
  discount: null,
};

export default Summary;
