import moment from 'moment';

export const ACCESS_TOKEN_INFO = 'ACCESS_TOKEN_INFO';
export const REFRESH_TOKEN_INFO = 'REFRESH_TOKEN_INFO';

// ACCESS TOKENS
export const setAccessTokenInfo = (data) => localStorage.setItem(ACCESS_TOKEN_INFO, data);
export const getAccessTokenInfo = () => localStorage.getItem(ACCESS_TOKEN_INFO);
export const removeAccessTokenInfo = () => localStorage.removeItem(ACCESS_TOKEN_INFO);

// REFRESH TOKENS
export const setRefreshTokenInfo = (data) => localStorage.setItem(REFRESH_TOKEN_INFO, data);
export const getRefreshTokenInfo = () => localStorage.getItem(REFRESH_TOKEN_INFO);
export const removeRefreshTokenInfo = () => localStorage.removeItem(REFRESH_TOKEN_INFO);

const isTokenValid = (tokenExpires) => {
  const now = moment();
  return moment(tokenExpires).isAfter(now);
};

export const getRefreshTokenObj = () => {
  try {
    const refreshTokenInfo = getRefreshTokenInfo();
    if (refreshTokenInfo) {
      return JSON.parse(refreshTokenInfo);
    }
    throw new Error('No refresh token');
  } catch (err) {
    return {
      token: null,
      expires: null,
    };
  }
};

export const getAccessTokenObj = () => {
  try {
    const accessTokenInfo = getAccessTokenInfo();
    if (accessTokenInfo) {
      return JSON.parse(accessTokenInfo);
    }
    throw new Error('No access token');
  } catch (err) {
    return {
      token: null,
      expires: null,
    };
  }
};

export const isAccessTokenValid = () => {
  try {
    const token = getAccessTokenInfo();
    if (token) {
      const { expires } = JSON.parse(token);
      return isTokenValid(expires);
    }
    throw new Error('No access token');
  } catch (err) {
    console.warn('ERROR :: isAccessTokenValid', err);
    return false;
  }
};

export const isRefreshTokenValid = () => {
  try {
    const token = getRefreshTokenInfo();
    if (token) {
      const { expires } = JSON.parse(token);
      return isTokenValid(expires);
    }
    throw new Error('No refresh token');
  } catch (err) {
    console.warn('ERROR :: isAccessTokenValid', err);
    return false;
  }
};

export const setTokens = ({ access, refresh }) => new Promise((resolve, reject) => {
  try {
    setRefreshTokenInfo(JSON.stringify(refresh));
    setAccessTokenInfo(JSON.stringify(access));
    return resolve(true);
  } catch (err) {
    console.warn('ERROR :: setTokens', err);
    return reject(err);
  }
});

export const removeTokens = () => new Promise((resolve, reject) => {
  try {
    console.warn('removeTokens called ?');
    removeRefreshTokenInfo();
    removeAccessTokenInfo();
    return resolve(true);
  } catch (err) {
    console.warn('ERROR :: removeTokens', err);
    return reject(err);
  }
});
