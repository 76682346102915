const fishTypeMappings = {
  sjobirtingur: 'Sjóbirtingur',
  lax: 'Lax',
  bleikja: 'Bleikja',
  urridi: 'Urriði',
  hnudlax: 'Hnúðlax',
};

const baitTypeMappings = {
  fluga: 'Fluga',
  madkur: 'Maðkur',
  spunn: 'Spúnn',
};

const fishSexMappings = {
  male: 'Hængur',
  female: 'Hrygna',
  unknown: 'Óþekkt',
};

const couponTypeMappings = {
  percentage: 'Prósenta',
  amount: 'Upphæð',
};

const bookingTypeMappings = {
  reserved: 'Frátekið',
  booked: 'Bókað',
};

const bookingStatusMappings = {
  abandoned: 'Hætt við',
  pending: 'Í bið',
  paid: 'Greitt',
  reserved: 'Frátekið',
  voided: 'Ógilt',
};

module.exports = {
  fishTypeMappings,
  baitTypeMappings,
  fishSexMappings,
  couponTypeMappings,
  bookingTypeMappings,
  bookingStatusMappings,
};
