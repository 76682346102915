import api from './api';
import catchAsync from './error';

const register = catchAsync(async (body) => {
  const response = await api.POST('/auth/register', body);
  return response.data;
});

const resendCode = catchAsync(async () => {
  const response = await api.POST('/auth/resend-verify-email-code');
  return response.data;
});

const login = catchAsync(async (data) => {
  const response = await api.POST('/auth/login', data);
  return response.data;
});

const logout = catchAsync(async (refreshToken) => {
  await api.POST('/auth/logout', { refreshToken });
});

const refreshTokens = catchAsync(async (refreshToken) => {
  const response = await api.POST('/auth/refresh-tokens', { refreshToken }, {});
  return response;
});

const forgotPassword = catchAsync(async (email) => {
  const response = await api.POST('/auth/forgot-password', { email });
  return response;
});

const resetPassword = catchAsync(async (password, token) => {
  const response = await api.POST('/auth/reset-password', { password }, { token });
  return response;
});

const verifyEmail = catchAsync(async (token) => {
  const response = await api.POST('/auth/verify-email', { }, { token });
  return response;
});

export default {
  register,
  login,
  logout,
  forgotPassword,
  refreshTokens,
  resetPassword,
  verifyEmail,
  resendCode,
};
