import { toast } from 'react-toastify';
import Toast from '../components/toast/toast';

const defaultToastProps = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

const success = (message, title = null) => {
  if (typeof message === 'string') {
    toast(<Toast title={title} message={message} type='success' />, { ...defaultToastProps, type: 'success' });
  }
};

const info = (message) => {
  if (typeof message === 'string') {
    toast(<Toast title='Athugaðu' message={message} type='info' />, { ...defaultToastProps, type: 'info' });
  }
};

const warning = (message) => {
  if (typeof message === 'string') {
    toast(<Toast title='Varúð' message={message} type='warning' />, { ...defaultToastProps, type: 'warning' });
  }
};

const error = (message) => {
  if (typeof message === 'string') {
    toast(<Toast title='Villa' message={message} type='error' />, { ...defaultToastProps, type: 'error' });
  }
};

export default {
  success,
  info,
  warning,
  error,
};
