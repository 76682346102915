import React from 'react';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { CompleteSignupSchema } from '../../../schemas/auth.schema';
import countries from '../../../constants/countries.constants';
import {
  Input, Select, Button, CheckBox,
} from '../../../components/ui';
import { getError } from '../../../utils/formik';
import routeConstants from '../../../constants/route.constants';

const CompleteSignupForm = ({ onSubmit }) => (
  <Formik
    initialValues={{
      phone: '',
      kennitala: null,
      address: {
        street: '',
        city: '',
        country: '',
        postalCode: '',
      },
      newsletter: true,
    }}
    validationSchema={CompleteSignupSchema}
    onSubmit={onSubmit}
  >
    {({
      errors, touched, values, setFieldValue, setFieldTouched,
    }) => (
      <Form noValidate>
        <Input
          label='Kennitala'
          name='kennitala'
          error={getError(errors, touched, 'kennitala')}
          onChange={(e) => {
            const value = e.target.value || null;
            setFieldValue('kennitala', value);
          }}
        />
        <Input
          required
          label='Símanúmer'
          name='phone'
          error={getError(errors, touched, 'phone')}
          value={values.phone}
          onChange={(e) => {
            e.preventDefault();
            const { value } = e.target;
            const regex = /^[\\+\d]?(?:[\d-.\s()]*)$/;
            if (regex.test(value.toString()) || !value) {
              setFieldValue('phone', value);
            }
          }}
        />
        <Select
          required
          options={countries}
          label='Land'
          value={countries.find((c) => c.value === values.address.country)}
          onChange={({ value }) => {
            setFieldValue('address.country', value);
          }}
          onBlur={() => setFieldTouched('address.country')}
          error={getError(errors, touched, 'address.country')}
        />
        <Input required label='Póstnúmer' name='address.postalCode' error={getError(errors, touched, 'address.postalCode')} />
        <Input required label='Borg' name='address.city' error={getError(errors, touched, 'address.city')} />
        <Input required label='Gata' name='address.street' error={getError(errors, touched, 'address.street')} />
        <div className='mv-1'>
          <p>
            Með því að velja
            {' '}
            <span className='bold'>Samþykkja og nýskrá,</span>
            {' '}
            samþykkir þú
            {' '}
            <Link className='underline primary' to={routeConstants.TERMS_AND_CONDITIONS} rel='norefferrer' target='blank'>skilmála</Link>
            {' '}
            og
            {' '}
            <Link className='underline primary' to={routeConstants.PRIVACY_POLICY} rel='norefferrer' target='blank'>persónuverndarstefnu</Link>
            {' '}
            Veiðitorgs
          </p>
          <CheckBox
            checked={!values.newsletter}
            onChange={(v) => {
              setFieldValue('newsletter', !values.newsletter);
            }}
            className='mv-1'
            name='newsletter'
            label='Ég vil ekki póst um tilboð og fríðindi'
          />
        </div>
        <Button type='submit' block>Samþykkja og nýskrá</Button>
      </Form>
    )}
  </Formik>
);

CompleteSignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default CompleteSignupForm;
