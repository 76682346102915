import * as logAreaTypes from './log-areas.types';

const defaultParams = {
  dateFrom: null,
  dateTo: null,
  sortBy: 'createdAt:desc',
  limit: 25,
  year: new Date().getFullYear(),
  page: 0,
  totalPages: 0,
  totalResults: 0,
};

const initLogsState = {
  logAreas: [],
  getLogAreasPending: false,
  getLogAreasError: null,
  area: null,
  getLogAreaPending: false,
  getLogAreaError: null,
  ...defaultParams,
};

const logAreasReducer = (state = initLogsState, action) => {
  const {
    type,
    results,
    page,
    year,
    limit,
    totalPages,
    totalResults,
    error,
    area,
  } = action;
  switch (type) {
    case logAreaTypes.GET_LOG_AREAS_REQUEST:
      return {
        ...state,
        getLogAreasPending: true,
        getLogAreasError: null,
      };
    case logAreaTypes.SET_LOG_AREA_YEAR:
      return {
        ...state,
        year,
      };
    case logAreaTypes.GET_LOG_AREAS_SUCCESS:
      return {
        ...state,
        logAreas: results,
        page,
        limit,
        totalPages,
        totalResults,
        getLogAreasPending: false,
        getLogAreasError: null,
      };
    case logAreaTypes.GET_LOG_AREAS_FAILURE:
      return {
        ...state,
        getLogAreasPending: false,
        getLogAreasError: error,
      };
    case logAreaTypes.PAGINATE_LOG_AREAS:
      return {
        ...state,
        page,
        limit,
      };
    case logAreaTypes.GET_LOG_AREA_REQUEST:
      return {
        ...state,
        getLogAreaPending: true,
        getLogAreaError: null,
      };
    case logAreaTypes.GET_LOG_AREA_SUCCESS:
      return {
        ...state,
        area,
        getLogAreaPending: false,
        getLogAreaError: null,
      };
    case logAreaTypes.GET_LOG_AREA_FAILURE:
      return {
        ...state,
        getLogAreaPending: false,
        getLogAreaError: error,
      };
    case logAreaTypes.CLEAR_CURRENT_AREA:
      return {
        ...state,
        area: null,
      };
    default:
      return state;
  }
};

export default logAreasReducer;
