import api from './api';
import catchAsync from './error';

const createCart = catchAsync(async (body) => {
  const response = await api.POST('/bookings/cart', body);
  return response.data;
});

const cancelBooking = catchAsync(async (orderId) => {
  const response = await api.DELETE(`/bookings/my-bookings/${orderId}`);
  return response.data;
});

const checkout = catchAsync(async (body) => {
  const response = await api.POST('/bookings/book', body);
  return response.data;
});

const getMyBookings = catchAsync(async (params) => {
  const response = await api.GET('/bookings/my-bookings', params);
  return response.data;
});

const getBookingByOrderId = catchAsync(async (orderId) => {
  const response = await api.GET(`/bookings/my-bookings/${orderId}`);
  return response.data;
});

const resendPdfPermitsByOrderId = catchAsync(async (orderId, body) => {
  const response = await api.POST(`/bookings/my-bookings/${orderId}/pdf`, body);
  return response.data;
});

export default {
  createCart,
  cancelBooking,
  checkout,
  getMyBookings,
  getBookingByOrderId,
  resendPdfPermitsByOrderId,
};
