import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../ui';
import FisherMan from '../../../assets/fisherman.svg';
import './error-result.scss';

const ErrorResult = ({
  title,
  message,
  buttonText,
  onClick,
}) => (
  <div className='empty-result-container'>
    <img src={FisherMan} alt='fisherman fishing' />
    <h2 className='ta-center mt-3 mb-1'>{title}</h2>
    <p className='ta-center mv-1 warning'>{message}</p>
    <Button onClick={onClick}>
      {buttonText}
    </Button>
  </div>
);

ErrorResult.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.string.isRequired,
};

ErrorResult.defaultProps = {
  message: null,
};

export default ErrorResult;
