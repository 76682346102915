import {
  take, call, put, all, select,
} from 'redux-saga/effects';
import areasApi from '../../api/areas';
import message from '../../services/message.service';
import { load, ready } from '../init/init.sagas';
import { getParams } from './areas.selectors';
import * as areaTypes from './areas.types';

function* getAreas() {
  while (true) {
    yield take(areaTypes.GET_AREAS_REQUEST);
    try {
      yield call(load);
      const params = yield select(getParams);
      const {
        results, page, limit, totalPages, totalResults,
      } = yield call(areasApi.getAreas, params);
      yield put({
        type: areaTypes.GET_AREAS_SUCCESS, results, page, limit, totalPages, totalResults,
      });
    } catch (error) {
      message.error(error);
      yield put({
        type: areaTypes.GET_AREAS_FAILURE,
        error,
      });
    } finally {
      yield call(ready);
    }
  }
}

function* getAreaBySlug() {
  while (true) {
    const { slug } = yield take(areaTypes.GET_AREA_BY_SLUG_REQUEST);
    try {
      yield call(load);
      const area = yield call(areasApi.getAreaBySlug, slug);
      yield put({
        type: areaTypes.GET_AREA_BY_SLUG_SUCCESS, area,
      });
    } catch (error) {
      message.error(error);
      yield put({
        type: areaTypes.GET_AREA_BY_SLUG_FAILURE,
        error,
      });
    } finally {
      yield call(ready);
    }
  }
}

export default function* areaSaga() {
  yield all([
    getAreas(),
    getAreaBySlug(),
  ]);
}
