import React from 'react';
import { AlertCircle } from 'react-feather';
import './input.scss';
import { Field } from 'formik';
import PropTypes from 'prop-types';

export default function Input({
  label, required, error, ...rest
}) {
  return (
    <div className={`input-wrapper ${!label && 'no-label'}`}>
      <label htmlFor={rest.name}>{`${label} ${required ? '*' : ''}`}</label>
      <span className='error'>
        {error}
        {error && (
          <AlertCircle className='svg-error' />
        )}
      </span>
      <Field className={`field ${error ? 'field-error' : ''}`} {...rest} />
    </div>
  );
}

Input.defaultProps = {
  error: '',
  label: '',
  required: false,
};

Input.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};
