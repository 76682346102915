import * as logTypes from './logs.types';

export const getLogs = (id) => ({
  type: logTypes.GET_LOGS_REQUEST,
  id,
});

export const createLog = (id, body) => ({
  type: logTypes.CREATE_LOG_REQUEST,
  id,
  body,
});

export const paginateLogs = (page, limit) => ({
  type: logTypes.PAGINATE_LOGS,
  page,
  limit,
});

export const resetParams = () => ({
  type: logTypes.RESET_PARAMS,
});

export const sortLogs = (sortBy) => ({
  type: logTypes.SORT_LOGS,
  sortBy,
});

export const filterBySubarea = (subarea) => ({
  type: logTypes.FILTER_LOGS_BY_SUBAREA,
  subarea,
});

export const filterBySubplace = (subplace) => ({
  type: logTypes.FILTER_LOGS_BY_SUBPLACE,
  subplace,
});

export const filterByYear = (year) => ({
  type: logTypes.FILTER_LOGS_BY_YEAR,
  year,
});

export const filterByFishType = (fishType) => ({
  type: logTypes.FILTER_LOGS_BY_FISH_TYPE,
  fishType,
});
