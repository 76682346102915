export const GET_LOGS_REQUEST = 'GET_LOGS_REQUEST';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOGS_FAILURE = 'GET_LOGS_FAILURE';
export const CREATE_LOG_REQUEST = 'CREATE_LOG_REQUEST';
export const CREATE_LOG_SUCCESS = 'CREATE_LOG_SUCCESS';
export const CREATE_LOG_FAILURE = 'CREATE_LOG_FAILURE';
export const PAGINATE_LOGS = 'PAGINATE_LOGS';
export const SORT_LOGS = 'SORT_LOGS';
export const FILTER_LOGS_BY_SUBAREA = 'FILTER_LOGS_BY_SUBAREA';
export const FILTER_LOGS_BY_SUBPLACE = 'FILTER_LOGS_BY_SUBPLACE';
export const FILTER_LOGS_BY_YEAR = 'FILTER_LOGS_BY_YEAR';
export const FILTER_LOGS_BY_FISH_TYPE = 'FILTER_LOGS_BY_FISH_TYPE';
export const RESET_PARAMS = 'RESET_PARAMS';
