import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Check } from 'react-feather';
import { ValidateCouponSchema } from '../../../../../schemas/coupon.schema';
import { validateCoupon, clearCoupon } from '../../../../../redux/bookings/bookings.actions';
import { Input, Button } from '../../../../../components/ui';
import { getError } from '../../../../../utils/formik';
import './coupon-form.scss';

const CouponForm = () => {
  const { coupon } = useSelector((state) => state.bookings);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        coupon: coupon ? coupon.coupon : '',
      }}
      validationSchema={ValidateCouponSchema}
      onSubmit={(values) => {
        dispatch(validateCoupon(values.coupon));
      }}
    >
      {({
        errors, setFieldValue, touched, values,
      }) => (
        <Form noValidate>
          <div className='coupon-row'>
            <Input
              label='Afsláttarkóði'
              name='coupon'
              error={getError(errors, touched, 'coupon')}
              onChange={(e) => {
                let value = e.target.value || null;
                if (value) {
                  value = value.toUpperCase().trim();
                }
                setFieldValue('coupon', value);
                if (coupon) {
                  dispatch(clearCoupon());
                }
              }}
            />
            <Button type='submit' disabled={coupon?.coupon === values.coupon}>
              {coupon?.coupon === values.coupon ? <Check /> : 'Virkja'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CouponForm;
