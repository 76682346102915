import React from 'react';
import './icon-button.scss';
import PropTypes from 'prop-types';

export default function IconButton({
  children, className, onClick, disabled, primary,
}) {
  return (
    <div className={`
      icon-button-container
      ${className}
    `}
    >
      <button
        disabled={disabled}
        className={`
          button
          ${primary && 'b-primary'}
        `}
        type='submit'
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
}

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  primary: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

IconButton.defaultProps = {
  disabled: false,
  primary: false,
  onClick: () => {},
  className: '',
};
