/* eslint-disable max-len */
import * as React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ fill, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={571.64}
    height={145.25}
    viewBox='0 0 571.64 145.25'
    {...rest}
  >
    <g data-name='Layer 2'>
      <g data-name='Layer 1' fill={fill}>
        <path d='M0 57.2l29.25-16.05 7.65 20.1h.3c2.4-8.1 6.3-20.1 16.5-20.1 8.4 0 14.85 5.7 23.7 2.25l2.85 2.1a71.82 71.82 0 00-3.9 8.4L55.8 103.55a108 108 0 00-4.2 13.5h-18a27.92 27.92 0 00-1.5-3.75l-1.5-3.6L8.25 62 3 64.4zM60.3 62a19.56 19.56 0 01-3.15.6c-1.35 0-9-2.4-10.8-2.55s-5.85.3-5.85 6A11.88 11.88 0 0041.7 70L50 88.1zM150 99.8c-8.7 11.55-20.7 19.5-37.35 19.5-22.65 0-36.6-15.9-36.6-39.75 0-20.85 15.75-38.4 36.9-38.4 20.25 0 31.5 13.35 36.75 31.65l-42.6 15.6c3.45 8.55 9.6 13.2 18.9 13.2 7.05 0 13.8-2.4 18.3-7.95zM122 70c-2.1-6.45-5.55-17.4-13.65-17.4-5.85 0-8.25 4.65-8.25 11 0 5.85 1.65 9.75 2.55 13.8z' />
        <path d='M149.1 117.05v-9h5.7V58l-5.4 1v-8.55l32.85-9.3v66.9h4.95v9zm21-98.4c6.45 0 13.35 1.65 13.35 9.45 0 9.45-11.85 12.75-19.35 12.75-6 0-10.2-3.15-10.2-9.3 0-7.35 9.75-12.9 16.2-12.9zM316.2 55.1h-7.35v-8.25c11.69-3.3 22.19-11.4 26.84-22.8l9 1.65a50.73 50.73 0 00-1.05 11.7v6H357l-2.25 11.7h-11.1v43.35c0 4.65 3.9 6 6.15 6a13.76 13.76 0 007.2-2.4l3.9 6.45c-7.5 5.55-16.05 10.8-25.8 10.8-13.65 0-18.89-6.75-18.89-19.8z' />
        <path d='M356.69 80.15c0-24.15 18.45-39 42.3-39s42.45 14.85 42.45 39S423 119.3 399 119.3s-42.31-14.85-42.31-39.15zm27.45-10.65c0 10.8 4.5 38.4 19.65 38.4 8.1 0 10.2-8.85 10.2-17 0-10.8-4.5-38.4-19.65-38.4-10.2.05-10.2 11.5-10.2 17zM441.74 49.25l30.15-8.1v17.7h.3c1.8-5.1 4.65-17.7 11.7-17.7 4.5 0 6.9.9 11.1 3l1.8-3L505 44l-9 25.35c-3.9-1.5-7.8-3-12.15-3.3A11.45 11.45 0 00474 71.6v36.45h7.35v9h-39.9v-9h5.1v-51.6l-4.8 1.95z' />
        <path d='M565.64 45.35c-1.8-1.8-2.7-3-5.4-3-1.8 0-2.7 1.65-2.7 3.3 0 3 14.1 7.65 14.1 25.2 0 23.7-21.75 28.35-39.9 33v.3c12.45 1.35 37.5 4 37.5 20.85 0 16.35-21.75 20.25-30.75 20.25-13.2 0-26.7-2.85-26.7-15 0-7.35 8.1-12.15 14.55-12.9v-.3c-8.55-.75-13.8-1.35-20.1-.45l-10.05-9.75c9.9-1.5 19.35-4.5 28.65-7.95v-.3C511.94 96.65 499 87.8 499 72.05c0-21.6 17.1-30.9 33.45-30.9 8 0 12.3 1.35 16.5 2l1.8-5.7c1.8-5.55 4.5-7.8 12.6-7.8 1.8 0 3.75.3 5.55.45zm-42.15 15.3c0 8.1 4.65 30.75 15.9 30.75 5.55 0 7.8-2.85 7.8-10.05 0-8.1-4.65-30.75-15.9-30.75-5.55 0-7.8 2.85-7.8 10.05zm9.45 69.6c0 3.75 1.95 7.8 6.15 7.8 3.75 0 5.7-1.8 5.7-5.55 0-6.9-3.9-9.15-9.15-12.45a18.59 18.59 0 00-2.7 10.2zM286.5 40.85c7.5 0 19.34-3.3 19.34-12.75 0-7.8-6.89-9.45-13.34-9.45s-16.2 5.55-16.2 12.9c0 6.15 4.2 9.3 10.2 9.3z' />
        <path d='M304.64 108.05v-66.9l-32.84 9.3V59l5.4-1v50h-11.4V23.77l5.56-2.1v-9.61l-5.56 1.83V0l-27.49 9.09V23l-5.11 1.69v9l5.11-1.35v11A48 48 0 00223 41.05c-19.38 0-34.85 18-34.85 43.86 0 18.32 11.42 34.39 29.59 34.39 7.66 0 15.77-4.8 20.88-10.21l2.25 8h68.73v-9zm-66.33-8.57a21.59 21.59 0 01-7.36 1.2c-13.82 0-15.32-12.77-15.32-25.09 0-13.52 1.5-24.33 12-24.33a12.78 12.78 0 0110.66 6.16z' />
      </g>
    </g>
  </svg>
);

Logo.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default Logo;
