import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetPassword } from '../../redux/auth/auth.actions';
import { Modal } from '../../components/ui';
import { ResetPasswordForm } from './components';

const ResetPasswordModal = ({ ...rest }) => {
  const dispatch = useDispatch();
  const { forgotPasswordEmail } = useSelector((state) => state.auth);

  const onSubmit = ({ code, password }) => {
    dispatch(resetPassword(code, password));
  };

  return (
    <Modal title='Nýtt lykilorð' {...rest}>
      <p>Þú hefur fengið staðfestingarkóða sendan á netfangið:</p>
      <br />
      <p className='ta-center medium mv-1'>{forgotPasswordEmail}</p>
      <ResetPasswordForm onSubmit={onSubmit} />
    </Modal>
  );
};

export default ResetPasswordModal;
