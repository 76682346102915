import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImgsViewer from 'react-images-viewer';
import { Button } from '../../../../../components/ui';
import { useWindowDimensions } from '../../../../../hooks';
import { CloudinaryImage } from '../../../../../utils/cloudinary';
import './area-images.scss';

const AreaImages = ({ images, image: { imagePublicId } }) => {
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { width } = useWindowDimensions();
  const imgs = [{ src: CloudinaryImage(imagePublicId), alt: '' }, ...images.map(((img) => ({ src: CloudinaryImage(img.imagePublicId), alt: '' })))];

  const onClickPrev = () => {
    const nextIndex = activeIndex === 0 ? imgs.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const onClickNext = () => {
    const nextIndex = activeIndex === imgs.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const isFullGrid = images.length > 3;

  const buttonVisible = images.length >= 3;

  return (
    <>
      <ImgsViewer
        imgs={imgs}
        isOpen={visible}
        currImg={activeIndex}
        onClickPrev={onClickPrev}
        onClickNext={onClickNext}
        backdropCloseable
        onClose={() => {
          setActiveIndex(0);
          setVisible(false);
        }}
      />
      <div className='img mobile-img base-img' style={{ backgroundImage: `url(${CloudinaryImage(imagePublicId)})` }}>
        <Button smaller inverse className='images-button' onClick={() => setVisible(true)}>
          Sjá allar myndir
        </Button>
      </div>
      <div className={`mb-2 area-img-grid ${isFullGrid ? 'area-img-grid-4' : 'area-img-grid-2'}`}>
        <div className='img base-img' style={{ backgroundImage: `url(${CloudinaryImage(imagePublicId)})` }} />
        {images.map(({ imagePublicId: img }, i) => (
          <div
            key={img}
            onClick={() => {
              setActiveIndex(i + 1);
              setVisible(true);
            }}
            className={`
              base-img
              pointer
              ${`img${i + 1}`}
            `}
            style={{ backgroundImage: `url(${CloudinaryImage(img)})` }}
          />
        ))}
        {buttonVisible && (
          <Button smaller inverse className='images-button' onClick={() => setVisible(true)}>
            <span>
              Sjá allar myndir
            </span>
          </Button>
        )}
      </div>
    </>
  );
};

AreaImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    imagePublicId: PropTypes.string.isRequired,
  })),
  image: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired,
    imagePublicId: PropTypes.string.isRequired,
  }).isRequired,
};

AreaImages.defaultProps = {
  images: [],
};

export default AreaImages;
