import React, { useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import PropTypes from 'prop-types';
import { X, ArrowLeft } from 'react-feather';
import IconButton from '../icon-button/icon-button';
import { useOnClickOutside } from '../../../hooks';
import './modal.scss';

const Modal = ({
  icon, closeOnClickOutside, title, onClose, onIconClick, children, open,
}) => {
  const modalRef = useRef(null);
  useOnClickOutside(modalRef, onClose, closeOnClickOutside);

  useEffect(() => {
    if (open) {
      disableBodyScroll(modalRef.current, { reserveScrollBarGap: true });
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [open]);

  const IconComp = icon === 'close' ? X : ArrowLeft;

  return (
    <>
      <div className='overlay' />
      <div className='modal-container'>
        <div className='modal' ref={modalRef}>
          <div className='modal-inner'>
            <div className='modal-header'>
              <IconButton className='modal-header__button' onClick={onIconClick || onClose}>
                <IconComp color='#707070' />
              </IconButton>
              <p className='title medium'>{title}</p>
            </div>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

Modal.defaultProps = {
  icon: 'close',
  closeOnClickOutside: true,
};

Modal.propTypes = {
  icon: PropTypes.string,
  closeOnClickOutside: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onIconClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Modal;
