const formatIsk = (price) => {
  let formatted = '0';
  if (price) {
    formatted = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return `${formatted} kr.`;
};

export {
  formatIsk,
};
