import Empty from './empty/empty-result';
import Error from './error/error-result';
import Loading from './loading/loading-result';
import Success from './success/success-result';

export default {
  Empty,
  Error,
  Loading,
  Success,
};
