import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getAccessTokenInfo } from '../../services/token.service';
import routeConstants from '../../constants/route.constants';

// restricted = false meaning public route
// restricted = true meaning restricted route
const PublicRoute = ({ children, restricted, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) => {
      const token = getAccessTokenInfo();
      if (token && restricted) {
        return <Redirect to={routeConstants.PROFILE} from={location} />;
      }
      return children;
    }}
  />
);

PublicRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]).isRequired,
  restricted: PropTypes.bool,
};

PublicRoute.defaultProps = {
  restricted: false,
};

export default PublicRoute;
