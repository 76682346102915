import React from 'react';
import PropTypes from 'prop-types';
import './permit-subarea.scss';
import { NO_SUBAREA } from '../../../utils/permits';

const PermitSubarea = ({ subarea, displayEmptySubareas }) => {
  if (subarea === NO_SUBAREA && displayEmptySubareas) {
    return (
      <div className='mt-1'>
        <p className='success medium'>Annað</p>
      </div>
    );
  }
  if (subarea !== NO_SUBAREA) {
    return (
      <div className='mt-1'>
        <p className='success medium'>{subarea}</p>
      </div>
    );
  }
  return null;
};

PermitSubarea.propTypes = {
  subarea: PropTypes.string.isRequired,
  displayEmptySubareas: PropTypes.bool.isRequired,
};

export default PermitSubarea;
