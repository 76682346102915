// Router Types
import { PrivateRoute, PublicRoute } from '../components/routes';

// Layout Types
import { DefaultLayout, MapLayout } from '../components/layouts';

// Route names
import routeConstants from '../constants/route.constants';

// Pages
import Public from './public';
import Private from './private';
//
export default [
  {
    path: routeConstants.HOME,
    exact: true,
    inverse: true,
    component: Public.Home,
    routeType: PublicRoute,
    layout: DefaultLayout,
  },
  {
    path: routeConstants.AREAS,
    exact: true,
    component: Public.Areas,
    routeType: PublicRoute,
    layout: MapLayout,
  },
  {
    path: routeConstants.ALL_AREAS,
    exact: true,
    component: Public.AllAreas,
    routeType: PublicRoute,
    layout: DefaultLayout,
  },
  {
    path: routeConstants.LOG_AREAS,
    exact: true,
    component: Public.LogAreas,
    routeType: PublicRoute,
    layout: DefaultLayout,
  },
  {
    path: `${routeConstants.LOG_AREAS}/:id`,
    exact: true,
    component: Public.Logs,
    routeType: PublicRoute,
    layout: DefaultLayout,
  },
  {
    path: routeConstants.ABOUT,
    exact: true,
    component: Public.About,
    routeType: PublicRoute,
    layout: DefaultLayout,
  },
  {
    path: routeConstants.PRIVACY_POLICY,
    exact: true,
    component: Public.Privacy,
    routeType: PublicRoute,
    layout: DefaultLayout,
  },
  {
    path: routeConstants.TERMS_AND_CONDITIONS,
    exact: true,
    component: Public.Terms,
    routeType: PublicRoute,
    layout: DefaultLayout,
  },
  {
    path: `${routeConstants.AREAS}/:slug${routeConstants.PERMITS}`,
    exact: false,
    component: Public.Permits,
    routeType: PublicRoute,
    layout: DefaultLayout,
  },
  {
    path: `${routeConstants.AREAS}/:slug`,
    exact: false,
    component: Public.Area,
    routeType: PublicRoute,
    layout: DefaultLayout,
  },
  {
    path: routeConstants.PROFILE,
    exact: true,
    component: Private.Profile,
    routeType: PrivateRoute,
    layout: DefaultLayout,
  },
  {
    path: routeConstants.CLUBS,
    exact: true,
    component: Private.Clubs,
    routeType: PrivateRoute,
    layout: DefaultLayout,
  },
  {
    path: routeConstants.CHECKOUT,
    exact: true,
    component: Private.Checkout,
    routeType: PrivateRoute,
    layout: DefaultLayout,
  },
  {
    path: routeConstants.BOOKINGS,
    exact: true,
    component: Private.Bookings,
    routeType: PrivateRoute,
    layout: DefaultLayout,
  },
  {
    path: `${routeConstants.BOOKINGS}/:orderId`,
    exact: false,
    component: Private.Booking,
    routeType: PrivateRoute,
    layout: DefaultLayout,
  },
  {
    path: `${routeConstants.CANCEL_BOOKING}/:orderId`,
    exact: false,
    component: Private.CancelBooking,
    routeType: PrivateRoute,
    layout: DefaultLayout,
  },
  {
    path: `${routeConstants.EXPIRED_BOOKING}/:orderId`,
    exact: false,
    component: Private.ExpiredBookingPage,
    routeType: PrivateRoute,
    layout: DefaultLayout,
  },
  {
    path: `${routeConstants.CONFIRM_BOOKING}/:orderId`,
    exact: false,
    component: Private.ConfirmBooking,
    routeType: PrivateRoute,
    layout: DefaultLayout,
  },
  {
    path: routeConstants.NOT_FOUND,
    exact: false,
    component: Public.NotFound,
    routeType: PublicRoute,
    layout: DefaultLayout,
  },
];
