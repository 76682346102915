import * as Yup from 'yup';
import {
  baitTypesEnum,
  fishSexEnum,
  fishTypesEnum,
  MAX_FISH_LENGTH,
  MAX_FISH_WEIGHT,
} from '../constants/logs.constants';

const CreateLogSchema = Yup.object().shape({
  subarea: Yup.object().when('isEmpty', {
    is: true,
    then: Yup.object().shape({
      name: Yup.string(),
      code: Yup.string(),
    }).nullable(),
    otherwise: Yup.object().shape({
      name: Yup.string(),
      code: Yup.string(),
    }).typeError('Þú þarft að velja undirsvæði').required('Þú þarft að velja undirsvæði'),
  }),
  subplace: Yup.object().when('isEmpty', {
    is: true,
    then: Yup.object().shape({
      name: Yup.string(),
      code: Yup.string(),
    }).nullable(),
    otherwise: Yup.object().shape({
      name: Yup.string(),
      code: Yup.string(),
    }).typeError('Þú þarft að velja veiðistað').required('Þú þarft að velja veiðistað'),
  }),
  date: Yup.date().required('Þú þarft að velja dagsetningu').typeError('Þú þarft að velja dagsetningu'),
  fishType: Yup.string().when('isEmpty', {
    is: true,
    then: Yup.string().nullable(),
    otherwise: Yup.string('Þú þarft að velja tegund').oneOf(fishTypesEnum).required('Þú þarft að velja tegund'),
  }),
  baitType: Yup.string().when('isEmpty', {
    is: true,
    then: Yup.string().nullable(),
    otherwise: Yup.string('Þú þarft að agn').oneOf(baitTypesEnum).required('Þú þarft að velja agn'),
  }),
  baitName: Yup.string().max(100).nullable(),
  merkiNr: Yup.string().max(100).nullable(),
  ecoSystem: Yup.string().nullable(),
  released: Yup.boolean().when('isEmpty', {
    is: true,
    then: Yup.boolean().nullable(),
    otherwise: Yup
      .boolean('Þú þarft að velja hvort að fisk var sleppt')
      .required('Þú þarft að velja hvort að fisk var sleppt')
      .typeError('Þú þarft að velja hvort að fisk var sleppt'),
  }),
  sex: Yup.string().when('isEmpty', {
    is: true,
    then: Yup.string().nullable(),
    otherwise: Yup.string().oneOf(fishSexEnum).required('Þú þarft að velja kyn'),
  }),
  length: Yup.number().when('isEmpty', {
    is: true,
    then: Yup.number().nullable(),
    otherwise: Yup.number().when('weight', {
      is: (val) => val !== undefined && !Number.isNaN(val),
      then: Yup.number().nullable(),
      otherwise: Yup.number().typeError('Þú þarft að skrá lengd eða þyngd').required('Þú þarft að skrá lengd eða þyngd').min(0, 'Lengd má ekki vera minna en 0')
        .max(MAX_FISH_LENGTH, `Lengd má ekki vera hærri en ${MAX_FISH_LENGTH} cm`),
    }),
  }),
  weight: Yup.number().when('isEmpty', {
    is: true,
    then: Yup.number().nullable(),
    otherwise: Yup.number().when('length', {
      is: (val) => val !== undefined && !Number.isNaN(val),
      then: Yup.number().nullable(),
      otherwise: Yup.number().typeError('Þú þarft að skrá þyngd eða lengd').required('Þú þarft að skrá þyngd eða lengd').min(0, 'Þyngd má ekki vera minna en 0')
        .max(MAX_FISH_WEIGHT, `Þyngd má ekki vera hærri en ${MAX_FISH_WEIGHT} kg`),
    }),
  }),
  comment: Yup.string().nullable(),
  isEmpty: Yup.boolean(),
});

export {
  CreateLogSchema
};

