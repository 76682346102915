import * as initTypes from './init.types';

const initState = {
  appStart: false,
  appLoad: true,
};

const init = (state = initState, action) => {
  const { type } = action;
  switch (type) {
    case initTypes.APP_START:
      return { ...state, appStart: true, appLoad: true };
    case initTypes.APP_LOAD:
      return { ...state, appLoad: true };
    case initTypes.APP_READY:
      return { ...state, appLoad: false };
    default:
      return state;
  }
};

export default init;
