import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { ValidateReceiptEmailSchema } from '../../../../../schemas/booking.schema';
import { Input, Button } from '../../../../../components/ui';
import { getError } from '../../../../../utils/formik';
import './email-form.scss';

const EmailForm = ({ text, onSubmit }) => (
  <Formik
    initialValues={{
      email: '',
    }}
    validationSchema={ValidateReceiptEmailSchema}
    onSubmit={({ email }) => onSubmit(email)}
  >
    {({
      errors, touched,
    }) => (
      <Form noValidate>
        <div className='receipt-row'>
          <Input
            label='Netfang'
            name='email'
            type='email'
            error={getError(errors, touched, 'email')}
          />
          <Button type='submit'>
            {text}
          </Button>
        </div>
      </Form>
    )}
  </Formik>
);

EmailForm.propTypes = {
  text: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EmailForm;
