import React from 'react';
import { Switch } from 'react-router-dom';
import routes from '../pages';

const Router = () => (
  <Switch>
    {routes.map(({
      path, exact, routeType: RouteType, layout: Layout, component: Component, ...rest
    }) => (
      <RouteType
        key={path}
        path={path}
        exact={exact}
      >
        <Layout {...rest}>
          <Component />
        </Layout>
      </RouteType>
    ))}
  </Switch>
);

export default Router;
