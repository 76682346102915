import * as authTypes from './auth.types';

export const login = (data) => ({
  type: authTypes.LOGIN_REQUEST,
  data,
});

export const logout = () => ({
  type: authTypes.LOGOUT_REQUEST,
});

export const register = (data) => ({
  type: authTypes.REGISTER_REQUEST,
  data,
});

export const setSignupData = (data) => ({
  type: authTypes.SET_SIGNUP_DATA,
  signupData: data,
});

export const getMe = () => ({
  type: authTypes.GET_ME_REQUEST,
});

export const resendCode = () => ({
  type: authTypes.RESEND_VERIFY_EMAIL_CODE_REQUEST,
});

export const verifyEmail = (code) => ({
  type: authTypes.VERIFY_EMAIL_REQUEST,
  code,
});

export const forgotPassword = (email) => ({
  type: authTypes.FORGOT_PASSWORD_REQUEST,
  email,
});

export const resetPassword = (code, password) => ({
  type: authTypes.RESET_PASSWORD_REQUEST,
  code,
  password,
});

export const updateMe = (body) => ({
  type: authTypes.UPDATE_ME_REQUEST,
  body,
});
