import NotFound from './404/404.page';
import About from './about/about.page';
import AllAreas from './all-areas/all-areas.page';
import Area from './area/area.page';
import Areas from './areas/areas.page';
import Home from './home/home.page';
import LogAreas from './logs-areas/logs-areas.page';
import Logs from './logs/logs.page';
import Permits from './permits/permits.page';
import Privacy from './privacy/privacy.page';
import Terms from './terms/terms.page';

export default {
  Home,
  Areas,
  AllAreas,
  LogAreas,
  Logs,
  Area,
  About,
  Privacy,
  Terms,
  Permits,
  NotFound,
};
