import React from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import { ResetPasswordSchema } from '../../../schemas/auth.schema';
import { Input, Button } from '../../../components/ui';
import { getError } from '../../../utils/formik';

const ResetPasswordForm = ({ onSubmit }) => (
  <Formik
    initialValues={{
      password: '',
      code: '',
    }}
    validationSchema={ResetPasswordSchema}
    onSubmit={onSubmit}
  >
    {({ errors, touched }) => (
      <Form noValidate autoComplete='off'>
        <Input autoComplete='off' required label='Staðfestingarkóði' name='code' error={getError(errors, touched, 'code')} />
        <Input required label='Lykilorð' name='password' type='password' error={getError(errors, touched, 'password')} />
        <Button type='submit' block>Staðfesta</Button>
      </Form>
    )}
  </Formik>
);

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
