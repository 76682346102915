export const CREATE_CART_REQUEST = 'CREATE_CART_REQUEST';
export const CREATE_CART_SUCCESS = 'CREATE_CART_SUCCESS';
export const CREATE_CART_FAILURE = 'CREATE_CART_FAILURE';

export const CANCEL_BOOKING_REQUEST = 'CANCEL_BOOKING_REQUEST';
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_FAILURE = 'CANCEL_BOOKING_FAILURE';

export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

export const GET_MY_BOOKINGS_REQUEST = 'GET_MY_BOOKINGS_REQUEST';
export const GET_MY_BOOKINGS_SUCCESS = 'GET_MY_BOOKINGS_SUCCESS';
export const GET_MY_BOOKINGS_FAILURE = 'GET_MY_BOOKINGS_FAILURE';

export const GET_BOOKING_BY_ORDER_ID_REQUEST = 'GET_BOOKING_BY_ORDER_ID_REQUEST';
export const GET_BOOKING_BY_ORDER_ID_SUCCESS = 'GET_BOOKING_BY_ORDER_ID_SUCCESS';
export const GET_BOOKING_BY_ORDER_ID_FAILURE = 'GET_BOOKING_BY_ORDER_ID_FAILURE';

export const PAGINATE_BOOKINGS = 'PAGINATE_BOOKINGS';
export const RESET_BOOKING_PARAMS = 'RESET_BOOKING_PARAMS';
export const RESET_BOOKING_BY_ORDER_ID = 'RESET_BOOKING_BY_ORDER_ID';

export const VALIDATE_COUPON_REQUEST = 'VALIDATE_COUPON_REQUEST';
export const VALIDATE_COUPON_SUCCESS = 'VALIDATE_COUPON_SUCCESS';
export const VALIDATE_COUPON_FAILURE = 'VALIDATE_COUPON_FAILURE';

export const CLEAR_BOOKING = 'CLEAR_BOOKING';
export const CLEAR_COUPON = 'CLEAR_COUPON';
export const TICK = 'TICK';
export const SET_BOOKING_MESSAGE = 'SET_BOOKING_MESSAGE';

export const RESEND_PDF_PERMITS_EMAIL_REQUEST = 'RESEND_PDF_PERMITS_EMAIL_REQUEST';
export const RESEND_PDF_PERMITS_EMAIL_FAILURE = 'RESEND_PDF_PERMITS_EMAIL_FAILURE';
export const RESEND_PDF_PERMITS_EMAIL_SUCCESS = 'RESEND_PDF_PERMITS_EMAIL_SUCCESS';
