import api from './api';
import catchAsync from './error';

const validateCoupon = catchAsync(async (body) => {
  const response = await api.POST('/coupons/validate', body);
  return response.data;
});

export default {
  validateCoupon,
};
