import React from 'react';
import './checkbox.scss';
import { Field } from 'formik';
import PropTypes from 'prop-types';

export default function Checkbox({
  label, error, ...rest
}) {
  return (
    <div className='checkbox-wrapper'>
      <Field class={`checkbox ${error ? 'checkbox-error' : ''}`} id={rest.name} type='checkbox' {...rest} />
      <label htmlFor={rest.name}>{label}</label>
    </div>
  );
}

Checkbox.defaultProps = {
  error: '',
  label: '',
};

Checkbox.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
};
